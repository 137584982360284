import "./RecepcionTerceros.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function RecepcionTerceros() {
  const SCREEN_CODE = "fun0224_recepcion_terceros";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [filter, setFilter] = useState({
    vin: null,
    hojaRuta: null,
    proveedor: null,
    puntoOrigen: null,
    calle: null,
    fechaDeRecepcion: new Date(),
    columna: null,
    nivel: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];

  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {}
  const [vin, setVin] = useState([]);
  const [hojaRuta, setHojaRuta] = useState([]);
  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);
  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  const [puntoOrigen, setPuntoOrigen] = useState([]);
  const [loadingPuntoOrigen, setLoadingPuntoOrigen] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/vin",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "EL VIN no existe");
          }
          setVin(data);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadHojaRuta(vin: any) {
    try {
      setLoadingHojaRuta(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/hoja-ruta-by-vin",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: vin || null,
          }
        );

        if (status === 200) {
          setHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaRuta(false);
    }
    return [];
  }
  async function loadReport(hojaRuta: any) {}
  async function loadProveedor() {
    try {
      setLoadingProveedor(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/transportistas",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          data.unshift({ id: "TODOS", descripcion: "TODOS" });
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
    return [];
  }
  async function loadPuntoOrigen() {
    try {
      setLoadingPuntoOrigen(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/punto-playa",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          data.unshift({ id: "TODOS", descripcion: "TODOS" });
          setPuntoOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPuntoOrigen(false);
    }
    return [];
  }
  async function loadCalle() {
    try {
      setLoadingCalle(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/calles",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    return [];
  }
  async function loadColumna(calle: any) {
    try {
      setLoadingColumna(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/columnas",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }
  async function loadNivel(columna: any) {
    try {
      setLoadingNivel(true);
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/niveles",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: filter?.calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }
  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.country) {
        try {
          const hojasRuta = await loadHojaRuta(null);
          const proveedores = await loadProveedor();
          const puntoOrigen = await loadPuntoOrigen();
          const calles = await loadCalle();
          setFilter({
            ...filter,
            hojaRuta: hojasRuta[0],
            proveedor: proveedores[0],
            puntoOrigen: puntoOrigen[0],
          });
          handleCalleChange(calles[0]);
          handleHojaRutaChange(hojasRuta[0]);
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [
    securityFilters.securityValues.country,
    securityFilters.securityValues.account,
    securityFilters.securityValues.subaccount,
  ]);

  async function handleVinChange(value: any) {
    try {
      const hojasRutas = await loadHojaRuta(value);
      setFilter({
        ...filter,
        vin: value,
      });
      handleHojaRutaChange(hojasRutas[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCalleChange(value: any) {
    try {
      const columnas = await loadColumna(value);
      setFilter({
        ...filter,
        calle: value,
      });
      handleColumnaChange(columnas[0]);
      setColumna(columnas);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleColumnaChange(value: any) {
    try {
      const niveles = await loadNivel(value);
      setFilter({
        ...filter,
        columna: value,
        nivel: niveles[0],
      });
      setColumna(niveles);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleHojaRutaChange(value: any) {
    try {
      const reporte = await loadReport(value);
      setFilter({
        ...filter,
        hojaRuta: value,
      });
      setReporte(reporte);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="recepcion-terceros">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"327"} alt="Taller" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <SipcoAutocomplete
                  value={filter?.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => handleVinChange(e.value)}
                  field="id"
                  maxLength={17}
                  forceSelection
                />
                <label>
                  <LanguageProvider id={"2521"} alt="Proveedor " />
                </label>
                <SipcoDropdown
                  value={filter.proveedor}
                  options={proveedor}
                  onChange={(e) => setFilter({ ...filter, proveedor: e.value })}
                  optionLabel="descripcion"
                  loading={loadingProveedor}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta " />
                </label>
                <SipcoDropdown
                  value={filter.hojaRuta}
                  options={hojaRuta}
                  onChange={(e) => handleHojaRutaChange(e.value)}
                  optionLabel="id"
                  loading={loadingHojaRuta}
                  filter
                />
                <label>
                  <LanguageProvider id={"22908"} alt="Punto orígen " />
                </label>
                <SipcoDropdown
                  value={filter.puntoOrigen}
                  options={puntoOrigen}
                  onChange={(e) =>
                    setFilter({ ...filter, puntoOrigen: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingPuntoOrigen}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Recepción de Terceros
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "34",
                    alt: "Procesar o guardar cambios",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <SipcoColumn field="0" header={"País"} sortable filter />
          <SipcoColumn field="1" header={"Cliente"} sortable filter />
          <SipcoColumn field="2" header={"Cuenta"} sortable filter />
          <SipcoColumn field="3" header={"VIN"} sortable filter />
          <SipcoColumn field="4" header={"Punto orígen"} sortable filter />
          <SipcoColumn field="5" header={"Nro. Hoja Ruta"} sortable filter />
          <SipcoColumn field="6" header={"Proveedor"} sortable filter />
          <SipcoColumn field="7" header={"Nro. Oferta Servicio"} />
        </SipcoDataTable>
        <Divider />
        <Accordion>
          <AccordionTab
            header={
              <LanguageProvider
                id={"884"}
                alt="Ingreso de Datos para la Recepción"
              />
            }
          >
            <div className="filter-options">
              <div className={"sipco-options-line"}>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"921"} alt="Ubicaciones" />
                  </label>
                  <SipcoDropdown
                    value={filter.calle}
                    options={calle}
                    onChange={(e) => handleCalleChange(e.value)}
                    optionLabel="descripcion"
                    loading={loadingCalle}
                    filter
                  />
                  <label>
                    <LanguageProvider id={"18071"} alt="Fecha de Recepcion" />
                  </label>
                  <SipcoCalendar
                    value={filter.fechaDeRecepcion}
                    onChange={(e) => {
                      setFilter({ ...filter, fechaDeRecepcion: e.value });
                    }}
                    showButtonBar
                    showIcon
                    showTime={true}
                    //dateFormat="dd/mm/yy"
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"991"} alt="Columna" />
                  </label>
                  <SipcoDropdown
                    value={filter.columna}
                    options={columna}
                    onChange={(e) => handleColumnaChange(e.value)}
                    optionLabel="descripcion"
                    loading={loadingColumna}
                    filter
                  />
                  <label>
                    <LanguageProvider id={"992"} alt="Nivel" />
                  </label>
                  <SipcoDropdown
                    value={filter.nivel}
                    options={nivel}
                    onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                    optionLabel="descripcion"
                    loading={loadingNivel}
                    filter
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
    </div>
  );
}
