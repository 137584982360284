import "./TiemposEntrega.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import { useInactivoColor } from "../../../hooks/useInactivoColor";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TiemposEntrega() {
  const SCREEN_CODE = "con0029_tiempos_de_entrega";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1436, 1342, 10774, 266, 396, 10904];
  const filterNames = [];
  const filterColumnsId = [
    1491, 2941, 2892, 25188, 9934, 490, 1446, 1447, 1448, 9809, 23888, 17759,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 275, 367, 396, 490, 1182, 1283, 1284, 1436, 1446, 1447, 1448, 1491,
    1791, 2621, 2622, 2892, 2941, 5044, 5583, 9809, 9934, 10898, 10904, 17759,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    tipoFecha: true,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    mercadoDesde: null,
    mercadoHasta: null,
    concesionarioDesde: null,
    concesionarioHasta: null,
    marcaDesde: null,
    marcaHasta: null,
    direccionEntregaDesde: null,
    direccionEntregaHasta: null,
  });

  const [loading, setLoading] = useState(false);

  const inactivoColor = useInactivoColor();

  const [mercados, setMercados] = useState([]);
  const [concesionarios, setConcesionarios] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [dirEntrega, setDirEntrega] = useState([]);

  const [loadingMarcas, setLoadingMarcas] = useState(false);
  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  const [loadingMercados, setLoadingMercados] = useState(false);
  const [loadingDirEntrega, setLoadingDirEntrega] = useState(false);

  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/tiempo-entrega/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  async function loadConcesionarios() {
    setLoadingConcesionario(true);
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/tiempo-entrega/concesionario",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setConcesionarios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
  }
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/tiempo-entrega/marcas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  async function loadDirEntregaDesde() {
    setLoadingDirEntrega(true);
    try {
      if (
        filter.concesionarioDesde &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const selectedConcesionario = filter.concesionarioDesde;
        const { status, data } = await sipcoAxiosService.post(
          "/tiempo-entrega/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            concesionario: selectedConcesionario,
          }
        );
        if (status === 200) {
          setDirEntrega(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDirEntrega(false);
    }
  }
  async function loadDirEntregaHasta() {
    setLoadingDirEntrega(true);
    try {
      if (
        filter.concesionarioDesde &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const selectedConcesionario = filter.concesionarioHasta;
        const { status, data } = await sipcoAxiosService.post(
          "/tiempo-entrega/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            concesionario: selectedConcesionario,
          }
        );
        if (status === 200) {
          setDirEntrega(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDirEntrega(false);
    }
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "tiempo-entrega/report",
        {
          tiemposEntregaDto: {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            fechaDespachoDesde: filter.fechaInicio,
            fechaDespachoHasta: filter.fechaFinal,
            mercadoDesde: filter.mercadoDesde,
            mercadoHasta: filter.mercadoHasta,
            concesionarioDesde: filter.concesionarioDesde,
            concesionarioHasta: filter.concesionarioHasta,
            marcaDesde: filter.marcaDesde,
            marcaHasta: filter.marcaHasta,
            dirEntregaDesde: filter.direccionEntregaDesde,
            dirEntregaHasta: filter.direccionEntregaHasta,
            fechaPor: filter.tipoFecha,
            codUsuario: user.userName,
          },
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const mercados = await loadMercados();
          const concesionarios = await loadConcesionarios();
          const marcas = await loadMarcas();
          setFilter({
            ...filter,
            mercadoDesde: mercados[0],
            mercadoHasta: mercados[mercados.length - 1],
            concesionarioDesde: concesionarios[0],
            concesionarioHasta: concesionarios[concesionarios.length - 1],
            marcaDesde: marcas[0],
            marcaHasta: marcas[marcas.length - 1],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  useEffect(() => {
    async function handleConcecionarioChange() {
      if (filter.concesionarioDesde != null) {
        try {
          const dirEntregaDesde = await loadDirEntregaDesde();
          const dirEntregaHasta = await loadDirEntregaHasta();
          // setFilter({
          //   ...filter,
          //   direccionEntregaDesde: dirEntregaDesde[0],
          //   direccionEntregaHasta: dirEntregaHasta[0],
          // });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleConcecionarioChange();
  }, [filter.concesionarioDesde]);

  return (
    <div className="tiempos-entrega">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.tipoFecha}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFecha: true });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"1284"} alt="Liberação" />
                </label>
                <RadioButton
                  checked={!filter.tipoFecha}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFecha: false });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"275"} alt="Remitos" />
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <SipcoDropdown
                  value={filter.mercadoDesde}
                  options={mercados}
                  onChange={(e) =>
                    setFilter({ ...filter, mercadoDesde: e.value })
                  }
                  optionLabel="descripcion"
                  disabled={!securityFilters.securityValues.country}
                  loading={loadingMercados}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <SipcoDropdown
                  value={filter.mercadoHasta}
                  options={mercados}
                  onChange={(e) =>
                    setFilter({ ...filter, mercadoHasta: e.value })
                  }
                  optionLabel="descripcion"
                  disabled={!securityFilters.securityValues.country}
                  filter
                  loading={loadingMercados}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario" />
                </label>
                <SipcoDropdown
                  value={filter.concesionarioDesde}
                  options={concesionarios}
                  onChange={(e) =>
                    setFilter({ ...filter, concesionarioDesde: e.value })
                  }
                  itemTemplate={inactivoColor}
                  optionLabel="descripcionFull"
                  disabled={!securityFilters.securityValues.country}
                  filter
                  loading={loadingConcesionario}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario" />
                </label>
                <SipcoDropdown
                  value={filter.concesionarioHasta}
                  options={concesionarios}
                  onChange={(e) =>
                    setFilter({ ...filter, concesionarioHasta: e.value })
                  }
                  itemTemplate={inactivoColor}
                  optionLabel="descripcionFull"
                  disabled={!securityFilters.securityValues.country}
                  loading={loadingConcesionario}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filter.marcaDesde}
                  options={marcas}
                  onChange={(e) =>
                    setFilter({ ...filter, marcaDesde: e.value })
                  }
                  optionLabel="descripcion"
                  disabled={!securityFilters.securityValues.country}
                  loading={loadingMarcas}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filter.marcaHasta}
                  options={marcas}
                  onChange={(e) =>
                    setFilter({ ...filter, marcaHasta: e.value })
                  }
                  optionLabel="descripcion"
                  disabled={!securityFilters.securityValues.country}
                  loading={loadingMarcas}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10904"} alt="Dir. Entrega" />
                </label>
                <SipcoDropdown
                  value={filter.direccionEntregaDesde}
                  options={dirEntrega}
                  onChange={(e) =>
                    setFilter({ ...filter, direccionEntregaDesde: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingDirEntrega}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10904"} alt="Dir. Entrega" />
                </label>
                <SipcoDropdown
                  value={filter.direccionEntregaHasta}
                  options={dirEntrega}
                  onChange={(e) =>
                    setFilter({ ...filter, direccionEntregaHasta: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingDirEntrega}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id="1437"
                  alt="Consulta de Tiempos de Entrega"
                />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"TiempoEntrega"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default TiemposEntrega;
