import "./PedidosConfirmadosJd.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function PedidosConfirmadosJd() {
  const SCREEN_CODE = "con0100_pedidos_confirmados_jd";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [pedidosConfirmadosJd, setPedidosConfirmadosJd] = useState(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const data = convertDateObjects(pedidosConfirmadosJd?.filas);
  const columns = [];
  const filterLabelId = [2940, 4170];
  const filterNames = [];
  const filterColumnsId = [
    924, 3344, 1491, 6781, 13358, 3184, 4170, 25029, 25030, 25031, 25032, 25033,
    25034, 25035, 25036, 25037, 25038, 25039, 25040, 25041, 25042, 25045, 25043,
    25044,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 54, 65, 159, 164, 396, 448, 504, 6781, 740, 743, 924, 1131, 1342, 1491,
    13358, 2139, 2621, 2622, 2941, 3184, 3344, 3625, 4170, 4285, 4655, 4724,
    5044, 10136, 10502, 10902, 11787, 14561, 15862, 16453, 16454, 16455, 16456,
    16457, 16458, 16459, 16460, 367, 1850, 1849, 5592, 18985, 22927, 22928,
    23020, 23097, 24859, 3206, 4145, 2940, 4170, 25029, 25030, 25031, 25032,
    25033, 25034, 25035, 25036, 25037, 25038, 25039, 25040, 25041, 25042, 25043,
    25044, 25045,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    numeroHojaRuta: "",
    numeroPedido: "",
  });
  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/pedidos-confirmados-jd/reporte",
        {
          pais: securityFilters.securityValues.country,
          numeroHojaRuta: filter.numeroHojaRuta || "",
          numeroPedido: filter.numeroPedido || "",
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="pedidos-confirmados-jd">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Nro. Hoja de Ruta</label>
                <SipcoInputText
                  value={filter.numeroHojaRuta}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroHojaRuta: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>Número Pedido</label>
                <SipcoInputText
                  value={filter.numeroPedido}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroPedido: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Consuta Interface JD - HR Confirmada
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consuta Interface JD - HR Confirmada"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte?.filas}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loading}
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="11" header={columnsNames[10].label} />
          <SipcoColumn field="12" header={columnsNames[11].label} />
          <SipcoColumn field="13" header={columnsNames[12].label} />
          <SipcoColumn field="14.date" header={columnsNames[13].label} />
          <SipcoColumn field="15" header={columnsNames[14].label} />
          <SipcoColumn field="16" header={columnsNames[15].label} />
          <SipcoColumn field="17" header={columnsNames[16].label} />
          <SipcoColumn field="18" header={columnsNames[17].label} />
          <SipcoColumn field="19" header={columnsNames[18].label} />
          <SipcoColumn field="20.date" header={columnsNames[19].label} />
          <SipcoColumn field="21.date" header={columnsNames[20].label} />
          <SipcoColumn field="22.date" header={columnsNames[21].label} />
          // <SipcoColumn field="23" header={columnsNames[22].label} />
          // <SipcoColumn field="24" header={columnsNames[23].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default PedidosConfirmadosJd;
