import React, { useEffect, useState } from "react";

import { Card } from "primereact/card";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";

const DatosDelRemito: React.FC<{ data: any }> = ({ data }) => {
  const [reporte, setReporte] = useState({});
  useEffect(() => {
    setReporte(data);
  }, [data]);
  //  const filterColumnsIdLectora = [1491, 1569, 5788, 8380, 2521, 1669];

  // const columNames = useFilterColumnsIds(filterColumnsIdLectora);

  return (
    <Card>
      <div className="filter-options-column">
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"845"} alt="Despacho" />
            </label>
            <SipcoInputText value={data?.fechaDespacho?.date} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16925"} alt="Fec. Ant. Retorno" />
            </label>
            <SipcoInputText value={data?.fechaAnticipoRetorno?.date} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"295"} alt="Retorno" />
            </label>
            <SipcoInputText value={data?.fechaRetorno?.date} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10868"} alt="Cod. Dir. Entrega" />
            </label>
            <SipcoInputText value={data?.direccionEntrega?.id} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"3069"} alt="Dirección" />
            </label>
            <SipcoInputText value={data?.direccionEntrega?.direccion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10871"} alt="Cod. Concesionario" />
            </label>
            <SipcoInputText value={data?.concesionario?.id} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"15818"} alt="Fecha Confirmación" />
            </label>
            <SipcoInputText />
          </div>
        </div>
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"396"} alt="Marca" />
            </label>
            <SipcoInputText value={data?.vin?.marca?.descripcion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"23864"} alt="Modelo" />
            </label>
            <SipcoInputText value={data?.vin?.modelo?.descripcion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2496"} alt="Origen" />
            </label>
            <SipcoInputText value={data?.vin?.origen?.descripcion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2140"} alt="Color" />
            </label>
            <SipcoInputText value={data?.vin?.color?.descripcion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1491"} alt="VIN" />
            </label>
            <SipcoInputText value={data?.vin?.id} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2941"} alt="Concesionario" />
            </label>
            <SipcoInputText value={data?.concesionario?.descripcion} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"20804"} alt="Estado" />
            </label>
            <SipcoInputText value={data?.estadoConfirmacion?.descripcion} />
          </div>
        </div>
      </div>
      <div className="tags">
        <Tag
          value={<LanguageProvider id={"17762"} alt={"Conforme"} />}
          style={{ background: "#66ff00", color: "black" }}
        />
        <Tag
          value={<LanguageProvider id={"17763"} alt={"No Conforme"} />}
          style={{ background: "#ffff00", color: "black" }}
        />
        <Tag
          value={<LanguageProvider id={"17764"} alt={"No Recibido"} />}
          style={{ background: "#ff0000" }}
        />
      </div>
    </Card>
  );
};
export default DatosDelRemito;
