import "./ReingresoStock.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import PanelCustom from "../../../../src/components/panel-custom/PanelCustom";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ReingresoStock() {
  const SCREEN_CODE = "fun0062_reingreso_stock";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);

  const [dataTableValue, setDataTableValue] = useState([]);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    vin: [],
    playas: [],
    calle: [],
    columna: [],
    nivel: [],
    nota: "",
    cobraFlete: false,
    tramo: [],
    provincia: [],
    ciudad: [],
    transportista: [],
    nota2: "",
  });
  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  const [playa, setPlaya] = useState([]);
  const [loadingPlaya, setLoadingPlaya] = useState(false);

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);

  const [tramo, setTramo] = useState([]);
  const [loadingTramo, setLoadingTramo] = useState(false);

  const [provincia, setProvincia] = useState([]);
  const [loadingProvincia, setLoadingProvincia] = useState(false);

  const [ciudad, setCiudad] = useState([]);
  const [loadingCiudad, setLoadingCiudad] = useState(false);

  const [transportista, setTransportista] = useState([]);
  const [loadingTransportista, setLoadingTransportista] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/vin",
          {
            vin: {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              id: query,
            },
          }
        );
        if (status === 200) {
          const response = [data].map((x: any) => ({
            ...x,
            label: `${x.id}`,
          }));

          console.log("Response", response);
          setVin(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadTramo() {
    try {
      setLoadingTramo(true);
      if (
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.country != null &&
        filter.cobraFlete === true
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/tramo",
          {
            cliente: securityFilters.securityValues.client,
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setTramo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTramo(false);
    }
    return [];
  }
  async function loadProvincia() {
    try {
      setLoadingProvincia(true);
      if (
        securityFilters?.securityValues?.country != null &&
        filter.cobraFlete === true
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/provincia",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setProvincia(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvincia(false);
    }
    return [];
  }
  async function loadCiudad() {
    try {
      setLoadingCiudad(true);
      if (filter.provincia != null && filter.cobraFlete === true) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/ciudad",
          {
            provincia: filter.provincia,
          }
        );

        if (status === 200) {
          setCiudad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudad(false);
    }
    return [];
  }
  async function loadTransportista() {
    try {
      setLoadingTransportista(true);
      if (
        securityFilters?.securityValues?.country != null &&
        filter.cobraFlete === true
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/proveedor",
          {
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: filter.playas,
          }
        );

        if (status === 200) {
          setTransportista(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
    return [];
  }

  const usuarioLogado = JSON.parse(localStorage.getItem("loginData"));

  async function loadSubcuenta() {
    try {
      setLoadingPlaya(true);
      if (
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/subcuenta",
          {
            usuario: {
              id: usuarioLogado.userName,
              idioma: {
                id: usuarioLogado.idioma.codIdioma,
              },
              nivel: usuarioLogado.nivel,
            },
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          setPlaya(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
    return [];
  }
  async function loadCalle() {
    try {
      setLoadingCalle(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.playas != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/calle",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: filter.playas,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    return [];
  }
  async function loadColumna() {
    try {
      setLoadingColumna(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.playas != null &&
        filter.calle != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/columna",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: filter?.playas,
            calle: filter?.calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }

  async function loadNivel() {
    try {
      setLoadingNivel(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.calle != null &&
        filter.playas != null &&
        filter.columna != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/nivel",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: filter.playas,
            calle: filter?.calle,
            columna: filter?.columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }

  useEffect(() => {
    async function handleProvincia() {
      if (filter.provincia != null) {
        try {
          const ciudads = await loadCiudad();
          setFilter({
            ...filter,
            ciudad: ciudads[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleProvincia();
  }, [filter.provincia]);

  useEffect(() => {
    async function handleSubcuenta() {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.playas != null
      ) {
        try {
          const calles = await loadCalle();
          setFilter({
            ...filter,
            calle: calles[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleSubcuenta();
  }, [filter.playas]);

  useEffect(() => {
    async function handleCalles() {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.playas != null &&
        filter.calle != null
      ) {
        try {
          const columnas = await loadColumna();
          setFilter({
            ...filter,
            columna: columnas[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleCalles();
  }, [filter.calle]);

  useEffect(() => {
    async function handleNiveles() {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        filter.playas != null &&
        filter.calle != null &&
        filter.columna != null
      ) {
        try {
          const niveles = await loadNivel();
          setFilter({
            ...filter,
            nivel: niveles[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleNiveles();
  }, [filter.columna]);

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters.securityValues.client != null &&
        securityFilters.securityValues.account != null
      ) {
        try {
          const playasDestino = await loadSubcuenta();
          setFilter({
            ...filter,
            playas: playasDestino[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  useEffect(() => {
    async function initializeCobra() {
      if (
        securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null &&
        filter.cobraFlete === true
      ) {
        try {
          const tramos = await loadTramo();
          const provincia = await loadProvincia();
          const transportistas = await loadTransportista();

          setFilter({
            ...filter,
            tramo: tramos[0],
            provincia: provincia[0],
            transportista: transportistas[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initializeCobra();
  }, [securityFilters.securityValues, filter.cobraFlete]);

  return (
    <div className="reingreso-stock">
      <Toast ref={toast} position="top-center" />
      <Filter
        //onSearch={     }
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={"318"} alt="Reingreso Gefco" />}>
        <div className="fieldset-content">
          <div>
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                  </label>
                  <SipcoAutocomplete
                    value={filter.vin}
                    suggestions={vin}
                    completeMethod={searchVin}
                    onChange={(e) =>
                      setFilter({ ...filter, vin: e.value || null })
                    }
                    field="label"
                    forceSelection
                  />
                </div>
              </div>
            </div>

            <div className="panel-container">
              <div className="panel">
                <PanelCustom
                  header={LanguageProvider({
                    id: "1136",
                    alt: "Datos del Vehículo",
                  })}
                >
                  <div className="form">
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"396"} alt="Marca" />
                        </label>
                        <SipcoInputText />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"2139"} alt="Modelo" />
                        </label>
                        <SipcoInputText />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"2496"} alt="Origen" />
                        </label>
                        <SipcoInputText />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"2140"} alt="Color" />
                        </label>
                        <SipcoInputText />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"13335"} alt="Motor" />
                        </label>
                        <SipcoInputText />
                      </div>
                    </div>
                  </div>
                </PanelCustom>
              </div>
              <div className="panel">
                <PanelCustom
                  header={LanguageProvider({
                    id: "1138",
                    alt: "Ingrese los Datos para el Reingreso del Vehículo",
                  })}
                >
                  <div className="form">
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"441"} alt="Playas" />
                        </label>
                        <SipcoDropdown
                          value={filter.playas}
                          options={playa}
                          onChange={(e) =>
                            setFilter({ ...filter, playas: e.value })
                          }
                          optionLabel="descripcion"
                          loading={loadingPlaya}
                          filter
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"990"} alt="Calle" />
                        </label>
                        <SipcoDropdown
                          value={filter.calle}
                          options={calle}
                          onChange={(e) =>
                            setFilter({ ...filter, calle: e.value })
                          }
                          optionLabel="descripcion"
                          loading={loadingCalle}
                          filter
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"991"} alt="Columna" />
                        </label>
                        <SipcoDropdown
                          value={filter.columna}
                          options={columna}
                          onChange={(e) =>
                            setFilter({ ...filter, columna: e.value })
                          }
                          optionLabel="descripcion"
                          loading={loadingColumna}
                          filter
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"992"} alt="Nivel" />
                        </label>
                        <SipcoDropdown
                          value={filter.nivel}
                          options={nivel}
                          onChange={(e) =>
                            setFilter({ ...filter, nivel: e.value })
                          }
                          optionLabel="descripcion"
                          loading={loadingNivel}
                          filter
                        />{" "}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"1410"} alt="Nota" />
                        </label>
                        <SipcoInputText
                          value={filter.nota}
                          onChange={(e) =>
                            setFilter({ ...filter, nota: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <Card
                    header={
                      <div className="flex items-center gap-2">
                        <Checkbox
                          checked={filter.cobraFlete}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              cobraFlete: e.target.checked,
                            })
                          }
                        />

                        <label className="text-lg font-bold">
                          <LanguageProvider id={"1037"} alt="Cobra Flete ?" />
                        </label>
                      </div>
                    }
                    className="card"
                  >
                    <div className="form">
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"10672"} alt="Tramo" />
                          </label>
                          <SipcoDropdown
                            value={filter.tramo}
                            options={tramo}
                            onChange={(e) =>
                              setFilter({ ...filter, tramo: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTramo}
                            filter
                            disabled={!filter.cobraFlete}
                          />{" "}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"159"} alt="Provincia" />
                          </label>
                          <SipcoDropdown
                            value={filter.provincia}
                            options={provincia}
                            onChange={(e) =>
                              setFilter({ ...filter, provincia: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingProvincia}
                            filter
                            disabled={!filter.cobraFlete}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"164"} alt="Ciudad" />
                          </label>
                          <SipcoDropdown
                            value={filter.ciudad}
                            options={ciudad}
                            onChange={(e) =>
                              setFilter({ ...filter, ciudad: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingCiudad}
                            filter
                            disabled={!filter.cobraFlete}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"754"} alt="Transportista" />
                          </label>
                          <SipcoDropdown
                            value={filter.transportista}
                            options={transportista}
                            onChange={(e) =>
                              setFilter({ ...filter, transportista: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTransportista}
                            filter
                            disabled={!filter.cobraFlete}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"1410"} alt="Nota" />
                          </label>
                          <SipcoInputText
                            value={filter.nota2}
                            disabled={!filter.cobraFlete}
                            onChange={(e) =>
                              setFilter({ ...filter, nota2: e.target.value })
                            }
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </Card>
                </PanelCustom>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({ id: "23824", alt: "Alta" })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default ReingresoStock;
