import "./VehiculosPendientesTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Tag } from "primereact/tag";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function VehiculosPendientesTaller() {
  const SCREEN_CODE = "con0049_vehiculos_pendiente_taller";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();

  const dt = useRef(null);
  const filterLabelId = [396, 901];
  const filterNames = [];
  const filterColumnsId = [
    396, 17088, 321, 2139, 17091, 17092, 5701, 9857, 3232, 9904, 2520, 1043,
    10541, 16243, 17028, 336, 3625, 16480, 2519, 16950, 2520, 19689, 2496,
    19690, 14624, 11370, 16553, 16478,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 187, 321, 336, 396, 653, 654, 901, 1043, 1791, 2139, 2502, 2519,
    2520, 3232, 3625, 4791, 5044, 5583, 5701, 9857, 9904, 9905, 10541, 16243,
    16480, 16950, 17028, 17088, 17091, 17092, 2496, 14624, 11370, 16553, 16478,
    19689, 19690,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    vin: "",
    marca: null,
  });

  const [vins, setVins] = useState([]);
  const [loadingVins, setLoadingVins] = useState(false);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(vin: any) {
    setLoadingVins(true);
    try {
      if (
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.account?.id &&
        vin
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/vehiculos-pendientes-taller/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codCliente: securityFilters.securityValues.client.id,
              vin: vin,
            },
          }
        );

        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVins(vin);
          return vin;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingVins(false);
    }
  }

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/vehiculos-pendientes-taller/combo/marca",
        {
          params: {
            codPais: securityFilters?.securityValues?.country?.id,
            codCliente: securityFilters?.securityValues?.client?.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });
        setMarcas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  async function loadClients() {
    setLoadingClients(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/screens/get-cliente-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              screenCode: SCREEN_CODE,
              level: user.nivel,
            },
          }
        );
        if (status === 200) {
          setClients(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClients(false);
    }
  }

  const [reporte, setReporte] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        marca: filter.marca.id ?? null,
        marcaDescripcion: filter.marca.descripcion ?? null,
        vin: filter.vin,
        clientes: clients,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/vehiculos-pendientes-taller/report",
        filters
      );
      if (status === 200) {
        // console.log("Consulta Loading Report", data);
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const marcas = await loadMarca();
        await loadClients();
        setFilter({
          ...filter,
          marca: marcas[0] ?? null,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="vehiculos-pendientes-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filter?.marca}
                  options={marcas}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingMarcas}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="9854" alt="Vehiculos Pendiente Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte?.filas}
                  columns={columnsNames}
                  screenName={"Vehiculos Pendientes Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
          rowClassName={getRowStyles}
        >
          <SipcoColumn field="marca" header={"Marca"} sortable filter />
          <SipcoColumn field="vin" header={"Vin"} sortable filter />
          <SipcoColumn field="remitoDespacho" header={"Remito de Despacho"} />
          <SipcoColumn field="modelo" header={"Modelo"} sortable filter />
          <SipcoColumn
            field="fechaPendienteIngreso"
            header={"Fecha Pend. Ing."}
          />
          <SipcoColumn
            field="descripcionPendienteIngreso"
            header={"Desc Pend Ingreso"}
          />
          <SipcoColumn field="codigoOrdenReparacion" header={"Nro. Orden"} />
          <SipcoColumn field="fechaOrden" header={"Fecha orden Rep."} />
          <SipcoColumn field="fechaIngreso" header={"Fecha Ingreso"} />
          <SipcoColumn field="fechaPromesaEntrega" header={"Fecha Promesa"} />
          <SipcoColumn field="fechaCierreReal" header={"Fecha Cierre"} />
          <SipcoColumn field="codigoGravedad" header={"Daños del VIN"} />
          <SipcoColumn field="tipoDanio" header={"Descripción Daño"} />
          <SipcoColumn field="partesDanios" header={"Parte dañada"} />
          <SipcoColumn field="fechaReparado" header={"Fecha Reparado"} />
          <SipcoColumn field="responsables" header={"Responsable"} />
          <SipcoColumn field="ordenReparacionObs" header={"Observaciones"} />
          <SipcoColumn field="detalleNroPedido" header={"Nro."} />
          <SipcoColumn field="ordenReparacionObs" header={"Descripción Daño"} />
          <SipcoColumn field="detalleNroPedido" header={"Nro. Pedido PSA"} />
          <SipcoColumn field="fechaCompra" header={"Fecha Compra"} />
          <SipcoColumn
            field="fechaPrevista"
            header={"Fec, Prev. Entrega PSA"}
          />
          <SipcoColumn field="fechaCierre" header={"Fecha Cierre"} />
          <SipcoColumn
            field="usuarioPriorizacion"
            header={"Usuario priorización"}
          />
          <SipcoColumn field="origenAuto" header={"Origen"} sortable filter />
          <SipcoColumn
            field="fechaPriorizacion"
            header={"Fecha Priorización"}
          />
          <SipcoColumn
            field="fecEmisionRemito"
            header={"Fecha Emisión Remito"}
          />
          <SipcoColumn field="ordenOrigen" header={"Orden Origen"} />
          <SipcoColumn
            field="fechaUltimoIngresoAPlaya"
            header={"Último ingresso playa"}
          />
          <SipcoColumn field="numeroIngreso" header={"Número de ingreso"} />
        </SipcoDataTable>
        <Tag
          style={{ background: "#ff0000", marginBottom: "10px" }}
          value={
            <LanguageProvider
              id={"9905"}
              alt="Vehiculos con Fecha de Promesa de la Orden de Reparación retrasada"
            />
          }
        />
      </Panel>
    </div>
  );
}
export default VehiculosPendientesTaller;

const getRowStyles = (data) => {
  let className = "";

  if (data.fechaPromesaEntrega != null) {
    const fechaHoy = new Date();
    const fechaPromesa = new Date(data.fechaPromesaEntrega);
    if (fechaPromesa < fechaHoy) {
      className = "vehiculo-con-fecha-promesa-retrasada";
    }
  }

  return className.trim();
};
