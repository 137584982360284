import "./HistoriaVin.scss";

import * as XLSX from "xlsx";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function HistoriaVin() {
  const SCREEN_CODE = "con0036_historia_del_vin";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const filterColumnsId = [
    1491, 16478, 1151, 16796, 16797, 4243, 9965, 396, 2139, 4724, 2140, 1317,
    2205, 5701, 5788, 1684, 31, 16798, 2496, 4137, 4587, 17012, 3568, 4061,
    24997,
  ];
  const filterColumnsIdRepuesto = [
    1491, 16478, 20804, 16796, 16797, 4243, 1384, 23888, 2139, 4724, 2140,
    11250, 2205, 5701, 5788, 2020, 3756, 3773, 8818, 2496, 22724, 22725, 17012,
    3568, 4061, 24997,
  ];
  const columnsNames = [];
  const columnsNamesRepuestos = [];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  for (const element of filterColumnsIdRepuesto) {
    columnsNamesRepuestos.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    2, 31, 54, 65, 187, 327, 396, 467, 468, 681, 1002, 1151, 1182, 1253, 1317,
    1491, 1684, 1791, 2020, 2139, 2140, 2205, 2248, 2249, 2496, 2502, 3231,
    3232, 3233, 4137, 4243, 4587, 4724, 4791, 5044, 5583, 5701, 5788, 8818,
    9965, 10531, 16478, 16796, 16797, 16798, 17012, 3568, 4061, 24997,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [loadingTalleres, setLoadingTalleres] = useState(false);
  const [talleres, setTalleres] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);

  const [loadingServicesReportData, setLoadingServicesReportData] =
    useState(false);
  const [loadingRepuestosReportData, setLoadingRepuestosReportData] =
    useState(false);

  const [servicesReportData, setServicesReportData] = useState(null);
  const [repuestosReportData, setRepuestosReportData] = useState(null);

  const [filter, setFilter] = useState({
    taller: null,
    estado: null,
    vin: "",
    aplicarFiltroFecha: false,
    tipoFiltro: "Fecha Ingreso",
    fechaInicio: null,
    fechaFinal: null,
  });

  async function loadTaller() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/historia-del-vin/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/historia-del-vin/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }
  async function loadServicesReport() {
    setLoadingServicesReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        vin: filter?.vin || null,
        estado: null,
        estadoDescripcion: filter?.estado?.descripcion || null,
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        filtroPorFecha: filter.tipoFiltro,
      };

      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "historia-del-vin/findServiciosBy",
        filters
      );
      if (status === 200) {
        setFilterExport();
        setServicesReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServicesReportData(false);
    }
  }
  async function loadRepuestosReport() {
    setLoadingRepuestosReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        vin: filter?.vin || null,
        estado: null,
        estadoDescripcion: filter?.estado?.descripcion || null,
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        filtroPorFecha: filter.tipoFiltro,
      };

      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "historia-del-vin/findRepuestosBy",
        filters
      );
      if (status === 200) {
        setRepuestosReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRepuestosReportData(false);
    }
  }

  function getTableHeaders() {
    const columns = dt.current?.props.children || [];
    return columns.map((col) => ({
      header: col.props.header,
      field: col.props.field,
    }));
  }

  function formatExportData(data, headers) {
    return data.map((row) => {
      let formattedRow = {};
      headers.forEach(({ header, field }) => {
        formattedRow[header] = row[field];
      });
      return formattedRow;
    });
  }

  function exportExcel() {
    const headers = getTableHeaders();
    const servicesData = formatExportData(
      formattedServiceReportData || [],
      headers
    );
    const repuestosData = formatExportData(
      formattedRepuestosReportData || [],
      headers
    );

    const workbook = XLSX.utils.book_new();

    if (servicesData.length > 0) {
      const ws1 = XLSX.utils.json_to_sheet(servicesData);
      XLSX.utils.book_append_sheet(workbook, ws1, "Servicios");
    }

    if (repuestosData.length > 0) {
      const ws2 = XLSX.utils.json_to_sheet(repuestosData);
      XLSX.utils.book_append_sheet(workbook, ws2, "Repuestos");
    }

    XLSX.writeFile(workbook, "ValeRepuestos.xlsx");
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const formattedServiceReportData = convertDateObjects(
    servicesReportData?.filas
  );
  const formattedRepuestosReportData = convertDateObjects(
    repuestosReportData?.filas
  );

  const [filterParamsExport, setFilterParamsExport] = useState([]);
  function setFilterExport() {
    const filters = [
      { 1499: securityFilters.securityValues.country?.descripcion },
      { 488: securityFilters.securityValues.client?.descripcion },
      { 1791: securityFilters.securityValues.account?.descripcion },
      { 4791: securityFilters.securityValues.subaccount?.descripcion },
      { 327: filter.taller.descripcion },
      { 1151: filter.estado.descripcion },
      { 1491: filter.vin },
      { 3233: filter.aplicarFiltroFecha ? "S" : "N" },
      {
        1317:
          filter.tipoFiltro === "Fecha Ingreso"
            ? "Fecha Ingreso"
            : "Fecha Facturacion",
      },
      { 467: filter.fechaInicio },
      { 468: filter.fechaFinal },
    ];
    setFilterParamsExport(filters);
  }

  return (
    <div className="historia-vin">
      <Filter
        onSearch={() => {
          loadServicesReport();
          loadRepuestosReport();
        }}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={talleres}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingTalleres}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoDropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estado: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <SipcoInputText
                  value={filter.vin}
                  onChange={(e) => {
                    setFilter({ ...filter, vin: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"3233"}
                    alt="Aplicar Filtro por Fecha"
                  />
                </label>
                <Checkbox
                  checked={filter.aplicarFiltroFecha}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: new Date(),
                        fechaFinal: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: null,
                        fechaFinal: null,
                      });
                    }
                  }}
                />
              </div>
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.tipoFiltro === "Fecha Ingreso"}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFiltro: "Fecha Ingreso" });
                  }}
                  disabled={!filter.aplicarFiltroFecha}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"3232"} alt="Fecha Ingreso" />
                </label>
                <RadioButton
                  checked={filter.tipoFiltro === "Fecha Facturacion"}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFiltro: "Fecha Facturacion" });
                  }}
                  disabled={!filter.aplicarFiltroFecha}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"3231"} alt="Fecha Facturación" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"1684"} alt="Servicios" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  dataArray={[
                    {
                      data: formattedServiceReportData,
                      columns: columnsNames,
                      tab: { id: 1684, alt: "Servicios" },
                    },
                    {
                      data: formattedRepuestosReportData,
                      columns: columnsNamesRepuestos,
                      tab: { id: 2248, alt: "Repuestos" },
                    },
                  ]}
                  screenName={"Historia del Vin"}
                  pdf={false}
                  csv={false}
                  filterProps={filterParamsExport}
                  filter={true}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={formattedServiceReportData}
          loading={loadingServicesReportData}
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
          <SipcoColumn field="23" header={columnsNames[23].label} />
          <SipcoColumn field="24" header={columnsNames[24].label} />
        </SipcoDataTable>
      </Panel>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"2248"} alt="Repuestos" />
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          value={formattedRepuestosReportData}
          loading={loadingRepuestosReportData}
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id={"1491"} alt="VIN" />}
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id={"16478"} alt="Número de ingreso" />}
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id={"20804"} alt="Estado" />}
          />
          <SipcoColumn
            field="3"
            header={
              <LanguageProvider id={"16796"} alt="Fecha de Facturación" />
            }
          />
          <SipcoColumn
            field="4"
            header={
              <LanguageProvider id={"16797"} alt="Fecha de ingreso taller" />
            }
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id={"4243"} alt="Fecha Egreso" />}
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id={"1384"} alt="Estadía" />}
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id={"23888"} alt="Marca" />}
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id={"2139"} alt="Modelo" />}
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id={"4724"} alt="Patente" />}
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id={"2140"} alt="Color" />}
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id={"11250"} alt="Tipo" />}
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id={"2205"} alt="Saneamiento" />}
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id={"5701"} alt="Nro. Orden" />}
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id={"5788"} alt="Línea" />}
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id={"2020"} alt="Pieza" />}
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id={"3756"} alt="Descripción" />}
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id={"3773"} alt="Cantidad" />}
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id={"8818"} alt="Importe Neto" />}
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id={"2496"} alt="Origen" />}
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id={"22724"} alt="Prestacion" />}
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id={"22725"} alt="Subprestacion" />}
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id={"17012"} alt="Incidentes" />}
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id={"3568"} alt="Imputado a" />}
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id={"4061"} alt="Buque" />}
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id={"24997"} alt="Fecha Bajada Buque" />}
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default HistoriaVin;
