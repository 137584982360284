import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";

import React from "react";

type SipcoInputTextAreaProps = InputTextareaProps & {};

const SipcoInputTextArea: React.FC<SipcoInputTextAreaProps> = ({
  ...props
}) => {
  return <InputTextarea className="sipco-input-text-area" {...props} />;
};

export default SipcoInputTextArea;
