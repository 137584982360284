import { AutoComplete, AutoCompleteProps } from "primereact/autocomplete";

import React from "react";

type SipcoAutocompleteProps = AutoCompleteProps & {};

const SipcoAutocomplete: React.FC<SipcoAutocompleteProps> = ({ ...props }) => {
  return <AutoComplete className="sipco-autocomplete" {...props} />;
};

export default SipcoAutocomplete;
