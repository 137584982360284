import "./GastosExtraPlaya.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import useAuth from "@shared/AuthContext";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsDataTable = [
  { header: 3627, field: "@idDocumento" },
  { header: 16641, field: "@caratula.id" },
  { header: 2940, field: "@hojaRuta.id" },
  { header: 23037, field: "@fechaGasto" },
  { header: 23056, field: "@fechaCargaGasto" },
  { header: 8384, field: "@usuarioCarga" },
  { header: 9912, field: "@servicio.descripcion" },
  { header: 1669, field: "@observacion" },
  { header: 5372, field: "@compra" },
  { header: 5373, field: "@venta" },
  { header: 3750, field: "@codcli" },
  { header: 3758, field: "@cfcuni" },
  { header: 4585, field: "@cfckms" },
  { header: 2898, field: "@cfcpes" },
  { header: 1513, field: "@cfcvol" },
  { header: 4586, field: "@cfctie" },
  { header: 32, field: "@cfcval" },
  { header: 2521, field: "@codpro" },
  { header: 3758, field: "@cfpuni" },
  { header: 4585, field: "@cfpkms" },
  { header: 2898, field: "@cfppes" },
  { header: 1513, field: "@cfpvol" },
  { header: 4586, field: "@cfptie" },
  { header: 32, field: "@cfpval" },
  { header: 4784, field: "@anulado" },
  { header: 23058, field: "@idContable" },
  { header: 1545, field: "@moneda.descripcion" },
];

export function GastosExtraPlaya() {
  const SCREEN_CODE = "fun0225_gastos_extra_playa";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const columnsDataTableNames = useFilterColumnsIds(columnsDataTable);
  const [dataTableValue, setDataTableValue] = useState([]);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    ofertaServicio: "",
    nroHr: "",
    dtFechaDesde: { date: new Date() },
    dtFechaHasta: { date: new Date() },
    cliente: "",
    servicio: "",
    estContab: "",
  });
  const [porHrorOS, setPorHrorOS] = useState("porHr"); //porOs

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [clienteOptions, setClienteOptions] = useState([]);
  async function loadCliente() {
    try {
      const params = {
        codPais: securityFilters.securityValues.country.id,
        screenCode: SCREEN_CODE,
        level: user.nivel,
      };
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-cliente-options",
        { params }
      );
      if (status === 200) {
        setClienteOptions(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }
  const [loadingServicio, setLoadingServicio] = useState(false);
  const [servicioOptions, setServicioOptions] = useState([]);
  async function loadServicio() {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/gastos-extra-playa/servicio-extra",
        payload
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setServicioOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingEstContab, setLoadingEstContab] = useState(false);
  const [estContabOptions, setEstContabOptions] = useState([]);
  async function loadEstContab() {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/gastos-extra-playa/contabilizacion-gastos-extra",
        payload
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setEstContabOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function loadReportData() {}

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  function setLoadingAll(boolean) {
    setLoadingEstContab(boolean);
    setLoadingServicio(boolean);
    setLoadingCliente(boolean);
  }

  useEffect(() => {
    async function initialize() {
      const check = checkSecurityFilters();
      if (check) {
        setLoadingAll(true);
        try {
          const initialEstContab = await loadEstContab();
          const initialServicio = await loadServicio();
          const initialCliente = await loadCliente();

          setFilter({
            ...filter,
            estContab: initialEstContab[0],
            servicio: initialServicio[0],
            cliente: initialCliente[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingAll(false);
        }
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  return (
    <div className="gastos-extra-playa">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16641" alt="Oferta de Servicios" />
                </label>
                <SipcoInputText
                  value={filter.ofertaServicio}
                  onChange={(e) =>
                    setFilter({ ...filter, ofertaServicio: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2940"} alt="Nro. Hoja de Ruta" />
                </label>
                <SipcoInputText
                  value={filter.nroHr}
                  onChange={(e) =>
                    setFilter({ ...filter, nroHr: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.dtFechaDesde.date}
                  onChange={(e) =>
                    setFilter({ ...filter, dtFechaDesde: { date: e.value } })
                  }
                  dateFormat="dd/mm/yy"
                  showIcon
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.dtFechaHasta.date}
                  onChange={(e) =>
                    setFilter({ ...filter, dtFechaHasta: { date: e.value } })
                  }
                  dateFormat="dd/mm/yy"
                  showIcon
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"54"} alt="Cliente" />
                </label>
                <SipcoDropdown
                  loading={loadingCliente}
                  value={filter.cliente}
                  options={clienteOptions}
                  onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="descripcionFull"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3206"} alt="Tipo Servicio" />
                </label>
                <SipcoDropdown
                  loading={loadingServicio}
                  value={filter.servicio}
                  options={servicioOptions}
                  onChange={(e) => setFilter({ ...filter, servicio: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23030"} alt="Agrupar por" />
                </label>
                <div className="form-row">
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                    </label>
                    <RadioButton
                      value="porHr"
                      onChange={(e) => {
                        setPorHrorOS(e.target.value);
                      }}
                      checked={porHrorOS === "porHr"}
                    />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider
                        id={"16641"}
                        alt="Oferta de Servicios"
                      />
                    </label>
                    <RadioButton
                      value="porOs"
                      onChange={(e) => {
                        setPorHrorOS(e.target.value);
                      }}
                      checked={porHrorOS === "porOs"}
                    />
                  </div>
                </div>
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23125"} alt="Estado Contabilización" />
                </label>
                <SipcoDropdown
                  loading={loadingEstContab}
                  value={filter.estContab}
                  options={estContabOptions}
                  onChange={(e) => setFilter({ ...filter, estContab: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="descripcionFull"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"3715"} alt="Gastos Extraordinarios" />}
      >
        <div className="fieldset-content">
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableValue}
                  columns={columnsDataTableNames}
                  screenName={"GastosExtraPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsDataTableNames.map((column, index) => {
              if (column.field === "fechaEmision.date") {
                return (
                  <SipcoColumn
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <SipcoColumn
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </SipcoDataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "23032",
                alt: "Nuevo gasto",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "1309",
                alt: "Contabilizar",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "23033",
                alt: "Anular gasto",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "187",
                alt: "Imprimir o generar archivo Excel",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default GastosExtraPlaya;
