import "./DespachoMan.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DespachoMan() {
  const SCREEN_CODE = "fun0217_despacho_man";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  usePageViews();
  const data = convertDateObjects(reporte?.filas);
  const [filter, setFilter] = useState({
    vin: null,
    colecta: null,
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/existe",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === 200) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [colecta, setColecta] = useState([]);
  const [loadingColecta, setLoadingColecta] = useState(false);

  async function loadColecta() {
    try {
      setLoadingColecta(true);
      const { status, data } = await sipcoAxiosService.post(
        "/despacho-man/colecta",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        setColecta(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColecta(false);
    }
  }

  useEffect(() => {
    const init = () => {
      try {
        const colecta = loadColecta();

        setFilter({
          ...filter,
          colecta: colecta[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  return (
    <div className="despacho-man">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          subaccount: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset legend={<LanguageProvider id={"21385"} alt="Despacho MAN" />}>
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option"}>
              <label style={{ marginRight: "10px" }}>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label style={{ marginRight: "10px" }}>
                <LanguageProvider id={"TODO"} alt="Fecha de Despacho Real:" />
              </label>
              <SipcoCalendar value={new Date()} showTime showIcon />
            </div>

            <div
              className={"sipco-option"}
              style={{ width: "100px", marginTop: "20px" }}
            >
              <SipcoDropdown
                value={filter.colecta}
                options={colecta}
                optionLabel="label"
                onChange={(e) =>
                  setFilter({ ...filter, colecta: e.value || null })
                }
                placeholder="Colecta"
              />
            </div>
          </div>
        </div>

        <Fieldset>
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={null}
            value={null}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"VIN"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Forma Transporte"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Proveedor"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Modelo"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Categoria Modelo"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Tipo NF"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Serie NF"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Número NF"}
              sortable
              filter
            />
            <SipcoColumn
              key={0}
              field={"TODO"}
              header={"Reingreso"}
              sortable
              filter
            />
          </SipcoDataTable>

          <Panel
            footerTemplate={(props) => {
              return (
                <div className={props.className}>
                  {/* butons */}
                  <div>
                    <Button
                      // className="buttons"
                      label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                      text
                    />
                    <Button
                      // className="buttons"
                      label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                      text
                    />

                    <Button
                      // className="buttons"
                      label={LanguageProvider({ id: "593", alt: "Quitar" })}
                      text
                    />

                    <Button
                      // className="buttons"
                      label={LanguageProvider({
                        id: "748",
                        alt: "Seleccionar Todo",
                      })}
                      text
                    />

                    <Button
                      // className="buttons"
                      label={LanguageProvider({
                        id: "749",
                        alt: "Deseleccionar Todo",
                      })}
                      text
                    />
                  </div>
                </div>
              );
            }}
          ></Panel>
        </Fieldset>
      </Fieldset>
    </div>
  );
}
export default DespachoMan;
