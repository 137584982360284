import "./ConsultaOt.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useScreenCodeContext } from "@shared/ScrennContext";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaOt() {
  const SCREEN_CODE = "con0061_consulta_de_ot";
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];

  const filterColumnsId = [
    14369, 1491, 1569, 14368, 13324, 11833, 32, 11986, 11035, 1669, 396, 13514,
    2139, 18305, 2140, 367, 367, 2892, 6367, 16580, 3410, 18308, 18307,
  ];
  const columnsNames = [];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  // Campos da requisição
  const [filter, setFilter] = useState({
    fechaGeracaoOt: false,
    fechaGeracaoOtInicio: null,
    fechaGeracaoOtFim: null,
    estadoOt: null,
    numeroOt: "",
    vin: "",
    orignesSolicitacao: [],
    grupoOpercacao: null,
    operacoes: [],
  });

  const [vinOptions, setVinOptions] = useState([]);
  const [estadoOT, setEstadoOT] = useState([]);
  const [origines, setOrigines] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [gruposOperacao, setGruposOperacao] = useState([]);
  const [loadingGruposOperacao, setLoadingGruposOperacao] = useState(false);
  const [loadingOperaciones, setLoadingOperaciones] = useState(false);
  const [loadingOrigines, setLoadingOrigines] = useState(false);
  const [loadingEstadosOt, setLoadingEstadosOt] = useState(true);

  const [loadingReport, setLoadingReport] = useState(false);

  // Requisição -> getEstado
  async function loadEstadoOt() {
    setLoadingEstadosOt(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/estado",
          {
            params: { codPais: securityFilters.securityValues.country.id },
          }
        );

        if (status === 200) {
          data.unshift({
            id: "TODOS",
            descripcionFull: "TODOS",
          });
          setEstadoOT(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosOt(false);
    }
    return [];
  }

  // Requisição -> getOrigen
  async function loadOrigines() {
    setLoadingOrigines(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/origen",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          setOrigines(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigines(false);
    }
    return [];
  }
  async function loadOperaciones() {
    setLoadingOperaciones(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOperaciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperaciones(false);
    }
    return [];
  }
  async function loadGruposOperaciones() {
    setLoadingGruposOperacao(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/grupo-operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          data.unshift({
            id: 0,
            descripcion: "TODOS",
          });
          setGruposOperacao(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGruposOperacao(false);
    }
    return [];
  }

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVinOptions(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadReport() {
    setLoadingReport(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/report/consulta-ot",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
          estadoOt: filter.estadoOt?.value || null,
          numeroOrden: filter?.numeroOt || null,
          rangeFecha: [
            filter?.fechaGeracaoOtInicio || null,
            filter?.fechaGeracaoOtFim || null,
          ],
          operacion: filter.operacoes?.map((x) => x.id) || [],
          origenSolicitud: filter.orignesSolicitacao?.map((x) => x.id) || [],
          grupoOperacion: filter.grupoOpercacao?.id || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        if (securityFilters?.securityValues?.country != null) {
          const estadosOt = await loadEstadoOt();
          const origenes = await loadOrigines();
          const operaciones = await loadOperaciones();
          const gruposOperacao = await loadGruposOperaciones();
          setFilter({
            ...filter,
            estadoOt: estadosOt[0],
            orignesSolicitacao: origenes,
            operacoes: operaciones,
            grupoOpercacao: gruposOperacao[0],
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="consulta-ot">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.fechaGeracaoOt}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        fechaGeracaoOt: true,
                        fechaGeracaoOtInicio: new Date(),
                        fechaGeracaoOtFim: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        fechaGeracaoOt: false,
                        fechaGeracaoOtInicio: null,
                        fechaGeracaoOtFim: null,
                      });
                    }
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id="14370" alt="Fecha generación OT" />
                </label>
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="467" alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaGeracaoOtInicio}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaGeracaoOtInicio: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaGeracaoOt}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="468" alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaGeracaoOtFim}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaGeracaoOtFim: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaGeracaoOt}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id="14368" alt="Estado OT" />
                </label>
                <SipcoDropdown
                  value={filter.estadoOt}
                  options={estadoOT}
                  onChange={(e) => setFilter({ ...filter, estadoOt: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  loading={loadingEstadosOt}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="14369" alt="Número OT" />
                </label>
                <SipcoInputText
                  value={filter.numeroOt}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroOt: e.target.value })
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="901" alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vinOptions}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="18308" alt="Origen Solicitud" />
                </label>
                <SipcoMultiSelect
                  value={filter.orignesSolicitacao}
                  options={origines}
                  onChange={(e) =>
                    setFilter({ ...filter, orignesSolicitacao: e.value })
                  }
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigines}
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="18309" alt="Grupo Operação" />
                </label>
                <SipcoDropdown
                  value={filter.grupoOpercacao}
                  options={gruposOperacao}
                  onChange={(e) =>
                    setFilter({ ...filter, grupoOpercacao: e.value })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingGruposOperacao}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="1569" alt="Operação" />
                </label>
                <SipcoMultiSelect
                  value={filter.operacoes}
                  options={operaciones}
                  onChange={(e) => setFilter({ ...filter, operacoes: e.value })}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOperaciones}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="14380" alt="Ordenes de Trabajo" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Orden Trabajo"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOt;
function setLoadingEstadosOt(arg0: boolean) {
  throw new Error("Function not implemented.");
}
