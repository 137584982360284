import "./CargaDanos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CargaDanos() {
  const SCREEN_CODE = "fun0059_carga_danos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);

  const [checkpoint, setCheckpoint] = useState([]);
  const [loadingCheckpoint, setLoadingCheckpoint] = useState(false);

  const [imputacion, setImputacion] = useState([]);
  const [loadingImputacion, setLoadingImputacion] = useState(false);

  const [responsable, setResponsable] = useState([]);
  const [loadingResponsable, setLoadingResponsable] = useState(false);

  const [parteDanada, setParteDanada] = useState([]);
  const [loadingParteDanada, setLoadingParteDanada] = useState(false);

  const [transaccion, setTransaccion] = useState([]);
  const [loadingTransaccion, setLoadingTransaccion] = useState(false);

  const [tipoDano, setTipoDano] = useState([]);
  const [loadingTipoDano, setLoadingTipoDano] = useState(false);

  const [cuadrante, setCuadrante] = useState([]);
  const [loadingCuadrante, setLoadingCuadrante] = useState(false);

  const [gravedad, setGravedad] = useState([]);
  const [loadingGravedad, setLoadingGravedad] = useState(false);

  const [medida, setMedida] = useState([]);
  const [loadingMedida, setLoadingMedida] = useState(false);

  const [inspector, setInspector] = useState([]);
  const [loadingInspector, setLoadingInspector] = useState(false);

  const [resultadoAnalisis, setResultadoAnalisis] = useState([]);
  const [loadingResultadoAnalisis, setLoadingResultadoAnalisis] =
    useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [filter, setFilter] = useState({
    checkpoint: null,
    responsable: null,
    imputacion: null,
    numeroRemito: null,
    parteDanada: null,
    transaccion: null,
    tipoDano: null,
    cuadrante: null,
    vin: null,
    gravedad: null,
    medida: null,
    fechaDano: null,
    importe: "",
    inspector: null,
    comentario: "",
    valor: "",
    observaciones: "",
    nroChecklist: "",
    nroDossier: "",
    resultadoAnalisis: null,
    fechaSagai: null,
  });

  const filterColumnsId = [
    20216, 10540, 10541, 2490, 5701, 16243, 16575, 17007, 16544, 17009, 8385,
    3344, 6197, 20854, 11314, 11314, 18096, 18125, 18097, 18099, 18098, 21675,
    7280, 32,
  ];
  const columnsNames = filterColumnsIds(filterColumnsId);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  async function loadReportData() {
    setLoading(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/find-danios-by",
          {
            pais: securityFilters?.securityValues?.country || null,
            cliente: securityFilters?.securityValues?.client || null,
            cuenta: securityFilters?.securityValues?.account || null,
            vin: filter.vin || null,
          }
        );

        if (status === 200) {
          setReporte(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/vin",
          {
            pais: securityFilters?.securityValues?.country,
            cliente: securityFilters?.securityValues?.client,
            cuenta: securityFilters?.securityValues?.account,
            vin: { id: query },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadCheckpoint() {
    setLoadingCheckpoint(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/checkpoint",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setCheckpoint(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCheckpoint(false);
    }
    return [];
  }
  async function handleCheckpointChange(value: any) {
    try {
      const imputaciones = await loadImputacion(value);
      const transacciones = await loadTransaccion();
      setFilter({
        ...filter,
        checkpoint: value,
        imputacion: imputaciones[0] ?? null,
        transaccion: transacciones[0] ?? null,
      });
      setImputacion(imputaciones);
      setTransaccion(transacciones);
    } catch (error) {
      console.error(error);
    }
  }
  async function loadImputacion(checkpoint: any) {
    setLoadingImputacion(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        checkpoint
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/imputacion",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            checkpoint: checkpoint,
          }
        );
        if (status === 200) {
          setImputacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingImputacion(false);
    }
    return [];
  }
  async function handleImputacionChange(value: any) {
    try {
      const responsables = await loadResponsable(value);
      setFilter({
        ...filter,
        imputacion: value,
        responsable: responsables[0] ?? null,
      });
      setResponsable(responsables);
    } catch (error) {
      console.error(error);
    }
  }
  async function loadResponsable(imputacion: any) {
    setLoadingCheckpoint(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        filter.checkpoint != null &&
        imputacion != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/responsable",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            checkpoint: filter.checkpoint || null,
            imputacion: imputacion,
          }
        );
        if (status === 200) {
          setCheckpoint(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCheckpoint(false);
    }
    return [];
  }
  async function handleResponsableChange(value: any) {
    try {
      setFilter({
        ...filter,
        responsable: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadParteDanada() {
    setLoadingParteDanada(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/partes-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setImputacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingParteDanada(false);
    }
    return [];
  }
  async function handleParteDanadaChange(value: any) {
    try {
      const cuadrantes = await loadCuadrante(value);
      setFilter({
        ...filter,
        parteDanada: value,
        cuadrante: cuadrantes[0] ?? null,
      });
      setCuadrante(cuadrantes);
    } catch (error) {
      console.error(error);
    }
  }
  async function loadTransaccion() {
    setLoadingTransaccion(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        filter.vin != null &&
        filter.checkpoint != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/transacciones",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            vin: filter.vin,
            checkpoint: filter.checkpoint,
          }
        );
        if (status === 200) {
          setTransaccion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransaccion(false);
    }
    return [];
  }
  async function handleTransaccionChange(value: any) {
    try {
      //const responsables = await loadResponsable(value);
      setFilter({
        ...filter,
        transaccion: value,
        //   responsable: responsables[0] ?? null,
      });
      //   setResponsable(responsables);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTipoDano() {
    setLoadingTipoDano(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/tipo-de-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setTipoDano(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoDano(false);
    }
    return [];
  }
  async function handleTipoDanoChange(value: any) {
    try {
      setFilter({
        ...filter,
        tipoDano: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadCuadrante(parte: any) {
    setLoadingCuadrante(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/cuadrante",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            parte: parte,
          }
        );
        if (status === 200) {
          setCuadrante(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuadrante(false);
    }
    return [];
  }
  async function handleCuadranteChange(value: any) {
    try {
      setFilter({
        ...filter,
        tipoDano: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadGravedad() {
    setLoadingCuadrante(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/gravedad-de-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setGravedad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGravedad(false);
    }
    return [];
  }
  async function handleGravedadChange(value: any) {
    try {
      setFilter({
        ...filter,
        gravedad: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadMedida() {
    setLoadingMedida(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/unidad-medida-playa",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            tipoDeDanio: filter?.tipoDano || null,
          }
        );
        if (status === 200) {
          setMedida(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMedida(false);
    }
    return [];
  }
  async function handleMedidaChange(value: any) {
    try {
      setFilter({
        ...filter,
        medida: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadInspector() {
    setLoadingInspector(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/inspector-averia",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setInspector(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInspector(false);
    }
    return [];
  }
  async function handleInspectorChange(value: any) {
    try {
      setFilter({
        ...filter,
        inspector: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function loadResultadoAnalisis() {
    setLoadingResultadoAnalisis(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-danos/resultado-analisis",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setResultadoAnalisis(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResultadoAnalisis(false);
    }
    return [];
  }
  async function handleResultadoAnalisisChange(value: any) {
    try {
      setFilter({
        ...filter,
        resultadoAnalisis: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    async function initialize() {
      try {
        const checkpoints = await loadCheckpoint();
        const partesDanada = await loadParteDanada();
        const tiposDano = await loadTipoDano();
        const gravedads = await loadGravedad();
        const medidas = await loadMedida();
        const inspectores = await loadInspector();
        const resultadosAnalisis = await loadResultadoAnalisis();
        setFilter({
          ...filter,
          checkpoint: checkpoints[0],
          parteDanada: partesDanada[0],
          tipoDano: tiposDano[0],
          gravedad: gravedads[0],
          medida: medidas[0],
          inspector: inspectores[0],
          resultadoAnalisis: resultadosAnalisis[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div className="carga-danos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Carga de Daños</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte}
                  columns={columnsNames}
                  screenName={"CargaDanos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="filter-options">
                <div className={"filter-options-left"}>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"11314"} alt="CheckPoint" />
                    </label>
                    <SipcoDropdown
                      value={filter.checkpoint}
                      options={checkpoint}
                      onChange={(e) => handleCheckpointChange(e.value)}
                      loading={loadingCheckpoint}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"11097"} alt="Responsable" />
                      </label>
                      <SipcoDropdown
                        value={filter.responsable}
                        options={responsable}
                        onChange={(e) => handleResponsableChange(e.value)}
                        loading={loadingResponsable}
                        optionLabel="descripcion"
                        filter
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"3774"} alt="Nro. Remito" />
                      </label>
                      <SipcoInputText
                        value={filter.numeroRemito}
                        onChange={(e) =>
                          setFilter({ ...filter, numeroRemito: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"21910"}
                        alt="Parte dañada (Elemento)"
                      />
                    </label>
                    <SipcoDropdown
                      value={filter.parteDanada}
                      options={parteDanada}
                      onChange={(e) => handleParteDanadaChange(e.value)}
                      loading={loadingParteDanada}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"21675"} alt="Cuadrante (LD)" />
                    </label>
                    <SipcoDropdown
                      value={filter.cuadrante}
                      options={cuadrante}
                      onChange={(e) => handleCuadranteChange(e.value)}
                      loading={loadingCuadrante}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"17007"} alt="Gravedad del Daño" />
                    </label>
                    <SipcoDropdown
                      value={filter.gravedad}
                      options={gravedad}
                      onChange={(e) => handleGravedadChange(e.value)}
                      loading={loadingGravedad}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>

                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"16577"} alt="Fecha del daño" />
                      </label>
                      <SipcoCalendar
                        value={filter.fechaDano}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            fechaDano: e.value,
                          });
                        }}
                        showButtonBar
                        showIcon
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"1974"} alt="Importe" />
                      </label>
                      <SipcoInputText
                        value={filter.importe}
                        onChange={(e) =>
                          setFilter({ ...filter, importe: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"11070"} alt="Inspector" />
                      </label>
                      <SipcoDropdown
                        value={filter.inspector}
                        options={inspector}
                        onChange={(e) => handleInspectorChange(e.value)}
                        loading={loadingInspector}
                        optionLabel="descripcion"
                        filter
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"3344"} alt="Estado" />
                      </label>
                      <SipcoInputText />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"18125"} alt="Comentario Dossier" />
                    </label>
                    <SipcoInputText
                      value={filter.comentario}
                      onChange={(e) =>
                        setFilter({ ...filter, comentario: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className={"filter-options-right"}>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"8335"} alt="Imputaciones" />
                    </label>
                    <SipcoDropdown
                      value={filter.imputacion}
                      options={imputacion}
                      loading={loadingImputacion}
                      onChange={(e) => handleImputacionChange(e.value)}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"14288"} alt="Transacción" />
                    </label>
                    <SipcoDropdown
                      value={filter.transaccion}
                      options={transaccion}
                      onChange={(e) => handleTransaccionChange(e.value)}
                      loading={loadingTransaccion}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"21911"}
                        alt="Descripción daño (CD)"
                      />
                    </label>
                    <SipcoDropdown
                      value={filter.tipoDano}
                      options={tipoDano}
                      onChange={(e) => handleTipoDanoChange(e.value)}
                      loading={loadingTipoDano}
                      optionLabel="descripcion"
                      filter
                      showClear
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"21912"} alt="Medida (T)" />
                      </label>
                      <SipcoDropdown
                        value={filter.medida}
                        options={medida}
                        onChange={(e) => handleMedidaChange(e.value)}
                        loading={loadingMedida}
                        optionLabel="descripcion"
                        filter
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"32"} alt="Valor" />
                      </label>
                      <SipcoInputText
                        value={filter.valor}
                        onChange={(e) =>
                          setFilter({ ...filter, valor: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <SipcoInputText
                      value={filter.observaciones}
                      onChange={(e) =>
                        setFilter({ ...filter, observaciones: e.target.value })
                      }
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18097"} alt="Fecha SAGAI" />
                      </label>
                      <SipcoCalendar
                        value={filter.fechaSagai}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            fechaSagai: e.value,
                          });
                        }}
                        showButtonBar
                        showIcon
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={""} alt="use??" />
                      </label>
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"18098"}
                          alt="Resultado Analisis"
                        />
                      </label>
                      <SipcoDropdown
                        value={filter.resultadoAnalisis}
                        options={resultadoAnalisis}
                        onChange={(e) => handleResultadoAnalisisChange(e.value)}
                        loading={loadingResultadoAnalisis}
                        optionLabel="descripcion"
                        filter
                        showClear
                      />
                    </div>
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18099"} alt="Nro. Dossier" />
                      </label>
                      <SipcoInputText
                        value={filter.nroDossier}
                        onChange={(e) =>
                          setFilter({ ...filter, nroDossier: e.target.value })
                        }
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18096"} alt="Nro. Check List" />
                      </label>
                      <SipcoInputText
                        value={filter.nroChecklist}
                        onChange={(e) =>
                          setFilter({ ...filter, nroChecklist: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "25",
                    alt: "Alta",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "26",
                    alt: "Baja",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2761",
                    alt: "Reparado",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "9627",
                    alt: "Destrabar daños",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "20874",
                    alt: "Reactivar daño",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoInputText
          value={`${filter?.vin?.marca?.descripcion || ""} - ${filter?.vin?.modelo?.descripcion || ""} - ${filter?.vin?.color?.descripcion || ""}`}
          style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
          readOnly={true}
        />
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte || []}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <SipcoColumn
            field="tieneFoto"
            header={columnsNames[0].label}
            body={(rowData) => (rowData.tieneFoto ? "Si" : "No")}
          />
          <SipcoColumn field="tipoDeDanio.id" header={columnsNames[1].label} />
          <SipcoColumn
            field="tipoDeDanio.descripcion"
            header={columnsNames[2].label}
          />
          <SipcoColumn field="partesDanios.id" header={columnsNames[3].label} />
          <SipcoColumn field="ordenReparacion" header={columnsNames[4].label} />
          <SipcoColumn
            field="partesDanios.descripcion"
            header={columnsNames[5].label}
          />
          <SipcoColumn
            field="gravedadDeDanio.id"
            header={columnsNames[6].label}
          />
          <SipcoColumn
            field="gravedadDeDanio.descripcion"
            header={columnsNames[7].label}
          />
          <SipcoColumn field="observacion" header={columnsNames[8].label} />
          <SipcoColumn
            field="fechaMovimientoDanio.date"
            header={columnsNames[9].label}
            body={(rowData) => {
              if (rowData?.fechaMovimientoDanio?.date) {
                return new Date(
                  rowData.fechaMovimientoDanio.date
                ).toLocaleString("pt-BR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                });
              } else {
                return "";
              }
            }}
          />
          <SipcoColumn
            field="fechaCarga.date"
            header={columnsNames[10].label}
            body={(rowData) => {
              if (rowData?.fechaCarga?.date) {
                return new Date(rowData.fechaCarga.date).toLocaleString(
                  "pt-BR",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  }
                );
              } else {
                return "";
              }
            }}
          />
          <SipcoColumn
            field="estadoDanio.descripcion"
            header={columnsNames[11].label}
          />
          <SipcoColumn
            field="fechaBaja.date"
            header={columnsNames[12].label}
            body={(rowData) => {
              if (rowData?.fechaBaja?.date) {
                return new Date(rowData.fechaBaja.date).toLocaleString(
                  "pt-BR",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  }
                );
              } else {
                return "";
              }
            }}
          />
          <SipcoColumn
            field="motivoBajaDanio.id"
            header={columnsNames[13].label}
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default CargaDanos;
