import "./MantenerServicos.scss";

import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MantenerServicos() {
  const SCREEN_CODE = "fun0213_mantener_servicios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const toast = useRef<Toast>(null);

  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const [mantenerServices, setMantenerServices] = useState([]);

  const columnsNames = [];
  const filterColumnsId = [19775, 31, 19776];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportDate() {
    try {
      setLoading(true);
      const { data, status } = await sipcoAxiosService.post(
        "/mantener-servicios/grid",
        {
          interfazEjecutable: {
            id: null,
          },
        }
      );

      if (status === 200) {
        const response = data.map((x: any) => {
          return {
            ...x,
            codigoInterfaz: x?.id,
            description: x?.descripcion,
            detenida: x?.detenerEjecucion ? "Si" : "No",
          };
        });
        setMantenerServices(response);
        setReporte(response);
        loadReportDate();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsName = [
    { field: "codigoInterfaz", header: "Código Interfaz" },
    { field: "description", header: "Descripción" },
    { field: "detenida", header: "Detenida" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  // Funciones
  const [loadingActive, setLoadingActive] = useState(false);
  async function setActive(id: string) {
    try {
      setLoadingActive(true);
      const { data, status } = await sipcoAxiosService.post(
        "/mantener-servicios/activar",
        {
          interfazEjecutable: {
            id: id[0],
          },
        }
      );

      if (status === 200) {
        console.log(data);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Su transacción se realizó con éxito."
            />
          ),
          life: 3000,
        });
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingActive(false);
    }
  }

  const [loadingDesactive, setLoadingDesactive] = useState(false);
  async function setDesactivar(id: string) {
    try {
      setLoadingDesactive(true);
      const { data, status } = await sipcoAxiosService.post(
        "/mantener-servicios/desactivar",
        {
          interfazEjecutable: {
            id: id[0],
          },
        }
      );

      if (status === 200) {
        console.log(data);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Su transacción se realizó con éxito."
            />
          ),
          life: 3000,
        });

        console.log("Chegou", HttpStatusCode.Ok);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDesactive(false);
    }
  }

  function getId(
    sedding: Array<{ id: string | number }>
  ): Array<string | number> {
    return sedding.map(({ id }) => id);
  }

  return (
    <div className="mantener-servicos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportDate}
        securityOptions={{
          account: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Mantener Sevicios</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "12255", alt: "Activar" })}
                  text
                  onClick={() => {
                    if (Array.isArray(selectedRows)) {
                      const ids = getId(selectedRows);
                      if (ids.length > 0) {
                        setActive(ids[0] as string);
                      } else {
                        console.error("Nenhum ID foi selecionado.");
                      }
                    } else {
                      console.error("selectedRows não é um array válido.");
                    }
                  }}
                />
                <Button
                  label={LanguageProvider({ id: "12256", alt: "Desactivar" })}
                  text
                  onClick={() => {
                    if (Array.isArray(selectedRows)) {
                      const ids = getId(selectedRows);
                      if (ids.length > 0) {
                        setDesactivar(ids[0] as string);
                      } else {
                        console.error("Nenhum ID foi selecionado.");
                      }
                    } else {
                      console.error("selectedRows não é um array válido.");
                    }
                  }}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={mantenerServices}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsName.map((colum, index) => (
            <SipcoColumn
              key={index}
              field={colum.field}
              header={colum.header}
              sortable
              filter
              body={(rowData) => (
                <span
                  style={{
                    color: rowData.detenida === "Si" ? "red" : "black",
                  }}
                >
                  {rowData[colum.field]}
                </span>
              )}
            />
          ))}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
