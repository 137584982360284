import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CopiarPermisos({
  titleModal,
  userData,
  activeModal,
  setActiveModal,
}) {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [listPaises, setListPaises] = useState([]);
  const [listAllUsuarios, setListAllUsuarios] = useState([]);
  const [checkedMarcas, setCheckedMarcas] = useState(false);
  const [checkedConcesionarios, setCheckedConcesionarios] = useState(false);
  const [checkedOperaciones, setCheckedOperaciones] = useState(false);
  const [rolesPaisSelected, setRolesPaisSelected] = useState(
    userData?.pais?.descripcion
  );
  const [rolesPaisSelectedObject, setRolesPaisSelectedObject] = useState(
    userData?.pais
  );

  useEffect(() => {
    findPaises();
    findAllusuarios();
  }, []);

  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => aceptar()}
        autoFocus
      />

      <Button
        label="Cerrar"
        icon="pi pi-times"
        onClick={() => {
          setActiveModal(null);
        }}
        className="p-button-text"
      />
    </div>
  );

  function aceptar(): void {
    throw new Error("Function not implemented.");
  }

  async function findPaises() {
    try {
      const { data, status } = await sipcoAxiosService.get(
        "/mantenerUsuario/findBySistema",
        {
          params: {
            codSistema: "02",
          },
        }
      );

      if (status === 200) {
        setListPaises(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAllusuarios() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findAllUsuarios",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
          },
        }
      );
      if (status === 200) {
        console.log(data);
        setListAllUsuarios(data);
      }
    } catch (error) {
      alert("dhjd");
      console.error(error);
    }
  }

  return (
    <>
      <div className={""}>
        <Dialog
          header={titleModal}
          visible={activeModal}
          maximizable
          style={{ width: "45vw" }}
          footer={footerContent}
          onHide={() => {
            if (!activeModal) return;
            setActiveModal(false);
          }}
        >
          <div className="cp-option-modal">
            <div className="centerOption">
              <div className={"org-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="País" />
                </label>
                <SipcoDropdown
                  value={rolesPaisSelected}
                  options={listPaises}
                  onChange={(e) => {
                    setRolesPaisSelectedObject(e.value);
                    setRolesPaisSelected(e.value.descripcion);
                  }}
                  optionLabel="descripcion"
                  placeholder={rolesPaisSelected}
                  className="w-full md:w-14rem"
                  loading={listPaises.length > 0 ? false : true}
                />
              </div>

              <div className={"org-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Usuario" />
                </label>
                <SipcoDropdown
                  value={""}
                  placeholder={""}
                  options={[]}
                  className="w-full md:w-14rem"
                  onChange={(e) => ""}
                  optionLabel="descripcion"
                  //loading={listRolesRoles.length > 0 ? false : true}
                  filter
                />
              </div>
            </div>

            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Marcas" />
              </label>
              <Checkbox
                checked={checkedMarcas}
                onClick={() => {
                  setCheckedMarcas(!checkedMarcas);
                }}
              ></Checkbox>
            </div>
            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Consecionarios" />
              </label>
              <Checkbox
                checked={checkedConcesionarios}
                onClick={() => {
                  setCheckedConcesionarios(!checkedConcesionarios);
                }}
              ></Checkbox>
            </div>
            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Operaciones" />
              </label>
              <Checkbox
                checked={checkedOperaciones}
                onClick={() => {
                  setCheckedOperaciones(!checkedOperaciones);
                }}
              ></Checkbox>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default CopiarPermisos;
