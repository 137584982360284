import "./AnulacionEcom.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function AnulacionEcom() {
  const SCREEN_CODE = "fun0111_anulacion_ecom";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const [filter, setFilter] = useState({
    vin: "",
    checkCopiarVin: false,
    fechaFabricacion: null,
    actualizoConFecha: null,
    mercado: null,
    origen: null,
    modelo: null,
    marca: null,
    colores: null,
    genero: null,
    acabado: null,
    motorizacion: null,
    denominacionComercial: null,
    cliente: null,
    silueta: null,
    caja: null,
    baseConcepcion: null,
    clienteConcepcion: null,
    anioFabricacion: null,
    modificacion: null,
    tapizado: null,
    cabeceraOpcional: null,
    numeroMotor: "",
    modeloLargo: "",
    ncm: "",
    destino: "",
    destinoOrigen: "",
    ordenFabricacion: "",
  });

  const [vin, setVin] = useState([]);

  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/anulacion-ecom/vin-full",
          {
            vin: {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              id: query,
            },
          }
        );
        if (status === 200) {
          const response = [data].map((x: any) => ({
            ...x,
            label: `${x.id}`,
          }));

          console.log("Response", response);
          setVin(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [mercado, setMercado] = useState([]);
  const [loadingMercado, setLoadingMercado] = useState(false);

  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setMercado(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
  }

  return (
    <div className="anulacion-ecom">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          subaccount: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset legend={<LanguageProvider id={"11279"} alt="Anulación ECOM" />}>
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option"}>
              <label style={{ marginRight: "10px" }}>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            {filter.checkCopiarVin && (
              <div className={"sipco-option"}>
                <label style={{ marginRight: "10px" }}>
                  <LanguageProvider id={"TODO"} alt="Cliente" />
                </label>
                <SipcoDropdown
                  value={filter.cliente}
                  onChange={(e) =>
                    setFilter({ ...filter, cliente: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                />
              </div>
            )}
          </div>
        </div>

        <Fieldset>
          <div className="form">
            <div className="column">
              {/* Primeira Parte */}
              <div className="form-row ">
                <div className="sipco-option">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"396"} alt="Marca" />
                    </label>

                    <SipcoInputText
                      value={filter?.destino || null}
                      disabled={true}
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2139"} alt="Modelo" />
                    </label>

                    <SipcoInputText
                      value={filter?.destino || null}
                      disabled={true}
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2140"} alt="Color" />
                    </label>

                    <SipcoInputText
                      value={filter?.destino || null}
                      disabled={true}
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13338"} alt="Modelo Largo" />
                    </label>
                    <SipcoInputText
                      value={filter?.destino || null}
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"14535"} alt="Destino" />
                    </label>
                    <SipcoInputText
                      value={filter?.destinoOrigen || null}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Panel
            footerTemplate={(props) => {
              return (
                <div className={props.className}>
                  {/* butons */}
                  <div>
                    <Button
                      // className="buttons"
                      label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                      text
                    />
                  </div>
                </div>
              );
            }}
          ></Panel>
        </Fieldset>
      </Fieldset>
    </div>
  );
}
export default AnulacionEcom;
