import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const Masiva: React.FC<any> = () => {
  const [reporte, setReporte] = useState(null);
  const data = reporte?.filas ? convertDateObjects(reporte.filas) : [];
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const { values: securityFilters } = useFilterContext();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    numeroOt: null,
    fechaRealizacion: new Date(),
    vin: "",
    linea: "",
    resultado: "",
    operacion: "",
    proveedor: "",
  });
  const table1 = [1569, 5788, 8380, 2521, 1669];
  const table2 = [1491, 18605, 1669];
  const columNames1 = useFilterColumnsIds(table1);
  const dt = useRef(null);
  const [ingresoManual, setIngresoManual] = useState([]);
  const [numeroOt, setNumeroOt] = useState([]);
  const [loadingNumeroOt, setLoadingNumeroOt] = useState(false);
  const [proveedor, setProveedor] = useState(null);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [manual, setManual] = useState([]);
  const columnsNames = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  const seachNumeroOt = async (event: any) => {
    return await loadNumeroOt(event.query);
  };
  const loadNumeroOt = async (query: any) => {
    try {
      setLoadingNumeroOt(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-ot",
        {
          codPais: securityFilters.securityValues.country.id,
          codCliente: securityFilters.securityValues.client.id,
          codCuenta: securityFilters.securityValues.account.id,
          codOrdenTrabalho: query,
        }
      );

      if (status === HttpStatusCode.Ok) {
        const linea = data.map((x: any) => {
          return {
            linea: x?.lineaOperacion?.descripcion,
          };
        });

        const response = data.map((x: any) => {
          return {
            label: x?.ordenTrabajo?.id,
          };
        });

        if (linea.length > 0) {
          setFilter({
            ...filter,
            linea: linea[0].linea,
          });
        }

        setNumeroOt(response);
        setLoadingVin(true);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNumeroOt(false);
    }
  };

  const [operacion, setOperacion] = useState(null);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  async function loadOperacion(idOperacion: any) {
    setLoadingOperacion(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        numeroOt !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/find-op",
          {
            operacion: {
              pais: securityFilters.securityValues.country,
              id: idOperacion,
            },
          }
        );
        if (status === 200) {
          setOperacion(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  const loadProveedor = async (query: any) => {
    try {
      setLoadingProveedor(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-proveedor-activo",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          operacion: {
            id: filter.operacion || null,
          },
        }
      );
      if (status === 200) {
        setProveedor(data);

        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  };
  const seachLinea = async (event: any) => {
    return await loadProveedor(event.query);
  };

  function iso8601ToTimestamp(isoDate) {
    const date = new Date(isoDate);
    return date.getTime();
  }

  function compareArrays(selectRows: any[], manual: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && manual.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.vin;
        const operacionValue = row.operacion;
        const lineaValue = row.linea;

        const matchingObject = manual.find(
          (item: any) =>
            item?.vin.id === vinValue &&
            item?.lineaOperacion?.descripcion === lineaValue &&
            item?.operacion?.descripcion === operacionValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const [resultadoOTFunction, setResultadoOTFunction] = useState([]);
  const [loadingResultadoOT, setLoadingResultadoOT] = useState(false);
  async function findByResultadoOT() {
    try {
      setLoadingResultadoOT(true);
      if (
        securityFilters.securityValues.country !== null &&
        manual.length > 0
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/find-resultado-op",
          {
            pais: securityFilters.securityValues.country,
            lineaOp: manual.map((x) => x.lineaOperacion)[0],
            operacion: manual.map((x) => x.operacion)[0],
          }
        );

        if (status === HttpStatusCode.Ok) {
          setResultadoOTFunction(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResultadoOT(false);
    }
  }

  async function loadReportData() {
    try {
      const reportResult = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-ot",
        {
          codPais: securityFilters.securityValues?.country?.id,
          codCuenta: securityFilters.securityValues?.account?.id,
          codCliente: securityFilters.securityValues?.client?.id,
          codOrdenTrabalho: filter?.numeroOt?.label || null,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === HttpStatusCode.Ok && data) {
          const response = data.map((x: any) => {
            return {
              ...x,
              estadoOt: x?.ordenTrabajo?.estado?.descripcion,
              resultado: x?.valor,
              numeroOt: x?.ordenTrabajo?.id,
              operacion: x?.operacion?.descripcion,
              nrOperacion: x?.operacionRegistrada?.id,
              linea: x?.lineaOperacion?.descripcion,
              vin: x?.vin?.id,
            };
          });

          setManual(data);

          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function load(value: any) {
    const map = [];
    return loadReportData()
      .then((dataTable) => {
        setLoading(true);
        findByResultadoOT().then((resultadoArray) => {
          if (resultadoArray.length > 0) {
            const { id, descripcion, operacion, lineaOperacion } =
              resultadoArray[0];

            if (id === value || descripcion === value) {
              console.log("Entrou id === value || descripcion === value ");
              const operacionId = operacion?.id;
              const lineaId = lineaOperacion?.descripcion;

              const match = dataTable.find((x: any) => x.linea === lineaId);

              map.push(match);

              setIngresoManual(map);

              return match;
            }
          }
          return null; // Retorna null se nenhum objeto for encontrado
        });
      })
      .catch((error) => {
        console.error("Erro ao carregar os dados:", error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const columnsName = [
    { field: "numeroOt", header: "Numero Ot" },
    { field: "estadoOt", header: "Estado Ot" },
    { field: "vin", header: "VIN" },
    { field: "operacion", header: "Operacion" },
    { field: "nrOperacion", header: "Nr Operacion" },
    { field: "linea", header: "Linea" },
  ];

  const resetFilter = () => {
    setFilter({
      ...filter,
      numeroOt: "",
      fechaRealizacion: new Date(),
      vin: "",
      linea: "",
      resultado: "",
      operacion: "",
      proveedor: "",
    });
  };

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  async function validaProcesarOperacion() {
    try {
      if (filter.fechaRealizacion !== null && selectedRows.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/validate-lineas",
          {
            pais: securityFilters.securityValues.country,
            operaciones:
              compareArrays(selectedRows, manual).map(
                (x) => x.operacionRegistrada
              )[0] || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "No se pudo procesar la operacion",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <div className="search-action mb-3">
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={null}
        />
      </div>
      <div className="masiva">
        <div className="filter-options-left">
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"14369"} alt="Número OT" />
                </label>
                <SipcoAutocomplete
                  value={filter.numeroOt}
                  suggestions={numeroOt}
                  completeMethod={seachNumeroOt}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroOt: e.value || null })
                  }
                  field="label"
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2781"} alt="Fecha de Realización" />
                </label>
                <SipcoCalendar
                  value={filter.fechaRealizacion}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaRealizacion: e.value })
                  }
                  showButtonBar
                  showIcon
                  showTime
                />
              </div>
            </div>
            <Divider />
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Total de VIN's" />
                </label>
                <Chip label="0" />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Total de OT's" />
                </label>
                <Chip label="0" />
              </div>
            </div>
            <div className="form-row align-items-end">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1569"} alt="Operación" />
                </label>
                <SipcoInputText
                  value={filter?.operacion}
                  onChange={(e) =>
                    setFilter({ ...filter, operacion: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadOperacion(filter.operacion);
                    }
                  }}
                />
              </div>
              <div className="sipco-option">
                <SipcoInputText
                  value={operacion?.descripcion}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row align-items-end">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2521"} alt="Proveedor" />
                </label>
                <SipcoInputText
                  value={filter?.proveedor}
                  onChange={(e) =>
                    setFilter({ ...filter, proveedor: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadProveedor(filter.proveedor);
                    }
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <SipcoInputText
                  value={proveedor ? proveedor[0].descripcion : ""}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10828"} alt="Linea" />
                </label>
                <SipcoInputText value={filter.linea || ""} disabled={true} />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"8380"} alt="Resultado" />
                </label>
                <SipcoInputText
                  value={filter.resultado || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, resultado: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      load(filter.resultado);
                    }
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1669"} alt="Observación" />
                </label>
                <SipcoInputText />
              </div>
            </div>
          </div>
        </div>
        <Divider layout="vertical" />
        <div className="filter-options-right">
          <Panel
            className="sipco-AdmRemitosConcesionarios"
            headerTemplate={(props) => {
              return (
                <div className={props.className} onClick={props.onTogglerClick}>
                  <span className={props.titleClassName}>
                    <LanguageProvider
                      id={"14379"}
                      alt="Generación y Cierre de Ordenes de Trabajo"
                    />
                  </span>
                  <div className="action">
                    <ExportButtons
                      dt={dt}
                      data={data}
                      columns={columnsNames}
                      screenName={"AdministracionRemitos"}
                    />
                  </div>
                </div>
              );
            }}
          >
            <SipcoDataTable
              size={"small"}
              showGridlines
              stripedRows
              ref={dt}
              value={ingresoManual}
              paginator
              scrollHeight="flex"
              filterDisplay="menu"
              rows={25}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              style={{ maxWidth: "100%" }}
              loading={loading}
              selectionMode="multiple"
              selection={selectedRows}
              onSelectionChange={(e) => setSelectedRows(e.value)}
              metaKeySelection={true}
              dragSelection
            >
              {columnsName.map((colum, index) => {
                if (colum.header === "Actualizo con Fecha") {
                  return (
                    <SipcoColumn
                      key={index}
                      field={colum.field}
                      body={(e) => dateColumnTemplate(e, colum.field)}
                      header={colum.header}
                      sortable
                      filter
                    />
                  );
                } else {
                  return (
                    <SipcoColumn
                      key={index}
                      field={colum.field}
                      header={colum.header}
                      sortable
                      filter
                    />
                  );
                }
              })}
            </SipcoDataTable>
          </Panel>
        </div>
      </div>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"14379"}
                  alt="Generación y Cierre de Ordenes de Trabajo"
                />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          className="table-top-right"
          size={"small"}
          showGridlines
          stripedRows
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columNames1.map((colum, index) => (
            <SipcoColumn
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </SipcoDataTable>
      </Panel>
      <div>
        <Button
          label={LanguageProvider({
            id: "2366",
            alt: "Importar Archivo",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          text
          onClick={validaProcesarOperacion}
        />
        <Button
          label={LanguageProvider({
            id: "750",
            alt: "Limpiar Grilla",
          })}
          text
          onClick={resetFilter}
        />
      </div>
    </div>
  );
};
export default Masiva;
