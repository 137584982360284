import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const CargaMasivaAba: React.FC<{ vinData: any }> = ({ vinData }) => {
  const filterColumnsId = [1491, 14288, 3625];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [filter, setFilter] = useState({
    checkpoint: null,
    parteDanada: null,
    tipoDano: null,
    imputacion: null,
    cuadrante: null,
    unidadMedida: null,
    responsable: null,
    gravedadDano: null,
    codInspector: null,
    resultadoAnlisis: null,
    vin: null,
  });

  const [checkPoints, setCheckPoints] = useState([]);
  const [loadingCheckPoints, setLoadingCheckPoints] = useState(false);

  const [parteDanadas, setParteDanadas] = useState([]);
  const [loadingParteDanadas, setLoadingParteDanadas] = useState(false);

  const [tipoDano, setTipoDano] = useState([]);
  const [loadingTipoDano, setLoadingTipoDano] = useState(false);

  const [imputaciones, setImputaciones] = useState([]);
  const [loadingImputaciones, setLoadingImputaciones] = useState(false);

  const [cuadrantes, setCuadrantes] = useState([]);
  const [loadingCuadrantes, setLoadingCuadrantes] = useState(false);

  const [unidadMedidas, setUnidadMedidas] = useState([]);
  const [loadingUnidadMedidas, setLoadingUnidadMedidas] = useState(false);

  const [responsables, setResponsables] = useState([]);
  const [loadingResponsables, setLoadingResponsables] = useState(false);

  const [gravedadDanos, setGravedadDanos] = useState([]);
  const [loadingGravedadDanos, setLoadingGravedadDanos] = useState(false);

  const [codInspectores, setCodInspectores] = useState([]);
  const [loadingCodInspectores, setLoadingCodInspectores] = useState(false);

  const [resultadosAnlisis, setResultadosAnlisis] = useState([]);
  const [loadingResultadosAnlisis, setLoadingResultadosAnlisis] =
    useState(false);

  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);
  //VIN - PRONTO
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/vin",
          {
            pais: securityFilters?.securityValues?.country,
            cliente: securityFilters?.securityValues?.client,
            cuenta: securityFilters?.securityValues?.account,
            vin: { id: query },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  //RESULTADO ANALISIS - PRONTO
  async function loadResultadosAnlisis() {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      securityFilters.securityValues.account != null
    ) {
      setLoadingResultadosAnlisis(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/resultado-analis",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setResultadosAnlisis(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingResultadosAnlisis(false);
      }
    }
  }
  //COD INSPECTORES - PRONTO
  async function loadCodInspectores() {
    if (securityFilters.securityValues.country != null) {
      setLoadingCodInspectores(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/inspector-averia",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setCodInspectores(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCodInspectores(false);
      }
    }
  }
  //GRAVEDAD DANOS - PRONTO
  async function loadGravedadDanos() {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null
    ) {
      setLoadingGravedadDanos(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/gravedad-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setGravedadDanos(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGravedadDanos(false);
      }
    }
  }
  //RESPONSABLE - PRONTO depende de IMPUTACION
  async function loadResponsables(imputacion: any) {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      securityFilters.securityValues.account != null &&
      filter.checkpoint != null &&
      imputacion != null
    ) {
      setLoadingResponsables(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/responsable",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            imputacion: imputacion,
            checkpoint: filter.checkpoint,
          }
        );
        if (status === 200) {
          setResponsables(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingResponsables(false);
      }
    }
  }
  //UNIDAD MEDIDA - PRONTO depende de TIPO DANIO
  async function loadUnidadMedidas(tipoDano: any) {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      tipoDano != null
    ) {
      setLoadingUnidadMedidas(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/unidad-medida-playa",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            historicoDanos: {
              tipoDeDanio: tipoDano,
            },
          }
        );
        if (status === 200) {
          setUnidadMedidas(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingUnidadMedidas(false);
      }
    }
  }
  //CUADRANTES - PRONTO - depende de  PARTE DANADA
  async function loadCuadrantes(parteDanada: any) {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      parteDanada != null
    ) {
      setLoadingCuadrantes(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/cuadrante",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            historicoDanos: {
              partesDanios: parteDanada,
            },
          }
        );
        if (status === 200) {
          setCuadrantes(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCuadrantes(false);
      }
    }
  }
  //IMPUTACIONES - PRONTO - depende de CHECKPOINT
  async function loadImputaciones(checkpoint: any) {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      securityFilters.securityValues.account != null &&
      checkpoint != null
    ) {
      setLoadingImputaciones(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/imputacion",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            checkpoint: checkpoint,
          }
        );
        if (status === 200) {
          setImputaciones(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingImputaciones(false);
      }
    }
  }
  //TIPOS DANOS - PRONTO
  async function loadTipoDanos() {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null
    ) {
      setLoadingTipoDano(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/tipo-de-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setTipoDano(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTipoDano(false);
      }
    }
  }
  //PARTE DANADAS - PRONTO
  async function loadParteDanadas() {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null
    ) {
      setLoadingParteDanadas(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/partes-dano",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setParteDanadas(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingParteDanadas(false);
      }
    }
  }
  //CHECKPOINT - PRONTO
  async function loadCheckPoints() {
    if (
      securityFilters.securityValues.country != null &&
      securityFilters.securityValues.client != null &&
      securityFilters.securityValues.account != null
    ) {
      setLoadingCheckPoints(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/checkpoint",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setCheckPoints(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCheckPoints(false);
      }
    }
  }

  async function handleCheckpointChange(value: any) {
    try {
      const imputaciones = await loadImputaciones(value);
      setFilter({
        ...filter,
        checkpoint: value,
        imputacion: imputaciones[0] ?? null,
      });
      setImputaciones(imputaciones);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleParteDanadaChange(value: any) {
    try {
      const cuadrantes = await loadCuadrantes(value);
      setFilter({
        ...filter,
        parteDanada: value,
        cuadrante: cuadrantes[0] ?? null,
      });
      setCuadrantes(cuadrantes);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleTipoDanoChange(value: any) {
    try {
      const unidadesMedidas = await loadUnidadMedidas(value);
      setFilter({
        ...filter,
        tipoDano: value,
        unidadMedida: unidadesMedidas[0] ?? null,
      });
      setImputaciones(cuadrantes);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleImputacionChange(value: any) {
    try {
      const responsables = await loadResponsables(value);
      setFilter({
        ...filter,
        tipoDano: value,
        responsable: responsables[0] ?? null,
      });
      setResponsables(responsables);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const checkpointss = await loadCheckPoints();
          const parteDanadass = await loadParteDanadas();
          const tiposDanoss = await loadTipoDanos();
          const gravedadDanoss = await loadGravedadDanos();
          const codInspectores = await loadCodInspectores();
          const resultadoAnalisiss = await loadResultadosAnlisis();
          const imputacioness = await loadImputaciones(filter.checkpoint);
          setFilter({
            ...filter,
            gravedadDano: gravedadDanoss[0],
            codInspector: codInspectores[0],
            resultadoAnlisis: resultadoAnalisiss[0],
          });
          handleCheckpointChange(checkpointss[0]);
          handleParteDanadaChange(parteDanadass[0]);
          handleTipoDanoChange(tiposDanoss[0]);
          handleImputacionChange(imputacioness[0]);
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="vin-view">
      <Panel>
        <div className="form-row">
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>CheckPoint</label>
              <SipcoDropdown
                value={filter.checkpoint}
                options={checkPoints}
                onChange={(e) => handleCheckpointChange(e.value)}
                loading={loadingCheckPoints}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Parte dañada</label>
              <SipcoDropdown
                value={filter.parteDanada}
                options={parteDanadas}
                onChange={(e) => handleParteDanadaChange(e.value)}
                loading={loadingParteDanadas}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Descripción Daño</label>
              <SipcoDropdown
                value={filter.tipoDano}
                options={tipoDano}
                onChange={(e) => handleTipoDanoChange(e.value)}
                loading={loadingTipoDano}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Observaciones</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Nro. Check List</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Comentario Dossier</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
          </div>

          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Imputaciones</label>
              <SipcoDropdown
                value={filter.imputacion}
                options={imputaciones}
                onChange={(e) => handleImputacionChange(e.value)}
                loading={loadingImputaciones}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Cuadrante LD</label>
              <SipcoDropdown
                value={filter.cuadrante}
                options={cuadrantes}
                onChange={(e) => setFilter({ ...filter, cuadrante: e.value })}
                loading={loadingCuadrantes}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Unidad de Medida</label>
              <SipcoDropdown
                value={filter.unidadMedida}
                options={unidadMedidas}
                onChange={(e) =>
                  setFilter({ ...filter, unidadMedida: e.value })
                }
                loading={loadingUnidadMedidas}
                optionLabel="descripcion"
                filter
                showClear
              />
              <label>Valor</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Fecha del daño</label>
              <SipcoCalendar
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                value={new Date()}
              />
              <label>Importe</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Fecha SAGAI</label>
              <SipcoCalendar
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                value={new Date()}
              />
            </div>
          </div>
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Responsable</label>
              <SipcoDropdown
                value={filter.responsable}
                options={responsables}
                onChange={(e) => setFilter({ ...filter, responsable: e.value })}
                loading={loadingResponsables}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Gravedad del Daño</label>
              <SipcoDropdown
                value={filter.gravedadDano}
                options={gravedadDanos}
                onChange={(e) =>
                  setFilter({ ...filter, gravedadDano: e.value })
                }
                loading={loadingGravedadDanos}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Cod. Inspector</label>
              <SipcoDropdown
                value={filter.codInspector}
                options={codInspectores}
                onChange={(e) =>
                  setFilter({ ...filter, codInspector: e.value })
                }
                loading={loadingCodInspectores}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Resultado Analisis</label>
              <SipcoDropdown
                value={filter.resultadoAnlisis}
                options={resultadosAnlisis}
                onChange={(e) =>
                  setFilter({ ...filter, resultadoAnlisis: e.value })
                }
                loading={loadingResultadosAnlisis}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className="sipco-option">
              <label>Nro. Dossier</label>
              <SipcoInputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
          </div>
        </div>
      </Panel>
      <Fieldset
        legend={
          <label>
            Datos - Cantidad de Registros a Procesar.: 0 - Registros con
            errores.: 0
          </label>
        }
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <LanguageProvider id={1491} alt="VIN:" />
          </label>
          <SipcoAutocomplete
            value={filter.vin}
            suggestions={vin}
            completeMethod={searchVin}
            onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
            field="label"
            dropdown
            forceSelection
          />
        </div>

        <Divider />

        <SipcoDataTable
          size="small"
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          selectionMode="single"
        >
          <SipcoColumn
            field="1"
            header={columnsNames[0].label}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={columnsNames[1].label}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={columnsNames[2].label}
            sortable
            filter
          />
        </SipcoDataTable>
        <div>
          <Button label={LanguageProvider({ id: "23824", alt: "Alta" })} text />
        </div>
      </Fieldset>
    </div>
  );
};

export default CargaMasivaAba;
