import "./InformaTransportista.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function InformaTransportista() {
  const SCREEN_CODE = "fun0227_informa_transportista";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [filter, setFilter] = useState({
    fecha: null,
    calle: null,
    columna: null,
    nivel: null,
    vin: null,
    despachante: null,
    marca: null,
    modelo: null,
    transporte: null,
    categoria: null,
    transportista: null,
    playaDestino: null,
    exportacion: false,
  });
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];
  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }
  const [exportacion, setExportacion] = useState(false);

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);

  const [vin, setVin] = useState([]);

  const [despachante, setDespachante] = useState([]);
  const [loadingDespachante, setLoadingDespachante] = useState(false);

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  const [transporte, setTransporte] = useState([]);
  const [loadingTransporte, setLoadingTransporte] = useState(false);

  const [categoria, setCategoria] = useState([]);
  const [loadingCategoria, setLoadingCategoria] = useState(false);

  const [fecha, setFecha] = useState([]);
  const [loadingFecha, setLoadingFecha] = useState(false);

  const [transportista, setTransportista] = useState([]);
  const [loadingTransportista, setLoadingTransportista] = useState(false);

  const [playaDestino, setPlayaDestino] = useState([]);
  const [loadingPlayaDestino, setLoadingPlayaDestino] = useState(false);

  async function loadReportData() {}
  async function loadCalle() {
    try {
      setLoadingCalle(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        exportacion != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/calles",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            exportacion: filter.exportacion,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    return [];
  }
  async function loadColumna(calle: any) {
    try {
      setLoadingColumna(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        calle != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/columnas",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }

  async function loadNivel(columna: any) {
    try {
      setLoadingNivel(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        filter.calle != null &&
        columna != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/niveles",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: filter?.calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        query != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadDespachante() {
    try {
      setLoadingDespachante(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/proveedor",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            cliente: securityFilters.securityValues.client,
            tipoDeProveedor: {
              id: 3,
            },
          }
        );

        if (status === 200) {
          setDespachante(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDespachante(false);
    }
    return [];
  }
  async function loadTransportista() {
    try {
      setLoadingTransportista(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/proveedor",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            cliente: securityFilters.securityValues.client,
            tipoDeProveedor: {
              id: 1,
            },
          }
        );

        if (status === 200) {
          setTransportista(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
    return [];
  }
  async function loadMarca() {
    try {
      setLoadingMarca(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/marcas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );

        if (status === 200) {
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    return [];
  }
  async function loadModelo(marca: any) {
    try {
      setLoadingModelo(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/modelos",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );

        if (status === 200) {
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    return [];
  }
  async function loadCategoria(modelo: any) {
    try {
      setLoadingCategoria(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        filter.marca != null &&
        modelo != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/categorias",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: filter.marca,
            modelo: modelo,
          }
        );

        if (status === 200) {
          setCategoria(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategoria(false);
    }
    return [];
  }

  async function loadTransporte() {
    try {
      setLoadingTransporte(true);
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/transportes",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setTransporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporte(false);
    }
    return [];
  }

  async function loadFecha(transporte: any) {
    try {
      setLoadingFecha(true);
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        transporte != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/find-fechas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            transporte: transporte,
          }
        );

        if (status === 200) {
          setFecha(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFecha(false);
    }
    return [];
  }
  const usuarioLogado = JSON.parse(localStorage.getItem("loginData"));

  async function loadSubcuenta() {
    try {
      setLoadingPlayaDestino(true);
      if (
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/informar-transportista/subcuenta",
          {
            usuario: {
              id: usuarioLogado.userName,
              idioma: {
                id: usuarioLogado.idioma.codIdioma,
              },
              nivel: usuarioLogado.nivel,
            },
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          setPlayaDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayaDestino(false);
    }
    return [];
  }
  useEffect(() => {
    async function initialize() {
      if (
        securityFilters.securityValues.country != null &&
        securityFilters.securityValues.account != null &&
        securityFilters.securityValues.subaccount != null
      ) {
        try {
          const calles = await loadCalle();
          const despachantes = await loadDespachante();
          const transportistas = await loadTransportista();
          const marcas = await loadMarca();
          const transportes = await loadTransporte();
          const playasDestino = await loadSubcuenta();
          setFilter({
            ...filter,
            despachante: despachantes[0],
            transportista: transportistas[0],
            playaDestino: playasDestino[0],
          });
          await handleMarcaChange(marcas[0]);
          await handleCalleChange(calles[0]);
          await handleTransporteChange(transportes[0]);
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [
    securityFilters.securityValues.country,
    securityFilters.securityValues.account,
    securityFilters.securityValues.subaccount,
    filter.exportacion,
  ]);

  async function handleCalleChange(calle: any) {
    try {
      const columnas = await loadColumna(calle);
      setFilter({
        ...filter,
        calle: calle,
      });
      await handleColumnaChange(columnas[0]);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleColumnaChange(columna: any) {
    try {
      const niveles = await loadNivel(columna);
      setFilter({
        ...filter,
        columna: columna,
        nivel: niveles[0],
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleMarcaChange(value: any) {
    try {
      const modelos = await loadModelo(value);
      setFilter({
        ...filter,
        marca: value,
      });
      handleModeloChange(modelos[0]);
    } catch (error) {
      console.error(error);
    }
  }
  async function handleModeloChange(value: any) {
    try {
      const categorias = await loadCategoria(value);
      setFilter({
        ...filter,
        modelo: value,
        categoria: categorias[0],
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function handleTransporteChange(value: any) {
    try {
      const fechas = await loadFecha(value);
      setFilter({
        ...filter,
        transporte: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="informa-transportista">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"921"} alt="Ubicaciones" />}
        >
          <div className="form">
            <div className="filter-options">
              <div className={"sipco-options-line"}>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"411"} alt="Exportación" />
                  </label>
                  <Checkbox
                    checked={filter.exportacion}
                    onChange={(e) =>
                      setFilter({ ...filter, exportacion: e.checked })
                    }
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"990"} alt="Calle " />
                  </label>
                  <SipcoDropdown
                    value={filter.calle}
                    options={calle}
                    onChange={(e) => handleCalleChange(e.value)}
                    loading={loadingCalle}
                    filter
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"991"} alt="Columna" />
                  </label>
                  <SipcoDropdown
                    value={filter.columna}
                    options={columna}
                    onChange={(e) => handleColumnaChange(e.value)}
                    loading={loadingColumna}
                    filter
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"992"} alt="Nivel" />
                  </label>
                  <SipcoDropdown
                    value={filter.nivel}
                    options={nivel}
                    onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                    loading={loadingNivel}
                    filter
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  forceSelection
                />{" "}
                <label>
                  <LanguageProvider id={"396"} alt="Marca " />
                </label>
                <SipcoDropdown
                  value={filter.marca}
                  options={marca}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcion"
                  loading={loadingMarca}
                  filter
                />
                <label>
                  <LanguageProvider id={"376"} alt="Transporte " />
                </label>
                <SipcoDropdown
                  value={filter.transporte}
                  options={transporte}
                  onChange={(e) => handleTransporteChange(e.value)}
                  optionLabel="descripcion"
                  loading={loadingTransporte}
                  filter
                />
                <label>
                  <LanguageProvider id={"1256"} alt="Categoria " />
                </label>
                <SipcoDropdown
                  value={filter.categoria}
                  options={categoria}
                  onChange={(e) => setFilter({ ...filter, categoria: e.value })}
                  optionLabel="descripcion"
                  loading={loadingCategoria}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1058"} alt="Despachante" />
                </label>
                <SipcoDropdown
                  value={filter.despachante}
                  options={despachante}
                  onChange={(e) =>
                    setFilter({ ...filter, despachante: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingDespachante}
                  filter
                />
                <label>
                  <LanguageProvider id={"408"} alt="Modelos" />
                </label>
                <SipcoDropdown
                  value={filter.modelo}
                  options={modelo}
                  onChange={(e) => handleModeloChange(e.value)}
                  optionLabel="descripcion"
                  loading={loadingModelo}
                  filter
                />
                <label>
                  <LanguageProvider id={"2131"} alt="Fecha" />
                </label>
                <SipcoDropdown
                  value={filter.fecha}
                  options={fecha}
                  onChange={(e) => setFilter({ ...filter, fecha: e.value })}
                  optionLabel="descripcion"
                  loading={loadingFecha}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "34",
                    alt: "Procesar o guardar cambios",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <SipcoColumn field="0" header={"VIN"} sortable filter />
          <SipcoColumn field="1" header={"Marca"} sortable filter />
          <SipcoColumn field="2" header={"Modelo"} sortable filter />
          <SipcoColumn field="3" header={"Color"} sortable filter />
          <SipcoColumn field="4" header={"Punto de Retiro"} sortable filter />
          <SipcoColumn field="5" header={"Transportista"} sortable filter />
          <SipcoColumn field="6" header={"Despachante"} sortable filter />
          <SipcoColumn field="7" header={"Terminal"} sortable filter />
          <SipcoColumn field="8" header={"Categoría"} sortable filter />
          <SipcoColumn field="9" header={"Motor"} sortable filter />
        </SipcoDataTable>
        <Divider />
        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"18071"} alt="Seleccione Transportista" />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"18071"} alt="Fecha de Recepcion" />
              </label>
            </div>
          </div>
        </div>
        <Accordion>
          <AccordionTab
            header={
              <LanguageProvider
                id={"884"}
                alt="Ingreso de Datos para la Recepción"
              />
            }
          >
            <div className="form">
              <div className={"form-row"}>
                <div className="sp-option">
                  <label>
                    <LanguageProvider id={"18071"} alt="Fecha de Recepcion" />
                  </label>
                  <SipcoCalendar showIcon showTime />
                  <label>
                    <LanguageProvider
                      id={"1063"}
                      alt="Seleccione Transportista"
                    />
                  </label>
                  <SipcoDropdown
                    value={filter.transportista}
                    options={transportista}
                    onChange={(e) =>
                      setFilter({ ...filter, transportista: e.value })
                    }
                    optionLabel="descripcion"
                    loading={loadingTransportista}
                    filter
                  />{" "}
                  <label>
                    <LanguageProvider id={"740"} alt="Playa Destino" />
                  </label>
                  <SipcoDropdown
                    value={filter.playaDestino}
                    options={playaDestino}
                    onChange={(e) =>
                      setFilter({ ...filter, playaDestino: e.value })
                    }
                    optionLabel="descripcion"
                    loading={loadingPlayaDestino}
                    filter
                  />{" "}
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
    </div>
  );
}
