import "./ConsultaGeneralTransacciones.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TiemposEntrega() {
  const SCREEN_CODE = "con0055_consulta_general_de_transacciones";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);

  const columns = [];
  const filterLabelId = [466, 467, 468];
  const filterNames = [];
  const filterColumnsId = [
    31, 4589, 1491, 16495, 16496, 21428, 3753, 3753, 12103, 21412,
  ];
  const columnsNames = [];

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 466, 467, 468, 1002, 1182, 31, 4589, 1491,
    16495, 16496, 21427, 21428, 748, 749, 16501, 3753, 12103, 21412,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    transacciones: [],
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [transacciones, setTransacciones] = useState([]);
  const [loadingTransacciones, setLoadingTransacciones] = useState(false);
  async function loadTransacciones() {
    setLoadingTransacciones(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/combo/transacciones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setTransacciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransacciones(false);
    }
  }

  const [reportData, setReportData] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        transacciones: filter?.transacciones.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/consulta-general-transacciones",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const transacciones = await loadTransacciones();
          setFilter({
            ...filter,
            transacciones: transacciones,
            fechaDesde: new Date(),
            fechaHasta: new Date(),
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const formattedReportData = convertDateObjects(reportData?.filas);

  return (
    <div className="consulta-general-transacciones">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"466"} alt="Transacciones" />
                </label>
                <SipcoMultiSelect
                  value={filter?.transacciones}
                  options={transacciones}
                  onChange={(e) =>
                    setFilter({ ...filter, transacciones: e.value || [] })
                  }
                  display="chip"
                  filter
                  showClear
                  loading={loadingTransacciones}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"6697"} alt="Listado" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"ConsultaGeneralTransacciones"}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default TiemposEntrega;
