import { InputText, InputTextProps } from "primereact/inputtext";

import React from "react";

type SipcoInputTextProps = InputTextProps & {};

const SipcoInputText = React.forwardRef<HTMLInputElement, SipcoInputTextProps>(
  ({ ...props }, ref) => {
    return <InputText className="sipco-input-text" {...props} ref={ref} />;
  }
);

export default SipcoInputText;
