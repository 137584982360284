import "./mantener-hojas-ruta.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import MantenerHojaRutaCambiarFechaDialog from "./componentes/mantener-hoja-ruta-cambiar-fecha-dialog";
import MantenerHojaRutaDialog from "./componentes/mantener-hoja-ruta-dialog";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MentenerHojasRuta() {
  const SCREEN_CODE = "fun0090_modificacion_manual_hr";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef(null);
  const dt = useRef(null);
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const [displayFormDialog, setDisplayFormDialog] = useState(false);
  const [displayCambiarFechaDialog, setDisplayCambiarFechaDialog] =
    useState(false);
  const [formModel, setFormModel] = useState(null);
  const [cambiarFechaFormModel, setCambiarFechaFormModel] = useState({
    fecha: new Date(),
  });

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        postion: "bottom-left",
        summary: <LanguageProvider id={"13"} alt={"Erro"} />,
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const showSuccess = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "success",
        postion: "bottom-left",
        summary: "",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getRowStyles = (data) => {
    let className = "";

    if (selectedHr && JSON.stringify(selectedHr) === JSON.stringify(data)) {
      className = "highlight-row";
    }

    //verificar validacao    --vin.tieneOperacionesPendientes == null
    if (data.length > 0) {
      className += " color-font-bold ";
    }

    return className.trim();
  };

  const [selectedHr, setSelectedHr] = useState(null);
  const [hojaRuta, setHojaRuta] = useState(null);
  const [selectedChildReportData, setSelectedChildReportData] = useState(null);

  const rowStyles = useRowStyles([selectedHr, selectedChildReportData]);
  const { formatDateTime } = useFormattedDate();

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);
  const formattedReportData = convertDateObjects(reportData?.filas);

  const [loadingChildReportData, setLoadingChildReportData] = useState(false);
  const [childReportData, setChildReportData] = useState([]);

  const [filterForm, setFilterForm] = useState({
    vin: null,
    hr: null,
    fechaDespachoDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/find-vin",
          {
            codPais: securityFilters?.securityValues?.country?.id,
            codCuenta: securityFilters?.securityValues?.account?.id,
            vin: {
              id: vin,
            },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "900",
              "No existe VIN para el Cliente, Cuenta y Subcuenta seleccionado"
            );
          }
          setVin(data);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadReportData() {
    setLoadingReportData(true);
    try {
      let filters = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        vinObj: filterForm?.vin ?? null,
        numeroHojaRuta: filterForm?.hr || null,
        rangeFecha: [filterForm?.fechaDespachoDesde, filterForm?.fechaHasta],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  async function loadReportDataDetails() {
    setLoadingChildReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        idHojaRuta: selectedHr[0] || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/linea-hoja-ruta",
        filters
      );

      if (status === 200) {
        setChildReportData(Array.isArray(data) ? data : []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChildReportData(false);
    }
  }
  async function findHojaRuta(codigoHojaRuta) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/findFullByPkPaisClienteCuenta",
        {
          id: codigoHojaRuta,
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleGridCabeceraSelection(row: any) {
    try {
      setSelectedHr(row);
      const hojaRuta = await findHojaRuta(row[0]);
      if (hojaRuta) {
        setHojaRuta(hojaRuta);
      }
    } catch (error) {
      console.error(error);
    }
  }

  //#region Alta
  async function onAltaClick() {
    try {
      setDisplayFormDialog(true);
      setFormModel({ id: 0, fechaDespacho: new Date() });
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion
  async function onModificarClick() {
    try {
      setDisplayFormDialog(true);
      setFormModel({ id: 1 });
    } catch (error) {
      console.error(error);
    }
  }

  //#region Borrar
  function onBorrarClick() {
    try {
      confirmDialog({
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        message: (
          <LanguageProvider id={"829"} alt="¿Elimina el o los registros?" />
        ),
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => validarBorrar(hojaRuta),
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function validarBorrar(hojaRuta: any) {
    try {
      if (!hojaRuta) {
        showError("753", "Deve selecionar ao menos um registro do quadro");
        return;
      }
      if (hojaRuta.origenWs) {
        showError(
          "24521",
          "La Hoja de Ruta seleccionada fue creada en otra aplicación, no puede ser modificada en SIPCO"
        );
        return;
      }
      await remove(hojaRuta);
    } catch (error) {
      console.error(error);
    }
  }
  async function remove(hojaRuta: any) {
    try {
      const { status } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/remove",
        hojaRuta
      );
      if (status === 200) {
        showSuccess("gen.delete.success.message", "Registros eliminados");
        loadReportData();
      }
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  //#region Cambiar Fecha
  async function validarCambiarFecha() {
    try {
      if (!hojaRuta) {
        showError("753", "Deve selecionar ao menos um registro do quadro");
        return;
      }
      if (hojaRuta.origenWs) {
        showError(
          "24521",
          "La Hoja de Ruta seleccionada fue creada en otra aplicación, no puede ser modificada en SIPCO"
        );
        return;
      }
      if (hojaRuta.estado.id !== "1") {
        showError(
          "10006",
          'Para poder Modificar a Data da folha de rota, aa mesma deve estar em estado "nova"'
        );
        return;
      }
      setDisplayCambiarFechaDialog(true);
      setCambiarFechaFormModel({
        fecha: new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function aceptarCambioFecha(fechaNueva: any) {
    try {
      setDisplayCambiarFechaDialog(false);
      await validateCambioFecha(hojaRuta, fechaNueva);
    } catch (error) {
      console.error(error);
    }
  }
  async function validateCambioFecha(hojaRuta: any, newFecha: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/validate-cambio-fecha",
        {
          fecha: newFecha,
          hojaRuta: hojaRuta,
        }
      );
      if (status === 200) {
        const isValid = await validateCambioFechaResult(data);
        if (isValid) {
          hojaRuta.fechaHojaRuta = { date: newFecha, timeStamp: newFecha };
          cambiarFecha(hojaRuta);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function validateCambioFechaResult(result: any) {
    try {
      if (result != 0) {
        showError(result, "Erro ao validar nova data!");
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return true;
  }
  async function cambiarFecha(hojaRuta: any) {
    try {
      const { status } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/update-fecha",
        hojaRuta
      );
      if (status === 200) {
        showSuccess("14755", "Los cambios se guardaron exitosamente.");
        loadReportData();
      }
    } catch (error) {
      console.error(error);
    }
    return true;
  }
  //#endregion

  //#region Cancelar Contable
  async function validarCancelarContable() {
    try {
      if (!hojaRuta) {
        showError("753", "Deve selecionar ao menos um registro do quadro");
        return;
      }
      await validateCancelarContable(hojaRuta);
    } catch (error) {
      console.error(error);
    }
  }
  async function validateCancelarContable(hojaRuta: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/validate-cancelar-contable",
        hojaRuta
      );
      if (status === 200) {
        const isValid = await validarCancelarContableResult(data);
        if (isValid) {
          confirmDialog({
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            message: (
              <LanguageProvider id={"28"} alt="¿Confirma las modificaciones?" />
            ),
            header: <LanguageProvider id={"16239"} alt="Atención" />,
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "reject",
            accept: () => cancelaContable(hojaRuta),
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function validarCancelarContableResult(result: any) {
    try {
      if (result != "") {
        showError(result, "Erro ao cancelar contable!");
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return true;
  }
  async function cancelaContable(hojaRuta: any) {
    try {
      await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/cancela-contable",
        hojaRuta
      );
      await loadReportData();
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  //#region Contabilizar
  async function validarContabilizar() {
    try {
      if (!hojaRuta) {
        showError("753", "Deve selecionar ao menos um registro do quadro");
        return;
      }
      await validateContabilizar(hojaRuta);
    } catch (error) {
      console.error(error);
    }
  }
  async function validateContabilizar(hojaRuta: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/validate-contabilizar",
        hojaRuta
      );
      if (status === 200) {
        const isValid = await validarContabilizarResult(data);
        if (isValid) {
          await contabilizar(hojaRuta);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function validarContabilizarResult(result: any) {
    try {
      if (result != "") {
        showError(result, "Erro ao contabilizar!");
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return true;
  }
  async function contabilizar(hojaRuta: any) {
    try {
      await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/genera-contable",
        hojaRuta
      );
      await loadReportData();
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  useEffect(() => {
    if (selectedHr) {
      loadReportDataDetails();
    }
  }, [selectedHr]);

  const acceptLabel = LanguageProvider({ id: "10043", alt: "Sim" });
  const rejectLabel = LanguageProvider({ id: "10044", alt: "Não" });

  return (
    <div className="mantener-hojas-ruta">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filterForm.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilterForm({ ...filterForm, vin: e.value })
                  }
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5431"} alt="HR" />
                </label>
                <SipcoInputText
                  value={filterForm.hr}
                  onChange={(e) =>
                    setFilterForm({ ...filterForm, hr: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"25192"} alt="Fecha Despacho Desde" />
                </label>
                <SipcoCalendar
                  value={filterForm.fechaDespachoDesde}
                  onChange={(e) => {
                    setFilterForm({
                      ...filterForm,
                      fechaDespachoDesde: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filterForm.fechaHasta}
                  onChange={(e) => {
                    setFilterForm({ ...filterForm, fechaHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="mb-3"
        header={<LanguageProvider id={"4301"} alt="Mantener Hojas de Ruta" />}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          onSelectionChange={(e) => handleGridCabeceraSelection(e.value)}
          selection={selectedHr}
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="924" alt="Hoja de Ruta" />}
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="16453" alt="Fecha Hoja de Ruta" />}
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="3344" alt="Estado" />}
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="10136" alt="Km." />}
            sortable
            filter
            body={(rowData) => (rowData[18] ? rowData[18].toFixed(2) : "0,00")}
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="4285" alt="Tractor" />}
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="10902" alt="Batea" />}
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="4655" alt="Chofer" />}
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="4655" alt="Chofer" />}
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="3206" alt="Tipo Servicio" />}
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="16454" alt="Proveedor Remolque" />}
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="16454" alt="Proveedor Remolque" />}
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="16773" alt="Aforo compra" />}
            sortable
            filter
            body={(rowData) => (rowData[19] ? rowData[19].toFixed(2) : "0,00")}
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="16455" alt="Aforo Venta" />}
            filter
            body={(rowData) => (rowData[24] ? rowData[24].toFixed(2) : "0,00")}
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="16456" alt="Modificó" />}
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="16774" alt="Hoha ruta anterior" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="1849" alt="Compra" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="1850" alt="Venta" />}
            sortable
            filter
            body={(rowData) =>
              rowData[29] === 1 ? (
                <LanguageProvider id="10043" alt="Si" />
              ) : (
                <LanguageProvider id="10044" alt="No" />
              )
            }
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="5592" alt="Carga Combinada" />}
            sortable
            filter
            body={(rowData) =>
              rowData[28] === 1 ? (
                <LanguageProvider id="10043" alt="Si" />
              ) : (
                <LanguageProvider id="10044" alt="No" />
              )
            }
          />
          <SipcoColumn
            field="30"
            header={<LanguageProvider id="23020" alt="Tipo Viaje Retorno" />}
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="3625" alt="Observaciones" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="5371" alt="Externa" />}
            sortable
            filter
            body={(rowData) =>
              rowData[36] === 1 ? (
                <LanguageProvider id="10043" alt="Si" />
              ) : (
                <LanguageProvider id="10044" alt="No" />
              )
            }
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="23097" alt="Código referencia" />}
          />
          <SipcoColumn
            field="37"
            header={<LanguageProvider id="25193" alt="COEF" />}
          />
          <SipcoColumn
            field="39"
            header={<LanguageProvider id="24991" alt="Limite inferior" />}
            sortable
            filter
            body={(rowData) => (rowData[39] ? rowData[39].toFixed(2) : "0,00")}
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="24992" alt="Limite Superior" />}
          />
        </SipcoDataTable>
      </Panel>
      <Panel
        className="mb-3"
        header={<LanguageProvider id={"16226"} alt="Listado" />}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={Array.isArray(childReportData) ? childReportData : []}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingChildReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedChildReportData(e.value)}
          selection={selectedChildReportData}
        >
          <SipcoColumn
            field="cliente.descripcion"
            header={<LanguageProvider id="0" alt="Cliente" />}
          />
          <SipcoColumn
            field="vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="modelo.patente"
            header={<LanguageProvider id="4724" alt="Patente" />}
          />
          <SipcoColumn
            field="remito"
            header={<LanguageProvider id="3184" alt="Remito" />}
          />
          <SipcoColumn
            field="subcuentaOrigen.descripcion"
            header={<LanguageProvider id="16457" alt="Playa de la Unidad" />}
          />
          <SipcoColumn
            field="direccionEntregaP.descripcion"
            header={<LanguageProvider id="740" alt="Playa Destino" />}
          />
          <SipcoColumn
            field="concesionarioP.descripcion"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="22927" alt="Playa/Punto Origen" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="22928" alt="Playa/Punto destino" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="4399" alt="Posición" />}
          />
          <SipcoColumn
            field="ordenEntrega"
            header={<LanguageProvider id="16459" alt="Orden entrega" />}
          />
          <SipcoColumn
            field="usuarioUltimaModificacion.id"
            header={<LanguageProvider id="id" alt="Modificó" />}
          />
          <SipcoColumn
            field="fechaUltimaModificacion.date"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaUltimaModificacion?.date
                ? formatDateTime(rowData.fechaUltimaModificacion.date)
                : rowData.fechaUltimaModificacion || ""
            }
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="164" alt="Ciudad" />}
          />
          <SipcoColumn
            field="tipoRegistro.descripcion"
            header={<LanguageProvider id="504" alt="Tipo Despacho" />}
          />
          <SipcoColumn
            field="estado.descripcion"
            header={<LanguageProvider id="3344" alt="Estado" />}
          />
          <SipcoColumn
            field="vin.marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="16460" alt="Pto Retiro" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="16460" alt="Pto Retiro" />}
          />
          <SipcoColumn
            field="vin.marca.id"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="coeficienteVin"
            header={<LanguageProvider id="25194" alt="Coef Vin" />}
          />
        </SipcoDataTable>
        <div className="flex flex-wrap gap-2 mt-5">
          <Button
            label={LanguageProvider({ id: "23824", alt: "Alta" })}
            icon="pi pi-plus-circle"
            onClick={onAltaClick}
          />
          <Button
            label={LanguageProvider({ id: "19267", alt: "Modificar" })}
            icon="pi pi-pencil"
            onClick={onModificarClick}
          />
          <Button
            label={LanguageProvider({ id: "2016", alt: "Borrar" })}
            icon="pi pi-times"
            onClick={onBorrarClick}
          />
          <Button
            label={LanguageProvider({ id: "16785", alt: "Cambiar fecha" })}
            icon="pi pi-calendar"
            onClick={validarCambiarFecha}
          />
          <Button
            label={LanguageProvider({
              id: "11683",
              alt: "Cancelar Contable",
            })}
            icon="pi pi-times-circle"
            onClick={validarCancelarContable}
          />
          <Button
            label={LanguageProvider({ id: "1309", alt: "Contabilizar" })}
            icon="pi pi-check-circle"
            onClick={validarContabilizar}
          />
          <Button
            label={LanguageProvider({
              id: "5513",
              alt: "Listado de hojas de ruta",
            })}
            icon="pi pi-file"
            onClick={() => window.open("/listado-de-hojas-de-rutas", "_blank")}
          />
          {/*
          <Button
            label={LanguageProvider({ id: "5522", alt: "Posición Batea" })}
            icon="pi pi-box"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnPosicionBateaDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Button
            label={LanguageProvider({ id: "1309", alt: "Contabilizar" })}
            icon="pi pi-check-circle"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnContabilizarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Button
            label={LanguageProvider({
              id: "5513",
              alt: "Listado de hojas de ruta",
            })}
            icon="pi pi-file"
            onClick={() => window.open("/listado-de-hojas-de-rutas", "_blank")}
          />
          <Button
            label={LanguageProvider({
              id: "25067",
              alt: "Recalcular KM",
            })}
            icon="pi pi-calculator"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;
              const isDespachada = selectedReportData?.["2"] === "4"; //estado 4-despachada

              if (hasData) {
                isDespachada
                  ? setValidaDespachKMDialog(true)
                  : setBtnRecalcKMDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Button
            label={LanguageProvider({
              id: "25103",
              alt: "Generar Guía al SPI",
            })}
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnContabilizarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          /> */}
          {/* Dialog de aviso para selecionar ao menos um registro */}
          {/* <Dialog
              header={getLabel("16239", "Atención")}
              visible={validaDataDialog}
              style={{ width: "20vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    onClick={() => setValidaDataDialog(false)}
                    autoFocus
                  />
                </div>
              }
              onHide={() => setValidaDataDialog(false)}
            >
              <p>
                {getLabel(
                  "753",
                  "Debe seleccionar al menos un registro de la Grilla."
                )}
              </p>
            </Dialog> */}

          {/* <Dialog
              header={getLabel("16239", "Atención")}
              visible={validaDespachadaDialog}
              style={{ width: "20vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    onClick={() => setValidaDespachadaDialog(false)}
                    autoFocus
                  />
                </div>
              }
              onHide={() => setValidaDespachadaDialog(false)}
            >
              <p>
                {getLabel(
                  "5451",
                  "La hoja de ruta esta despachada, debe cancelar contablemente el despacho antes de modificar"
                )}
              </p>
            </Dialog>
            <Dialog
              header={`${getLabel("4301", "Mantener Hojas de Ruta")} - ${getLabel("19267", "Modificar")}`}
              visible={btnModificarDialog}
              style={{ width: "40vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("9491", "Aceptar")}
                    icon="pi pi-check"
                    onClick={() => handleBtnModificar(true)}
                    autoFocus
                    disabled={loadingBtnModificar}
                  />
                  <Button
                    label={getLabel("3155", "Cancelar")}
                    icon="pi pi-times"
                    onClick={() => handleBtnModificar(false)}
                    className="p-button-text"
                    disabled={loadingBtnModificar}
                  />
                </div>
              }
              onHide={() => setBtnModificarDialog(false)}
            >
              {loadingBtnModificar && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog> */}

          {/* validacao ao borrar se origenWs is true entao abrir dialog, id= "24521" */}
          {/* <Dialog
              header={`${getLabel("16239", "Atención")}`}
              visible={btnBorrarDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("9491", "Aceptar")}
                    icon="pi pi-check"
                    onClick={() => handleBtnBorrar(true)}
                    autoFocus
                    disabled={loadingBtnBorrar}
                  />
                  <Button
                    label={getLabel("3155", "Cancelar")}
                    icon="pi pi-times"
                    onClick={() => handleBtnBorrar(false)}
                    className="p-button-text"
                    disabled={loadingBtnBorrar}
                  />
                </div>
              }
              onHide={() => setBtnBorrarDialog(false)}
            >
              <p>{getLabel("829", "¿Elimina el o los registros?")}</p>
              {loadingBtnBorrar && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog> */}

          {/* Dialog origenWs */}

          {/* <Dialog
              header={getLabel("16239", "Atención")}
              visible={validaNuevaDialog}
              style={{ width: "20vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    onClick={() => setValidaNuevaDialog(false)}
                    autoFocus
                  />
                </div>
              }
              onHide={() => setValidaNuevaDialog(false)}
            >
              <p>
                {getLabel(
                  "5429",
                  "La compra de la hoja de ruta no ha sido efectuada todavía, no puede generar los negativos"
                )}
              </p>
            </Dialog> */}
          {/* <Dialog
              header={`${getLabel("16239", "Atención")}`}
              visible={btnCancContableDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("9491", "Aceptar")}
                    icon="pi pi-check"
                    onClick={() => handleBtnCancelarContable(true)}
                    autoFocus
                    disabled={loadingBtnCancContable}
                  />
                  <Button
                    label={getLabel("3155", "Cancelar")}
                    icon="pi pi-times"
                    onClick={() => handleBtnCancelarContable(false)}
                    className="p-button-text"
                    disabled={loadingBtnCancContable}
                  />
                </div>
              }
              onHide={() => setBtnCancContableDialog(false)}
            >
              <p>{getLabel("28", "¿Confirma las modificaciones?")}</p>
              {loadingBtnCancContable && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog> */}

          {/* <Dialog
              header={getLabel("16239", "Atención")}
              visible={validaDespachKMDialog}
              style={{ width: "20vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    onClick={() => setValidaDespachKMDialog(false)}
                    autoFocus
                  />
                </div>
              }
              onHide={() => setValidaDespachKMDialog(false)}
            >
              <p>
                {getLabel(
                  "25202",
                  `La HR se encuentra en estado DESPACHADA por lo que no puede Recalcular los KM`
                )}
              </p>
            </Dialog> */}
          {/* <Dialog
              header={`${getLabel("16239", "Atención")}`}
              visible={btnRecalcKMDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    label={getLabel("9491", "Aceptar")}
                    icon="pi pi-check"
                    onClick={() => handleBtnRecalcKM(true)}
                    autoFocus
                    disabled={loadingBtnRecalcKM}
                  />
                  <Button
                    label={getLabel("3155", "Cancelar")}
                    icon="pi pi-times"
                    onClick={() => handleBtnRecalcKM(false)}
                    className="p-button-text"
                    disabled={loadingBtnRecalcKM}
                  />
                </div>
              }
              onHide={() => setBtnRecalcKMDialog(false)}
            >
              <p>{getLabel("25069", "¿Desea recalcular los KM de la HR?")}</p>
              {loadingBtnRecalcKM && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog> */}
        </div>
      </Panel>
      <ConfirmDialog />
      <MantenerHojaRutaDialog
        visible={displayFormDialog}
        model={formModel}
        onClose={() => {
          setDisplayFormDialog(false);
          setFormModel(null);
        }}
      />
      <MantenerHojaRutaCambiarFechaDialog
        visible={displayCambiarFechaDialog}
        model={cambiarFechaFormModel}
        onCambiarFecha={aceptarCambioFecha}
        onClose={() => {
          setDisplayCambiarFechaDialog(false);
          setCambiarFechaFormModel(null);
        }}
      />
    </div>
  );
}

export default MentenerHojasRuta;
