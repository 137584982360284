import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TransfOrd() {
  const SCREEN_CODE = "con0078_transf_ord";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const columns = [];
  const toast = useRef<Toast>(null);

  // const showError = (id: string, alt: string) => {
  //   toast.current.show({
  //     severity: "error",
  //     summary: "Erro",
  //     detail: <LanguageProvider id={id} alt={alt} />,
  //     life: 3000,
  //   });
  // };

  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);
  const [estadosRemito, setEstadosRemito] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [tipoTO, setTipoTO] = useState([]);
  const [estadosHojaRuta, setEstadosHojaRuta] = useState([]);

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    estadoRemito: [],
    mercados: [],
    tipo: [],
    vin: "",
    transportOrder: "",
    tipoTo: [],
    estadoHojaRuta: [],
  });

  const filterLabelId = [467, 468, 1151, 10774, 1617, 25142, 25138];
  const filterNames = [];
  const filterColumnsId = [
    25143, 25144, 54, 25145, 25146, 25147, 19265, 25148, 25149, 25150, 25151,
    25152, 25153, 11847, 13, 24864, 25154, 25169, 13889, 15818, 14712, 25155,
    25156, 25157, 25158, 12631, 25159, 25160, 25161, 25162, 25163, 25164, 25165,
    25166, 25167, 25168, 25175, 25176, 25177, 25178, 266, 25110, 921, 13097,
    159, 164, 3186, 10502, 16305, 25170, 367, 1123, 1125, 1127, 1129, 4197,
    1342, 10577, 25171, 848, 16288, 4976, 1446, 25172, 2350, 17355, 3241,
  ];

  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];

  const ids = [
    42, 2502, 18718, 10938, 5406, 11939, 10860, 5407, 54, 65, 467, 468, 653,
    901, 924, 1342, 1791, 2526, 3344, 4285, 4655, 4791, 5044, 5431, 7795, 10136,
    10902, 11301, 11787, 16453, 16455, 16456, 16774, 273, 10938, 5406, 11939,
    10860, 5407, 6697, 10710, 36, 39, 54, 25175, 25176, 25177, 25178, 266, 396,
    504, 653, 654, 655, 662, 740, 753, 754, 829, 870, 990, 991, 1269, 1342,
    1491, 1500, 2139, 2140, 2941, 3069, 3141, 3184, 3245, 3246, 3344, 3774,
    4284, 4285, 4399, 4724, 4976, 5271, 5394, 5536, 5701, 7645, 10043, 10044,
    10344, 10711, 10712, 10718, 10723, 10740, 10761, 10942, 10943, 10951, 11049,
    11057, 11058, 11059, 11060, 11061, 11142, 11143, 11144, 11296, 11298, 11302,
    11307, 11352, 13784, 16239, 16368, 16456, 16457, 16459, 16460, 16988, 17038,
    17134, 5405, 1489, 10858, 273, 11048, 17743, 12402, 21377, 21384, 21468,
    21490, 22638, 22849, 18303,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  async function searchMercados(event: any) {
    const mercados = await loadMercados(event.query);
    return mercados; // Retorna os dados carregados
  }
  async function loadMercados(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          const estadoMercado = data.map((x: any) => ({
            label: `${x.descripcion} - (${x.id})`,
          }));

          estadoMercado.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const filtered = estadoMercado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setMercados(filtered);
            return filtered;
          } else {
            setMercados(estadoMercado);
            return estadoMercado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return []; // Retorna lista vazia em caso de erro
  }

  async function searchTransportOrder(event: any) {
    const transportOrder = await loadTransportOrder(event.query);
    return transportOrder;
  }
  async function loadTransportOrder(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/transport-order",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          const estadoTO = data.map((x: any) => ({
            label: `${x.label} - (${x.id})`,
          }));

          estadoTO.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const filtered = estadoTO.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setTipoTO(filtered);
            return filtered;
          } else {
            setTipoTO(estadoTO);
            return estadoTO;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchTipoRemito(event: any) {
    const tipoRemito = await loadTipos(event.query);
    return tipoRemito;
  }

  async function loadTipos(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/tipo-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          const estadoTipoRemito = data.map((x: any) => ({
            label: `${x.descripcion} - (${x.id})`,
          }));

          estadoTipoRemito.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const filtered = estadoTipoRemito.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setTipo(filtered);
            return filtered;
          } else {
            setTipo(estadoTipoRemito);
            return estadoTipoRemito;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchEstadoHojaRuta(event: any) {
    const estadoHojaRuta = await loadEstadosHojaRuta(event.query);
    return estadoHojaRuta;
  }
  async function loadEstadosHojaRuta(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/estado-hoja-ruta-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estadoH = data.map((x: any) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));

          estadoH.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const filtered = estadoH.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstadosHojaRuta(filtered);
            return filtered;
          } else {
            setEstadosHojaRuta(estadoH);
            return estadoH;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchEstadoRemito(event: any) {
    const estadoRemito = await loadEstadoRemito(event.query);
    return estadoRemito;
  }
  async function loadEstadoRemito(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/estado-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          const estadoRemitoData = data.map((x: any) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));

          estadoRemitoData.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const filtered = estadoRemitoData.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstadosRemito(filtered);
            return filtered;
          } else {
            setEstadosRemito(estadoRemitoData);
            return estadoRemitoData;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // const [vinSuggestions, setVinSuggestions] = useState([]);
  // async function searchVin(event: any) {
  //   await loadVin(event.query);
  // }
  // async function loadVin(vin: any) {
  //   try {
  //     if (
  //       securityFilters?.securityValues?.account != null &&
  //       securityFilters?.securityValues?.country
  //     ) {
  //       const { status, data } = await sipcoAxiosService.get(
  //         "/report/find-vin",
  //         {
  //           params: {
  //             codPais: securityFilters?.securityValues?.country?.id,
  //             codCuenta: securityFilters?.securityValues?.account?.id,
  //             vin: vin,
  //           },
  //         }
  //       );
  //       if (status === 200) {
  //         if (data.length === 0) {
  //           return showError(
  //             "900",
  //             "No existe VIN para el Cliente, Cuenta y Subcuenta seleccionado"
  //           );
  //         }
  //         setVinSuggestions(data);
  //         return vin;
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return [];
  // }

  useEffect(() => {
    const init = async () => {
      try {
        const estados = await searchEstadoRemito("");
        const mercados = await searchMercados("");
        const transportOrder = await searchTransportOrder("");
        const tipoRemito = await searchTipoRemito("");
        const estadoHojaRuta = await searchEstadoHojaRuta("");
        setFilter({
          ...filter,
          estadoRemito: estados,
          mercados: mercados,
          tipoTo: transportOrder,
          tipo: tipoRemito,
          estadoHojaRuta: estadoHojaRuta,
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/report/transf-ord",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          rangeFecha: [filter.fechaDesde ?? null, filter.fechaHasta ?? null],
          estados: filter?.estadoRemito?.map((x) => x.id) || [],
          mercado: filter?.mercados?.map((x) => x.id) || [],
          remitos: filter?.tipo?.map((x) => x.id) || [],
          vin: filter?.vin || "",
          transportOrder: filter?.transportOrder || "",
          tipoTransportOrder: filter?.tipoTo.map((x) => x.id) || [],
          estadoHojaRuta: filter?.estadoHojaRuta.map((x) => x.id) || [],
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1070"} alt="Fecha desde" />
              </label>
              <SipcoCalendar
                value={filter.fechaDesde}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    fechaDesde: e.value,
                  });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1133"} alt="Fecha hasta" />
              </label>
              <SipcoCalendar
                value={filter.fechaHasta}
                onChange={(e) => {
                  setFilter({ ...filter, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Estado Remito"
                />
              </label>
              <SipcoMultiSelect
                value={filter.estadoRemito}
                options={estadosRemito}
                optionLabel="label"
                onChange={(e) =>
                  setFilter({ ...filter, estadoRemito: e.value })
                }
                filter
                display="chip"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Mercado" />
              </label>
              <SipcoMultiSelect
                value={filter.mercados}
                options={mercados}
                optionLabel="label"
                onChange={(e) =>
                  setFilter({ ...filter, mercados: e.target.value })
                }
                filter
                display="chip"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Tipo" />
              </label>
              <SipcoMultiSelect
                value={filter.tipo}
                options={tipo}
                onChange={(e) => setFilter({ ...filter, tipo: e.value })}
                optionLabel="label"
                filter
                display="chip"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider alt="Estado Hoja Ruta" />
              </label>
              <SipcoMultiSelect
                value={filter.estadoHojaRuta}
                options={estadosHojaRuta}
                onChange={(e) =>
                  setFilter({ ...filter, estadoHojaRuta: e.value })
                }
                optionLabel="label"
                filter
                display="chip"
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          header={<LanguageProvider alt="Filtros Transport Order" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={17088} alt="Vin" />
              </label>
              <SipcoInputText
                value={filter.vin}
                onChange={(e) =>
                  setFilter({ ...filter, vin: e.target.value || "" })
                }
                maxLength={17}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={25173} alt="Número Transport Order" />
              </label>
              <SipcoInputText
                value={filter.transportOrder}
                onChange={(e) =>
                  setFilter({ ...filter, transportOrder: e.target.value || "" })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={25174} alt="Tipo Transport Order" />
              </label>
              <SipcoMultiSelect
                value={filter.tipoTo}
                options={tipoTO}
                optionLabel="label"
                onChange={(e) =>
                  setFilter({ ...filter, tipoTo: e.target.value })
                }
                filter
                display="chip"
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"25138"} alt="Consulta Transport Order" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Transport Order"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          loading={loading}
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          columnResizeMode="expand"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
          <SipcoColumn field="23" header={columnsNames[23].label} />
          <SipcoColumn field="24" header={columnsNames[24].label} />
          <SipcoColumn field="25" header={columnsNames[25].label} />
          <SipcoColumn field="26" header={columnsNames[26].label} />
          <SipcoColumn field="27" header={columnsNames[27].label} />
          <SipcoColumn field="28" header={columnsNames[28].label} />
          <SipcoColumn field="29" header={columnsNames[29].label} />

          <SipcoColumn field="30" header={columnsNames[30].label} />
          <SipcoColumn field="31" header={columnsNames[31].label} />
          <SipcoColumn field="32" header={columnsNames[32].label} />
          <SipcoColumn field="33" header={columnsNames[33].label} />
          <SipcoColumn field="34" header={columnsNames[34].label} />
          <SipcoColumn field="35" header={columnsNames[35].label} />

          <SipcoColumn field="36" header={columnsNames[36].label} />
          <SipcoColumn field="37" header={columnsNames[37].label} />
          <SipcoColumn field="38" header={columnsNames[38].label} />
          <SipcoColumn field="39" header={columnsNames[39].label} />
          <SipcoColumn field="40" header={columnsNames[40].label} />

          <SipcoColumn field="41" header={columnsNames[41].label} />
          <SipcoColumn field="42" header={columnsNames[42].label} />
          <SipcoColumn field="43" header={columnsNames[43].label} />
          <SipcoColumn field="44" header={columnsNames[44].label} />

          <SipcoColumn field="45" header={columnsNames[45].label} />
          <SipcoColumn field="46" header={columnsNames[46].label} />
          <SipcoColumn field="47" header={columnsNames[47].label} />
          <SipcoColumn field="48" header={columnsNames[48].label} />
          <SipcoColumn field="49" header={columnsNames[49].label} />
          <SipcoColumn field="50" header={columnsNames[50].label} />
          <SipcoColumn field="51" header={columnsNames[51].label} />
          <SipcoColumn field="52" header={columnsNames[52].label} />
          <SipcoColumn field="53" header={columnsNames[53].label} />
          <SipcoColumn field="54" header={columnsNames[54].label} />

          <SipcoColumn field="55" header={columnsNames[55].label} />
          <SipcoColumn field="56" header={columnsNames[56].label} />
          <SipcoColumn field="57" header={columnsNames[57].label} />
          <SipcoColumn field="58" header={columnsNames[58].label} />
          <SipcoColumn field="59" header={columnsNames[59].label} />
          <SipcoColumn field="60" header={columnsNames[60].label} />
          <SipcoColumn field="61" header={columnsNames[61].label} />
          <SipcoColumn field="62" header={columnsNames[62].label} />
          <SipcoColumn field="63" header={columnsNames[63].label} />
          <SipcoColumn field="64" header={columnsNames[64].label} />

          <SipcoColumn field="65" header={columnsNames[65].label} />
          <SipcoColumn field="66" header={columnsNames[66].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default TransfOrd;
