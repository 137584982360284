import React, { MutableRefObject, useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export const ModalOperacionVin: React.FC<{
  vin: any;
  isOpen: boolean;
  country: any;
  onClose: () => void;
  toast: MutableRefObject<Toast>;
}> = ({ vin, isOpen, onClose, country, toast }) => {
  const sipcoAxiosService = useSipcoAxiosService();

  const [gruposOperaciones, setGruposOperaciones] = useState([]);
  const [selectGrupoOperacion, setSelectGrupoOperacion] = useState(null);

  async function forzarPreparado() {
    confirmDialog({
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => apiForzarPreparado(),
      // reject
    });
  }
  async function apiForzarPreparado() {
    gruposOperaciones.forEach((element: any) => {
      if (selectGrupoOperacion.includes(element)) {
        element.selected = true;
      }
    });
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/modal/forzar-preparado",
        {
          vin: vin,
          listGrupoOperacion: selectGrupoOperacion,
        }
      );
      if (status === 200) {
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Su transacción se realizó con éxito."
            />
          ),

          life: 3000,
        });
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadGrupoOperacion() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/modal/grupo-operacion",
        {
          pais: country,
        }
      );
      if (status === 200) {
        setGruposOperaciones(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      loadGrupoOperacion();
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog visible={isOpen} onHide={onClose}>
        <SipcoDataTable
          value={gruposOperaciones}
          selection={selectGrupoOperacion}
          onSelectionChange={(e) => setSelectGrupoOperacion(e.value)}
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
        >
          <SipcoColumn
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          />
          <SipcoColumn field="descripcion" header="Grupo Operacion" />
        </SipcoDataTable>
        <div className="button-modal-vin">
          <Button
            label="Aceitar"
            icon="pi pi-check"
            onClick={() => {
              forzarPreparado();
              onClose();
            }}
            style={{ margin: ".5em" }}
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={onClose}
            style={{ margin: ".5em" }}
          />
        </div>
      </Dialog>
    </div>
  );
};
export default ModalOperacionVin;
