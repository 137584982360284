import React, { useState } from "react";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const DestranadoMasivoDanos: React.FC<{ vinData: any }> = ({ vinData }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const filterColumnsId = [1491, 8385, 3625];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  const [filter, setFilter] = useState({
    gravedadDano: null,
    vin: null,
  });
  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-masiva-danos/vin-destrabar",
          {
            pais: securityFilters?.securityValues?.country,
            cliente: securityFilters?.securityValues?.client,
            cuenta: securityFilters?.securityValues?.account,
            vin: { id: query },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  return (
    <div className="vin-view">
      <Fieldset
        legend={
          <label>
            Datos - Cantidad de Registros a Procesar.: 0 - Registros con
            errores.: 0
          </label>
        }
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <LanguageProvider id={1491} alt="VIN:" />
          </label>
          <SipcoAutocomplete
            value={filter.vin}
            suggestions={vin}
            completeMethod={searchVin}
            onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
            field="label"
            dropdown
            forceSelection
          />
        </div>
        <Divider />
        <SipcoDataTable
          size="small"
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          selectionMode="single"
        >
          <SipcoColumn
            field="1"
            header={columnsNames[0].label}
            sortable
            filter
          />
        </SipcoDataTable>
        <div>
          <Button label={LanguageProvider({ id: "23824", alt: "Alta" })} text />
        </div>
      </Fieldset>
    </div>
  );
};

export default DestranadoMasivoDanos;
