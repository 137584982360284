import "./ConsultaVinesPreparar.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaVinesPreparar() {
  const SCREEN_CODE = "con0062_consulta_vines_a_preparar";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);

  const filterLabelId = [14466, 14467, 14534, 1491];
  const filterNames = [];
  const filterColumnsId = [1491, 990, 991, 396, 2139, 2140, 14458, 14534];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 396, 467, 468, 653, 901, 990, 991, 1182, 1184, 1491, 1791, 2139,
    2140, 2502, 4791, 5044, 5583, 10531, 14458, 14466, 14467, 14534,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    vin: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    aPreparar: true,
  });

  const [vins, setVins] = useState([]);
  const [loadingVins, setLoadingVins] = useState(false);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    setLoadingVins(true);
    try {
      if (
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.account?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/vines-preparar/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codCliente: securityFilters.securityValues.client.id,
              vin: vin,
            },
          }
        );

        if (status === 200) {
          setVins(data);
          return data;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingVins(false);
    }
  }

  const [reporte, setReporte] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        aPreparar: filter.aPreparar,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        vin: filter.vin ?? "",
      };
      const { status, data } = await sipcoAxiosService.post(
        "/vehiculos-pendientes-taller/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const formattedReportData = convertDateObjects(reporte?.filas);

  return (
    <div className="consulta-vines-preparar">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.aPreparar}
                  onChange={() => setFilter({ ...filter, aPreparar: true })}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"14466"} alt="A Preparar" />
                </label>
                <RadioButton
                  checked={!filter.aPreparar}
                  onChange={() => setFilter({ ...filter, aPreparar: false })}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"14467"} alt="A Despachar" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"14482"}
                  alt="Consulta Vines a Preparar"
                />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Vines a Preparar"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="5" header={columnsNames[4].label} />
          <SipcoColumn field="6" header={columnsNames[5].label} />
          <SipcoColumn field="7" header={columnsNames[6].label} />
          <SipcoColumn field="8" header={columnsNames[7].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaVinesPreparar;
