import IngressoTaller, {
  ConIngresoTaller,
} from "@pages/queries/ingresso-taller/ConIngresoTaller";
import { Route, Routes } from "react-router-dom";

import AdministracionNotasFiscales from "@pages/functions/administracion-notas-fiscales/AdministracionNotasFiscales";
import AdministracionRemitosAConcesionarios from "@pages/functions/administracion-remitos-a-concesionarios/AdministracionRemitosAConcesionarios";
import { AdministracionRemitosFicticio } from "@pages/functions/administracion-remitos-ficticio/AdministracionRemitosFicticio";
import AntiguedadStock from "@pages/queries/antiguedad-stock/AntiguedadStock";
import AnulacionEcom from "@pages/functions/anulacion-ecom/AnulacionEcom";
import AnularRemitos from "@pages/functions/anular-remitos/AnularRemitos";
import { AprobacionOperaciones } from "@pages/functions/aprobacion-operaciones/AprobacionOperaciones";
import BloqueoUbicaciones from "@pages/functions/bloqueo-ubicaciones/BloqueoUbicaciones";
import { BloqueosDesbloqueos } from "@pages/functions/bloqueos-desbloqueos/BloqueosDesbloqueos";
import CargaDanos from "@pages/functions/carga-danos/CargaDanos";
import CargaDeRetornos from "@pages/functions/carga-de-retornos/CargaDeRetornos";
import { CargaEstadoVin } from "@pages/functions/carga-estado-vin/CargaEstadoVin";
import CargaManualRemitos from "@pages/functions/carga-manual-remitos/CargaManualRemitos";
import CargaManualVin from "@pages/functions/carga-manual-vin/CargaManualVin";
import { CargaMasivaDanos } from "@pages/functions/carga-masiva-danos/carga-masiva-danos";
import { CargaOperacionesForzadas } from "@pages/functions/CargaOpForzadas/Carga-operaciones-forzadas";
import { CierreContable } from "@pages/functions/cierre-contable/CierreContable";
import CierreEstadia from "@pages/functions/cierre-estadia/CierreEstadia";
import Colecta from "@pages/functions/colecta/Colecta";
import ComprasTaller from "@pages/queries/compras-taller/ComprasTaller";
import ConsultaBloqueos from "@pages/queries/consulta-bloqueos/ConsultaBloqueos";
import ConsultaGeneralTransacciones from "@pages/queries/consulta-general-de-transacciones/ConsultaGeneralTransacciones";
import ConsultaOfertasServicio from "@pages/queries/consulta-ofertas-servicio/ConsultaOfertasServicio";
import ConsultaOperacionesCanceladasWeb from "@pages/queries/consulta-operaciones-canceladas-web/ConsultaOperacionesCanceladasWeb";
import ConsultaOperacionesPendientes from "@pages/queries/consulta-operaciones-pendientes/ConsultaOperacionesPendientes";
import ConsultaOsTerceros from "@pages/queries/consulta-os-terceros/ConsultaOsTerceros";
import ConsultaOt from "@pages/queries/consulta-ot/ConsultaOt";
import ConsultaTranslado from "@pages/queries/consulta-translado/ConsultaTranslado";
import { ConsultaVin } from "@pages/queries/consulta-vin/consulta-vin";
import ConsultaVinesPreparar from "@pages/queries/consulta-vines-preparar/ConsultaVinesPreparar";
import { ContabilizacionAnulacion } from "@pages/functions/contabilizacion-anulacion-de-operaciones/ContabilizacionAnulacion";
import ContabilizarViajesTerceros from "@pages/functions/contabilizar-viajes-terceros/ContabilizarViajesTerceros";
import DaniosCheckpoint from "@pages/queries/danio-x-checkpoint/DaniosCheckpoint";
import DespachoMan from "@pages/functions/despacho-man/DespachoMan";
import DespachoMercadoExportacion from "@pages/functions/despacho-mercado-exportacion/DespachoMercadoExportacion";
import DespachoMercadoNacional from "@pages/functions/despacho-mercado-nacional/DespachoMercadoNacional";
import DespachoTerceros from "@pages/functions/despacho-terceros/DespachoTerceros";
import Despachos from "@pages/queries/despachos/despacho";
import DevolucionMan from "@pages/functions/devolucion-man/DevolucionMan";
import { DevolucionMercadoLocal } from "@pages/queries/devolucion-mercado-local/DevolucionMercadoLocal";
import DisponibilidadImportacion from "@pages/functions/disponibilidad-importacion/DisponibilidadImportacion";
import DocumentacionTransportes from "@pages/queries/documentacio-de-transportes/DocumentacionTransportes";
import EgresoTaller from "@pages/functions/egreso-taller/EgresoTaller";
import { EliminarBuqueCamion } from "@pages/functions/eliminar-llegada-buque-camion/EliminarBuqueCamion";
import { FilterTest } from "@pages/filter-test/filter-test";
import { FunComprasTaller } from "@pages/functions/compras-taller/fun-Compras-Taller";
import GastosExtraPlaya from "@pages/functions/gastos-extra-playa/GastosExtraPlaya";
import GenPicking from "@pages/functions/gen-picking/GenPicking";
import GeneracionOt from "@pages/functions/generacion-ot/GeneracionOt";
import GestionOperaciones from "@pages/functions/gestion-de-operaciones/GestionOperaciones";
import GestionPreparacion from "@pages/functions/gestion-preparacion/GestionPreparacion";
import HistoriaVin from "@pages/queries/historia-vin/HistoriaVin";
import HistoricoDanos from "@pages/queries/historico-danios/HistoricoDanos";
import Home from "@pages/home/home";
import ImpresionChecklist from "@pages/functions/impresion-checklist/ImpresionChecklist";
import ImpresionEtiquetas from "@pages/functions/impresion-etiquetas/ImpresionEtiquetas";
import { ImpresionManualGuias } from "@pages/functions/impresion-manual-guias/ImpresionManualGuias";
import ImpressionHojaRuta from "@pages/queries/impression-hoja-ruta/ImpressionHojaRuta";
import { InformaTransportista } from "@pages/functions/informa-transportista/InformaTransportista";
import IngresoEcom from "@pages/functions/ingreso-ecom/ingreso-ecom";
import { IngresoEcomDaniosFord } from "@pages/functions/ingreso-ecom-danios-ford/IngresoEcomDaniosFord";
import { IngresoManualVehiculos } from "@pages/functions/ingreso-manual-de-vehiculos/IngresoManualVehiculos";
import { IngresoMovManuales } from "@pages/functions/ingreso-mov-manuales/IngresoMovManuales";
import { IngresoTaler } from "@pages/functions/ingreso-taller/IngresoTaler";
import IngresoTallerManual from "@pages/functions/ingreso-taller-manual/IngresoTallerManual";
import IngressoPlaya from "@pages/queries/ingresso-playa/IngressoPlaya";
import { InterfazIngresoFord } from "@pages/functions/interfaz-ingreso-ford/InterfazIngresoFord";
import { LevantarIndisponibilidad } from "@pages/functions/levantar-indisponibilidad/LevantarIndisponibilidad";
import LiberacionAduana from "@pages/functions/liberacion-aduana/LiberacionAduana";
import LiberacionDespachos from "@pages/queries/liberacion-despachos/LiberacionDespachos";
import LiberadosDespachados from "@pages/queries/liberados-despachados/LiberadosDespachados";
import ListadoApoyoEstadia from "@pages/functions/listado-apoyo-estadia/ListadoApoyoEstadia";
import ListadoArmadoViajes from "@pages/queries/listado-armado-viajes/ListadoArmadoViajes";
import ListadoColectas from "@pages/queries/listado-colectas/ListadoColectas";
import ListadoHojaRuta from "@pages/queries/listado-hoja-ruta/listado-hoja-ruta";
import ListadoOperacionesRealizadas from "@pages/queries/listado-operaciones-realizadas/ListadoOperacionesRealizadas";
import LlegadaBuqueCamion from "@pages/functions/llegada-buque-camion/LlegadaBuqueCamion";
import Login from "@pages/admin/authentication/login";
import LoginLayout from "@shared/login-layout";
import MainLayout from "@shared/main-layout";
import ManejarErroresRemitos from "@pages/functions/manejar-errores-remitos/ManejarErroresRemitos";
import MantenerCatalogoRepuestos from "@pages/functions/mantener-catalogo-repuestos/MantenerCatalogoRepuestos";
import MantenerCatalogoServicios from "@pages/functions/mantener-catalogo-servicios/MantenerCatalogoServicios";
import MantenerClientes from "@pages/functions/mantener-clientes/MantenerClientes";
import { MantenerServicos } from "@pages/functions/mantener-sevicos/MantenerServicos";
import MantenerUsuarios from "@pages/functions/mantener-usuarios/MantenerUsuarios";
import MantenerValesDeRepuestos from "@pages/functions/mantener-vales-de-repuestos/MantenerValesDeRepuestos";
import MentenerHojasRuta from "@pages/functions/mantener-hojas-ruta/mantener-hojas-ruta";
import { MigracionClientes } from "@pages/functions/migracion-clientes/MigracionClientes";
import { ModificacionMasivaMotor } from "@pages/functions/modificacion-masiva-motor/ModificacionMasivaMotor";
import ModificacionVin from "@pages/functions/modificacion-vin/ModificacionVin";
import { MonitorInterfaces } from "@pages/functions/monitor-interfaces/MonitorInterfaces";
import MovimentoStock from "@pages/queries/movimento-stock/MovimentoStock";
import NotFound from "./shared/not-found";
import OrdenReparacion from "@pages/functions/orden-reparacion/OrdenReparacion";
import OrdenReparacionProveedores from "@pages/functions/orden-reparacion/components/OrdenReparacionProveedores";
import OrdenReparacionRepuestos from "@pages/functions/orden-reparacion/components/OrdenReparacionRepuestos";
import { OrdenReparacionServicios } from "@pages/functions/orden-reparacion/components/OrdenReparacionServicios";
import PedidosConfirmadosJd from "@pages/queries/pedidos-confirmados-jd/PedidosConfirmadosJd";
import PedidosPlaya from "@pages/functions/pedidos-playa/PedidosPlaya";
import { Peritaje } from "@pages/functions/peritaje/Peritaje";
import ProtectedRoutes from "./protected-routes";
import RecepcionPlaya from "@pages/functions/recepcion-playa/RecepcionPlaya";
import { RecepcionTerceros } from "@pages/functions/recepcion-terceros/RecepcionTerceros";
import RegistracionOperacionAutomatica from "@pages/functions/registracion-operacion-automatica/RegistracionOperacionAutomatica";
import RegistracionOperacionPorOt from "@pages/functions/registracion-operacion-por-ot/RegistracionOperacionPorOt";
import ReimpresionEtiquetas from "@pages/functions/reimpresion-etiquetas/ReimpresionEtiquetas";
import ReimpresionRemitos from "@pages/functions/reimpresion-remitos/ReimpresionRemitos";
import ReingresoStock from "@pages/functions/reingreso-stock/ReingresoStock";
import ReingressoStock from "@pages/functions/reingreso-de-stock/ReingresoDeStock";
import RemitoElectronico from "@pages/functions/remito-electronico/RemitoElectronico";
import Remitos from "@pages/queries/remitos/Remitos";
import { RepararVines } from "@pages/functions/reparar-vines/RepararVines";
import RepuestosComprasTaller from "@pages/functions/compras-taller/components/RepuestosComprasTaller";
import Stock from "@pages/queries/stock/stock";
import StockTaller from "@pages/queries/stock-taller/StockTaller";
import TestScreen from "@pages/test-screen";
import ThemeLayout from "@shared/theme-layout";
import TiemposCentroPerdidos from "@pages/queries/transit-time-pedidos-playa/TiemposCentroPerdidos";
import TiemposEntrega from "@pages/queries/tiempo-de-entrega/TiemposEntrega";
import TranferenciaPlaya from "@pages/functions/tranferencia-playa/TranferenciaPlaya";
import TransaccionesUsuarios from "@pages/queries/transacciones-usuario/TransaccionesUsuarios";
import TransfOrd from "@pages/queries/transf-ord/TransfOrd";
import TransferenciaUbicacion from "@pages/functions/transferencia-ubicacion/TransferenciaUbicacion";
import ValeRepuestos from "@pages/queries/vale-repuestos/ValeRepuestos";
import VehiculosPendientesTaller from "@pages/queries/vehiculos-pendientes-taller/VehiculosPendientesTaller";
import VehiculosTaller from "@pages/queries/vehiculos-en-taller/VehiculosTaller";

const NavProvider = () => {
  return (
    <Routes>
      <Route element={<ThemeLayout />}>
        <Route path="auth" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="filter-test" element={<FilterTest />} />
            <Route path="consulta-vin" element={<ConsultaVin />} />
            <Route path="stock" element={<Stock />} />
            <Route
              path="listado-de-hojas-de-rutas"
              element={<ListadoHojaRuta />}
            />
            <Route
              path="consulta-operaciones-pendientes"
              element={<ConsultaOperacionesPendientes />}
            />
            <Route path="ingreso-playa" element={<IngressoPlaya />} />
            <Route
              path="impression-hoja-ruta"
              element={<ImpressionHojaRuta />}
            />
            <Route path="remitos" element={<Remitos />} />
            <Route path="despachos" element={<Despachos />} />
            <Route path="consulta-ot" element={<ConsultaOt />} />
            <Route
              path="listado-operaciones-realizadas"
              element={<ListadoOperacionesRealizadas />}
            />
            <Route path="historico-danos" element={<HistoricoDanos />} />
            <Route path="listado-colectas" element={<ListadoColectas />} />
            <Route path="stock-taller" element={<StockTaller />} />
            <Route path="egreso-taller" element={<EgresoTaller />} />

            <Route
              path="impresion-manual-guias"
              element={<ImpresionManualGuias />}
            />

            <Route
              path="transacciones-usuarios"
              element={<TransaccionesUsuarios />}
            />
            <Route path="carga-masiva-danos" element={<CargaMasivaDanos />} />
            <Route
              path="listado-armado-viajes"
              element={<ListadoArmadoViajes />}
            />
            <Route path="movimento-stock" element={<MovimentoStock />} />
            <Route path="consulta-bloqueos" element={<ConsultaBloqueos />} />
            <Route
              path="liberados-despachados"
              element={<LiberadosDespachados />}
            />
            <Route path="transf-ord" element={<TransfOrd />} />
            <Route
              path="vehiculo-pendientes-taller"
              element={<VehiculosPendientesTaller />}
            />
            <Route path="historia-vin" element={<HistoriaVin />} />
            <Route path="compras-taller" element={<ComprasTaller />} />
            <Route path="vale-repuestos" element={<ValeRepuestos />} />
            <Route path="antiguedad-stock" element={<AntiguedadStock />} />
            <Route path="danios-checkpoint" element={<DaniosCheckpoint />} />
            <Route path="tiempos-entrega" element={<TiemposEntrega />} />
            <Route path="vehiculos-taller" element={<VehiculosTaller />} />
            <Route path="ingresso-taller" element={<ConIngresoTaller />} />

            <Route
              path="reimpresion-remitos"
              element={<ReimpresionRemitos />}
            />

            <Route path="anular-remitos" element={<AnularRemitos />} />

            <Route path="reparar-vines" element={<RepararVines />} />

            <Route
              path="contabilizacion-anulacion-de-operaciones"
              element={<ContabilizacionAnulacion />}
            />

            <Route path="ingreso-taller" element={<IngresoTaler />} />

            <Route path="recepcion-terceros" element={<RecepcionTerceros />} />

            <Route
              path="modificar-estado-del-vin"
              element={<CargaEstadoVin />}
            />

            <Route
              path="aprobacion-operaciones"
              element={<AprobacionOperaciones />}
            />

            <Route path="migracion-clientes" element={<MigracionClientes />} />

            <Route
              path="informa-transportista"
              element={<InformaTransportista />}
            />

            <Route
              path="liberacion-despacho"
              element={<LiberacionDespachos />}
            />
            <Route
              path="documentacion-transportes"
              element={<DocumentacionTransportes />}
            />
            <Route
              path="general-transacciones"
              element={<ConsultaGeneralTransacciones />}
            />
            <Route path="vines-preparar" element={<ConsultaVinesPreparar />} />
            <Route path="translados" element={<ConsultaTranslado />} />
            <Route
              path="ofertas-servicio"
              element={<ConsultaOfertasServicio />}
            />
            <Route
              path="operaciones-canceladas-web"
              element={<ConsultaOperacionesCanceladasWeb />}
            />
            <Route
              path="transit-time-pedidos-playa"
              element={<TiemposCentroPerdidos />}
            />
            <Route
              path="listado-apoyo-estadia"
              element={<ListadoApoyoEstadia />}
            />
            <Route
              path="consulta-os-terceros"
              element={<ConsultaOsTerceros />}
            />
            <Route
              path="pedidos-confirmados-jd"
              element={<PedidosConfirmadosJd />}
            />
            <Route
              path="contabilizar-viajes-terceros"
              element={<ContabilizarViajesTerceros />}
            />
            <Route path="mantener-hojas-ruta" element={<MentenerHojasRuta />} />
            <Route
              path="administracion-remitos-a-concesionarios"
              element={<AdministracionRemitosAConcesionarios />}
            />
            <Route
              path="administracion-notas-fiscales"
              element={<AdministracionNotasFiscales />}
            />
            <Route
              path="despacho-mercado-nacional"
              element={<DespachoMercadoNacional />}
            />
            <Route path="carga-danos" element={<CargaDanos />} />
            <Route path="transferencia-playa" element={<TranferenciaPlaya />} />
            <Route path="recepcion-de-playa" element={<RecepcionPlaya />} />
            <Route path="generacion-ot" element={<GeneracionOt />} />
            <Route
              path="ingreso-taller-manual"
              element={<IngresoTallerManual />}
            />
            <Route path="orden-reparacion" element={<OrdenReparacion />} />

            <Route
              path="orden-reparacion-repuestos"
              element={<OrdenReparacionRepuestos />}
            />

            <Route
              path="orden-reparacion-servicios"
              element={<OrdenReparacionServicios />}
            />

            <Route
              path="orden-reparacion-proveedores"
              element={<OrdenReparacionProveedores />}
            />

            <Route
              path="mantener-catalogo-servicios"
              element={<MantenerCatalogoServicios />}
            />

            <Route
              path="mantener-catalogo-repuestos"
              element={<MantenerCatalogoRepuestos />}
            />
            <Route
              path="registracion-operacion-por-ot"
              element={<RegistracionOperacionPorOt />}
            />
            <Route
              path="registracion-operacion-automatica"
              element={<RegistracionOperacionAutomatica />}
            />
            <Route path="ingreso-ecom" element={<IngresoEcom />} />
            <Route path="carga-manual-vin" element={<CargaManualVin />} />
            <Route
              path="/carga-operaciones-forzadas"
              element={<CargaOperacionesForzadas />}
            />
            <Route path="modificacion-vin" element={<ModificacionVin />} />
            <Route
              path="transferencia-ubicacion"
              element={<TransferenciaUbicacion />}
            />
            <Route path="remito-electronico" element={<RemitoElectronico />} />

            <Route path="monitor-interfaces" element={<MonitorInterfaces />} />

            <Route
              path="ingreso-manual-de-vehiculos"
              element={<IngresoManualVehiculos />}
            />

            <Route
              path="disponibilidad-importacion"
              element={<DisponibilidadImportacion />}
            />

            <Route
              path="devolucion-mercado-local"
              element={<DevolucionMercadoLocal />}
            />

            <Route path="anulacion-ecom" element={<AnulacionEcom />} />

            <Route path="peritaje" element={<Peritaje />} />

            <Route
              path="ingreso-ecom-danios-ford"
              element={<IngresoEcomDaniosFord />}
            />

            <Route path="devolucion-man" element={<DevolucionMan />} />

            <Route
              path="ingreso-mov-manuales"
              element={<IngresoMovManuales />}
            />

            <Route path="despacho-man" element={<DespachoMan />} />

            <Route
              path="interfaz-ingreso-ford"
              element={<InterfazIngresoFord />}
            />

            <Route
              path="bloqueos-desbloqueos"
              element={<BloqueosDesbloqueos />}
            />

            <Route path="liberacion-aduana" element={<LiberacionAduana />} />
            <Route path="pedidos-playa" element={<PedidosPlaya />} />

            <Route
              path="administracion-remitos-ficticio"
              element={<AdministracionRemitosFicticio />}
            />

            <Route
              path="llegada-buque-camion"
              element={<LlegadaBuqueCamion />}
            />
            <Route
              path="despacho-mercado-exportacion"
              element={<DespachoMercadoExportacion />}
            />
            <Route path="carga-de-retornos" element={<CargaDeRetornos />} />
            <Route path="despacho-terceros" element={<DespachoTerceros />} />
            <Route path="colecta" element={<Colecta />} />
            <Route
              path="carga-manual-remitos"
              element={<CargaManualRemitos />}
            />
            <Route path="cierre-contable" element={<CierreContable />} />

            <Route
              path="modificacion-masiva-motor"
              element={<ModificacionMasivaMotor />}
            />

            <Route
              path="eliminar-llegada-buque-camion"
              element={<EliminarBuqueCamion />}
            />

            <Route
              path="gestion-de-operaciones"
              element={<GestionOperaciones />}
            />
            <Route path="reingreso-de-stock" element={<ReingressoStock />} />
            <Route
              path="mantener-vales-de-repuestos"
              element={<MantenerValesDeRepuestos />}
            />
            <Route
              path="reimpresion-etiquetas"
              element={<ReimpresionEtiquetas />}
            />
            <Route path="cierre-estadia" element={<CierreEstadia />} />
            <Route
              path="impresion-etiquetas"
              element={<ImpresionEtiquetas />}
            />
            <Route
              path="impresion-checklist"
              element={<ImpresionChecklist />}
            />
            <Route
              path="gestion-preparacion"
              element={<GestionPreparacion />}
            />
            <Route path="gen-picking" element={<GenPicking />} />

            <Route path="fun-compras-taller" element={<FunComprasTaller />} />

            <Route
              path="repuestos-compras-taller"
              element={<RepuestosComprasTaller />}
            />

            <Route
              path="levantar-indisponibilidad"
              element={<LevantarIndisponibilidad />}
            />

            <Route path="mantener-servicos" element={<MantenerServicos />} />

            <Route
              path="bloqueo-ubicaciones"
              element={<BloqueoUbicaciones />}
            />
            <Route path="mantener-usuarios" element={<MantenerUsuarios />} />
            <Route path="gastos-extra-playa" element={<GastosExtraPlaya />} />
            <Route path="mantener-clientes" element={<MantenerClientes />} />
            <Route
              path="manejar-errores-remitos"
              element={<ManejarErroresRemitos />}
            />
            <Route path="reingreso-stock" element={<ReingresoStock />} />
          </Route>
        </Route>
      </Route>
      <Route path="test-screen" element={<TestScreen />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavProvider;
