import "./MantenerClientes.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";
import { format, set } from "date-fns";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import useAuth from "@shared/AuthContext";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsDataTableCpnj = [
  { header: 6590, field: "id" },
  { header: 8300, field: "cnpj" },
  { header: 54, field: "descripcion" },
  { header: 3069, field: "direccion" },
  { header: 154, field: "@pais.descripcion" },
  { header: 159, field: "@provincia.descripcion" },
  { header: 164, field: "@ciudad.descripcion" },
  { header: 3186, field: "@localidad.descripcion" },
  { header: 11176, field: "cp" },
  { header: 3071, field: "telefono" },
  { header: 10415, field: "email" },
  { header: 1669, field: "observacion" },
  { header: 16301, field: "largoModeloBase" },
  { header: 16303, field: "@clientePadre.descripcion" },
  { header: 14188, field: "codClienteContable" },
];
const columnsDataTableCuit = [
  { header: 6590, field: "id" },
  { header: 11178, field: "cuit" },
  { header: 54, field: "descripcion" },
  { header: 3069, field: "direccion" },
  { header: 154, field: "@pais.descripcion" },
  { header: 159, field: "@provincia.descripcion" },
  { header: 164, field: "@ciudad.descripcion" },
  { header: 3186, field: "@localidad.descripcion" },
  { header: 11176, field: "cp" },
  { header: 3071, field: "telefono" },
  { header: 10415, field: "email" },
  { header: 1669, field: "observacion" },
  { header: 16301, field: "largoModeloBase" },
  { header: 16303, field: "@clientePadre.descripcion" },
  { header: 14188, field: "codClienteContable" },
];

export function MantenerClientes() {
  const SCREEN_CODE = "fun0013_mantener_clientes";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const { user } = useAuth();
  const columnsDataTableNames = useFilterColumnsIds(
    user.pais.codePais === 2 ? columnsDataTableCpnj : columnsDataTableCuit
  );
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [dataTableValue, setDataTableValue] = useState([]);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    pais: "",
    codigo: "",
    descripcion: "",
    activo: true,
  });

  const [loadingPais, setLoadingPais] = useState(false);
  const [paisOptions, setPaisOptions] = useState([]);
  async function loadPais() {
    setLoadingPais(true);
    try {
      const params = {
        screenCode: SCREEN_CODE,
        level: user.nivel,
      };
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-pais-options",
        {
          params,
        }
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setPaisOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPais(false);
    }
  }

  async function loadReport() {
    setLoadingReportData(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-clientes/find",
        {
          pais: filter.pais,
          codigo: filter.codigo,
          descripcion: filter.descripcion,
          activo: filter.activo,
        }
      );

      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    async function initialize() {
      setLoadingPais(true);
      try {
        const initialPais = await loadPais();

        setFilter({
          ...filter,
          pais: initialPais[0],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPais(false);
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gastos-extra-playa">
      <Toast ref={toast} position="top-center" />
      <Accordion activeIndex={0}>
        <AccordionTab
          header={
            <div className="sipco-accordion-header">
              <LanguageProvider id={"7795"} alt="Filtrar Por:" />
              <Button
                onClick={loadReport}
                label={LanguageProvider({ id: "9665", alt: "Buscar" })}
                style={{ gap: 0 }}
              ></Button>
            </div>
          }
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="5044" alt="País" />
                </label>
                <SipcoDropdown
                  loading={loadingPais}
                  value={filter.pais}
                  options={paisOptions}
                  onChange={(e) => setFilter({ ...filter, pais: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"6590"} alt="Código" />
                </label>
                <SipcoInputText
                  value={filter.codigo}
                  onChange={(e) =>
                    setFilter({ ...filter, codigo: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3756"} alt="Descripción" />
                </label>
                <SipcoInputText
                  value={filter.descripcion}
                  onChange={(e) =>
                    setFilter({ ...filter, descripcion: e.target.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"72"} alt="Activo" />
                </label>
                <Checkbox
                  checked={filter.activo}
                  onChange={(e) =>
                    setFilter({ ...filter, activo: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Fieldset
        legend={<LanguageProvider id={"3715"} alt="Gastos Extraordinarios" />}
      >
        <div className="fieldset-content">
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableValue}
                  columns={columnsDataTableNames}
                  screenName={"GastosExtraPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={reportData}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            <SipcoColumn
              field={columnsDataTableNames[0].field}
              header={columnsDataTableNames[0].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[1].field}
              header={columnsDataTableNames[1].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[2].field}
              header={columnsDataTableNames[2].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[3].field}
              header={columnsDataTableNames[3].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[4].field}
              header={columnsDataTableNames[4].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[5].field}
              header={columnsDataTableNames[5].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[6].field}
              header={columnsDataTableNames[6].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[7].field}
              header={columnsDataTableNames[7].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[8].field}
              header={columnsDataTableNames[8].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[9].field}
              header={columnsDataTableNames[9].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[10].field}
              header={columnsDataTableNames[10].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[11].field}
              header={columnsDataTableNames[11].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[12].field}
              header={columnsDataTableNames[12].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[13].field}
              header={columnsDataTableNames[13].label}
              sortable
              filter
            />
            <SipcoColumn
              field={columnsDataTableNames[14].field}
              header={columnsDataTableNames[14].label}
              sortable
              filter
            />
          </SipcoDataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "19267",
                alt: "Modificar",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "12899",
                alt: "Ver",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "42",
                alt: "Excel",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default MantenerClientes;
