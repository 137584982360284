import "./ModalModificarMantenerCatalogoRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

interface InterfaceModalModificarMantenerCatalogoRepuestos {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
}

const ModalModificarMantenerCatalogoRepuestos: React.FC<
  InterfaceModalModificarMantenerCatalogoRepuestos
> = ({ visible, onClose, model, onConfirm }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: "",
    estados: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const data = convertDateObjects(reporte?.filas);

  return (
    <div>
      <Dialog
        className="modalAltaReparacionServicios"
        header="Orden Reparación Proveedores - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
                icon="pi pi-check"
                onClick={onClose}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"2020"} alt="Pieza" />
              </label>
              <SipcoInputText className="input-modal" disabled={true} />
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16953"} alt="Costo($)" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16954"} alt="Costo(U$D)" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"1253"} alt="Cantidad" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"6256"} alt="Fecha Cotización" />
                </label>
                <SipcoCalendar className="input-modal" showIcon />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16955"} alt="Peso(Gr.)" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16956"} alt="I.V.A (%)" />
                </label>
                <SipcoInputText className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2183"} alt="Rubro" />
                </label>
                <SipcoDropdown className="input-modal" />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2184"} alt="Cuenta Taller" />
                </label>
                <SipcoDropdown className="input-modal" />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2185"} alt="Grupo Taller" />
                </label>
                <SipcoDropdown className="input-modal" />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2186"} alt="Modelo Taller" />
                </label>
                <SipcoDropdown className="input-modal" />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalModificarMantenerCatalogoRepuestos;
