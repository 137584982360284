import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { LanguageProvider } from "@shared/components/language-provider";
import PanelCustom from "../../../../components/panel-custom/PanelCustom";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Skeleton } from "primereact/skeleton";
import { confirmDialog } from "primereact/confirmdialog";
import { useFilterColumnsIdsNew } from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const table = [
  { header: 14712, field: "hojaRuta.id" },
  { header: 1491, field: "vin.id" },
  { header: 396, field: "vin.marca.id" },
  { header: 396, field: "vin.marca.descripcion" },
  { header: 2139, field: "vin.modelo.id" },
  { header: 2139, field: "vin.modelo.descripcion" },
  { header: 2140, field: "vin.color.id" },
  { header: 2140, field: "vin.color.descripcion" },
  { header: 990, field: "stockPlaya.calle" },
  { header: 991, field: "stockPlaya.columna" },
  { header: 992, field: "stockPlaya.nivel" },
];

const TransferenciaColecta: React.FC<any> = (props) => {
  const { toast } = props;
  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();
  const [loading, setLoading] = useState(false);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [initialcolecta, setInitialColecta] = useState([]);
  const [colecta, setColecta] = useState(null);
  const [colectaSuggestions, setColectaSuggestions] = useState([]);
  const [invalidColeta, setInvalidColeta] = useState(false);
  const columNames = useFilterColumnsIdsNew(table);
  const [dataTableValue, setDataTableValue] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [autoForm, setAutoForm] = useState(new AutoForm());
  const [calle, setCalle] = useState([]);
  const [columna, setColumna] = useState([]);
  const [nivel, setNivel] = useState([]);
  const [lineasCarga, setLineasCarga] = useState(false);
  const [activarCTRC, setActivarCTRC] = useState(false);
  const [disableNuevaUbicacion, setDisableNuevaUbicacion] = useState(true);

  const MSG_ALL_FIELDS_REQUIRED = LanguageProvider({
    id: "gen.fillAllfields.required",
    alt: "Preencha todos os campos obrigatórios.",
  });

  const MSG_SELECTED_ROWS = LanguageProvider({
    id: "753",
    alt: "Deve selecionar ao menos um registro do quadro",
  });

  const MSG_ALL_FIELDS_SECURITY_REQUIRED = LanguageProvider({
    id: "gen.selectAllSecurityFields.required",
    alt: "Selecione todos os campos de segurança.",
  });

  async function loadColetaInitial() {
    try {
      setLoading(true);
      setLoadingDataTable(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-ubicacion/colecta",
        params
      );
      if (status === 200) {
        if (data) {
          setDataTableValue(data);
          setInitialColecta(data);
        } else {
          setDataTableValue([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingDataTable(false);
    }
  }

  async function loadColeta(query = "") {
    if (query) {
      const dataFiltered = initialcolecta
        .filter((x: any) =>
          x.hojaRuta.id.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.hojaRuta.id === value.hojaRuta.id)
        );
      setColectaSuggestions(dataFiltered);
    } else {
      const uniqueData = initialcolecta.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.hojaRuta.id === value.hojaRuta.id)
      );
      setColectaSuggestions(uniqueData);
    }
  }

  async function searchColecta(event: any) {
    await loadColeta(event.query);
  }

  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    );
  }
  async function selectedColecta(event) {
    setColecta(event.value);
    if (event?.value?.hojaRuta) {
      console.log("event?.value?.hojaRuta?.id => ", event?.value?.hojaRuta?.id);
      const filteredDataTable = initialcolecta.filter(
        (item) => item.hojaRuta.id === event.value.hojaRuta.id
      );
      setDataTableValue(filteredDataTable);
    } else {
      setDataTableValue(initialcolecta);
    }
  }

  const hasValidSecurityValues = () => {
    const { client, account, country, subaccount } =
      securityFilters.securityValues;
    if (
      country?.id &&
      account?.id &&
      subaccount?.id &&
      client?.id &&
      client?.descripcion
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: MSG_ALL_FIELDS_SECURITY_REQUIRED,
        life: 3000,
      });
      setLoading(false);
      return false;
    }
  };

  async function searchCalle(event: any) {
    await loadCalle(event.query);
  }
  async function loadCalle(query = "") {
    if (autoForm.columna !== "" || autoForm.nivel !== "") {
      console.log("entrou no if do loadcalle de limpeza");
      setAutoForm({ ...autoForm, columna: "", nivel: "" });
    }
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity) {
      // ajustar validação
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/calle",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const q = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setCalle(q);
          } else {
            setCalle(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function searchColumna(event: any) {
    await loadColumna(event.query);
  }
  async function loadColumna(query = "") {
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/columna",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
              calle: autoForm.calle,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const teste = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setColumna(teste);
          } else {
            setColumna(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  async function searchNivel(event: any) {
    await loadNivel(event.query);
  }

  async function loadNivel(query = "") {
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/nivel",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
              calle: autoForm.calle,
              columna: autoForm.columna,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const teste = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setNivel(teste);
          } else {
            setNivel(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  function hasValidAllFields() {
    const { client, account, country, subaccount } =
      securityFilters?.securityValues || {};

    const {
      calle: newCalle,
      columna: newColumna,
      nivel: newNivel,
      fecha: newFecha,
    } = autoForm || {};

    if (
      country?.id &&
      account?.id &&
      client?.id &&
      client?.descripcion &&
      subaccount?.id &&
      newFecha &&
      newCalle &&
      newColumna &&
      newNivel
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: MSG_ALL_FIELDS_REQUIRED,
        life: 3000,
      });
      setLoading(false);
      return false;
    }
  }

  function hasSelectRows() {
    if (selectedRows.length > 0) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: MSG_SELECTED_ROWS,
        life: 3000,
      });
      setLoading(false);
      return false;
    }
  }

  const postApiCambiarUbicacionColecta = async () => {
    const payload = {
      codPais: securityFilters.securityValues.country.id,
      codCliente: securityFilters.securityValues.client.id,
      descripcionCliente: securityFilters.securityValues.client.descripcion,
      codCuenta: securityFilters.securityValues.account.id,
      codSubcuenta: securityFilters.securityValues.subaccount.id,
      fecha: autoForm.fecha,
      colectas: selectedRows,

      newCalle: autoForm.calle,
      newColumna: autoForm.columna,
      newNivel: autoForm.nivel,

      chkPagoVin: false,
      activarCTRC: false,
    };
    console.log(payload);
    const ValidAllFields = hasValidAllFields();
    const ValidSelectRows = hasSelectRows();
    if (!ValidAllFields || !ValidSelectRows) {
      return;
    }

    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-ubicacion/cambiar-ubicacion-colecta",
        payload
      );
      if (status === 200) {
        toast.current.show({
          position: "bottom-left",
          severity: "success",
          summary: "Atención",
          detail: "su transaccion se realizó con éxito",
          life: 3000,
        });

        loadColetaInitial();
        setAutoForm(new AutoForm());
      } else {
        toast.current.show({
          position: "bottom-left",
          severity: "error",
          summary: status,
          detail: "ERROR",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function cancel() {
    setDisableNuevaUbicacion(true);
    setAutoForm(new AutoForm());
  }

  async function aceptarUbcacion() {
    confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => postApiCambiarUbicacionColecta(),
      reject: null,
    });
  }

  useEffect(() => {
    if (validateSecurityFilters()) {
      loadColetaInitial();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  console.log("colecta => ", colecta);

  return (
    <div>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10758"} alt="Nro. Colecta" />
            </label>
            {loading ? (
              <Skeleton width="100%" height="3rem"></Skeleton>
            ) : (
              <SipcoAutocomplete
                field="hojaRuta.id"
                value={colecta}
                suggestions={colectaSuggestions}
                completeMethod={searchColecta}
                onChange={selectedColecta}
                forceSelection
                dropdown
              />
            )}
          </div>
          {dataTableValue.length <= 1 && (
            <div className={"sipco-option-button"}>
              <Button
                onClick={loadColetaInitial}
                loading={loading}
                label={LanguageProvider({
                  id: "gen.searchAll.button",
                  alt: "Buscar Todos",
                })}
              />
            </div>
          )}

          {/* TODO: identificar uso desse checkbox */}
          {/* <div className="RadioButtonFilter">
            <label>
              <LanguageProvider id={"21367"} alt="Pago?" />
            </label>
            <Checkbox checked={false}></Checkbox>
          </div> */}
        </div>
      </div>
      <SipcoDataTable
        size={"small"}
        showGridlines
        stripedRows
        value={dataTableValue}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loadingDataTable}
        removableSort
        selectionMode="multiple"
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
      >
        {columNames.map((colum, index) => (
          <SipcoColumn
            key={index}
            field={colum.field}
            header={colum.label}
            sortable
          />
        ))}
      </SipcoDataTable>
      <PanelCustom
        header={<LanguageProvider id={"663"} alt="Nueva Ubicación" />}
      >
        <div style={{ display: "flex" }}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider id={"11313"} alt="Lineas de Carga?" />
                </label>
                <Checkbox
                  checked={lineasCarga}
                  onChange={(e) => setLineasCarga(e.checked)}
                  disabled={disableNuevaUbicacion}
                />
              </div>
              {/* {lineasCarga && (
                <div className="RadioButtonFilter">
                  <label>
                    <LanguageProvider id={"20006"} alt="Activar CTRC?" />
                  </label>
                  <Checkbox
                    checked={activarCTRC}
                    onChange={(e) => setActivarCTRC(e.checked)}
                    disabled={disableNuevaUbicacion}
                  />
                </div>
              )} */}
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.calle}
                  suggestions={calle}
                  completeMethod={searchCalle}
                  onChange={(e) => setAutoForm({ ...autoForm, calle: e.value })}
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.columna}
                  suggestions={columna}
                  completeMethod={searchColumna}
                  onChange={(e) =>
                    setAutoForm({ ...autoForm, columna: e.value })
                  }
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.nivel}
                  suggestions={nivel}
                  completeMethod={searchNivel}
                  onChange={(e) => setAutoForm({ ...autoForm, nivel: e.value })}
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"14407"} alt="Fecha de Transferencia" />
                </label>
                <SipcoCalendar
                  value={autoForm.fecha}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={true}
                  readOnlyInput={true}
                />
              </div>
            </div>
          </div>
        </div>
      </PanelCustom>

      {/* buttons */}
      {disableNuevaUbicacion ? (
        <div>
          <Button
            label={LanguageProvider({
              id: "19267",
              alt: "Modificar",
            })}
            text
            onClick={(e) => setDisableNuevaUbicacion(false)}
          />
          <Button
            label={LanguageProvider({
              id: "748",
              alt: "Seleccionar todo",
            })}
            onClick={(e) => setSelectedRows(dataTableValue)}
            text
          />
          <Button
            label={LanguageProvider({
              id: "749",
              alt: "Deseleccionar todo",
            })}
            onClick={(e) => setSelectedRows([])}
            text
          />
        </div>
      ) : (
        <div>
          <Button
            label={LanguageProvider({
              id: "9491",
              alt: "Aceitar",
            })}
            onClick={aceptarUbcacion}
            text
          />
          <Button
            label={LanguageProvider({
              id: "9492",
              alt: "Recusar",
            })}
            text
            onClick={cancel}
          />
        </div>
      )}
    </div>
  );
};
export default TransferenciaColecta;

class AutoForm {
  calle: string;
  columna: string;
  nivel: string;
  fecha: Date;

  constructor() {
    this.calle = "";
    this.columna = "";
    this.nivel = "";
    this.fecha = new Date();
  }
}
