import {
  DataTable,
  DataTableProps,
  DataTableValueArray,
} from "primereact/datatable";

import React from "react";

type SipcoDataTableProps<TValue extends DataTableValueArray> =
  DataTableProps<TValue>;

const SipcoDataTable = React.forwardRef(
  <TValue extends DataTableValueArray>(
    { ...props }: SipcoDataTableProps<TValue>,
    ref: React.Ref<DataTable<TValue>>
  ) => {
    return (
      <DataTable
        ref={ref}
        className="sipco-datatable"
        size="small"
        showGridlines
        stripedRows
        paginator
        scrollHeight="flex"
        rows={25}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        resizableColumns
        removableSort
        filterDisplay="menu"
        columnResizeMode="expand"
        {...props}
      />
    );
  }
);

SipcoDataTable.displayName = "SipcoDataTable";

export default SipcoDataTable;
