import "./CargaManualVin.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CargaManualVin() {
  const SCREEN_CODE = "fun0068_carga_manual_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();

  const [fieldsEnabled, setFieldsEnabled] = useState(false);
  const [dropDownEnabled, setdropDownEnabled] = useState(false);
  const [findVin, setFindVin] = useState([]);
  const { user } = useAuth();
  const [loadCodPlataforma, setLoadCodPlataforma] = useState("");
  const [loadPatente, setLoadPatente] = useState("");
  const [loadMotor, setLoadMotor] = useState("");
  const [loadModeloLargo, setLoadModeloLargo] = useState("");
  const [loadOrdenFabric, setLoadOrdenFabric] = useState("");
  const [loadUsername, setLoadUsername] = useState("");
  const [fechaFabricacion, setFechaFabricacion] = useState<Date | null>(null);
  const [actualizoFecha, setActualizoFecha] = useState<Date | null>(null);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);
  const [elVinNoExisteDialog, setElVinNoExisteDialog] = useState(false);
  const [datosEnBlancoDialog, setDatosEnBlancoDialog] = useState(false);
  const [transacConExitoDialog, setTransacConExitoDialog] = useState(false);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {}
  };

  async function handleVinInputKeyDown(event) {
    try {
      if (event.key === "Enter") {
        if (filter.vin.length >= 8 && filter.vin.length <= 17) {
          setLoadUsername(user?.userName || "");
          await loadVinActivo();
        } else {
          showError(
            "653",
            "Debe ingresar un mínimo de 8 y un máximo de 17 dígitos"
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleClearFields() {
    try {
      setFilter({ vin: filter.vin, copiaVin: false, cliente: null });
      setModel(null);

      setFechaFabricacion(new Date());
      setActualizoFecha(new Date());

      setLoadCodPlataforma("");
      setLoadPatente("");
      setLoadMotor("");
      setLoadModeloLargo("");
      setLoadOrdenFabric("");
    } catch (error) {
      console.error(error);
    }
  }

  function validNullFields(vin) {
    try {
      if (vin?.codigoPlataforma) {
        setLoadCodPlataforma(vin?.codigoPlataforma);
      } else {
        setLoadCodPlataforma("");
      }
      if (vin?.patente) {
        setLoadPatente(vin?.patente);
      } else {
        setLoadPatente("");
      }
      if (vin?.numeroMotor) {
        setLoadMotor(vin?.numeroMotor);
      } else {
        setLoadMotor("");
      }
      if (vin?.modeloLargo) {
        setLoadModeloLargo(vin?.modeloLargo);
      } else {
        setLoadModeloLargo("");
      }
      if (vin?.ordenFabricacion) {
        setLoadOrdenFabric(vin?.ordenFabricacion);
      } else {
        setLoadOrdenFabric("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinActivo() {
    try {
      if (securityFilters.securityValues.country && findVin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/find-vin",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            vin: { id: filter.vin || null },
          }
        );

        if (status === 200) {
          let vin = data[0];
          if (vin.id.length === 0) {
            return showError("654", "No existe el VIN");
          }

          setFindVin(data);
          setFieldsEnabled(true);
          setdropDownEnabled(false);
          validNullFields(vin);

          if (vin?.fechaSalidaFabrica?.date) {
            setFechaFabricacion(new Date(vin.fechaSalidaFabrica.date));
          }
          if (vin?.fechaUltimaModificacion?.date) {
            setActualizoFecha(new Date(vin.fechaUltimaModificacion.date));
          }

          setFilter({ ...filter, vin: vin.id });
          const [
            mercados,
            origens,
            marcas,
            modelos,
            colores,
            destinos,
            destOrigens,
            generos,
            denoComerciais,
            siluetas,
            acabados,
            motorizaciones,
            cajas,
            baseConcepciones,
            clienteConcesionarios,
            anioFabricaciones,
            modificaciones,
            tapizados,
            opcionais,
          ] = await Promise.all([
            loadMercado(),
            loadOrigen(),
            loadMarca(),
            loadModelo(vin.marca.id),
            loadColor(vin.marca.id),
            loadDestino(),
            loadDestinoOrig(),
            loadGenero(vin.marca.id),
            loadDenoComercial(vin.marca.id),
            loadSilueta(vin.marca.id),
            loadAcabado(vin.marca.id),
            loadMotorizacion(vin.marca.id),
            loadCaja(vin.marca.id),
            loadBaseConcepcion(vin.marca.id),
            loadCliConcesionario(vin.marca.id),
            loadAnioFabricacion(vin.marca.id),
            loadModificacion(vin.marca.id),
            loadTapizado(vin.marca.id),
            loadCabeceraOpcional(vin.marca.id),
          ]);

          vin.mercado = mercados.find((x) => x.id === vin?.mercado?.id) || null;
          vin.origen = origens.find((x) => x.id === vin?.origen?.id) || null;
          vin.marca = marcas.find((x) => x.id === vin?.marca?.id) || null;
          vin.color = colores.find((x) => x.id === vin?.color?.id) || null;
          vin.modelo = modelos.find((x) => x.id === vin?.modelo?.id) || null;
          vin.destino = destinos.find((x) => x.id === vin?.destino?.id) || null;
          vin.origenDeFabricacion =
            destOrigens.find((x) => x.id === vin?.origenDeFabricacion?.id) ||
            null;
          vin.genero = generos.find((x) => x.id === vin?.genero?.id) || null;
          vin.denominacionComercial =
            denoComerciais.find(
              (x) => x.id === vin?.denominacionComercial?.id
            ) || null;
          vin.silueta = siluetas.find((x) => x.id === vin?.silueta?.id) || null;
          vin.acabado = acabados.find((x) => x.id === vin?.acabado?.id) || null;
          vin.motorizacion =
            motorizaciones.find((x) => x.id === vin?.motorizacion?.id) || null;
          vin.caja = cajas.find((x) => x.id === vin?.caja?.id) || null;
          vin.baseConcepcion =
            baseConcepciones.find((x) => x.id === vin?.baseConcepcion?.id) ||
            null;
          vin.clienteConcepcion =
            clienteConcesionarios.find(
              (x) => x.id === vin?.clienteConcepcion?.id
            ) || null;
          vin.anioFabricacion =
            anioFabricaciones.find((x) => x.id === vin?.anioFabricacion?.id) ||
            null;
          vin.modificacion =
            modificaciones.find((x) => x.id === vin?.modificacion?.id) || null;
          vin.tapizado = tapizados.find((x) => x.id === vin?.tapizado?.id);
          vin.cabeceraOpcional =
            opcionais.find((x) => x.id === vin?.cabeceraOpcional?.id) || null;

          setModel((prevModel) => ({
            ...prevModel,
            mercado: vin.mercado,
            origen: vin.origen,
            marca: vin.marca,
            modelo: vin.modelo,
            color: vin.color,
            destino: vin.destino,
            destinoOrigen: vin.origenDeFabricacion,
            genero: vin.genero,
            denoComercial: vin.denominacionComercial,
            silueta: vin.silueta,
            acabado: vin.acabado,
            motorizacion: vin.motorizacion,
            caja: vin.caja,
            baseConcepcion: vin.baseConcepcion,
            clienteConcesionario: vin.clienteConcepcion,
            anoFabricacion: vin.anioFabricacion,
            modificacion: vin.modificacion,
            tapizado: vin.tapizado,
            opcional: vin.cabeceraOpcional,
          }));
        }
      }
    } catch (error) {
      console.error(error);
      loadFieldsNoData();
      setFieldsEnabled(false);
      setElVinNoExisteDialog(true);
    }
  }

  const loadFieldsNoData = () => {
    if (securityFilters?.securityValues?.country) {
      setdropDownEnabled(true);
      handleClearFields();
      try {
        const mercados = loadMercado();
        const origens = loadOrigen();
        const marcas = loadMarca();
        const destinos = loadDestino();
        const destinosOrig = loadDestinoOrig();

        setModel((prevModel) => ({
          ...prevModel,
          mercado: mercados,
          origen: origens,
          marca: marcas,
          destino: destinos,
          destinoOrigen: destinosOrig,
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const procesarClick = () => {
    try {
      if (requiredFields()) {
        return;
      }
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  function requiredFields() {
    try {
      if (
        !model?.mercado.id ||
        !model?.origen.id ||
        !model?.marca.id ||
        !model?.modelo.id ||
        !model?.color.id ||
        !model?.destino.id ||
        !model?.destinoOrigen.id ||
        !model?.genero.id ||
        !model?.silueta.id ||
        !model?.acabado.id ||
        !model?.motorizacion.id ||
        !model?.caja.id ||
        !model?.baseConcepcion.id ||
        !model?.clienteConcesionario.id ||
        !model?.anoFabricacion.id ||
        !model?.modificacion.id ||
        !model?.tapizado.id ||
        !model?.opcional.id ||
        !model?.motor ||
        !loadMotor ||
        !loadModeloLargo
      ) {
        setDatosEnBlancoDialog(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      setDatosEnBlancoDialog(true);
      return true;
    }
  }

  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      if (!fieldsEnabled) {
        aProcesar();
      } else {
        aBorrar();
      }
    }
  };

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        vin: {
          mercado: {
            id: model.mercado.id,
          },
        },
        files: [],
        vines: [],
        clienteNuevo: { id: filter?.cliente },
        marca: { id: model?.marca.id },
      };

      const { status } = await sipcoAxiosService.post(
        "/carga-manual-vin/save-vin",
        filters
      );
      if (status === 200) {
        handleClearFields();
        setTransacConExitoDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const borrarClick = () => {
    try {
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  async function aBorrar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        vin: { id: filter?.vin },
        files: [],
        vines: [],
        clienteNuevo: { id: filter?.cliente },
        marca: { id: model?.marca.id },
      };

      const { status } = await sipcoAxiosService.post(
        "/carga-manual-vin/remove-vin",
        filters
      );
      if (status === 200) {
        handleClearFields();
        setTransacConExitoDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const handleValidationClose = () => {
    setElVinNoExisteDialog(false);
    setDatosEnBlancoDialog(false);
    setTransacConExitoDialog(false);
  };

  const [filter, setFilter] = useState({
    vin: "",
    copiaVin: false,
    cliente: null,
  });

  const [model, setModel] = useState({
    mercado: null,
    origen: null,
    marca: null,
    modelo: null,
    color: null,
    destino: null,
    destinoOrigen: null,
    genero: null,
    denoComercial: null,
    codPlataforma: "",

    silueta: null,
    acabado: null,
    motorizacion: null,
    caja: null,
    baseConcepcion: null,
    clienteConcesionario: null,
    anoFabricacion: null,
    modificacion: null,
    fechaFabricacion: null,

    tapizado: null,
    opcional: null,
    patente: "",
    motor: "",
    modeloLargo: "",
    ordenFabricacion: "",
    actualizoUsuario: "",
    actualizoConFecha: null,
    ncm: "",
  });

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [cliente, setCliente] = useState([]);
  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cliente",
          {}
        );

        if (status === 200) {
          const clienteData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setCliente(clienteData);
          return clienteData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
    setCliente([]);
    return [];
  }

  const [loadingMercado, setLoadingMercado] = useState(false);
  const [mercado, setMercado] = useState([]);
  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/mercado",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const mercadoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setMercado(mercadoData);
          return mercadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    setMercado([]);
    return [];
  }

  const [loadingOrigen, setLoadingOrigen] = useState(false);
  const [origen, setOrigen] = useState([]);
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/origen",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const origenData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setOrigen(origenData);
          return origenData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
    setOrigen([]);
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/marca",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            activo: true,
          }
        );

        if (status === 200) {
          const marcaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setMarca(marcaData);
          return marcaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modelo, setModelo] = useState([]);
  async function loadModelo(marca: any) {
    setLoadingModelo(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modelo",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const modeloData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setModelo(modeloData);
          return modeloData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  const [loadingColor, setLoadingColor] = useState(false);
  const [color, setColor] = useState([]);
  async function loadColor(marca: any) {
    setLoadingColor(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/color",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const colorData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setColor(colorData);
          return colorData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColor(false);
    }
    setColor([]);
    return [];
  }

  const [loadingDestino, setLoadingDestino] = useState(false);
  const [destino, setDestino] = useState([]);
  async function loadDestino() {
    setLoadingDestino(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/destino",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const destinoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setDestino(destinoData);
          return destinoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestino(false);
    }
    setDestino([]);
    return [];
  }

  const [loadingDestinoOrig, setLoadingDestinoOrig] = useState(false);
  const [destinoOrig, setDestinoOrig] = useState([]);
  async function loadDestinoOrig() {
    setLoadingDestinoOrig(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/destino",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const destinoOrigData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setDestinoOrig(destinoOrigData);
          return destinoOrigData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinoOrig(false);
    }
    setDestinoOrig([]);
    return [];
  }

  const [loadingGenero, setLoadingGenero] = useState(false);
  const [genero, setGenero] = useState([]);
  async function loadGenero(marca: any) {
    setLoadingGenero(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/genero",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const generoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setGenero(generoData);
          return generoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGenero(false);
    }
    setGenero([]);
    return [];
  }

  const [loadingDenoComercial, setLoadingDenoComercial] = useState(false);
  const [denoComercial, setDenoComercial] = useState([]);
  async function loadDenoComercial(marca: any) {
    setLoadingDenoComercial(true);
    try {
      if (marca && securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/denominacion-comercial",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const denoComercialData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setDenoComercial(denoComercialData);
          return denoComercialData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComercial(false);
    }
    setDenoComercial([]);
    return [];
  }

  const [loadingSilueta, setLoadingSilueta] = useState(false);
  const [silueta, setSilueta] = useState([]);
  async function loadSilueta(marca: any) {
    setLoadingSilueta(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/silueta",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const siluetaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setSilueta(siluetaData);
          return siluetaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSilueta(false);
    }
    setSilueta([]);
    return [];
  }

  const [loadingAcabado, setLoadingAcabado] = useState(false);
  const [acabado, setAcabado] = useState([]);
  async function loadAcabado(marca: any) {
    setLoadingAcabado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/acabado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const acabadoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setAcabado(acabadoData);
          return acabadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAcabado(false);
    }
    setAcabado([]);
    return [];
  }

  const [loadingMotorizacion, setLoadingMotorizacion] = useState(false);
  const [motorizacion, setMotorizacion] = useState([]);
  async function loadMotorizacion(marca: any) {
    setLoadingMotorizacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/motorizacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const motorizacionData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setMotorizacion(motorizacionData);
          return motorizacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMotorizacion(false);
    }
    setMotorizacion([]);
    return [];
  }

  const [loadingCaja, setLoadingCaja] = useState(false);
  const [caja, setCaja] = useState([]);
  async function loadCaja(marca: any) {
    setLoadingCaja(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/caja",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const cajaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setCaja(cajaData);
          return cajaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCaja(false);
    }
    setCaja([]);
    return [];
  }

  const [loadingBaseConcepcion, setLoadingBaseConcepcion] = useState(false);
  const [baseConcepcion, setBaseConcepcion] = useState([]);
  async function loadBaseConcepcion(marca: any) {
    setLoadingBaseConcepcion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/base-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const baseConcepcionData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setBaseConcepcion(baseConcepcionData);
          return baseConcepcionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBaseConcepcion(false);
    }
    setBaseConcepcion([]);
    return [];
  }

  const [loadingClienteConc, setLoadingClienteConc] = useState(false);
  const [clienteConc, setClienteConc] = useState([]);
  async function loadCliConcesionario(marca: any) {
    setLoadingClienteConc(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cliente-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const clienteConcData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setClienteConc(clienteConcData);
          return clienteConcData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClienteConc(false);
    }
    setClienteConc([]);
    return [];
  }

  const [loadingAnoFabricacion, setLoadingAnoFabricacion] = useState(false);
  const [anoFabricacion, setAnoFabricacion] = useState([]);
  async function loadAnioFabricacion(marca: any) {
    setLoadingAnoFabricacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/anio-fabricacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const anoFabricacionData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setAnoFabricacion(anoFabricacionData);
          return anoFabricacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAnoFabricacion(false);
    }
    setAnoFabricacion([]);
    return [];
  }

  const [loadingModificacion, setLoadingModificacion] = useState(false);
  const [modificacion, setModificacion] = useState([]);
  async function loadModificacion(marca: any) {
    setLoadingModificacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modificacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );
        if (status === 200) {
          const modificacionData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setModificacion(modificacionData);
          return modificacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModificacion(false);
    }
    setModificacion([]);
    return [];
  }

  const [loadingTapizado, setLoadingTapizado] = useState(false);
  const [tapizado, setTapizado] = useState([]);
  async function loadTapizado(marca: any) {
    setLoadingTapizado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/tapizado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const tapizadoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setTapizado(tapizadoData);
          return tapizadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTapizado(false);
    }
    setTapizado([]);
    return [];
  }

  const [loadingOpcional, setLoadingOpcional] = useState(false);
  const [opcional, setOpcional] = useState([]);
  async function loadCabeceraOpcional(marca: any) {
    setLoadingOpcional(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cabecera-opcional",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          const opcionalData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setOpcional(opcionalData);
          return opcionalData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOpcional(false);
    }
    setOpcional([]);
    return [];
  }

  useEffect(() => {
    async function loadCopiaVin() {
      if (filter.copiaVin) {
        try {
          const clientes = await loadCliente();

          if (clientes?.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              cliente: clientes?.length > 0 ? clientes[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    loadCopiaVin();
  }, [filter.copiaVin]);

  useEffect(() => {
    async function dependsOnMarca() {
      if (!elVinNoExisteDialog && dropDownEnabled) {
        try {
          const [
            modelos,
            colores,
            generos,
            denoComerciais,
            siluetas,
            acabados,
            motorizaciones,
            cajas,
            baseConcepciones,
            cliConcesionarios,
            anoFabricaciones,
            modificaciones,
            tapizados,
            opcionais,
          ] = await Promise.all([
            loadModelo(model.marca.id),
            loadColor(model.marca.id),
            loadGenero(model.marca.id),
            loadDenoComercial(model.marca.id),
            loadSilueta(model.marca.id),
            loadAcabado(model.marca.id),
            loadMotorizacion(model.marca.id),
            loadCaja(model.marca.id),
            loadBaseConcepcion(model.marca.id),
            loadCliConcesionario(model.marca.id),
            loadAnioFabricacion(model.marca.id),
            loadModificacion(model.marca.id),
            loadTapizado(model.marca.id),
            loadCabeceraOpcional(model.marca.id),
          ]);

          setModel((prevFilter) => ({
            ...prevFilter,
            modelo: modelos,
            color: colores,
            genero: generos,
            denoComercial: denoComerciais,
            silueta: siluetas,
            acabado: acabados,
            motorizacion: motorizaciones,
            caja: cajas,
            baseConcepcion: baseConcepciones,
            clienteConcesionario: cliConcesionarios,
            anoFabricacion: anoFabricaciones,
            modificacion: modificaciones,
            tapizado: tapizados,
            opcional: opcionais,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }

    dependsOnMarca();
  }, [model?.marca]);

  useEffect(() => {
    async function loadModeloLargoField() {
      if (!elVinNoExisteDialog && model?.modelo?.id) {
        try {
          setLoadModeloLargo(model.modelo.id);
        } catch (error) {
          console.error(error);
        }
      }
    }
    loadModeloLargoField();
  }, [model.modelo]);

  return (
    <div className="carga-manual-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset
        legend={<LanguageProvider id={"1465"} alt="Carga Manual del VIN" />}
      >
        <div className="form" style={{ flexDirection: "column" }}>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <SipcoInputText
                value={filter.vin}
                onChange={(e) => {
                  setFilter({ ...filter, vin: e.target.value });
                }}
                onKeyDown={handleVinInputKeyDown}
                maxLength={17}
              />
            </div>
            <label>
              <LanguageProvider
                id={"10196"}
                alt="Copiar el Vin a Otro Cliente"
              />
            </label>
            <Checkbox
              checked={filter.copiaVin}
              onChange={(e) => setFilter({ ...filter, copiaVin: e.checked })}
              disabled={!fieldsEnabled && !dropDownEnabled}
            />
            {filter.copiaVin && (
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24084"} alt="Cliente" />
                </label>
                <SipcoDropdown
                  value={filter.cliente}
                  options={cliente}
                  onChange={(e) =>
                    setFilter({ ...filter, cliente: e.value || null })
                  }
                  optionLabel="label"
                  filter
                  loading={loadingCliente}
                />
              </div>
            )}
          </div>
        </div>
      </Fieldset>

      <Fieldset>
        <div className="form">
          <div className="column ">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.mercado || null}
                  options={mercado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      mercado: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingMercado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.origen || null}
                  options={origen}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      origen: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingOrigen}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.marca || null}
                  options={marca}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      marca: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  loading={loadingMarca}
                  disabled={!dropDownEnabled}
                  filter={false}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={() =>
                      window.open("/abm0010_mantener_marcas", "_blank")  //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.modelo || null}
                  options={modelo}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      modelo: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  loading={loadingModelo}
                  disabled={!dropDownEnabled}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0027_mantener_modelos", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.color || null}
                  options={color}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      color: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingColor}
                  disabled={!dropDownEnabled}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0012_mantener_colores", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4102"} alt="Destino" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.destino || null}
                  options={destino}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      destino: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingDestino}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16421"} alt="Destino de origen" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.destinoOrigen || null}
                  options={destinoOrig}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      destinoOrigen: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingDestinoOrig}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16422"} alt="Género" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.genero || null}
                  options={genero}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      genero: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingGenero}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"18202"} alt="Denominación Comercial" />
                </label>
                <SipcoDropdown
                  value={model?.denoComercial || null}
                  options={denoComercial}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      denoComercial: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingDenoComercial}
                  disabled={!dropDownEnabled}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                </label>
                <SipcoInputText
                  value={loadCodPlataforma}
                  onChange={(e) => {
                    setLoadCodPlataforma(e.target.value.toUpperCase());
                    setModel({
                      ...model,
                      codPlataforma: e.target.value.toUpperCase(),
                    });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16423"} alt="Silueta" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.silueta || null}
                  options={silueta}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      silueta: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingSilueta}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16424"} alt="Acabados" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.acabado || null}
                  options={acabado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      acabado: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingAcabado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16425"} alt="Motorización" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.motorizacion || null}
                  options={motorizacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      motorizacion: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingMotorizacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16426"} alt="Cajas" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.caja || null}
                  options={caja}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      caja: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingCaja}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16427"} alt="Base concepción" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.baseConcepcion || null}
                  options={baseConcepcion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      baseConcepcion: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingBaseConcepcion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16307"} alt="Cliente concesionario" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.clienteConcesionario || null}
                  options={clienteConc}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      clienteConcesionario: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingClienteConc}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16429"} alt="Año de fabricación" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.anoFabricacion || null}
                  options={anoFabricacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      anoFabricacion: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingAnoFabricacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13502"} alt="Modificación" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.modificacion || null}
                  options={modificacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      modificacion: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingModificacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                </label>
                <SipcoCalendar
                  value={fechaFabricacion}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setModel({ ...model, fechaFabricacion: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!dropDownEnabled}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16430"} alt="Tapizado" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.tapizado || null}
                  options={tapizado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      tapizado: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingTapizado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16431"} alt="Opcional" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.opcional || null}
                  options={opcional}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      opcional: e.value || null,
                    }))
                  }
                  optionLabel="label"
                  filter
                  loading={loadingOpcional}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4724"} alt="Patente" />
                </label>
                <SipcoInputText
                  value={loadPatente}
                  onChange={(e) => {
                    setLoadPatente(e.target.value);
                    setModel({ ...model, patente: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13335"} alt="Motor" />
                  <span className="required">*</span>
                </label>
                <SipcoInputText
                  value={loadMotor}
                  onChange={(e) => {
                    setLoadMotor(e.target.value);
                    setModel({ ...model, motor: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13338"} alt="Modelo Largo" />
                  <span className="required">*</span>
                </label>
                <SipcoInputText
                  value={loadModeloLargo}
                  onChange={(e) => {
                    setLoadModeloLargo(e.target.value);
                    setModel({ ...model, modeloLargo: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10784"} alt="Orden de Fabricación" />
                </label>
                <SipcoInputText
                  value={loadOrdenFabric}
                  onChange={(e) => {
                    setLoadOrdenFabric(e.target.value);
                    setModel({ ...model, ordenFabricacion: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13489"} alt="Actualizó Usuario" />
                </label>
                <SipcoInputText
                  value={loadUsername}
                  onChange={(e) => {
                    setModel({ ...model, actualizoUsuario: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13490"} alt="Actualizó con Fecha" />
                </label>
                <SipcoCalendar
                  value={actualizoFecha}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setModel({ ...model, actualizoConFecha: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={true}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3505"} alt="NCM" />
                </label>
                <SipcoInputText
                  value={findVin?.[0]?.ncm}
                  onChange={(e) => {
                    setModel({ ...model, ncm: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={procesarClick}
          disabled={!dropDownEnabled}
        />
        <Button
          label={LanguageProvider({ id: "2016", alt: "Borrar" })}
          icon="pi pi-times"
          onClick={borrarClick}
          disabled={!fieldsEnabled}
        />
        <Button
          label={LanguageProvider({
            id: "2366",
            alt: "Importar Archivo",
          })}
          icon="pi pi-upload"
          disabled={fieldsEnabled}
        />
      </div>

      <div className="dialogs">
        {/* El VIN no existe Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={elVinNoExisteDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="1480" alt="El VIN no existe" />
          </p>
        </Dialog>

        {/* Existen datos en blanco Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={datosEnBlancoDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="39" alt="Existen datos en blanco." />
          </p>
        </Dialog>

        {/* Procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Su transacción se realizó con éxito Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={transacConExitoDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
export default CargaManualVin;
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
