import "./MantenerCatalogoRepuestos.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalAltaMantenerCatalogoRepuestos from "./modals/ModalAltaMantenerCatalogoRepuestos";
import ModalModificarMantenerCatalogoRepuestos from "./modals/ModalModificarMantenerCatalogoRepuestos";
import ModalVerMantenerCatalogoRepuestos from "./modals/ModalVerMantenerCatalogoRepuestos";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import { useNavigate } from "react-router-dom";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MantenerCatalogoRepuestos() {
  const SCREEN_CODE = "abm0104_mantener_catalogo_repuestos";
  ScreenCodeValue(SCREEN_CODE);
  let navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const data = convertDateObjects(reporte?.filas);

  const [alta, setAlta] = useState(false);
  const [modificar, setModificar] = useState(false);
  const [ver, setVer] = useState(false);

  const showModalVer = () => {
    setVer(true);
  };
  const hideModalVer = () => {
    setVer(false);
  };
  const showModalModificar = () => {
    setModificar(true);
  };
  const hideModalModificar = () => {
    setModificar(false);
  };
  const showModalAlta = () => {
    setAlta(true);
  };
  const hideModalAlta = () => {
    setAlta(false);
  };

  const columnsNames = [];
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    pieza: "",
  });

  function barrar() {
    return confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider id={"829"} alt="¿Elimina el o los registros?" />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => console.log("aceptar"),
      reject: () => console.log("aceptar"),
    });
  }

  const [mantenerCatalogoRepuestos, setMantenerCatalogoRepuestos] = useState(
    []
  );

  async function loadReport() {
    try {
      if (
        securityFilters?.securityValues?.country !== null ||
        securityFilters?.securityValues?.client !== null
      ) {
        setLoading(true);
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-catalogos-repuestos/find",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            pieza: filter.pieza || null,
            start: 1,
            end: 100,
          }
        );
        if (status === HttpStatusCode.Ok) {
          const response = data.map((x: any) => {
            return {
              ...x,
              pieza: x?.id,
              descripcion: x?.descripcion,
              costo: x?.costoPeso,
              costoDollar: x?.costoDolar,
              cantidad: x?.cantidad,
              peso: x?.peso,
              ivan: x?.iva,
              grupoTaller: x?.grupoTaller?.id,
              modeloTaller: x?.modeloTaller?.id,
              rubro: x?.rubro?.descripcion || "NO INFORMADO",
              cuentaTaller: x?.cuentaTaller?.descripcion || "SERVICIOS",
              fechaCotizacion: x?.fechaCotizacion?.date,
              ultimaModificacion: x?.usuarioUltimaModificacion?.id,
              fechaUltimaModificacion: x?.fechaUltimaModificacion?.date,
            };
          });

          setMantenerCatalogoRepuestos(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "pieza", header: "Pieza" },
    { field: "descripcion", header: "Descripcion" },
    { field: "costo", header: "Costo($)" },
    { field: "costoDollar", header: "Costo(U$D)" },
    { field: "cantidad", header: "Cantidad" },
    { field: "peso", header: "Peso(Gr.)" },
    { field: "ivan", header: "I.V.A (%)" },
    { field: "grupoTaller", header: "Grupo Taller" },
    { field: "modeloTaller", header: "Modelo Taller" },
    { field: "rubro", header: "Rubro" },
    { field: "cuentaTaller", header: "Cuenta Taller" },
    { field: "fechaCotizacion", header: "Fecha Cotización" },
    { field: "ultimaModificacion", header: "Última Modificación" },
    { field: "fechaUltimaModificacion", header: "Fecha Última Modificación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  return (
    <div className="orden-reparacion-proveedores">
      <ModalAltaMantenerCatalogoRepuestos
        visible={alta}
        onClose={hideModalAlta}
      />
      <ModalModificarMantenerCatalogoRepuestos
        visible={modificar}
        onClose={hideModalModificar}
      />
      <ModalVerMantenerCatalogoRepuestos visible={ver} onClose={hideModalVer} />

      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />

      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2020"} alt="Pieza" />
                </label>
                <SipcoInputText
                  value={filter.pieza}
                  onChange={(e) =>
                    setFilter({ ...filter, pieza: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={() => showModalAlta()}
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                  onClick={() => showModalModificar()}
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                  onClick={() => showModalVer()}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                  onClick={() => barrar()}
                />
                <Button
                  label={LanguageProvider({
                    id: "2225",
                    alt: "Importar Datos",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "13882", alt: "Anterior" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "13883", alt: "Siguiente" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={mantenerCatalogoRepuestos}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Cotización" ||
              colum.header === "Fecha Última Modificación"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default MantenerCatalogoRepuestos;
