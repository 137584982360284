import { Dropdown, DropdownProps } from "primereact/dropdown";

import React from "react";

type SipcoDropdownProps = DropdownProps & {};

const SipcoDropdown: React.FC<SipcoDropdownProps> = ({ ...props }) => {
  return <Dropdown className="sipco-dropdown" filter {...props} />;
};

export default SipcoDropdown;
