import "./Peritaje.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  id: "TODOS",
  descripcionFull: "TODOS",
  descripcion: "TODOS",
  label: "TODOS",
};

export function Peritaje() {
  const SCREEN_CODE = "fun0103_peritaje";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState(new FilterModel());

  const [tableHojaRuta, setTableHojaRuta] = useState(null);

  const [dataTableValue, setDataTableValue] = useState([]);

  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    849, 850, 24084, 1491, 23892, 2139, 2140, 2941, 4216,
  ];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [vinSuggestions, setVinSuggestions] = useState([]);

  async function loadVin(query: any) {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post("/peritaje/vin", {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vin: {
            id: query,
          },
        });
        if (status === 200) {
          const vin = data.map((x: any) => {
            return {
              ...x,
              label: `${x.id}`,
            };
          });

          setVinSuggestions(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  const columnsNamesTable = [
    { field: "remitoLetra", header: "VIN" },
    { field: "puntoDeVenta", header: "Marca" },
    { field: "codRemito", header: "Modelo" },
    { field: "cliente", header: "Color" },
    { field: "vin", header: "Fecha de Peritaje" },
  ];

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marcaOptions, setMarcaOptions] = useState([]);
  async function loadMarca() {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/peritaje/marca",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const updatedElements = data.map((x) => {
            return {
              ...x,
              search: x.descripcion + x.id,
              descripcionFull: `${x.descripcion} - (${x.id})`,
            };
          });
          updatedElements.unshift(TODOS_OBJ);
          setMarcaOptions(updatedElements);
          return updatedElements;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modeloOptions, setModeloOptions] = useState([]);
  async function loadModelo() {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/peritaje/modelo",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const updatedElements = data.map((x) => {
            return {
              ...x,
              search: x.descripcion + x.id,
              descripcionFull: `${x.descripcion} - (${x.id})`,
            };
          });
          updatedElements.unshift(TODOS_OBJ);
          setModeloOptions(updatedElements);
          return updatedElements;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingColor, setLoadingColor] = useState(false);
  const [colorOptions, setColorOptions] = useState([]);
  async function loadColor() {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/peritaje/color",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const updatedElements = data.map((x) => {
            return {
              ...x,
              search: x.descripcion + x.id,
              descripcionFull: `${x.descripcion} - (${x.id})`,
            };
          });
          updatedElements.unshift(TODOS_OBJ);
          setColorOptions(updatedElements);
          return updatedElements;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setMarcaOptions([]);
    setModeloOptions([]);
    setColorOptions([]);
    const initialize = async () => {
      try {
        setLoadingMarca(true);
        const initialMarca = await loadMarca();
        if (initialMarca) {
          setFilter({
            marca: initialMarca[0],
            modelo: "",
            color: "",
            vin: "",
            peritado: true,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMarca(false);
      }
    };
    if (checkSecurityFilters()) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  useEffect(() => {
    if (filter.marca?.id !== TODOS_OBJ.id && filter.marca?.id !== "") {
      const initialize = async () => {
        try {
          setLoadingModelo(true);
          setLoadingColor(true);
          const initialModelo = await loadModelo();
          const initialColor = await loadColor();
          setFilter({
            ...filter,
            modelo: initialModelo[0] || "",
            color: initialColor[0] || "",
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingModelo(false);
          setLoadingColor(false);
        }
      };
      initialize();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.marca]);

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  return (
    <div className="peritaje">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>
                <SipcoDropdown
                  loading={loadingMarca}
                  value={filter.marca}
                  options={marcaOptions}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <SipcoDropdown
                  loading={loadingModelo}
                  value={filter.modelo}
                  options={modeloOptions}
                  onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                </label>
                <SipcoDropdown
                  loading={loadingColor}
                  value={filter.color}
                  options={colorOptions}
                  onChange={(e) => setFilter({ ...filter, color: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vinSuggestions}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                  maxLength={17}
                />

                <div className="radius-sipco">
                  <label>
                    <LanguageProvider id={"11123"} alt="Peritados" />
                  </label>
                  <RadioButton
                    value={true}
                    onChange={(e) =>
                      setFilter({ ...filter, peritado: e.value })
                    }
                    checked={filter.peritado === true}
                  />
                </div>
                <div className="radius-sipco">
                  <label>
                    <LanguageProvider id={"11124"} alt="Sin Peritar" />
                  </label>
                  <RadioButton
                    value={false}
                    onChange={(e) =>
                      setFilter({ ...filter, peritado: e.value })
                    }
                    checked={filter.peritado === false}
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Devolucion Nacional</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={dataTableValue}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "23826",
                    alt: "Cancelar",
                  })}
                  text
                />

                <Button
                  label={LanguageProvider({
                    id: "22183",
                    alt: "Descargar Layout",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={dataTableValue}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
        <Divider />
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"11121"} alt="Fecha de Peritaje" />
              </label>
              <SipcoCalendar showIcon showTime />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}

class FilterModel {
  marca: { id: string };
  modelo: any;
  color: any;
  vin: any;
  peritado: boolean;

  constructor() {
    this.marca = { id: "" };
    this.modelo = "";
    this.color = "";
    this.vin = "";
    this.peritado = true;
  }
}
