import { MultiSelect, MultiSelectProps } from "primereact/multiselect";

import React from "react";

type SipcoMultiSelectProps = MultiSelectProps & {};

const SipcoMultiSelect: React.FC<SipcoMultiSelectProps> = ({ ...props }) => {
  const selectedItemTemplate = (selectedItems: any[]) => {
    if (!selectedItems || selectedItems.length === 0) {
      return <span>Sin seleccion</span>;
    }
    return <span>{selectedItems.length} Selecionados</span>;
  };

  const panelFooterTemplate = () => {
    const length = props.value ? props.value.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <MultiSelect
      className="sipco-multiselect"
      filter
      display="chip"
      maxSelectedLabels={3}
      selectAll
      showClear
      // selectedItemTemplate={selectedItemTemplate}
      panelFooterTemplate={panelFooterTemplate}
      {...props}
    />
  );
};

export default SipcoMultiSelect;
