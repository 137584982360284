import "./TiemposCentroPerdidos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TiemposCentroPerdidos() {
  const SCREEN_CODE = "con0096_transit_time_pedidos_playa";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);

  const filterLabelId = [13859, 1491, 22137, 382, 16842, 266, 10904];
  const filterNames = [];
  const filterColumnsId = [
    22137, 10871, 2941, 10868, 10904, 23369, 23370, 11372, 4976, 1669, 1491,
    11158, 3774, 10898, 16925, 10577, 17263, 22209, 18727, 17203, 2830, 22406,
    13706, 13706,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];

  const ids = [
    0, 901, 65, 54, 1791, 13859, 22137, 382, 16842, 266, 530, 748, 749, 5583,
    5044, 11372, 22137, 3774, 1491, 10871, 2941, 10868, 10904, 11158, 10898,
    10577, 17203, 4976, 22209, 18727, 1669, 653, 2830, 22406, 23369, 23370,
    15624, 3184, 3329, 17263, 13706, 16925,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    concesionarios: null,
    direcciones: null,
    despacho: false,
    cargaRetorno: false,
    fechaDiponibilidadeDesde: new Date(),
    fechaDiponibilidadeHasta: new Date(),
    despachoDesde: null,
    despachoHasta: null,
    cargaRetornoDesde: null,
    cargaRetornoHasta: null,
    nroAsignacion: null,
    vin: "",
  });

  // Concesionarios
  const [concesionarios, setConcesionarios] = useState([]);
  const [loadingConcesionarios, setLoadingConcesionarios] = useState(false);

  // Vin
  const [vins, setVins] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  // Direcciones
  const [direcciones, setDirecciones] = useState([]);
  const [loadingDirecciones, setLoadingDirecciones] = useState(false);

  // Fecha Disponibilidad hasta e desde
  const [fechaDiponibilidadeDesde, setFechaDiponibilidadeDesde] =
    useState(null);
  const [fechaDiponibilidadeHasta, setFechaDiponibilidadeHasta] =
    useState(null);

  // Despacho hasta e desde
  const [despachoFechaDesde, setDespachoFechaDesde] = useState(null);
  const [despachoFechaHasta, setDespachoFechaHasta] = useState(null);

  // Carga Retorno hasta e desde
  const [cargaRetornoFechaDesde, setCargaRetornoFechaDesde] = useState(null);
  const [cargaRetornoFechaHasta, setCargaRetornoFechaHasta] = useState(null);

  const [reporte, setReporte] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  async function loadConcesionarios() {
    try {
      setLoadingConcesionarios(true);
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transit-time-pedido-playa/combo/concesionario",
          {
            params: {
              codPais: securityFilters?.securityValues?.country?.id,
              codCliente: securityFilters?.securityValues?.client?.id,
            },
          }
        );
        if (status === 200) {
          const reponse = data.map((x: any) => {
            return { ...x, label: `${x.label} - (${x.id})` };
          });

          setConcesionarios(reponse);

          return reponse;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarios(false);
    }
  }

  async function searchVin(event: any) {
    return await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      setLoadingVin(true);
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/transit-time-pedido-playa/combo/vin",
          {
            params: {
              codPais: securityFilters?.securityValues?.country?.id,
              codCuenta: securityFilters?.securityValues?.account?.id,
              codCliente: securityFilters?.securityValues?.client?.id,
              vin: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVins(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadDirecciones(concesionario: any) {
    try {
      setLoadingDirecciones(true);
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/transit-time-pedido-playa/combo/direccion-entrega",
          {
            params: {
              codPais: securityFilters?.securityValues?.country?.id,
              codCliente: securityFilters?.securityValues?.client?.id,
              codConcesionario: concesionario,
            },
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.label} - (${x.id})` };
          });
          setDirecciones(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDirecciones(false);
    }
  }

  async function loadReport() {
    setLoadingReport(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/transit-time-pedido-playa/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,

          rangeFecha: [
            filter?.fechaDiponibilidadeDesde || null,
            filter?.fechaDiponibilidadeHasta || null,
          ],
          fechaDespacho: [
            filter?.despachoDesde || null,
            filter?.despachoHasta || null,
          ],

          fechaCargaRetorno: [
            filter?.cargaRetornoDesde || null,
            filter?.cargaRetornoHasta || null,
          ],
          vin: vins?.[0]?.id || null,

          concesionarios: [filter?.concesionarios?.id || null],
          direccionEntrega: [filter?.direcciones?.id || null],
          nroAssignacion: filter?.nroAsignacion || "",
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const concessionarios = await loadConcesionarios();

        setFilter({
          ...filter,
          concesionarios: concessionarios,
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    if (filter.concesionarios) {
      loadDirecciones(filter.concesionarios.id);
    }
  }, [filter.concesionarios]);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="tiempos-centro-perdidos">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>Fecha Disponibilidad Desde</label>
                <SipcoCalendar
                  value={filter.fechaDiponibilidadeDesde}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaDiponibilidadeDesde: e.value })
                  }
                  showIcon
                  showTime
                />

                <label>Ingrese el VIN</label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                />

                <div className="form-row">
                  <Checkbox
                    checked={filter.despacho}
                    onChange={(e) => {
                      if (e.checked) {
                        setFilter({
                          ...filter,
                          despacho: true,
                          despachoDesde: new Date(),
                          despachoHasta: new Date(),
                        });
                      } else {
                        setFilter({
                          ...filter,
                          despacho: false,
                          despachoDesde: null,
                          despachoHasta: null,
                        });
                      }
                    }}
                  />
                  <label>Despacho</label>
                </div>

                <div className="form-row">
                  <Checkbox
                    checked={filter.cargaRetorno}
                    onChange={(e) => {
                      if (e.checked) {
                        setFilter({
                          ...filter,
                          cargaRetorno: true,
                          cargaRetornoDesde: new Date(),
                          cargaRetornoHasta: new Date(),
                        });
                      } else {
                        setFilter({
                          ...filter,
                          cargaRetorno: false,
                          cargaRetornoDesde: null,
                          cargaRetornoHasta: null,
                        });
                      }
                    }}
                  />
                  <label>Carga Retorno</label>
                </div>
              </div>

              <div className="sipco-option">
                <label>Fecha Disponibilidad Hasta</label>
                <SipcoCalendar
                  value={filter.fechaDiponibilidadeHasta}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaDiponibilidadeHasta: e.value })
                  }
                  showIcon
                  showTime
                />

                <label>Nro. Asignación</label>
                <SipcoInputText />

                <label>Despacho Desde</label>
                <SipcoCalendar
                  value={filter.despachoDesde}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      despachoDesde: e.value,
                    });
                  }}
                  showIcon
                />

                <label>Carga Retorno Desde</label>

                <SipcoCalendar
                  value={filter.cargaRetornoDesde}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      cargaRetornoDesde: e.value,
                    });
                  }}
                  showIcon
                  showTime
                />
              </div>

              <div className="sipco-option">
                <label>Concesionarios</label>
                <SipcoDropdown
                  loading={loadingConcesionarios}
                  options={concesionarios}
                  value={filter.concesionarios}
                  onChange={(e: any) =>
                    setFilter({ ...filter, concesionarios: e.value })
                  }
                />

                <label>Dirección de Entrega</label>
                <SipcoDropdown
                  loading={loadingDirecciones}
                  options={direcciones}
                  value={filter.direcciones}
                  onChange={(e: any) =>
                    setFilter({ ...filter, direcciones: e.value })
                  }
                />

                <label>Despacho Hasta</label>
                <SipcoCalendar
                  value={filter.despachoHasta}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      despachoHasta: e.value,
                    });
                  }}
                  showIcon
                  showTime
                />

                <label>Carga Retorno Hasta</label>
                <SipcoCalendar
                  value={filter.cargaRetornoHasta}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      cargaRetornoHasta: e.value,
                    });
                  }}
                  showIcon
                  showTime
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        header="Tiempos de Centro Perdidos"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Tiempos de Centro Perdidos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          {/*<SipcoColumn field="" header={columnsNames[7].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[29].label} sortable filter/>*/}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default TiemposCentroPerdidos;
