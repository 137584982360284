import "./CierreEstadia.scss";

import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const INITIAL_ETIQUETA = {
  marca: "",
  modelo: "",
  color: "",
  lcdv: "",
  pais: "",
};

export function CierreEstadia() {
  const SCREEN_CODE = "fun0065_cierre_estadia";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const toast = useRef<Toast>(null);

  const [vinForm, setVinForm] = useState("");
  const [etiqueta, setEtiqueta] = useState<any>(INITIAL_ETIQUETA);
  const [loading, setLoading] = useState(false);

  async function loadVin() {
    if (
      typeof vinForm !== "string" ||
      vinForm.length < 8 ||
      vinForm.length > 17
    ) {
      toast.current?.show({
        severity: "warn",
        detail: (
          <LanguageProvider
            id={"653"}
            alt="Deve Acessar um mínimo de 8 e um máximo de 17 dígitos"
          />
        ),
        life: 3000,
      });
      return;
    }
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.value,
        },
        cuenta: {
          id: securityFilters.securityValues.account.value,
        },
        cliente: {
          id: securityFilters.securityValues.client.value,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        vin: { id: vinForm },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/reimpresion-etiquetas/vin",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          return data[0];
        } else {
          toast.current?.show({
            severity: "warn",
            detail: <LanguageProvider id={"654"} alt="Não existe o VIN" />,
            life: 3000,
          });
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinFull() {
    try {
      setLoading(true);
      const vin = await loadVin();
      const params = {
        pais: {
          id: securityFilters.securityValues.country.value,
        },
        cuenta: {
          id: securityFilters.securityValues.account.value,
        },
        cliente: {
          id: securityFilters.securityValues.client.value,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        vin: vin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/reimpresion-etiquetas/vin-full",
        params
      );
      if (status === 200) {
        if (data) {
          setVinForm(data.id);
          setEtiqueta({
            marca: data.marca.descripcion,
            modelo: data.modelo.descripcion,
            color: data.color.descripcion,
            lcdv: data.modeloLargo,
            pais: data.origenDeFabricacion.descripcionFull,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="CierreEstadia">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={1385} alt="Cierre de Estadía" />}>
        <div className="form">
          <div className="form-row">
            <div
              className="sipco-option"
              style={{
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label style={{ marginRight: "5px" }}>
                <LanguageProvider
                  id={9653}
                  alt="Generar la Compra de Estadias Especiales"
                />
              </label>
              <Checkbox
                checked={true}
                style={{
                  justifyContent: "center",
                  position: "relative",
                  marginLeft: "10px",
                }}
              />
            </div>

            <div className="form">
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div className="sipco-option" style={{ marginBottom: "5px" }}>
                  <label>
                    <LanguageProvider
                      id={1412}
                      alt="Última Fecha de  Cierre de Estadía:"
                    />
                  </label>
                </div>
                <div className="sipco-option">
                  <SipcoCalendar showButtonBar showIcon dateFormat="dd/mm/yy" />
                </div>
              </div>

              <div className="sipco-option" style={{ marginBottom: "5px" }}>
                <label style={{ marginRight: "5px" }}>
                  <LanguageProvider id={1368} alt="Se cerrará la estadía al:" />
                </label>
              </div>
              <div className="sipco-option">
                <SipcoCalendar
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  style={{ marginRight: "5px" }}
                />
                <label>
                  <LanguageProvider id={12494} alt="(No Inclusive)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <Button
            label={LanguageProvider({
              id: "16283",
              alt: "Procesar",
            })}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default CierreEstadia;
