import "./BloqueosDesbloqueos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function BloqueosDesbloqueos() {
  const SCREEN_CODE = "fun0110_bloqueos_desbloqueos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  const [compraTaller, setCompraTaller] = useState([]);

  const [filter, setFilter] = useState({
    marca: null,
    modelo: null,
    color: null,
    stock: false,
    stockPendientes: false,
    fecha: false,
    fechaG500Fecha: new Date(),
    fechaG500Desde: new Date(),
    fechaZP8: false,
    fechaZP8Desde: new Date(),
    fechaZP8Hasta: new Date(),
    vin: null,
    anoFabricacion: "",
    anoModelo: "",
    chasisDesde: "",
    chasisHasta: "",
    fechaDate: new Date(),
    observaciones: "",
    familia: null,
    tipoBloqueo: null,
    paisDestino: null,
  });

  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);
  async function loadMarca() {
    try {
      setLoadingMarca(true);
      if (
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/marca",
          {
            pais: securityFilters.securityValues?.country,
            cliente: securityFilters.securityValues?.client,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  const [tipoBloqueo, setTipoBloqueo] = useState([]);
  const [loadingTipoBloqueo, setLoadingTipoBloqueo] = useState(false);
  async function loadTipoBloqueo() {
    try {
      setLoadingTipoBloqueo(true);
      if (
        securityFilters.securityValues?.account &&
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/tipo-bloqueo",
          {
            pais: securityFilters.securityValues?.country,
            cuenta: securityFilters.securityValues?.account,
            cliente: securityFilters.securityValues?.client,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setTipoBloqueo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoBloqueo(false);
    }
  }

  const [paisDestino, setPaisDestino] = useState([]);
  const [loadingPaisDestino, setLoadingPaisDestino] = useState(false);
  async function loadPaisDestino() {
    try {
      setLoadingPaisDestino(true);
      if (
        securityFilters.securityValues?.account &&
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country &&
        securityFilters.securityValues?.subaccount
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/pais-destino",
          {
            pais: securityFilters.securityValues?.country,
            cuenta: securityFilters.securityValues?.account,
            cliente: securityFilters.securityValues?.client,
            subcuenta: securityFilters.securityValues?.subaccount,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setPaisDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPaisDestino(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);
  async function loadModelo() {
    try {
      setLoadingModelo(true);
      if (
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/modelo",
          {
            pais: securityFilters.securityValues?.country,
            cliente: securityFilters.securityValues?.client,
            marca: filter.marca || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  const [color, setColor] = useState([]);
  const [loadingColor, setLoadingColor] = useState(false);

  async function loadColor() {
    try {
      setLoadingColor(true);
      if (
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/color",
          {
            pais: securityFilters.securityValues?.country,
            cliente: securityFilters.securityValues?.client,
            marca: filter.marca || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setColor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColor(false);
    }
  }

  const [familia, setFamilia] = useState([]);
  const [loadingFamilia, setLoadingFamilia] = useState(false);
  async function loadFamilia() {
    try {
      setLoadingFamilia(true);
      if (
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.country
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/familia",
          {
            pais: securityFilters.securityValues?.country,
            cliente: securityFilters.securityValues?.client,
            marca: filter.marca || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });

          setFamilia(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilia(false);
    }
  }

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/bloqueos-desbloqueos/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/compras-taller/find-compra-taller",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          subcuenta: securityFilters.securityValues?.subaccount,
          taller: {
            id: filter.marca,
          },
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          const response = data.map((x) => {
            return {
              ...x,
              codCompra: x?.id,
              tipoTaller: x?.taller?.id,
              descripcion: x?.tipoCompra.descripcion,
              fechaCompra: x?.fechaUltimaModificacion.date,
              codEstado: x?.estado.descripcion,
              nroPedidoPSA: x?.nroPedidPsa,
              codProveedor: x?.proveedor.id,
              descripcionProveedor: x?.proveedor.descripcion,
              codCliente: x?.cliente.id,
              descripcionCliente: x?.cliente.descripcion,
              codRemito: x?.remito,
              codMoneda: x?.moneda.id,
              descripcionMoneda: x?.moneda.descripcion,
            };
          });

          setCompraTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "codCompra", header: "VIN" },
    { field: "tipoTaller", header: "Calle" },
    { field: "descripcion", header: "Columna" },
    { field: "fechaCompra", header: "Nivel" },
    { field: "codEstado", header: "Fecha Bloque" },
  ];

  // Marca - Bloqueo
  useEffect(() => {
    const init = () => {
      try {
        const marca = loadMarca();
        const tipoBloqueo = loadTipoBloqueo();
        const paisDestino = loadPaisDestino();

        setFilter({
          ...filter,
          marca: marca[0],
          tipoBloqueo: tipoBloqueo[0],
          paisDestino: paisDestino[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    const init = () => {
      try {
        const modelo = loadModelo();
        const color = loadColor();
        const familia = loadFamilia();

        setFilter({
          ...filter,
          modelo: modelo[0],
          color: color[0],
          familia: familia[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [filter.marca]);

  const handleFilterUpdate = (key, value) =>
    setFilter((prev) => ({ ...prev, [key]: value }));

  const renderCalendar = (field, disabled) => (
    <SipcoCalendar
      value={filter[field]}
      onChange={(e) => handleFilterUpdate(field, e.value)}
      disabled={disabled}
      showIcon
    />
  );

  return (
    <div className="bloqueos-desbloqueos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filter.marca}
                  options={marca}
                  onChange={(e) => {
                    setFilter({ ...filter, marca: e.value });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <SipcoDropdown
                  value={filter.modelo}
                  options={modelo}
                  onChange={(e) => {
                    setFilter({ ...filter, modelo: e.value });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                </label>
                <SipcoDropdown
                  value={filter.color}
                  options={color}
                  onChange={(e) => {
                    setFilter({ ...filter, color: e.value });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"21412"} alt="Fecha G500" />
                </label>
                <div className="sipcoCalendar">
                  <Checkbox
                    checked={filter.fecha}
                    onChange={(e) => handleFilterUpdate("fecha", e.checked)}
                  />
                  {["fechaG500Desde", "fechaG500Fecha"].map((field) =>
                    renderCalendar(field, !filter.fecha)
                  )}
                </div>

                <div className="radioButton">
                  <label className="radioButton">
                    <LanguageProvider
                      id={"13210"}
                      alt="Bloq./Desbloq. desde Stock: "
                    />
                    <RadioButton
                      checked={filter.stock}
                      value={filter.stock}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          stock: e.checked,
                          stockPendientes: false,
                        })
                      }
                    />
                  </label>
                </div>

                <label>
                  <LanguageProvider id={"11085"} alt="Año Fabricacion: " />
                </label>
                <SipcoInputText
                  value={filter.anoFabricacion}
                  onChange={(e) =>
                    setFilter({ ...filter, anoFabricacion: e.target.value })
                  }
                />
                <label>
                  <LanguageProvider id={"22514"} alt="Chasis desde: " />
                </label>
                <SipcoInputText
                  value={filter.chasisDesde}
                  onChange={(e) =>
                    setFilter({ ...filter, chasisDesde: e.target.value })
                  }
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10681"} alt="País Destino: " />
                </label>
                <SipcoDropdown
                  value={filter.paisDestino}
                  options={paisDestino}
                  onChange={(e) => {
                    setFilter({ ...filter, paisDestino: e.value.id });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"10034"} alt="Familia" />
                </label>
                <SipcoDropdown
                  value={filter.familia}
                  options={familia}
                  onChange={(e) => {
                    setFilter({ ...filter, familia: e.value.id });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="id"
                  dropdown
                  forceSelection
                />
                <label>
                  <LanguageProvider id={"21413"} alt="Fecha ZP8: " />
                </label>
                <div className="sipcoCalendar">
                  <Checkbox
                    checked={filter.fechaZP8}
                    onChange={(e) =>
                      setFilter({ ...filter, fechaZP8: e.checked })
                    }
                  />
                  {filter.fechaZP8 ? (
                    <>
                      <SipcoCalendar
                        value={filter.fechaZP8Desde}
                        onChange={(e) =>
                          setFilter({ ...filter, fechaZP8Desde: e.value })
                        }
                        showIcon
                      />
                      <SipcoCalendar
                        value={filter.fechaZP8Hasta}
                        onChange={(e) =>
                          setFilter({ ...filter, fechaZP8Hasta: e.value })
                        }
                        showIcon
                      />
                    </>
                  ) : (
                    <>
                      <SipcoCalendar
                        value={filter.fechaZP8Desde}
                        onChange={(e) =>
                          setFilter({ ...filter, fechaZP8Desde: e.value })
                        }
                        disabled
                        showIcon
                      />
                      <SipcoCalendar
                        value={filter.fechaZP8Hasta}
                        onChange={(e) =>
                          setFilter({ ...filter, fechaZP8Hasta: e.value })
                        }
                        disabled
                        showIcon
                      />
                    </>
                  )}
                </div>

                <div className="radioButton">
                  <label className="radioButton">
                    <LanguageProvider
                      id={"13211"}
                      alt="Bloq./Desbloq. desde Stock + Pendientes"
                    />
                    <RadioButton
                      checked={filter.stockPendientes}
                      value={filter.stockPendientes}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          stockPendientes: e.checked,
                          stock: false,
                        })
                      }
                    />
                  </label>
                </div>

                <label>
                  <LanguageProvider id={"11086"} alt="Año Modelo: " />
                </label>
                <SipcoInputText
                  value={filter.anoModelo}
                  onChange={(e) =>
                    setFilter({ ...filter, anoModelo: e.target.value })
                  }
                />
                <label>
                  <LanguageProvider id={"22515"} alt="Chasis hasta: " />
                </label>
                <SipcoInputText
                  value={filter.chasisHasta}
                  onChange={(e) =>
                    setFilter({ ...filter, chasisHasta: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Ingreso Vines y Daños Por Archivo Texto.
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "11100", alt: "Bloquear VIN" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "11101",
                    alt: "Desbloquear VIN",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={compraTaller}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
        <Divider />

        <div className="form">
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados: " />
              </label>
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"19359"}
                    alt="Registros con advertencia (Se procesan)."
                  />
                }
                style={{ background: "blue" }}
              />
              {filter.stockPendientes && (
                <Tag
                  value={<LanguageProvider id={"4355"} alt="Pendientes" />}
                  style={{ background: "purple" }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="form" style={{ marginTop: "40px" }}>
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"2131"} alt="Fecha: " />
              </label>
              <SipcoCalendar showIcon />
              <label>
                <LanguageProvider id={"1669"} alt="Observación: " />
              </label>
              <SipcoInputText />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"11072"} alt="Cod. Bloqueo: " />
              </label>
              <SipcoDropdown
                value={filter.tipoBloqueo}
                options={tipoBloqueo}
                onChange={(e) => {
                  setFilter({ ...filter, tipoBloqueo: e.value });
                }}
                optionLabel="descripcion"
              />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
