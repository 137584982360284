import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import React from "react";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";

const VinView: React.FC<{ vinData: any }> = ({ vinData }) => {
  const filterColumnsId = [
    1491, 1165, 2892, 990, 991, 992, 1136, 396, 2140, 2496, 367, 4724, 22209,
    2139, 4006, 11443, 13335, 10034, 4102, 15538, 13338, 16440, 3505, 32, 18202,
    1081, 16553, 16554, 16555, 13798, 3244, 382, 12103, 13928, 9853, 22102,
    1150, 5003, 16448, 6261, 16556, 1137, 382, 326, 16557, 4492, 319, 16558,
    5628, 9564, 9620, 42, 254, 42, 1153, 3701, 15288, 16559, 2717, 42, 2760, 42,
    18604, 15596, 9574, 9578, 42, 5854, 18154, 22700, 42, 9955,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  return (
    <div className="vin-view">
      <Panel header={"Ubicacion Actual"}>
        <div className="form-row">
          <div className={"form-field"}>
            <label>Playa</label>
            <SipcoInputText
              readOnly={true}
              value={vinData?.stockPlaya?.subcuenta?.descripcion || ""}
            />
          </div>
          <div className={"form-field"}>
            <label>Calle</label>
            <SipcoInputText
              readOnly={true}
              value={vinData?.stockPlaya?.calle || ""}
            />
          </div>
          <div className={"form-field"}>
            <label>Columna</label>
            <SipcoInputText
              readOnly={true}
              value={vinData?.stockPlaya?.columna || ""}
            />
          </div>
          <div className={"form-field"}>
            <label>Nível</label>
            <SipcoInputText
              readOnly={true}
              value={vinData?.stockPlaya?.nivel || ""}
            />
          </div>
        </div>
      </Panel>

      <Panel header={"Datos del Vehículo"}>
        <div className="form-collumn">
          <div className="form-row">
            <div className={"form-field"}>
              <label>Marca</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.marca
                    ? vinData.vinResult.marca.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Color</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.color
                    ? vinData.vinResult.color.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Origen</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.origen
                    ? vinData.vinResult.origen.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Mercado</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.mercado
                    ? vinData.vinResult.mercado.descripcion
                    : ""
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"form-field"}>
              <label>Patente</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.patente
                    ? vinData.vinResult.patente
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Código Plataforma</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.codigoPlataforma
                    ? vinData.vinResult.codigoPlataforma
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Modelo</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo
                    ? vinData.vinResult.modelo.descripcionFull
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Modelo Impresso en Remito</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo
                    ? vinData.vinResult.modelo.descripcion
                    : ""
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"form-field"}>
              <label>Estado Vehículo</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.estadoVehiculo
                    ? vinData.vinResult.estadoVehiculo.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Motor</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.numeroMotor
                    ? vinData.vinResult.numeroMotor
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Familia</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo.familia
                    ? vinData.vinResult.modelo.familia.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Destino</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.destino
                    ? vinData.vinResult.destino.descripcion
                    : ""
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"form-field"}>
              <label>Categoría Translado</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo.categoriaTraslado
                    ? vinData.vinResult.modelo.categoriaTraslado.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Modelo Largo</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modeloLargo
                    ? vinData.vinResult.modeloLargo
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Categoría Estadia</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo.categoriaEstadia
                    ? vinData.vinResult.modelo.categoriaEstadia.descripcion
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>NCM</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.ncm ? vinData.vinResult.ncm : ""
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"form-field"}>
              <label>Valor</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.modelo
                    ? vinData.vinResult.modelo.valor
                    : ""
                }
              />
            </div>
            <div className={"form-field"}>
              <label>Denominación Comercial</label>
              <SipcoInputText
                readOnly={true}
                value={
                  vinData && vinData.vinResult.denominacionComercial
                    ? vinData.vinResult.denominacionComercial.id
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </Panel>

      <div className={"VinViewDatos"}>
        <Panel header={"Fechas del Vehículo"} className={"FechasTab"}>
          <div className="form-row">
            <div className={"form-collumn"}>
              <div className={"form-field"}>
                <label>Ultimo Ingreso Playa</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaUltimoIngresoPlaya &&
                    vinData.vinResult.fechaUltimoIngresoPlaya.date
                      ? `${new Date(vinData.vinResult.fechaUltimoIngresoPlaya.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaUltimoIngresoPlaya.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Ultima Salida de Playa</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaUltimaSalidaPLaya &&
                    vinData.vinResult.fechaUltimaSalidaPLaya.date
                      ? `${new Date(vinData.vinResult.fechaUltimaSalidaPLaya.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaUltimaSalidaPLaya.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Fecha Indisponible</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaIndisponible &&
                    vinData.vinResult.fechaIndisponible.date
                      ? `${new Date(vinData.vinResult.fechaIndisponible.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaIndisponible.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Fecha Bloqueo Calidad</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaBloqueoCalidad &&
                    vinData.vinResult.fechaBloqueoCalidad.date
                      ? `${new Date(vinData.vinResult.fechaBloqueoCalidad.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaBloqueoCalidad.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Fecha Entrega</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaEntrega &&
                    vinData.vinResult.fechaEntrega.date
                      ? `${new Date(vinData.vinResult.fechaEntrega.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaEntrega.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
            </div>
            <div className={"form-collumn"}>
              <div className={"form-field"}>
                <label>Despacho</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaMovimiento &&
                    vinData.vinResult.fechaMovimiento.date
                      ? `${new Date(vinData.vinResult.fechaMovimiento.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaMovimiento.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Fecha Fabricación</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaSalidaFabrica &&
                    vinData.vinResult.fechaSalidaFabrica.date
                      ? `${new Date(vinData.vinResult.fechaSalidaFabrica.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaSalidaFabrica.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Fecha Asignación</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaAsignacion &&
                    vinData.vinResult.fechaAsignacion.date
                      ? `${new Date(vinData.vinResult.fechaAsignacion.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaAsignacion.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Pediente Ingreso Taller</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.fechaPendienteIngresoTaller &&
                    vinData.vinResult.fechaPendienteIngresoTaller.date
                      ? `${new Date(vinData.vinResult.fechaPendienteIngresoTaller.date).toLocaleDateString()} ${new Date(vinData.vinResult.fechaPendienteIngresoTaller.date).toLocaleTimeString()}`
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Orden Fabricación</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData &&
                    vinData.vinResult &&
                    vinData.vinResult.ordenFabricacion &&
                    vinData.vinResult.ordenFabricacion
                      ? vinData.vinResult.ordenFabricacion
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </Panel>
        <Panel header={"Radio Possibles"} className={"DatosTabs"}>
          <div className={"form-collumn"}>
            <div className={"form-field"}>
              <SipcoInputTextArea
                readOnly={true}
                autoResize
                rows={5}
                value={
                  vinData && vinData.vinResult.puntero
                    ? vinData.vinResult.puntero
                    : ""
                }
              />
            </div>
            <div className="form-row">
              <div className={"form-field"}>
                <label>Nº Factura</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData && vinData && vinData.vinResult.numeroFactura
                      ? vinData.vinResult.numeroFactura
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>CAF</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData && vinData.vinResult.caf
                      ? vinData.vinResult.caf
                      : ""
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"form-field"}>
                <label>Fecha Factura</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData && vinData.vinResult.fechaFactura
                      ? new Date(
                          vinData.vinResult.fechaFactura.date
                        ).toLocaleDateString()
                      : ""
                  }
                />
              </div>
              <div className={"form-field"}>
                <label>Nº Embarque</label>
                <SipcoInputText
                  readOnly={true}
                  value={
                    vinData && vinData.vinResult.numeroLicencia
                      ? vinData.vinResult.numeroLicencia
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default VinView;
