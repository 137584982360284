import React, { useState } from "react";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";

const Lectora: React.FC<any> = () => {
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    capitalyHBA: true,
  });

  const filterColumnsIdLectora = [1491, 1569, 5788, 8380, 2521, 1669];

  const columNames = useFilterColumnsIds(filterColumnsIdLectora);

  return (
    <div>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1569"} alt="Operación" />
            </label>
            <SipcoInputText />
          </div>
          <div className={"sipco-option-extra-text"}>
            <SipcoInputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2521"} alt="Proveedor" />
            </label>
            <SipcoInputText />
          </div>
          <div className={"sipco-option-extra-text"}>
            <SipcoInputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <SipcoInputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            <SipcoInputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"1669"} alt="Observación" />
            </label>
            <SipcoInputText />
          </div>
        </div>
      </div>
      <SipcoDataTable
        size={"small"}
        showGridlines
        stripedRows
        value={data}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames.map((colum, index) => (
          <SipcoColumn key={index} field={colum.id} header={colum.label} />
        ))}
      </SipcoDataTable>
      {/* buttons */}
      <div>
        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          text
        />
      </div>
    </div>
  );
};
export default Lectora;
