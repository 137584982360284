import "./CargaDeRetornos.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import DanosDelVin from "./components/DanosDelVin";
import DatosDelRemito from "./components/DatosDelRemito";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

interface Filter {
  letra: string;
  puntoDeVenta: string;
  numero: string;
}
export function CargaDeRetornos() {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const SCREEN_CODE = "fun0055_carga_de_retornos";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [reporteVin, setReporteVin] = useState(null);

  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [filter, setFilter] = useState<Filter>({
    letra: "",
    puntoDeVenta: "",
    numero: "",
  });
  const data = convertDateObjects(reporte?.filas);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.value);
  };
  async function loadReportData() {
    setLoading(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/carga-de-retorno/remito-retorno",
        {
          remito: {
            pais: {
              id: securityFilters.securityValues.country.id,
            },

            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            },
            letra: filter.letra,
            puntoDeVenta: filter.puntoDeVenta,
            id: filter.numero,
          },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadHistoricoDanos();
  }, [reporte]);

  async function loadHistoricoDanos() {
    setLoading(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/carga-de-retorno/historico-danios",
        {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          cliente: {
            id: securityFilters.securityValues.client.id,
          },
          cuenta: {
            id: securityFilters.securityValues.account.id,
          },
          vin: reporte.vin,
        }
      );
      if (status === 200) {
        setReporteVin(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function processarCargaRetorno() {
    setLoading(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/carga-de-retorno/carga-retorno",
        {
          remito: reporte,
          fechaRetorno: {
            date: selectedDate,
          },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="carga-de-retornos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Accordion>
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option-text" style={{ maxWidth: "10%" }}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <SipcoInputText
                  name="letra"
                  value={filter.letra}
                  onChange={handleInputChange}
                />
              </div>
              <div className="sipco-option-text" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <SipcoInputText
                  name="puntoDeVenta"
                  value={filter.puntoDeVenta}
                  onChange={handleInputChange}
                />
              </div>
              <div className="sipco-option-text" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <SipcoInputText
                  name="numero"
                  value={filter.numero}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Fieldset legend={<LanguageProvider id={"303"} alt="Carga de Retorno" />}>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"295"} alt="Retorno" />
              </label>
              <SipcoCalendar
                showTime
                showButtonBar
                showIcon
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"18099"} alt="Nro. Check List" />
              </label>
              <SipcoInputText />
            </div>
          </div>
        </div>

        <TabView>
          <TabPanel
            header={<LanguageProvider id={"915"} alt="Datos del Remito" />}
          >
            <DatosDelRemito data={reporte} />
          </TabPanel>
          <TabPanel
            header={<LanguageProvider id={"1043"} alt="Daños del VIN" />}
          >
            <DanosDelVin data={reporteVin} />
          </TabPanel>
        </TabView>

        <div className="chips">
          <div className={"sipco-chip"}>
            <label>
              <LanguageProvider id={"10270"} alt="Total Selecionados" />
            </label>
            <Chip label="0" />
          </div>
        </div>
        {/* buttons */}
        <div>
          <Button
            label={LanguageProvider({
              id: "16283",
              alt: "Procesar",
            })}
            text
            onClick={processarCargaRetorno}
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default CargaDeRetornos;
