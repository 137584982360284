import "./ReingresoStock.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalReingresoDeStock from "@pages/functions/reingreso-de-stock/ModalReingresoDeStock";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ReingressoStock() {
  const SCREEN_CODE = "fun0082_reingreso_de_stock";
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [14370, 467, 14368, 14369, 901, 18308, 18309, 1569];
  const filterNames = [];
  const filterColumnsId = [16476, 11351, 12419, 1253, 2890];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }
  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();
  const [filter, setFilter] = useState({
    taller: null,
    rubro: null,
  });
  const [loadingReport, setLoadingReport] = useState(false);

  const [taller, setTaller] = useState(null);
  const [loadingTaller, setLoadingTaller] = useState(false);

  const [rubro, setRubro] = useState(null);
  const [loadingRubro, setLoadingRubro] = useState(false);

  async function loadReport() {
    setLoadingReport(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/reingreso-de-stock/find-stock",
        {
          pais: securityFilters.securityValues.country,
          subcuenta: securityFilters.securityValues.subaccount,
          cuenta: securityFilters.securityValues.account,
          taller: filter.taller,
          rubro: filter.rubro,
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/reingreso-de-stock/taller",
        {
          pais: securityFilters.securityValues.country,
          subcuenta: securityFilters.securityValues.subaccount,
          cuenta: securityFilters.securityValues.account,
        }
      );
      if (status === 200) {
        setTaller(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }
  async function loadRubro() {
    setLoadingRubro(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/reingreso-de-stock/rubro",
        {
          pais: securityFilters.securityValues.country,
        }
      );
      if (status === 200) {
        data.unshift({ id: "", descripcion: "TODOS" });
        setRubro(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRubro(false);
    }
  }
  useEffect(() => {
    async function initialize() {
      try {
        if (
          securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.account != null &&
          securityFilters?.securityValues?.subaccount != null
        ) {
          const talleres = await loadTaller();
          const rubros = await loadRubro();
          setFilter({
            ...filter,
            taller: talleres[0],
            rubro: rubros[0],
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="consulta-ot">
      <ModalReingresoDeStock
        visible={modalVisible}
        onHide={handleCloseModal}
        taller={filter.taller}
      />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[0].label} alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  loading={loadingTaller}
                  optionLabel="descripcion"
                  filter
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[0].label} alt="Rubro" />
                </label>
                <SipcoDropdown
                  value={filter.rubro}
                  options={rubro}
                  onChange={(e) => setFilter({ ...filter, rubro: e.value })}
                  loading={loadingRubro}
                  optionLabel="descripcion"
                  filter
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="6697" alt="Listado" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Orden Trabajo"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div style={{ marginTop: "1.25em" }}>
                <Button
                  onClick={handleOpenModal}
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <SipcoColumn
            field="clienteRespuesto.descripcion"
            header={columnsNames[0].label}
          />
          <SipcoColumn field="pieza.id" header={columnsNames[3].label} />
          <SipcoColumn
            field="pieza.descripcion"
            header={columnsNames[1].label}
          />
          <SipcoColumn field="cantidad" header={columnsNames[2].label} />
          <SipcoColumn field="valorizado" header={columnsNames[4].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ReingressoStock;

function setLoadingEstadosOt(arg0: boolean) {
  throw new Error("Function not implemented.");
}
