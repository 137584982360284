import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalAltaReparacionServicos from "./modals/ModalAltaReparacionServicos";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function OrdenReparacionServicios() {
  const SCREEN_CODE = "abm0108_orden_reparacion_servicios";
  ScreenCodeValue(SCREEN_CODE);

  const navigate = useNavigate();

  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};

  const [alta, setAlta] = useState(false);

  const showModalAlta = () => {
    setAlta(true);
  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  const values = {
    state: {
      pais: pais,
      cuenta: cuenta,
      subcuenta: subcuenta,
      nroOrden: nroOrden,
      tallerFilter: tallerFilter,
    },
  };

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  const [compraTaller, setCompraTaller] = useState([]);

  const [filter, setFilter] = useState({
    taller: null,
    codCompra: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }
  async function loadTaller() {
    try {
      setLoadingTaller(true);
      const { status, data } = await sipcoAxiosService.post(
        "/compras-taller/taller",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        const taller = data.map((x: any) => ({
          id: x.id,
          label: x.descripcion,
        }));

        setTaller(taller);
        return taller;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const taller = await loadTaller();

      setFilter({
        ...filter,
        taller: taller,
      });
    };
    init();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/compras-taller/find-compra-taller",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          subcuenta: securityFilters.securityValues?.subaccount,
          taller: {
            id: filter.taller,
          },
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          const response = data.map((x) => {
            return {
              ...x,
              codCompra: x?.id,
              tipoTaller: x?.taller?.id,
              descripcion: x?.tipoCompra.descripcion,
              fechaCompra: x?.fechaUltimaModificacion.date,
              codEstado: x?.estado.descripcion,
              nroPedidoPSA: x?.nroPedidPsa,
              codProveedor: x?.proveedor.id,
              descripcionProveedor: x?.proveedor.descripcion,
              codCliente: x?.cliente.id,
              descripcionCliente: x?.cliente.descripcion,
              codRemito: x?.remito,
              codMoneda: x?.moneda.id,
              descripcionMoneda: x?.moneda.descripcion,
            };
          });

          setCompraTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "codCompra", header: "Codigo Compra" },
    { field: "tipoTaller", header: "Tipo Taller" },
    { field: "descripcion", header: "Descripcion" },
    { field: "fechaCompra", header: "Fecha Compra" },
    { field: "codEstado", header: "Estado" },
    { field: "nroPedidoPSA", header: "Nro Pedido PSA" },
    { field: "codProveedor", header: "Proveedor" },
    { field: "descripcionProveedor", header: "Descripcion Proveedor" },
    { field: "codCliente", header: "Cliente" },
    { field: "descripcionCliente", header: "Descripcion Cliente" },
    { field: "codRemito", header: "Remito" },
    { field: "codMoneda", header: "Moneda" },
    { field: "descripcionMoneda", header: "Descripcion Moneda" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />
      <ModalAltaReparacionServicos visible={alta} onClose={hideModalAlta} />
      <Filter onSearch={loadReportData}>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <SipcoInputText value={pais?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoInputText value={tallerFilter} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <SipcoInputText value={cuenta?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <SipcoInputText value={nroOrden} disabled={true} />
              </div>
              <div className={"sipco-option"} style={{ marginBottom: "5em" }}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <SipcoInputText
                  value={subcuenta?.descripcion}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Fieldset
        legend={
          <LanguageProvider id={"2297"} alt="Orden Reparación Servicios" />
        }
      >
        <div className="fieldset-content">
          <SipcoDataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={compraTaller}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {/* Modificar Columns da Tabela */}
            <SipcoColumn
              field="id"
              header={columnsNames[0].label}
              sortable
              filter
            />
            <SipcoColumn
              field="fechaVale.timestamp"
              header={columnsNames[1].label}
              body={(data) => {
                return new Date(data.fechaVale.timestamp).toLocaleDateString();
              }}
              sortable
              filter
            />
            <SipcoColumn
              field="ordenReparacion.vin.id"
              header={columnsNames[2].label}
              sortable
              filter
            />
            <SipcoColumn
              field="ordenReparacion.vin.modeloLargo"
              header={columnsNames[3].label}
              sortable
              filter
            />
          </SipcoDataTable>
        </div>

        <Panel
          className="sipco-AdmRemitosConcesionarios"
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}>Listado</span>
                <div className="action">
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsNames}
                    screenName={"AdministracionRemitos"}
                  />
                </div>
              </div>
            );
          }}
          footerTemplate={(props) => {
            return (
              <div className={props.className}>
                <div>
                  <Button
                    label={LanguageProvider({ id: "25", alt: "Alta" })}
                    text
                    onClick={showModalAlta}
                  />
                  <Button
                    label={LanguageProvider({ id: "27", alt: "Modificar" })}
                    text
                  />

                  <Button
                    label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                    text
                  />
                  <Button
                    label={LanguageProvider({
                      id: "2247",
                      alt: "Detalle Proveedores",
                    })}
                    text
                    onClick={() => {
                      navigate("/orden-reparacion-proveedores", values);
                    }}
                  />
                </div>
              </div>
            );
          }}
        >
          <SipcoDataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={compraTaller}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {/* Modificar Columns da Tabela */}
            {columnsNamesTable.map((colum, index) => {
              if (colum.header === "Fecha Compra") {
                return (
                  <SipcoColumn
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <SipcoColumn
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}
          </SipcoDataTable>
        </Panel>
      </Fieldset>
    </div>
  );
}
