import "./ReimpresionEtiquetas.scss";

import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoInputText from "@shared/components/sipco-input-text";
import SkeletonCard from "./components/SkeletonCard";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const INITIAL_ETIQUETA = {
  marca: "",
  modelo: "",
  color: "",
  lcdv: "",
  pais: "",
};

export function ReimpresionEtiquetas() {
  const SCREEN_CODE = "fun0105_reimpresion_etiquetas";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const toast = useRef<Toast>(null);

  const [vinForm, setVinForm] = useState("");
  const [etiqueta, setEtiqueta] = useState<any>(INITIAL_ETIQUETA);
  const [loading, setLoading] = useState(false);

  async function loadVin() {
    if (
      typeof vinForm !== "string" ||
      vinForm.length < 8 ||
      vinForm.length > 17
    ) {
      toast.current?.show({
        severity: "warn",
        detail: (
          <LanguageProvider
            id={"653"}
            alt="Deve Acessar um mínimo de 8 e um máximo de 17 dígitos"
          />
        ),
        life: 3000,
      });
      return;
    }
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        vin: { id: vinForm },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/reimpresion-etiquetas/vin",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          return data[0];
        } else {
          toast.current?.show({
            severity: "warn",
            detail: <LanguageProvider id={"654"} alt="Não existe o VIN" />,
            life: 3000,
          });
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinFull() {
    try {
      setLoading(true);
      const vin = await loadVin();
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        vin: vin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/reimpresion-etiquetas/vin-full",
        params
      );
      if (status === 200) {
        if (data) {
          setVinForm(data.id);
          setEtiqueta({
            marca: data.marca.descripcion,
            modelo: data.modelo.descripcion,
            color: data.color.descripcion,
            lcdv: data.modeloLargo,
            pais: data.origenDeFabricacion.descripcionFull,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="reimpresion-etiquetas">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"10835"} alt="Reimpresión de Etiquetas" />
        }
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoInputText
                  value={vinForm}
                  onChange={(e) => setVinForm(e.target.value)}
                  maxLength={17}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadVinFull();
                    }
                  }}
                />
              </div>
              <div className={"sipco-option-button"}>
                <Button
                  label={LanguageProvider({
                    id: "9665",
                    alt: "Buscar",
                  })}
                  onClick={loadVinFull}
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonCard />
        ) : (
          <Card>
            <div className="fieldset-content">
              <div className="filter-options">
                <div className={"sipco-option-text-large"}>
                  <label>
                    <LanguageProvider id={"23888"} alt="Marca" />
                  </label>
                  <SipcoInputText value={etiqueta.marca} readOnly />
                </div>
                <div className={"sipco-option-text-large"}>
                  <label>
                    <LanguageProvider id={"2139"} alt="Modelo" />
                  </label>
                  <SipcoInputText value={etiqueta.modelo} readOnly />
                </div>
                <div className={"sipco-option-text-large"}>
                  <label>
                    <LanguageProvider id={"2140"} alt="Color" />
                  </label>
                  <SipcoInputText value={etiqueta.color} readOnly />
                </div>
                <div className={"sipco-option-text-large"}>
                  <label>
                    <LanguageProvider id={"10785"} alt="LCDV" />
                  </label>
                  <SipcoInputText value={etiqueta.lcdv} readOnly />
                </div>
                <div className={"sipco-option-text-large"}>
                  <label>
                    <LanguageProvider id={"11821"} alt="País" />
                  </label>
                  <SipcoInputText value={etiqueta.pais} readOnly />
                </div>
              </div>
            </div>
          </Card>
        )}
        <Divider />
        <div>
          <Button
            label={LanguageProvider({
              id: "78",
              alt: "Deshechar Cambios",
            })}
            onClick={() => setEtiqueta(INITIAL_ETIQUETA)}
            text
          />
          <Button
            label={LanguageProvider({
              id: "10840",
              alt: "Reimprimir las Etiquetas",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "21330",
              alt: "Reimprimir etiqueta MAN",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "21331",
              alt: "Reimpresion Cartelon MAN",
            })}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default ReimpresionEtiquetas;
