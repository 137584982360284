import "./HistoricoDanos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useDebounce } from "primereact/hooks";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function HistoricoDanos() {
  const SCREEN_CODE = "con0026_historico_danios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [
    467, 468, 1357, 11078, 1551, 1552, 901, 14535, 10541, 16243, 17007, 21675,
    10774, 2496, 396, 408, 16808, 491, 10021, 16809, 2837,
  ];
  const filterNames = [];
  const filterColumnsId = [
    20216, 1, 1, 1, 1, 1, 1, 1, 1396, 2892, 54, 1491, 10540, 16243, 21675, 7280,
    21836, 16505, 16246, 11314, 7811, 11097, 3344, 20854, 10774, 2496, 396, 408,
    491, 10021, 6590, 16809, 6590, 32, 16544, 1, 16576, 2941, 11070, 14712,
    4870, 4871, 4655, 18096, 18125, 18097, 18099, 18098, 1269, 19126, 14535,
    1355, 16541,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 2, 32, 54, 65, 396, 408, 467, 468, 491, 748, 749, 1182, 1357, 1396, 1491,
    1551, 1552, 1791, 2496, 3344, 20854, 2502, 2892, 2941, 4655, 4870, 4871,
    5044, 5583, 6590, 7811, 10021, 10531, 10540, 10774, 11070, 11078, 11097,
    11314, 14712, 16243, 16246, 16505, 16531, 16544, 16576, 16808, 16809, 18096,
    18097, 18098, 18099, 18125, 10541, 16243, 17007, 14535, 1269, 19126, 14535,
    20216, 20238, 21675, 21836, 7280, 1355, 16541, 653, 1480, 901, 2621, 2622,
    6697,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, debouncedFilter, setFilter] = useDebounce(
    {
      fechaInicio: new Date(),
      fechaFinal: new Date(),
      listarVehiculosConDanios: null,
      conceptosBloqueadores: null,
      usaFechaDeCargaDelDano: false,
      vin: "",
      destinos: [],
      descripcionesDanios: [],
      partesDanadas: [],
      gravedadDeDanios: [],
      cuadranteDanios: [],
      mercado: [],
      origens: [],
      marcas: [],
      modelos: [],
      versiones: [],
      colores: [],
      familias: [],
      categorias: [],
    },
    400
  );

  const [loading, setLoading] = useState(false);

  const [listarVehiculosConDanios, setListarVehiculosConDanios] = useState([]);
  const [conceptosBloqueadores, setConceptosBloqueadores] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [descripcionesDanios, setDescripcionesDanios] = useState([]);
  const [partesDanadas, setPartesDanadas] = useState([]);
  const [gravedadDeDanios, setGravedadDeDanios] = useState([]);
  const [cuadranteDanios, setCuadranteDanios] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [origen, setOrigen] = useState([]);
  const [marca, setMarca] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [colores, setColores] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const [loadingListarVehiculosConDanios, setLoadingListarVehiculosConDanios] =
    useState(true);
  const [loadingConceptosBloqueadores, setLoadingConceptosBloqueadores] =
    useState(true);
  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [loadingDescripcionesDanios, setLoadingDescripcionesDanios] =
    useState(true);
  const [loadingPartesDanadas, setLoadingPartesDanadas] = useState(true);
  const [loadingGravedadDeDanios, setLoadingGravedadDeDanios] = useState(true);
  const [loadingMercados, setLoadingMercados] = useState(true);
  const [loadingOrigen, setLoadingOrigen] = useState(true);
  const [loadingMarca, setLoadingMarca] = useState(true);
  const [loadingCuadranteDanios, setLoadingCuadranteDanios] = useState(false);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [loadingVersiones, setLoadingVersiones] = useState(false);
  const [loadingColores, setLoadingColores] = useState(false);
  const [loadingFamilias, setLoadingFamilias] = useState(false);
  const [loadingCategorias, setLoadingCategorias] = useState(false);

  async function loadListarVehiculosConDanios() {
    setLoadingListarVehiculosConDanios(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setListarVehiculosConDanios(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingListarVehiculosConDanios(false);
    }
  }
  async function loadConceptosBloqueadores() {
    setLoadingConceptosBloqueadores(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setConceptosBloqueadores(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConceptosBloqueadores(false);
    }
  }
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/destino",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );

      if (status === 200) {
        const destino = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setDestinos(destino);
        return destino;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }
  async function loadDescripcionesDanios() {
    setLoadingDescripcionesDanios(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/descripcion-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );

      if (status === 200) {
        const descripcionDano = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setDescripcionesDanios(descripcionDano);
        return descripcionDano;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDescripcionesDanios(false);
    }
  }
  async function loadPartesDanadas() {
    setLoadingPartesDanadas(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/partes-danadas",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );

      if (status === 200) {
        const parteDanada = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setPartesDanadas(parteDanada);
        return parteDanada;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPartesDanadas(false);
    }
  }
  async function loadGravedadDeDanios() {
    setLoadingGravedadDeDanios(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/gravedad-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );

      if (status === 200) {
        const gravedadDelDano = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setGravedadDeDanios(gravedadDelDano);
        return gravedadDelDano;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGravedadDeDanios(false);
    }
  }
  async function loadCuadranteDanios(parteDanificada: any) {
    setLoadingCuadranteDanios(true);
    try {
      if (parteDanificada) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/cuadrantes",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codParteDanio: parteDanificada.id,
            },
          }
        );

        if (status === 200) {
          const cuadrante = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setCuadranteDanios(cuadrante);
          return cuadrante;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuadranteDanios(false);
    }
  }
  async function loadMercados() {
    setLoadingMercados(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/mercados",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );

      if (status === 200) {
        const mercados = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setMercados(mercados);
        return mercados;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/origen",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );

      if (status === 200) {
        const origen = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setOrigen(origen);
        return origen;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }
  async function loadMarcas() {
    setLoadingMarca(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/marca",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );

      if (status === 200) {
        const marcas = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setMarca(marcas);
        return marcas;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }
  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );

        if (status === 200) {
          const modelos = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setModelos(modelos);
          return modelos;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function loadVersiones(marca: any) {
    setLoadingVersiones(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/versiones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );

        if (status === 200) {
          const versiones = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setVersiones(versiones);
          return versiones;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVersiones(false);
    }
  }
  async function loadColores(marca: any) {
    setLoadingColores(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );

        if (status === 200) {
          const colores = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setColores(colores);
          return colores;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
  }
  async function loadFamilias(marca: any) {
    setLoadingFamilias(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );

        if (status === 200) {
          const familias = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setFamilias(familias);
          return familias;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
  }
  async function loadCategorias(marca: any) {
    setLoadingCategorias(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/categorias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const categorias = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setCategorias(categorias);
          return categorias;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategorias(false);
    }
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        listarVehiculosConDanios: filter?.listarVehiculosConDanios?.id || "",
        conceptosBloqueadores: filter?.conceptosBloqueadores?.id || "",
        fechaUsadaHistoricoDanios: filter.usaFechaDeCargaDelDano
          ? "false"
          : "true",
        vin: filter?.vin || "",
        destinos: filter?.destinos?.map((x) => x.id) || [],
        descripcionesDanios:
          filter?.descripcionesDanios?.map((x) => x.id) || [],
        partesDanadas: filter?.partesDanadas?.map((x) => x.id) || [],
        gravedadDeDanios: filter?.gravedadDeDanios?.map((x) => x.id) || [],
        cuadranteDanios: filter?.cuadranteDanios?.map((x) => x.id) || [],
        mercado: filter?.mercado?.map((x) => x.id) || [],
        origens: filter?.origens?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
        modelos: filter?.modelos?.map((x) => x.id) || [],
        versiones: filter?.versiones?.map((x) => x.id) || [],
        colores: filter?.colores?.map((x) => x.id) || [],
        familias: filter?.familias?.map((x) => x.id) || [],
        categorias: filter?.categorias?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/historico-danos/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function handleMarcaSelection(event: MultiSelectChangeEvent) {
    try {
      if (event?.value?.length === 1) {
        const [modelos, versiones, colores, categorias, familias] =
          await Promise.all([
            loadModelos(event.value[0]),
            loadVersiones(event.value[0]),
            loadColores(event.value[0]),
            loadCategorias(event.value[0]),
            loadFamilias(event.value[0]),
          ]);
        setFilter({
          ...filter,
          marcas: event.value,
          modelos,
          versiones,
          colores,
          categorias,
          familias,
        });
      } else {
        setFilter({
          ...filter,
          marcas: event.value,
          modelos: [],
          versiones: [],
          colores: [],
          categorias: [],
          familias: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleParteDanificadaSelection(event: MultiSelectChangeEvent) {
    try {
      if (event?.value?.length === 1) {
        const cuadrantesDano = await loadCuadranteDanios(event.value[0]);
        setFilter({
          ...filter,
          partesDanadas: event.value,
          cuadranteDanios: cuadrantesDano,
        });
      } else {
        setFilter({
          ...filter,
          partesDanadas: event.value,
          cuadranteDanios: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const listarVehiculosConDanios = await loadListarVehiculosConDanios();
          const conceptosBloqueadores = await loadConceptosBloqueadores();
          const destinos = await loadDestinos();
          const descripcionesDanios = await loadDescripcionesDanios();
          const partesDanadas = await loadPartesDanadas();
          const gravedadDeDanios = await loadGravedadDeDanios();
          const mercados = await loadMercados();
          const origens = await loadOrigen();
          const marcas = await loadMarcas();

          setFilter({
            ...filter,
            listarVehiculosConDanios: listarVehiculosConDanios[0],
            conceptosBloqueadores: conceptosBloqueadores[0],
            destinos: destinos,
            descripcionesDanios: descripcionesDanios,
            partesDanadas: partesDanadas,
            gravedadDeDanios: gravedadDeDanios,
            mercado: mercados,
            origens: origens,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";

  return (
    <div className="historico-danos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={mensagens[71].id} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaInicio}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={mensagens[72].id} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaFinal}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[2].id}
                    alt="Listar Vehiculos com Danõs Reparados"
                  />
                </label>
                <SipcoDropdown
                  value={filter.listarVehiculosConDanios}
                  options={listarVehiculosConDanios}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      listarVehiculosConDanios: e.value,
                    });
                  }}
                  optionLabel="descripcion"
                  loading={loadingListarVehiculosConDanios}
                  filter
                  itemTemplate={(e) => `${e.id} - ${e.descripcion}`}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[3].id}
                    alt="Conceptos Bloqueantes"
                  />
                </label>
                <SipcoDropdown
                  value={filter.conceptosBloqueadores}
                  options={conceptosBloqueadores}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      conceptosBloqueadores: e.value,
                    });
                  }}
                  optionLabel="descripcion"
                  loading={loadingConceptosBloqueadores}
                  filter
                  itemTemplate={(e) => `${e.id} - ${e.descripcion}`}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={!filter.usaFechaDeCargaDelDano}
                  onChange={(e) => {
                    setFilter({ ...filter, usaFechaDeCargaDelDano: false });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id={filterNames[4].label}
                    alt="Usa fecha en que se produjo el Daño"
                  />
                </label>
                <RadioButton
                  checked={filter.usaFechaDeCargaDelDano}
                  onChange={(e) => {
                    setFilter({ ...filter, usaFechaDeCargaDelDano: true });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id={filterNames[5].label}
                    alt="Usa fecha de Carga del Daño"
                  />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[6].id}
                    alt="Ingrese el VIN"
                  />
                </label>
                <SipcoInputText
                  value={filter.vin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[7].id} alt="Destinos" />
                </label>
                <SipcoMultiSelect
                  value={filter.destinos}
                  options={destinos}
                  onChange={(e) => {
                    setFilter({ ...filter, destinos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDestinos}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[8].id}
                    alt="Descripcion Daño"
                  />
                </label>
                <SipcoMultiSelect
                  value={filter.descripcionesDanios}
                  options={descripcionesDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, descripcionesDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDescripcionesDanios}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[9].id} alt="Parte Dañada" />
                </label>
                <SipcoMultiSelect
                  value={filter.partesDanadas}
                  options={partesDanadas}
                  onChange={handleParteDanificadaSelection}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingPartesDanadas}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[10].id}
                    alt="Gravedad del Daño"
                  />
                </label>
                <SipcoMultiSelect
                  value={filter.gravedadDeDanios}
                  options={gravedadDeDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, gravedadDeDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingGravedadDeDanios}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[11].id}
                    alt="Cuadrante (LD)"
                  />
                </label>
                <SipcoMultiSelect
                  value={filter.cuadranteDanios}
                  options={cuadranteDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, cuadranteDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingCuadranteDanios}
                  showClear
                  disabled={
                    filter?.partesDanadas?.length !== 1 || clientIsTodos
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[12].id} alt="Mercado" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercado}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[13].id} alt="Origen" />
                </label>
                <SipcoMultiSelect
                  value={filter.origens}
                  options={origen}
                  onChange={(e) => {
                    setFilter({ ...filter, origens: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigen}
                  showClear
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[14].id} alt="Marca" />
                </label>
                <SipcoMultiSelect
                  value={filter.marcas}
                  options={marca}
                  onChange={handleMarcaSelection}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMarca}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <SipcoMultiSelect
                  value={filter.modelos}
                  options={modelos}
                  onChange={(e) => {
                    setFilter({ ...filter, modelos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingModelos}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[16].id} alt="Versiones" />
                </label>
                <SipcoMultiSelect
                  value={filter.versiones}
                  options={versiones}
                  onChange={(e) => {
                    setFilter({ ...filter, versiones: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingVersiones}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[17].id} alt="Colores" />
                </label>
                <SipcoMultiSelect
                  value={filter.colores}
                  options={colores}
                  onChange={(e) => {
                    setFilter({ ...filter, colores: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingColores}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[18].id} alt="Familias" />
                </label>
                <SipcoMultiSelect
                  value={filter.familias}
                  options={familias}
                  onChange={(e) => {
                    setFilter({ ...filter, familias: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingFamilias}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[19].id} alt="Categorias" />
                </label>
                <SipcoMultiSelect
                  value={filter.categorias}
                  options={categorias}
                  onChange={(e) => {
                    setFilter({ ...filter, categorias: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingCategorias}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[73].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado Operaciones Realizadas"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          {/* <SipcoColumn field="0" header={columnsNames[0].label} sortable filter />
          <SipcoColumn field="1" header={columnsNames[1].label} sortable filter />
          <SipcoColumn field="2" header={columnsNames[2].label} sortable filter />
          <SipcoColumn field="3" header={columnsNames[3].label} sortable filter />
          <SipcoColumn field="4" header={columnsNames[4].label} sortable filter />
          <SipcoColumn field="5" header={columnsNames[5].label} sortable filter />
          <SipcoColumn field="6" header={columnsNames[6].label} sortable filter />
          <SipcoColumn field="7" header={columnsNames[7].label} sortable filter /> */}
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
          <SipcoColumn field="23" header={columnsNames[23].label} />
          <SipcoColumn field="24" header={columnsNames[24].label} />
          <SipcoColumn field="25" header={columnsNames[25].label} />
          <SipcoColumn field="26" header={columnsNames[26].label} />
          <SipcoColumn field="27" header={columnsNames[27].label} />
          <SipcoColumn field="28" header={columnsNames[28].label} />
          <SipcoColumn field="29" header={columnsNames[29].label} />
          <SipcoColumn field="30" header={columnsNames[30].label} />
          <SipcoColumn field="31" header={columnsNames[31].label} />
          <SipcoColumn field="32" header={columnsNames[32].label} />
          <SipcoColumn field="33" header={columnsNames[33].label} />
          <SipcoColumn field="34" header={columnsNames[34].label} />
          <SipcoColumn field="35" header={columnsNames[35].label} />
          <SipcoColumn field="36" header={columnsNames[36].label} />
          <SipcoColumn field="37" header={columnsNames[37].label} />
          <SipcoColumn field="38" header={columnsNames[38].label} />
          <SipcoColumn field="39" header={columnsNames[39].label} />
          <SipcoColumn field="40" header={columnsNames[40].label} />
          <SipcoColumn field="41" header={columnsNames[41].label} />
          <SipcoColumn field="42" header={columnsNames[42].label} />
          <SipcoColumn field="43" header={columnsNames[43].label} />
          <SipcoColumn field="44" header={columnsNames[44].label} />
          <SipcoColumn field="45" header={columnsNames[45].label} />
          <SipcoColumn field="46" header={columnsNames[46].label} />
          <SipcoColumn field="47" header={columnsNames[47].label} />
          <SipcoColumn field="48" header={columnsNames[48].label} />
          <SipcoColumn field="49" header={columnsNames[49].label} />
          <SipcoColumn field="50" header={columnsNames[50].label} />
          <SipcoColumn field="51" header={columnsNames[51].label} />
          <SipcoColumn field="52" header={columnsNames[52].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default HistoricoDanos;
