import "./IngresoTallerManual.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function IngresoTallerManual() {
  const SCREEN_CODE = "fun0076_ingreso_taller_manual";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [defaultButtons, setDefaultButtons] = useState(true);
  const [hideDefaultButtons, setHideDefaultButtons] = useState(false);

  const [validationDatosDialog, setValidationDatosDialog] = useState(false);
  const [completarDatosDialog, setCompletarDatosDialog] = useState(false);

  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [procesarCambiosDialog, setProcesarCambiosDialog] = useState(false);

  const [loadingProcesarCambios, setLoadingProcesarCambios] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);

  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);
  const [vehicTallerErrorDialog, setVehicTallerErrorDialog] = useState(false);

  const [isAltaClicked, setIsAltaClicked] = useState(false);

  const [panelsVisible, setPanelsVisible] = useState({
    cargaManual: false,
    tallerDestino: false,
  });

  const rowStyles = useRowStyles([selectedReportData]);
  const { formatDateTime } = useFormattedDate();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  function validationFields() {
    try {
      if (isAltaClicked) {
        if (
          !cargaManualModel?.vin ||
          !cargaManualModel?.marca ||
          !cargaManualModel?.modelo
        ) {
          setValidationDatosDialog(true);
          return true;
        }
      }
      if (
        !tallerDestModel?.taller ||
        !tallerDestModel?.calle ||
        !tallerDestModel?.columna ||
        !tallerDestModel?.nivel
      ) {
        setCompletarDatosDialog(true);
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  function handleClearFields() {
    setFilter({ vin: "" });
  }

  const btnAltaClick = () => {
    setPanelsVisible({
      cargaManual: true,
      tallerDestino: true,
    });
    setIsAltaClicked(true);
    setDefaultButtons(false);
    setHideDefaultButtons(true);
  };

  const btnSelecctTodClick = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const btnDeSelecctTodClick = () => {
    if (selectedReportData) {
      setSelectedReportData([]);
    }
  };

  const btnProcesarClick = () => {
    try {
      if (validationFields()) {
        return;
      }
      if (isAltaClicked) {
        setProcesarDialog(true);
      } else {
        setProcesarCambiosDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      aProcesar();
    }
  };

  const handleProcesarCambios = (isConfirmed) => {
    setProcesarCambiosDialog(false);
    if (isConfirmed) {
      aProcesarCambios();
    }
  };

  const handleConfirmationClose = () => {
    handleClearFields();

    setPanelsVisible({
      cargaManual: false,
      tallerDestino: false,
    });

    setDefaultButtons(true);
    setHideDefaultButtons(false);

    setConfirmProcessDialog(false);
    setVehicTallerErrorDialog(false);
  };

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        vin: {
          id: cargaManualModel.vin,
          marca: {
            id: cargaManualModel.marca.id,
          },
          modelo: {
            id: cargaManualModel.modelo.id,
          },
          color: {
            id: cargaManualModel.color,
          },
        },
        tallerDto: {
          taller: {
            id: tallerDestModel.taller.id,
          },
          calle: tallerDestModel.calle,
          columna: tallerDestModel.columna,
          nivel: tallerDestModel.nivel,
          fechaPromesa: tallerDestModel.fechaPromesaEntrega.getTime(),
          saneamiento6Meses: tallerDestModel.saneamientoSeisM,
          saneamiento12Meses: tallerDestModel.saneamientoDozeM,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/ingreso-taller-manual/ingreso-manual-vin-nuevo",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
        loadReportData();
      } else if (status === 401) {
        setVehicTallerErrorDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  async function aProcesarCambios() {
    setLoadingProcesarCambios(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        vinList: selectedReportData.map((data) => ({
          id: data.id,
          pais: { id: data.pais.id },
          cliente: { id: data.cliente.id },
          cuenta: { id: data.cuenta.id },
        })),
        tallerDto: {
          taller: {
            id: tallerDestModel.taller.id,
          },
          calle: tallerDestModel.calle,
          columna: tallerDestModel.columna,
          nivel: tallerDestModel.nivel,
          fechaPromesa: tallerDestModel.fechaPromesaEntrega.getTime(),
          saneamiento6Meses: tallerDestModel.saneamientoSeisM,
          saneamiento12Meses: tallerDestModel.saneamientoDozeM,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/ingreso-taller-manual/ingreso-manual",
        filters
      );
      if (status === 200) {
        setConfirmProcessDialog(true);
        loadReportData();
      } else if (status === 401) {
        setVehicTallerErrorDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesarCambios(false);
    }
  }

  const btnDeshecharClick = () => {
    setPanelsVisible({
      cargaManual: false,
      tallerDestino: false,
    });
    setIsAltaClicked(false);
    setDefaultButtons(true);
    setHideDefaultButtons(false);
    setSelectedReportData([]);
  };

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
    setCompletarDatosDialog(false);
  };

  const [filter, setFilter] = useState({
    vin: null,
  });

  const [cargaManualModel, setCargaManualModel] = useState({
    vin: null,
    marca: null,
    modelo: null,
    color: null,
    patente: null,
    observaciones: null,
  });

  const [tallerDestModel, setTallerDestModel] = useState({
    taller: null,
    calle: null,
    columna: null,
    nivel: null,
    fechaPromesaEntrega: new Date(),
    saneamientoSeisM: false,
    saneamientoDozeM: false,
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/vin-taller",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            codSubcuenta: securityFilters.securityValues.subaccount.id || null,
            vin: vin || null,
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("654", "No existe el VIN");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/marca",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
          }
        );

        if (status === 200) {
          const marcaData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setMarca(marcaData);
          return marcaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modelo, setModelo] = useState([]);
  async function loadModelo(marca) {
    setLoadingModelo(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/modelo",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            marca: marca,
          }
        );

        if (status === 200) {
          const modeloData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setModelo(modeloData);
          return modeloData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  const [loadingTaller, setLoadingTaller] = useState(false);
  const [taller, setTaller] = useState(null);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/taller",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            codSubcuenta: securityFilters.securityValues.subaccount.id || null,
          }
        );

        if (status === 200) {
          const tallerData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setTaller(tallerData);
          return tallerData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    setTaller([]);
    return [];
  }

  const [loadingCalle, setLoadingCalle] = useState(false);
  const [calle, setCalle] = useState(null);
  async function loadCalle(taller) {
    setLoadingCalle(true);
    try {
      if (taller && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/calle",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            codSubcuenta: securityFilters.securityValues.subaccount.id || null,
            taller: taller,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    setCalle([]);
    return [];
  }

  const [loadingColumna, setLoadingColumna] = useState(false);
  const [columna, setColumna] = useState(null);
  async function loadColumna(taller) {
    setLoadingColumna(true);
    try {
      if (taller && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/columna",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            codSubcuenta: securityFilters.securityValues.subaccount.id || null,
            taller: taller,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    setColumna([]);
    return [];
  }

  const [loadingNivel, setLoadingNivel] = useState(false);
  const [nivel, setNivel] = useState(null);
  async function loadNivel(taller) {
    setLoadingNivel(true);
    try {
      if (taller && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller-manual/nivel",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            codSubcuenta: securityFilters.securityValues.subaccount.id || null,
            taller: taller,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    setNivel([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const marcas = await loadMarca();
          const tallers = await loadTaller();

          setCargaManualModel((prevFilter) => ({
            ...prevFilter,
            marca: marcas?.length > 0 ? marcas[0] : null,
          }));

          setTallerDestModel((prevFilter) => ({
            ...prevFilter,
            taller: tallers?.length > 0 ? tallers[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function dependsOnMarca() {
      if (cargaManualModel?.marca) {
        try {
          const modelos = await loadModelo(cargaManualModel.marca.id);

          setCargaManualModel((prevFilter) => ({
            ...prevFilter,
            modelo: modelos?.length > 0 ? modelos[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnMarca();
  }, [cargaManualModel?.marca]);

  useEffect(() => {
    async function dependsOnTaller() {
      if (tallerDestModel?.taller) {
        try {
          const calles = await loadCalle(tallerDestModel.taller.id);
          const columnas = await loadColumna(tallerDestModel.taller.id);
          const niveis = await loadNivel(tallerDestModel.taller.id);

          setTallerDestModel((prevFilter) => ({
            ...prevFilter,
            calle: calles?.length > 0 ? calles[0] : null,
            columna: columnas?.length > 0 ? columnas[0] : null,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnTaller();
  }, [tallerDestModel?.taller]);

  async function loadReportData() {
    setLoadingReportData(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-taller-manual/vin-taller",
        {
          codPais: securityFilters.securityValues.country.id || null,
          codCliente: securityFilters.securityValues.client.id || null,
          codCuenta: securityFilters.securityValues.account.id || null,
          codSubcuenta: securityFilters.securityValues.subaccount.id || null,
          vin: filter?.vin?.id || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="ingreso-taller-manual">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter?.vin ?? ""}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"2138"}
                  alt="Ingreso Taller Manual"
                ></LanguageProvider>
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={reporte}
          loading={loadingReportData}
          selectionMode="multiple"
          selection={selectedReportData}
          onSelectionChange={(e) => {
            setSelectedReportData(e.value);
            if (isAltaClicked) {
              setPanelsVisible({
                cargaManual: true,
                tallerDestino: true,
              });
            } else if (!isAltaClicked && selectedReportData) {
              setPanelsVisible({
                cargaManual: false,
                tallerDestino: true,
              });
            }
            setDefaultButtons(false);
            setHideDefaultButtons(true);
          }}
        >
          <SipcoColumn
            field="id"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
          />
          <SipcoColumn
            field="color.descripcion"
            header={<LanguageProvider id="2140" alt="Color" />}
          />
          <SipcoColumn
            field="usuarioUltimaModificacion.id"
            header={<LanguageProvider id="16957" alt="Última Modificación" />}
            body={(data) =>
              data.usuarioUltimaModificacion?.id
                ? `(${data.usuarioUltimaModificacion.id})`
                : ""
            }
          />
          <SipcoColumn
            field="fechaUltimaModificacion.date"
            header={<LanguageProvider id="13490" alt="Actualizó con Fecha" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaUltimaModificacion?.date
                ? formatDateTime(rowData.fechaUltimaModificacion.date)
                : rowData.fechaUltimaModificacion || ""
            }
          />
        </SipcoDataTable>
      </Panel>

      <div className="panels-flex">
        {panelsVisible.cargaManual && (
          <div className="carga-manual">
            <Panel
              headerTemplate={(props) => {
                return (
                  <div
                    className={props.className}
                    onClick={props.onTogglerClick}
                  >
                    <span className={props.titleClassName}>
                      <LanguageProvider id="1464" alt="Carga Manual" />
                    </span>
                  </div>
                );
              }}
            >
              <div className="form">
                <div className={"form-row"}>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={1491} alt="VIN" />
                    </label>
                    <SipcoInputText
                      value={cargaManualModel.vin}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          vin: e.target.value || "",
                        })
                      }
                      maxLength={17}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={3625} alt="Observaciones" />
                    </label>
                    <SipcoInputText
                      value={cargaManualModel.observaciones}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          observaciones: e.target.value || "",
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={23888} alt="Marca" />
                    </label>
                    <SipcoDropdown
                      value={cargaManualModel.marca}
                      options={marca}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          marca: e.value,
                        })
                      }
                      optionLabel="label"
                      loading={loadingMarca}
                      filter
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={2139} alt="Modelo" />
                    </label>
                    <SipcoDropdown
                      value={cargaManualModel.modelo}
                      options={modelo}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          modelo: e.value,
                        })
                      }
                      optionLabel="label"
                      loading={loadingModelo}
                      filter
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={2140} alt="Color" />
                    </label>
                    <SipcoInputText
                      value={cargaManualModel.color}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          color: e.target.value || "",
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={4724} alt="Patente" />
                    </label>
                    <SipcoInputText
                      value={cargaManualModel.patente}
                      onChange={(e) =>
                        setCargaManualModel({
                          ...cargaManualModel,
                          patente: e.target.value || "",
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        )}

        {panelsVisible.tallerDestino && (
          <div className="taller-destino">
            <Panel
              headerTemplate={(props) => {
                return (
                  <div
                    className={props.className}
                    onClick={props.onTogglerClick}
                  >
                    <span className={props.titleClassName}>
                      <LanguageProvider id="914" alt="Taller Destino" />
                    </span>
                  </div>
                );
              }}
            >
              <div>
                <div className="form">
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"664"} alt="Talleres" />
                      </label>
                      <SipcoDropdown
                        value={tallerDestModel.taller}
                        options={taller}
                        onChange={(e) =>
                          setTallerDestModel({
                            ...tallerDestModel,
                            taller: e.value,
                          })
                        }
                        optionLabel="label"
                        loading={loadingTaller}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"990"} alt="Calle" />
                      </label>
                      <SipcoDropdown
                        value={tallerDestModel.calle}
                        options={calle}
                        onChange={(e) =>
                          setTallerDestModel({
                            ...tallerDestModel,
                            calle: e.value,
                          })
                        }
                        optionLabel="label"
                        loading={loadingCalle}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"991"} alt="Columna" />
                      </label>
                      <SipcoDropdown
                        value={tallerDestModel.columna}
                        options={columna}
                        onChange={(e) =>
                          setTallerDestModel({
                            ...tallerDestModel,
                            columna: e.value,
                          })
                        }
                        optionLabel="label"
                        loading={loadingColumna}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"992"} alt="Nivel" />
                      </label>
                      <SipcoDropdown
                        value={tallerDestModel.nivel}
                        options={nivel}
                        onChange={(e) =>
                          setTallerDestModel({
                            ...tallerDestModel,
                            nivel: e.value,
                          })
                        }
                        optionLabel="label"
                        loading={loadingNivel}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"16961"}
                          alt="Fec. Promesa Entrega"
                        />
                      </label>
                      <SipcoCalendar
                        value={tallerDestModel.fechaPromesaEntrega}
                        onChange={(e) => {
                          const date = new Date(e.value);
                          setTallerDestModel({
                            ...tallerDestModel,
                            fechaPromesaEntrega: date,
                          });
                        }}
                        showButtonBar
                        showIcon
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                  </div>
                  <div className="form-row-check">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"5627"}
                          alt="Saneamiento 6 Meses"
                        />
                      </label>
                      <Checkbox
                        checked={tallerDestModel.saneamientoSeisM}
                        onChange={(e) => {
                          setTallerDestModel({
                            ...tallerDestModel,
                            saneamientoSeisM: !tallerDestModel.saneamientoSeisM,
                          });
                        }}
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"5628"}
                          alt="Saneamiento 12 Meses"
                        />
                      </label>
                      <Checkbox
                        checked={tallerDestModel.saneamientoDozeM}
                        onChange={(e) => {
                          setTallerDestModel({
                            ...tallerDestModel,
                            saneamientoDozeM: !tallerDestModel.saneamientoDozeM,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        )}
      </div>

      <div className="buttons">
        {defaultButtons && !hideDefaultButtons && (
          <div className="flex flex-wrap gap-2 mt-5">
            <Button
              label={LanguageProvider({ id: "23824", alt: "Alta" })}
              icon="pi pi-plus-circle"
              onClick={btnAltaClick}
            />
            <Button
              label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
              icon="pi pi-plus"
              onClick={btnSelecctTodClick}
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              icon="pi pi-minus"
              onClick={btnDeSelecctTodClick}
            />
          </div>
        )}

        {hideDefaultButtons && !defaultButtons && (
          <div className="flex flex-wrap gap-2 mt-5">
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              icon="pi pi-save"
              onClick={btnProcesarClick}
            />

            {/* Existen datos en blanco Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={validationDatosDialog}
              style={{ width: "5vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("5599", "OK")}
                    icon="pi pi-check"
                    onClick={handleValidationClose}
                    autoFocus
                  />
                </div>
              }
              onHide={handleValidationClose}
            >
              <p>
                <LanguageProvider id="39" alt="Existen datos en blanco." />
              </p>
            </Dialog>

            {/* Debe seleccionar un Taller Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={completarDatosDialog}
              style={{ width: "5vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("5599", "OK")}
                    icon="pi pi-check"
                    onClick={handleValidationClose}
                    autoFocus
                  />
                </div>
              }
              onHide={handleValidationClose}
            >
              <p>
                <LanguageProvider id="1002" alt="Debe seleccionar un Taller" />
              </p>
            </Dialog>

            {/* Procesar Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={procesarDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("10043", "Si")}
                    icon="pi pi-check"
                    onClick={() => handleProcesar(true)}
                    autoFocus
                    disabled={loadingProcesar}
                  />
                  <Button
                    label={getLabel("10044", "No")}
                    icon="pi pi-times"
                    onClick={() => handleProcesar(false)}
                    className="p-button-text"
                    disabled={loadingProcesar}
                  />
                </div>
              }
              onHide={() => setProcesarDialog(false)}
            >
              <p style={{ padding: "1rem 0", margin: "0" }}>
                <LanguageProvider
                  id="13275"
                  alt="¿Desea procesar los cambios realizados?"
                />
              </p>
              {loadingProcesar && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog>

            {/* Procesar Cambios Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={procesarCambiosDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("10043", "Si")}
                    icon="pi pi-check"
                    onClick={() => handleProcesarCambios(true)}
                    autoFocus
                    disabled={loadingProcesarCambios}
                  />
                  <Button
                    label={getLabel("10044", "No")}
                    icon="pi pi-times"
                    onClick={() => handleProcesarCambios(false)}
                    className="p-button-text"
                    disabled={loadingProcesarCambios}
                  />
                </div>
              }
              onHide={() => setProcesarCambiosDialog(false)}
            >
              <p style={{ padding: "1rem 0", margin: "0" }}>
                <LanguageProvider
                  id="13275"
                  alt="¿Desea procesar los cambios realizados?"
                />
              </p>
              {loadingProcesarCambios && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog>

            {/* Confirm Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={confirmProcessDialog}
              style={{ width: "5vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    icon="pi pi-check"
                    onClick={handleConfirmationClose}
                    autoFocus
                  />
                </div>
              }
              onHide={handleConfirmationClose}
            >
              <p style={{ padding: "1rem 0", margin: "0" }}>
                <LanguageProvider
                  id="36"
                  alt="Su transacción se realizó con éxito."
                />
              </p>
            </Dialog>

            {/* Error vehiculo en Taller Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={vehicTallerErrorDialog}
              style={{ width: "7vw", textAlign: "center" }}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    label={getLabel("5599", "OK")}
                    icon="pi pi-check"
                    onClick={handleConfirmationClose}
                    autoFocus
                  />
                </div>
              }
              onHide={handleConfirmationClose}
            >
              <p style={{ padding: "1rem 0", margin: "0" }}>
                <LanguageProvider
                  id="17344"
                  alt="La operación no se pudo completar"
                />
              </p>
              <p>
                <LanguageProvider
                  id="2130"
                  alt="El vehículo ya está en ese taller"
                ></LanguageProvider>
              </p>
            </Dialog>

            <Button
              label={LanguageProvider({ id: "78", alt: "Deshechar Cambios" })}
              icon="pi pi-times-circle"
              onClick={btnDeshecharClick}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default IngresoTallerManual;
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
