import "./OrdenReparacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import { useNavigate } from "react-router-dom";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function OrdenReparacion() {
  const SCREEN_CODE = "fun0079_orden_reparacion";
  ScreenCodeValue(SCREEN_CODE);
  let navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const toast = useRef<Toast>(null);

  const columnsNames = [];
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.codRemito;

        const matchingObject = arrayBase.find(
          (item: any) => item?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: null,
    estados: null,
    nroOrden: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
              descripcionCliente: "",
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/orden-reparacion/estados",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setEstados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  async function filterGrid(value: any[]) {
    if (filter?.estados?.descripcion) {
      const filteredData = value.filter(
        (item) => item.estado === filter.estados.descripcion
      );
      setReporte(filteredData);
      return filteredData;
    }

    setReporte(value);
    return value;
  }

  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion/findBy",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          taller: taller ? taller[0] : null,
          estados: filter.estados ? filter.estados : null,
          vin: vin ? vin[0] : null,
          enTaller: true,
          fueraTaller: true,
          fechaDesde: filter.desde ? filter.desde.getTime() : null,
          fechaHasta: filter.hasta ? filter.hasta.getTime() : null,
        }
      );
      if (status === HttpStatusCode.Ok) {
        const response = data.map((x: any) => ({
          ...x,
          numeroIngreso: x?.numeroIngreso,
          nroOrden: x?.id,
          fechaAlta: x?.fechaUltimaModificacion?.date,
          vin: x?.vin?.id,
          marca: x?.vin?.marca?.descripcion,
          modelo: x?.vin?.modelo?.descripcion,
          inspector: x?.inspector.descripcion,
          taller:
            x?.taller?.descripcion || taller ? taller[0].descripcion : null,
          subcuenta:
            x?.subcuenta?.descripcion ||
            securityFilters.securityValues.subaccount.descripcion,
          fechaCierreReal: x?.fechaCierreReal?.date,
          fechaCierreEstimada: x?.fechaCierreEstimada?.date,
          fechaFacturacion: x?.fechaFacturacion?.date,
          fechaAnulacion: x?.fechaAnulacion?.date || "",
          estado: x?.estado?.descripcion,
          cliente: x?.cliente?.id,
          incidentes: x?.observaciones,
        }));
        // aqui
        return filterGrid(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const taller = await loadTaller();
        const estados = await loadEstados();

        setFilter({ ...filter, taller: taller[0], estados: estados[0] });
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [securityFilters.securityValues]);

  const data = convertDateObjects(reporte?.filas);

  const values = {
    state: {
      pais: securityFilters?.securityValues?.country,
      cuenta: securityFilters?.securityValues?.account,
      subcuenta: securityFilters?.securityValues?.subaccount,
      nroOrden: filter?.nroOrden,
      tallerFilter: filter?.taller,
    },
  };

  console.log("values");

  const columnsNamesTable = [
    { field: "numeroIngreso", header: "Nro Ingreso" },
    { field: "nroOrden", header: "Nro Orden" },
    { field: "fechaAlta", header: "Fecha Alta" },
    { field: "vin", header: "Vin" },
    { field: "marca", header: "Marca" },
    { field: "modelo", header: "Modelo" },
    { field: "inspector", header: "Inspector" },
    { field: "subcuenta", header: "Subcuenta" },
    { field: "taller", header: "Taller" },
    { field: "fechaCierreReal", header: "Fecha Cierre Real" },
    { field: "fechaCierreEstimada", header: "Fecha Cierre Estimada" },
    { field: "fechaFacturacion", header: "Fecha Facturacion" },
    { field: "fechaAnulacion", header: "Fecha Anulacion" },
    { field: "estado", header: "Estado" },
    { field: "incidentes", header: "Incidentes" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoDropdown
                  loading={loadingTaller}
                  options={taller}
                  value={filter.taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                />
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoDropdown
                  loading={loadingEstados}
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                  optionLabel="descripcion"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <SipcoInputText
                  value={filter.nroOrden}
                  onChange={(e) =>
                    setFilter({ ...filter, nroOrden: e.target.value })
                  }
                />
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkVinTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkVinTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4009"} alt="VIN en taller" />
                </label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkFueraTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkFueraTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4010"} alt="VIN fuera de taller" />
                </label>
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2621"} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.desde}
                  onChange={(e) => setFilter({ ...filter, desde: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.hasta}
                  onChange={(e) => setFilter({ ...filter, hasta: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "20973", alt: "Anular" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "42", alt: "Excel" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "16582", alt: "Cerrar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "17491", alt: "Reabrir" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "19673", alt: "Aprobar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "2248", alt: "Repuestos" })}
                  text
                  onClick={() =>
                    navigate("/orden-reparacion-repuestos", values)
                  }
                />
                <Button
                  label={LanguageProvider({ id: "2249", alt: "Servicios" })}
                  text
                  onClick={() =>
                    navigate("/orden-reparacion-servicios", values)
                  }
                />
                <Button
                  label={LanguageProvider({
                    id: "2689",
                    alt: "Imp.Orden Taller",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2688",
                    alt: "Imp.Cierre de Orden",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24504",
                    alt: "Imp.Orden Cotizacion",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Alta" ||
              colum.header === "Fecha Cierre Real" ||
              colum.header === "Fecha Cierre Estimada" ||
              colum.header === "Fecha Facturacion" ||
              colum.header === "Fecha Anulacion"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacion;
