import "./ListadoApoyoEstadia.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ListadoApoyoEstadia() {
  const SCREEN_CODE = "fun0072_listado_apoyo_estadia";
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1491, 18309, 1569, 15739, 19265];
  const filterNames = [];
  const filterColumnsId = [54, 19179, 18637, 17088, 15739, 19265, 11986, 5271];
  const columnsNames = [];
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [listadoApoyoEstadia, setListadoApoyoEstadia] = useState(null);

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 2140, 2892, 3750, 4589, 4791, 5044,
    5271, 5583, 19179, 18637, 17088, 15739, 19265, 11986, 5271, 10034, 13706,
    14288, 16501, 13514, 18305, 367, 12251, 3410, 18202, 11507, 17131, 1492,
    3344, 10871, 2941, 10868, 10904, 18306, 18307, 748, 749, 321, 18308, 18309,
    10531, 5366, 19178, 16245, 10774, 16416, 5788, 19179, 8380, 2502, 2521,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  const [filter, setFilter] = useState({
    // filtros
    playas: null,
    marcas: null,
    fechaEstadiaPlayaInicio: null,
    fechaEstadiaPlayaFim: null,
    agrupadoPorVin: false,
  });

  const [playa, setPlaya] = useState([]);
  const [loadingPlaya, setLoadingPlaya] = useState(false);

  async function getPlaya() {
    try {
      setLoadingPlaya(true);
      const { status, data } = await sipcoAxiosService.post(
        "/listado-apoyo-estadia/get-estadias",
        securityFilters.securityValues.account
      );
      if (status === 200) {
        setPlaya(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);

  async function getMarca() {
    try {
      setLoadingMarca(true);
      const { status, data } = await sipcoAxiosService.post(
        "/listado-apoyo-estadia/get-marcas",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
        }
      );
      if (status === 200) {
        setMarca(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const playas = await getPlaya();
      const marcas = await getMarca();

      setFilter({
        ...filter,
        playas: playas,
        marcas: marcas,
      });
    };
    init();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/listado-apoyo-estadia/estadia-movimiento",
        {
          pais: securityFilters.securityValues?.country,
          cliente: securityFilters.securityValues?.client,
          cuenta: securityFilters.securityValues?.account,
          subcuentas: filter?.playas || null,
          marcas: filter?.marcas || null,
          fechaDesde: { timestamp: filter?.fechaEstadiaPlayaInicio ?? null },
          fechaHasta: { timestamp: filter?.fechaEstadiaPlayaFim ?? null },
          checkAgrupado: filter?.agrupadoPorVin || false,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setListadoApoyoEstadia(data);
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="consulta-operaciones-canceladas-web">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"441"} alt="Playas" />
              </label>
              <SipcoMultiSelect
                value={filter?.playas}
                options={playa}
                onChange={(e) =>
                  setFilter({ ...filter, playas: e.value || [] })
                }
                optionLabel="label"
                filter
                showClear
                display="chip"
                loading={loadingPlaya}
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"23888"} alt="Marca" />
              </label>
              <SipcoMultiSelect
                value={filter?.marcas}
                options={marca}
                onChange={(e) =>
                  setFilter({ ...filter, marcas: e.value || [] })
                }
                optionLabel="label"
                filter
                showClear
                display="chip"
                loading={loadingMarca}
              />
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <label>
                <LanguageProvider id={1504} alt="Fecha de estadía en Playa" />
              </label>

              <div className="sipco-option">
                <SipcoCalendar
                  value={filter.fechaEstadiaPlayaInicio}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaEstadiaPlayaInicio: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="sipco-option">
                <SipcoCalendar
                  value={filter.fechaEstadiaPlayaFim}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaEstadiaPlayaFim: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <label>
                <LanguageProvider id={4203} alt="Agrupado por VIN" />
              </label>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.agrupadoPorVin}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        agrupadoPorVin: true,
                      });
                    } else {
                      setFilter({
                        ...filter,
                        agrupadoPorVin: false,
                      });
                    }
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Operaciones Canceladas Web"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        {filter.agrupadoPorVin ? (
          <>
            <SipcoDataTable
              size={"small"}
              showGridlines
              stripedRows
              ref={dt}
              value={data}
              loading={loading}
              paginator
              scrollHeight="flex"
              rows={25}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              style={{ maxWidth: "100%" }}
              resizableColumns
              removableSort
              filterDisplay="menu"
            >
              <SipcoColumn field="0" header={"Subcuenta"} sortable filter />
              <SipcoColumn field="1" header={"Vin"} sortable filter />
              <SipcoColumn field="2" header={"Modelo"} sortable filter />
              <SipcoColumn field="3" header={"Patente"} sortable filter />
              <SipcoColumn field="4" header={"Estado"} sortable filter />
              <SipcoColumn
                field="5"
                header={"Ingreso a Playas"}
                sortable
                filter
              />
              <SipcoColumn field="6" header={"Color"} sortable filter />
              <SipcoColumn
                field="7"
                header={"Salida de Playas"}
                sortable
                filter
              />
              <SipcoColumn field="8" header={"Marca"} sortable filter />
              <SipcoColumn
                field="9"
                header={"Remito de Despacho"}
                sortable
                filter
              />
              <SipcoColumn field="10" header={"Mercado"} sortable filter />
              <SipcoColumn field="11" header={"Mercado"} sortable filter />
              <SipcoColumn field="12" header={"Categoría"} sortable filter />
              <SipcoColumn
                field="13"
                header={"Días a Cobrar"}
                sortable
                filter
              />
            </SipcoDataTable>
          </>
        ) : (
          <>
            <SipcoDataTable
              size={"small"}
              showGridlines
              stripedRows
              ref={dt}
              value={data}
              loading={loading}
              paginator
              scrollHeight="flex"
              rows={25}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              style={{ maxWidth: "100%" }}
              resizableColumns
              removableSort
              filterDisplay="menu"
            >
              <SipcoColumn field="0" header={"Subcuenta"} sortable filter />
              <SipcoColumn field="1" header={"Ubicación"} sortable filter />
              <SipcoColumn field="2" header={"Vin"} sortable filter />
              <SipcoColumn field="3" header={"Modelo"} sortable filter />
              <SipcoColumn field="4" header={"Patente"} sortable filter />
              <SipcoColumn field="5" header={"Estado"} sortable filter />
              <SipcoColumn field="6" header={"Techada"} sortable filter />
              <SipcoColumn
                field="7"
                header={"Ingreso a Playas"}
                sortable
                filter
              />
              <SipcoColumn
                field="8"
                header={"Salida de Playas"}
                sortable
                filter
              />
              <SipcoColumn
                field="9"
                header={"Ingreso ubicación"}
                sortable
                filter
              />
              <SipcoColumn
                field="10"
                header={"Salida ubicación"}
                sortable
                filter
              />
              <SipcoColumn field="11" header={"Marca"} sortable filter />
              <SipcoColumn
                field="12"
                header={"Remito de Despacho"}
                sortable
                filter
              />
              <SipcoColumn field="14" header={"Mercado"} sortable filter />
              <SipcoColumn
                field="15"
                header={"Cliente Terceros"}
                sortable
                filter
              />
              <SipcoColumn
                field="16"
                header={"Fecha Emisión"}
                sortable
                filter
              />
              <SipcoColumn field="17" header={"Categoría"} sortable filter />
              <SipcoColumn
                field="18"
                header={"Días a Cobrar"}
                sortable
                filter
              />
            </SipcoDataTable>
          </>
        )}
      </Panel>
    </div>
  );
}
export default ListadoApoyoEstadia;
