import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DespachoMercadoExportacionBrForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [
    6781, 13358, 3774, 9387, 4036, 1491, 396, 10746, 2140, 2496, 13335, 11679,
    2941, 492, 3591, 3159, 159, 3186, 3344, 1256, 2940, 16453,
  ];

  const columnsNames1 = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  const loadReportData = () => {
    console.log("crick");
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="filter-options">
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"266"} alt="Concessionários" />
                  </label>
                  <SipcoCalendar showButtonBar showIcon showTime />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1268"} alt="Destino:" />
                  </label>
                </div>
              </div>
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider
                      id={"13035"}
                      alt="Direção final da entrega"
                    />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16962"} alt="Tipo de Transporte" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="divide">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider
                        id={"10880"}
                        alt="Despacho por Coleta"
                      />
                    </label>
                    <Checkbox checked={true} />
                  </div>
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"10879"} alt="Despacho  por CTRC" />
                    </label>
                    <Checkbox checked={false} />
                  </div>
                </div>
              </div>
            </div>

            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"662"} alt="Acesse o VIN" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"310"} alt="Despacho Internacional" />}
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider
                    id={"1500"}
                    alt="Trabalhar com Leitora Cod.Barras"
                  />
                </label>
                <Checkbox checked={false} />
              </div>
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div className="header-data-table">
                <div className="chips">
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Selecionados"
                      />
                    </label>
                    <Chip label="0" />
                  </div>
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider id={"TODO"} alt="Total de Registros" />
                    </label>
                    <Chip label="0" />
                  </div>
                </div>

                <div
                  className="action"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsIds1}
                    screenName={"RecepcionPlaya"}
                  />
                </div>
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames1.map((colum, index) => (
              <SipcoColumn
                key={index}
                field={colum.id}
                header={colum.label}
                sortable
                filter
              />
            ))}
          </SipcoDataTable>
        </div>
        <Divider />

        <div className="fieldset-footer">
          <div className="filter-options-column">
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"4514"} alt="Data de saída teórica" />
                </label>
                <SipcoCalendar showTime showIcon showButtonBar />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={"1500"}
                    alt="Trabalhar com Leitora Cod.Barras"
                  />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"10683"} alt="Cidade Destino" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"16963"} alt="Prov. Danhos" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
          </div>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option-extra-text">
                <label>
                  <LanguageProvider id={"3625"} alt="Observações" />
                </label>
                <SipcoInputText />
              </div>
            </div>
          </div>
          <div className="filter-options-column">
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1118"} alt="Despachantes" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"10893"} alt="Transportadora" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"4655"} alt="Motorista" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"4285"} alt="Trator" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"2561"} alt="Quant Unid Totais" />
                </label>
                <SipcoInputText />
              </div>
            </div>
          </div>
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "187",
                alt: "Imprimir ou gerar arquivo Excel",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Selecionar tudo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deselecionar tudo",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default DespachoMercadoExportacionBrForm;
