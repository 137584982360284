
import { saveAs } from 'file-saver';
import cevaLogoBase64 from "../../../assets/excel-templates/cevaLogoBase64";
import { LanguageProviderString } from '../language-provider-string';
import { ExportPropsTypesListadoArmadoViajes } from './interfaces/ExportPropsTypesListadoArmadoViajes';
const ExcelJS = require('exceljs');



// Estilos pré-definidos
const cellFormatCliente = {
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00ff00' } },
    font: { bold: true, color: { argb: 'FF000000' } },
    border: {
        top: { style: 'thick', color: { argb: 'black' } },
        left: { style: 'thick', color: { argb: 'black' } },
        bottom: { style: 'thick', color: { argb: 'black' } },
        right: { style: 'thick', color: { argb: 'black' } }
    }
};

const cellFormatNombre = {
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '33cccc' } },
    font: { bold: true, color: { argb: 'FF000000' } },
    border: {
        top: { style: 'thick', color: { argb: 'black' } },
        left: { style: 'thick', color: { argb: 'black' } },
        bottom: { style: 'thick', color: { argb: 'black' } },
        right: { style: 'thick', color: { argb: 'black' } }
    }
};

const cellFormatCiudad = {
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffcc00' } },
    font: { bold: true, color: { argb: 'FF000000' } },
    border: {
        top: { style: 'thick', color: { argb: 'black' } },
        left: { style: 'thick', color: { argb: 'black' } },
        bottom: { style: 'thick', color: { argb: 'black' } },
        right: { style: 'thick', color: { argb: 'black' } }
    }
};

const headerColumns = {
    fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "003366" },
    },
    font: { bold: true, color: { argb: "FFFFFF" } },
    alignment: { vertical: "middle", horizontal: "center" },
}

// 54, 0, 0, 10502, 0, 164, 3186, 10509, 10508, 10507, 10506, 492, 1491, 932,
// 441, 2139, 4179, 1284, 924, 4145, 1492, 2496,
const columnIds = [1919, 10508, 10507, 10506, 492, 1491, 932, 441, 2139, 4179, 1284, 924, 4145, 1492, 2496]


const generateExcelListadoArmadoViajes = async ({ user, dataResponse }: ExportPropsTypesListadoArmadoViajes): Promise<boolean> => {
    const screenName = LanguageProviderString({ user, id: "10494", alt: "Listado de Armado de Viajes" })
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(screenName);

    try {




        // Adicionar imagem
        const imageId = workbook.addImage({
            base64: cevaLogoBase64,
            extension: "png",
        });
        worksheet.addImage(imageId, {
            tl: { col: 0, row: 0 },
            ext: { width: 150, height: 50 },
        });
        worksheet.mergeCells("A1:O2");
        worksheet.getCell("A1").fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '003366' }
        };

        // Informações básicas na parte superior
        // Cabeçalho

        worksheet.mergeCells("A3:M3");
        worksheet.getCell("A3").value = LanguageProviderString({
            user,
            id: "189",
            alt: 'GEFCO ARGENTINA S.A.'
        });
        worksheet.getCell("A3").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.getCell("A3").font = { bold: true };
        //titulo
        worksheet.mergeCells("A4:M4");
        worksheet.getCell("A4").value = screenName;
        worksheet.getCell("A4").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.getCell("A4").font = { bold: true };
        //infos user
        worksheet.mergeCells("A5:M5");
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString(user?.idioma?.codigoIso);
        const formattedTime = currentDate.toLocaleTimeString(user?.idioma?.codigoIso);
        worksheet.getCell("A5").value = `${LanguageProviderString({
            user,
            id: "9369",
            alt: "Usuário:",
        })} ${user?.nombre || "User Name"} - ${LanguageProviderString({
            user,
            id: "191",
            alt: "Data:",
        })} ${formattedDate} - ${LanguageProviderString({
            user,
            id: "2201",
            alt: "Hora",
        })}: ${formattedTime}`;
        worksheet.getCell("A5").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.getCell("A5").font = { bold: true };
        //deixar fontes em negrito

        // Linha vazia de A a O com cor 003366
        worksheet.mergeCells("A6:O6");
        worksheet.getCell("A6").fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '003366' }
        };

        //cliente
        worksheet.mergeCells("A7:M7");
        worksheet.getCell("A7").value = `${LanguageProviderString({
            user,
            id: "488",
            alt: "Clientes",
        })}: ${dataResponse.consult.client.value}`;
        worksheet.getCell("A7").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        //cuenta
        worksheet.mergeCells("A8:M8");
        worksheet.getCell("A8").value = `${LanguageProviderString({
            user,
            id: "55",
            alt: "Cuenta/Área",
        })}: ${dataResponse.consult.account.value}`;
        worksheet.getCell("A8").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        const diasTotalesString = dataResponse.consult.radioButton.value ? LanguageProviderString({
            user,
            id: "10528",
            alt: "Días totales a vencer para despacho",
        }) : LanguageProviderString({
            user,
            id: "10527",
            alt: "Días totales a vencer para entregas",
        })
        worksheet.mergeCells("A9:M9");
        worksheet.getCell("A9").value = diasTotalesString;
        worksheet.getCell("A9").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        //colunas Desde - Hasta 
        worksheet.mergeCells("F11:G11");
        worksheet.getCell("F11").value = LanguageProviderString({
            user,
            id: "2621",
            alt: "Desde",
        });
        worksheet.getCell("F11").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.mergeCells("H11:I11");
        worksheet.getCell("H11").value = LanguageProviderString({
            user,
            id: "2622",
            alt: "Hasta",
        });
        worksheet.getCell("H11").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        // Llegada
        worksheet.mergeCells("A12:E12");
        worksheet.getCell("A12").value = LanguageProviderString({
            user,
            id: "1285",
            alt: "Llegada",
        });
        worksheet.getCell("A12").alignment = {
            vertical: "middle",
            horizontal: "right",
        };
        worksheet.mergeCells("F12:G12");
        worksheet.getCell("F12").value = new Date(dataResponse.consult.llegadaDesde.value).toLocaleDateString(user?.idioma?.codigoIso);

        worksheet.getCell("F12").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.mergeCells("H12:I12");
        worksheet.getCell("H12").value = new Date(dataResponse.consult.llegadaHasta.value).toLocaleDateString(user?.idioma?.codigoIso);
        worksheet.getCell("H12").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        //Despacho 
        worksheet.mergeCells("A13:E13");
        worksheet.getCell("A13").value = LanguageProviderString({
            user,
            id: "382",
            alt: "Despacho",
        });
        worksheet.getCell("A13").alignment = {
            vertical: "middle",
            horizontal: "right",
        };
        worksheet.mergeCells("F13:G13");
        worksheet.getCell("F13").value = dataResponse.consult.despacho.value ? new Date(dataResponse.consult.fechaDespachoDesde.value).toLocaleDateString(user?.idioma?.codigoIso) : "__/__/____";

        worksheet.getCell("F13").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.mergeCells("H13:I13");
        worksheet.getCell("H13").value = dataResponse.consult.despacho.value ? new Date(dataResponse.consult.fechaDespachoHasta.value).toLocaleDateString(user?.idioma?.codigoIso) : "__/__/____";
        worksheet.getCell("H13").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        //Fecha Carga Retorno:
        worksheet.mergeCells("A14:E14");
        worksheet.getCell("A14").value = LanguageProviderString({
            user,
            id: "25189",
            alt: "Carga Retorno",
        });
        worksheet.getCell("A14").alignment = {
            vertical: "middle",
            horizontal: "right",
        };
        worksheet.mergeCells("F14:G14");
        worksheet.getCell("F14").value = dataResponse.consult.cargaRetorno.value ? new Date(dataResponse.consult.fechaCargaRetornoDesde.value).toLocaleDateString(user?.idioma?.codigoIso) : "__/__/____";

        worksheet.getCell("F14").alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.mergeCells("H14:I14");
        worksheet.getCell("H14").value = dataResponse.consult.cargaRetorno.value ? new Date(dataResponse.consult.fechaCargaRetornoHasta.value).toLocaleDateString(user?.idioma?.codigoIso) : "__/__/____";
        worksheet.getCell("H14").alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        let rowIndex = 0
        let corredor = " " as any
        let cidade = "" as any
        let cliente = "" as any
        const columnsNames = columnIds.map((c) => LanguageProviderString({ user, id: c.toString() }))

        const lastRowNumber = () => {
            return worksheet.lastRow.number + 1
        }

        for (let index = 0; index < dataResponse.items.length; index++) {
            const row = dataResponse.items[index];
           
            if (cliente !== row[0]) {
                // rowIndex++
                rowIndex = lastRowNumber();
                cliente = row[0]
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = `Cliente: ${cliente}`;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatCliente
                rowIndex = lastRowNumber();

                corredor = `${row[2]} - ${row[3]}`
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = `Corredor: ${corredor}`;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatNombre
                rowIndex = lastRowNumber();

                cidade = row[5]
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = cidade;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatCiudad

                //adiciona header
                const headerStyle = worksheet.addRow(columnsNames)
                headerStyle.eachCell((cell) => { cell.style = headerColumns })
                rowIndex = lastRowNumber();
            }
            if (corredor !== `${row[2]} - ${row[3]}`) {
                rowIndex = lastRowNumber();
                corredor = `${row[2]} - ${row[3]}`
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = `Corredor: ${corredor}`;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatNombre
                rowIndex = lastRowNumber();

                cidade = row[5]
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = cidade;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatCiudad
                const headerStyle = worksheet.addRow(columnsNames)
                headerStyle.eachCell((cell) => { cell.style = headerColumns })
                rowIndex = lastRowNumber();
            }
            if (cidade !== row[5]) {
                rowIndex = lastRowNumber();
                cidade = row[5]
                worksheet.mergeCells(`A${rowIndex}:O${rowIndex}`)
                worksheet.getCell(`A${rowIndex}`).value = cidade;
                worksheet.getCell(`A${rowIndex}`).style = cellFormatCiudad
                const headerStyle = worksheet.addRow(columnsNames)
                headerStyle.eachCell((cell) => { cell.style = headerColumns })
                rowIndex = lastRowNumber();
            }
            worksheet.addRow([])
            // Adiciona os valores das colunas restantes (preenchimento da linha de dados)
            for (let i = 7; i < row.length - 1; i++) {
                const colIndex = i - 7 + 1; // Ajusta a coluna para Excel
                const cellValue = row[i];

                // Define o valor da célula
                worksheet.getRow(rowIndex).getCell(colIndex).value = cellValue;

                // Preenche a célula com cor padrão
                worksheet.getRow(rowIndex).getCell(colIndex).style = {
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' },
                    },// Cinza claro para os dados
                    alignment: { vertical: "middle", horizontal: "center" }
                };
            }
            rowIndex = lastRowNumber();
        }






        // Ajustando largura das colunas
        worksheet.columns.forEach(column => {
            column.width = 20;
        });

        // Salvando o arquivo
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Usando file-saver para salvar o arquivo
        saveAs(blob, 'Relatório_de_Viagens.xlsx');
        return true

    } catch (error) {
        console.error(error)
        return false
    }
}

export default generateExcelListadoArmadoViajes;