import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import ExportButtons from "@shared/components/Export";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const Manual: React.FC<any> = () => {
  const [columNamesManual, setColumNamesManual] = useState();
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const { values: securityFilters } = useFilterContext();
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columnsNames = [];
  const [ingresoManual, setIngresoManual] = useState([]);
  const [manual, setManual] = useState([]);
  const dt = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    numeroOt: null,
    fechaRealizacion: new Date(),
    linea: "",
    operacion: null,
    resultado: null,
    proveedor: null,
    observacion: null,
    resultadoColumns: "",
    observacionColumns: "",
    proveedorColumns: "",
  });

  const filterColumnsIdManual = [
    14369, 14368, 1491, 1569, 13324, 5788, 8380, 3625, 2521,
  ];

  const [numeroOt, setNumeroOt] = useState([]);
  const [loadingNumeroOt, setLoadingNumeroOt] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);

  function iso8601ToTimestamp(isoDate) {
    const date = new Date(isoDate);
    return date.getTime();
  }
  const resetFilter = () => {
    setFilter({
      numeroOt: null,
      fechaRealizacion: new Date(),
      linea: "",
      operacion: null,
      resultado: "",
      proveedor: null,
      observacion: null,
      observacionColumns: "",
      proveedorColumns: "",
      resultadoColumns: "",
    });
  };

  const seachNumeroOt = async (event: any) => {
    return await loadNumeroOt(event.query);
  };

  function compareArrays(selectRows: any[], manual: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && manual.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.vin;
        const operacionValue = row.operacion;
        const lineaValue = row.linea;

        const matchingObject = manual.find(
          (item: any) =>
            item?.vin.id === vinValue &&
            item?.lineaOperacion?.descripcion === lineaValue &&
            item?.operacion?.descripcion === operacionValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const loadNumeroOt = async (query: any) => {
    try {
      setLoadingNumeroOt(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-ot",
        {
          codPais: securityFilters.securityValues.country.id,
          codCliente: securityFilters.securityValues.client.id,
          codCuenta: securityFilters.securityValues.account.id,
          codOrdenTrabalho: query,
        }
      );

      if (status === HttpStatusCode.Ok) {
        const response = data.map((x: any) => {
          return {
            label: `${x?.vin?.id}`,
            linea: x?.lineaOperacion?.descripcion,
            lineaObject: x?.lineaOperacion,
            operacionObject: x?.operacion,
            resultado: x?.valor,
            ordenTrabajo: x?.ordenTrabajo?.id,
          };
        });

        if (response.length > 0) {
          setFilter({
            ...filter,
            linea: response[0].linea,
          });
        }

        setNumeroOt(response);
        setLoadingVin(true);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNumeroOt(false);
    }
  };

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-ot",
        {
          codPais: securityFilters.securityValues?.country?.id,
          codCuenta: securityFilters.securityValues?.account?.id,
          codCliente: securityFilters.securityValues?.client?.id,
          codOrdenTrabalho: filter?.numeroOt.ordenTrabajo || null,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === HttpStatusCode.Ok && data) {
          const response = data.map((x) => {
            return {
              ...x,
              estadoOt: x?.ordenTrabajo?.estado?.descripcion,
              resultado: x?.valor,
              numeroOt: x?.ordenTrabajo?.id,
              operacion: x?.operacion?.descripcion,
              nrOperacion: x?.operacionRegistrada?.id,
              linea: x?.lineaOperacion?.descripcion,
              vin: x?.vin?.id,
            };
          });
          setManual(data);
          setIngresoManual(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsName = [
    { field: "numeroOt", header: "Numero Ot" },
    { field: "estadoOt", header: "Estado Ot" },
    { field: "vin", header: "VIN" },
    { field: "operacion", header: "Operacion" },
    { field: "nrOperacion", header: "Nr Operacion" },
    { field: "linea", header: "Linea" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  // Métodos de Funções

  function onRowSelect(lines: any[]) {
    setIngresoManual((prevData) =>
      prevData.map((row) => {
        const lineaRow = row?.lineaOperacion?.descripcion;

        console.log("Value: ", filter?.resultado);

        if (lines.length > 0 && lineaRow === lines[0]?.linea) {
          return {
            ...row,
            resultadoColumns:
              filter?.resultado?.descripcion || filter?.resultado || null,
            observacionColumns: lines[0]?.observacion,
            proveedorColumns: filter?.proveedor?.descripcion || null,
          };
        }

        return row;
      })
    );
  }

  async function validarOperacion() {
    try {
      if (filter.fechaRealizacion !== null && selectedRows.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/validar-registro-operacion",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            lineasOT: [
              compareArrays(selectedRows, manual).map(
                (x) => x.lineaOperacion
              )[0] || null,
            ],
            fechaRealizacion:
              iso8601ToTimestamp(filter.fechaRealizacion) || null,
            vin:
              compareArrays(selectedRows, manual).map((x) => x.vin)[0] || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          const realizar = realizarOperacion();
          if (realizar != null) {
            return realizar;
          }
          return;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "No se pudo registrar la operacion",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function realizarOperacion() {
    try {
      if (filter.fechaRealizacion !== null && selectedRows.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/registrar-operacion",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            ordenTrabajo:
              compareArrays(selectedRows, manual).map(
                (x) => x.ordenTrabajo
              )[0] || null,
            lineasOT: [
              compareArrays(selectedRows, manual).map(
                (x) => x.lineaOperacion
              )[0] || null,
            ],
            fechaRealizacion:
              iso8601ToTimestamp(filter.fechaRealizacion) || null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "No se pudo registrar la operacion",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [proveedor, seProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  async function findByProveedorOT() {
    try {
      setLoadingProveedor(true);
      if (selectedRows.length > 0 && manual.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/find-proveedor-activo",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            operacion: compareArrays(selectedRows, manual).map(
              (x) => x.operacion
            )[0],
          }
        );

        if (status === HttpStatusCode.Ok) {
          seProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  }

  const [resultadoOTFunction, setResultadoOTFunction] = useState([]);
  const [loadingResultadoOT, setLoadingResultadoOT] = useState(false);

  async function findByResultadoOT() {
    try {
      setLoadingResultadoOT(true);
      if (selectedRows.length > 0 && manual.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/find-resultado-op",
          {
            pais: securityFilters.securityValues.country,
            lineaOp: compareArrays(selectedRows, manual).map(
              (x) => x.lineaOperacion
            )[0],
            operacion: compareArrays(selectedRows, manual).map(
              (x) => x.operacion
            )[0],
          }
        );

        if (status === HttpStatusCode.Ok) {
          setResultadoOTFunction(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResultadoOT(false);
    }
  }

  // Valores linea e operacion - Para trocar quando click no dataTable
  useEffect(() => {
    const init = async () => {
      try {
        const registrarOperacionResultado = await findByResultadoOT();
        const registrarOperacionProveedor = await findByProveedorOT();

        setFilter({
          ...filter,
          linea: selectedRows.map((x: any) => x.linea)[0],
          operacion: selectedRows.map((x: any) => x.operacion)[0],
          resultado: registrarOperacionResultado.map(
            (x: any) => x.descripcion
          )[0],
          proveedor: registrarOperacionProveedor.map(
            (x: any) => x.descripcion
          )[0],
        });
      } catch (error) {
        console.log(error);
      }
    };

    init();
  }, [selectedRows]);

  return (
    <div>
      <Toast ref={toast} position="top-center" />

      <div className="search-action mb-3">
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={loadReportData}
        />
      </div>
      <div className="form mb-5">
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"14369"} alt="Número OT" />
            </label>
            <SipcoAutocomplete
              value={filter.numeroOt}
              suggestions={numeroOt}
              completeMethod={seachNumeroOt}
              onChange={(e) =>
                setFilter({ ...filter, numeroOt: e.value || null })
              }
              field="ordenTrabajo"
              dropdown
              forceSelection
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2781"} alt="Fecha de Realización" />
            </label>
            <SipcoCalendar
              value={filter.fechaRealizacion}
              onChange={(e) =>
                setFilter({ ...filter, fechaRealizacion: e.value })
              }
              showButtonBar
              showIcon
              showTime
            />
          </div>
        </div>
      </div>

      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"14379"}
                  alt="Generación y Cierre de Ordenes de Trabajo"
                />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={ingresoManual}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsName.map((colum, index) => {
            if (colum.header === "Actualizo con Fecha") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}

          <SipcoColumn
            key={columnsName.length + 1}
            field="resultadoColumns"
            header="Resultado"
          />
          <SipcoColumn
            key={columnsName.length + 2}
            field="observacionColumns"
            header="Observaciones"
          />
          <SipcoColumn
            key={columnsName.length + 3}
            field="proveedorColumns"
            header="Proveedor"
          />
        </SipcoDataTable>
      </Panel>

      <div className="form mb-5" style={{ marginTop: "20px" }}>
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2785"} alt="Operación" />
            </label>
            <SipcoInputText value={filter.operacion || ""} disabled={true} />
            <label>
              <LanguageProvider id={"24038"} alt="Proveedor" />
            </label>
            <SipcoDropdown
              value={filter.proveedor}
              loading={loadingProveedor}
              options={proveedor}
              onChange={(e) => setFilter({ ...filter, proveedor: e.value })}
              optionLabel="descripcion"
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <SipcoInputText value={filter.linea || ""} disabled={true} />
            <label>
              <LanguageProvider id={"1669"} alt="Observación" />
            </label>
            <SipcoInputText
              value={filter.observacion || ""}
              onChange={(e) =>
                setFilter({ ...filter, observacion: e.target.value })
              }
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            {resultadoOTFunction.length > 0 ? (
              <>
                <SipcoDropdown
                  value={filter.resultado}
                  loading={loadingResultadoOT}
                  options={resultadoOTFunction}
                  onChange={(e) => setFilter({ ...filter, resultado: e.value })}
                  optionLabel="descripcion"
                />
              </>
            ) : (
              <SipcoInputText
                onChange={(e) =>
                  setFilter({ ...filter, resultado: e.target.value })
                }
              />
            )}

            <Button
              style={{ marginTop: "20px" }}
              label={LanguageProvider({
                id: "14394",
                alt: "Registrar Operación",
              })}
              text
              onClick={() => onRowSelect(selectedRows)}
            />
          </div>
        </div>
      </div>
      <Button
        label={LanguageProvider({
          id: "14391",
          alt: "Realizar Operación",
        })}
        text
        onClick={validarOperacion}
      />

      <Button
        label={LanguageProvider({
          id: "3155",
          alt: "Cancelar",
        })}
        text
        onClick={resetFilter}
      />
    </div>
  );
};
export default Manual;
