import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoCalendar from "@shared/components/sipco-calendar";

interface MantenerHojaRutaCambiarFechaDialogProps {
  visible: boolean;
  model: any;
  onClose: () => void;
  onCambiarFecha: (e: any) => void;
}

const MantenerHojaRutaCambiarFechaDialog: React.FC<
  MantenerHojaRutaCambiarFechaDialogProps
> = ({ visible, onClose, model, onCambiarFecha }) => {
  const [cambioFechaModel, setCambioFechaModel] = useState({
    fecha: new Date(),
  });

  useEffect(() => {
    async function initialize() {
      if (visible) {
        setCambioFechaModel(model);
      }
    }
    initialize();
  }, [visible]);

  return (
    <Dialog
      className="mantener-hoja-ruta-dialog"
      header={`${LanguageProvider({ id: "4301", alt: "Manter Folhas de Rota" })} - ${LanguageProvider({ id: "16785", alt: "Cambiar data" })}`}
      visible={visible}
      onHide={onClose}
      style={{ width: "350px" }}
      footer={() => {
        return (
          <div>
            <Button
              label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
              icon="pi pi-times"
              onClick={onClose}
              className="p-button-text"
            />
            <Button
              label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
              icon="pi pi-check"
              onClick={() => onCambiarFecha(cambioFechaModel.fecha)}
              autoFocus
            />
          </div>
        );
      }}
    >
      <div className="form">
        <div className="form-row">
          <div className="sipco-option">
            <label>
              <LanguageProvider id="1342" alt="Fecha Despacho" />
            </label>
            <SipcoCalendar
              value={cambioFechaModel?.fecha}
              onChange={(e) =>
                setCambioFechaModel({
                  ...cambioFechaModel,
                  fecha: e.value,
                })
              }
              showTime
              hourFormat="24"
              showButtonBar
              showIcon
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MantenerHojaRutaCambiarFechaDialog;
