import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function TranferenciaPlayaForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const columnsIdsTranferenciaPlaya = [
    54, 1491, 990, 991, 992, 396, 2139, 2139, 2140, 367, 16846,
  ];
  const columnsIds2 = [
    396, 2139, 1491, 23006, 22908, 3069, 164, 23007, 22909, 3069, 164,
  ];

  const columnsNamesTranferenciaPlaya = filterColumnsIds(
    columnsIdsTranferenciaPlaya
  );

  const columnsNames2 = filterColumnsIds(columnsIds2);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="tranferencia-playa">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"740"} alt="Playa Destino" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"741"} alt="Ubicación de Traslado" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"746"} alt="Transferencia entre Playas" />
        }
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"740"} alt="Playa Destino" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"740"} alt="Playa Destino" />
                </label>
                <SipcoAutocomplete field="id" dropdown />
              </div>
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsIdsTranferenciaPlaya}
                  screenName={"TranferenciaPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {/* aqui */}
            {columnsNamesTranferenciaPlaya.map((colum, index) => (
              <SipcoColumn
                key={index}
                field={colum.id}
                header={colum.label}
                sortable
                filter
              />
            ))}
          </SipcoDataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          <Accordion>
            <AccordionTab
              header={
                <LanguageProvider id={"16641"} alt="Oferta de Servicios" />
              }
            >
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={listRemitos}
                paginator
                scrollHeight="flex"
                filterDisplay="menu"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                {columnsNames2.map((colum, index) => (
                  <SipcoColumn
                    key={index}
                    field={colum.id}
                    header={colum.label}
                    sortable
                    filter
                  />
                ))}
              </SipcoDataTable>
            </AccordionTab>
          </Accordion>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1517"} alt="Fecha de Transferencia" />
                </label>
                <SipcoCalendar
                  showIcon
                  showTime
                  hourFormat="24"
                  showButtonBar
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1517"} alt="Fecha de Transferencia" />
                </label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
          </div>
          <Accordion>
            <AccordionTab
              header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3127"} alt="Transportista :" />
                    </label>
                    <SipcoInputText />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4655"} alt="Chofer" />
                    </label>
                    <SipcoInputText />
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4285"} alt="Tractor" />
                    </label>
                    <SipcoInputText />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2561"} alt="Cant Unid Totales" />
                    </label>
                    <SipcoInputText />
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                    </label>
                    <SipcoInputText />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>

          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "42",
                alt: "Excel",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "16283",
                alt: "Procesar",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default TranferenciaPlayaForm;
