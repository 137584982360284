import "./AntiguedadStock.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from "primereact/tabview";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputNumber } from "primereact/inputnumber";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function AntiguedadStock() {
  const SCREEN_CODE = "con0023_antiguedad_stock";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);

  const [reportData, setReportData] = useState([]);

  const [tabViewActiveIntex, setTabViewActiveIntex] = useState(0);
  const filterLabelId = [4880, 2496, 10774, 369];
  const filterNames = [];
  const filterColumnsId = [
    4245, 5715, 4891, 5720, 1491, 2892, 10774, 2496, 396, 2139, 2140, 2370,
  ];
  const columnsNames = [];

  const fromLabel = LanguageProvider({ id: "2621", alt: "Desde" });
  const untilLabel = LanguageProvider({ id: "2622", alt: "Hasta" });

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [loading, setLoading] = useState(false);
  const [loadingMercados, setLoadingMercados] = useState(false);
  const [mercados, setMercados] = useState([]);
  const [loadingOrigens, setLoadingOrigens] = useState(false);
  const [origenes, setOrigenes] = useState([]);
  const [laodingDestinos, setLaodingDestinos] = useState(false);
  const [destinos, setDestinos] = useState([]);

  const [filter, setFilter] = useState({
    interval01Start: 0,
    interval01End: 30,
    interval02Start: 31,
    interval02End: 60,
    interval03Start: 61,
    interval03End: 90,
    interval04Start: 91,
    interval04End: 120,
    mercados: [],
    origenes: [],
    destinos: [],
  });

  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/mercados",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  async function loadOrigenes() {
    setLoadingOrigens(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/origenes",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOrigenes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigens(false);
    }
  }
  async function loadDestinos() {
    setLaodingDestinos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/antiguedad-stock/destinos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLaodingDestinos(false);
    }
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/antiguedad-stock/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          clienteDescripcion:
            securityFilters?.securityValues?.client?.descripcion || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
          mercado: filter?.mercados?.map((x) => x.id) || [],
          destinos: filter?.destinos?.map((x) => x.id) || [],
          origens: filter?.origenes?.map((x) => x.id) || [],
          intervalo: [
            [filter.interval01Start, filter.interval01End],
            [filter.interval02Start, filter.interval02End],
            [filter.interval03Start, filter.interval03End],
            [filter.interval04Start, filter.interval04End],
          ],
        }
      );

      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const origenes = await loadOrigenes();
        const mercados = await loadMercados();
        const destinos = await loadDestinos();
        setFilter({
          ...filter,
          origenes: origenes,
          mercados: mercados,
          destinos: destinos,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  const reportRange1 = convertDateObjects(reportData[0]?.filas);
  const reportRange2 = convertDateObjects(reportData[1]?.filas);
  const reportRange3 = convertDateObjects(reportData[2]?.filas);
  const reportRange4 = convertDateObjects(reportData[3]?.filas);

  const removeFirstAndLast = (array) => {
    return array.map((childArray) => {
      if (childArray.length > 2) {
        return childArray.slice(1, -2); // Remove o primeiro e os dois últimos elementos
      }
      return [];
    });
  };

  return (
    <div className="antiguedad-stock">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercados}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                </label>
                <SipcoMultiSelect
                  value={filter.origenes}
                  options={origenes}
                  onChange={(e) => {
                    setFilter({ ...filter, origenes: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigens}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"369"} alt="Destinos" />
                </label>
                <SipcoMultiSelect
                  value={filter.destinos}
                  options={destinos}
                  onChange={(e) => {
                    setFilter({ ...filter, destinos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={laodingDestinos}
                  showClear
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 1" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval01Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval01Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval01End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval01End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 2" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval02Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval02Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval02End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval02End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 3" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval03Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval03Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval03End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval03End: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Intervalo 4" />
                </label>
                <InputNumber
                  placeholder={fromLabel}
                  min={0}
                  value={filter.interval04Start}
                  onChange={(e) =>
                    setFilter({ ...filter, interval04Start: e.value })
                  }
                />
                <InputNumber
                  placeholder={untilLabel}
                  value={filter.interval04End}
                  onChange={(e) =>
                    setFilter({ ...filter, interval04End: e.value })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="281" alt="Antigüedad de Stock" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={
                    tabViewActiveIntex === 0
                      ? removeFirstAndLast(reportRange1)
                      : tabViewActiveIntex === 1
                        ? removeFirstAndLast(reportRange2)
                        : tabViewActiveIntex === 2
                          ? removeFirstAndLast(reportRange3)
                          : removeFirstAndLast(reportRange4)
                  }
                  columns={columnsNames}
                  screenName={"AntigüedadStock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <TabView
          className="tab-view"
          activeIndex={tabViewActiveIntex}
          onTabChange={(event: TabViewTabChangeEvent) =>
            setTabViewActiveIntex(event.index)
          }
        >
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval01Start} ~ {filter.interval01End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reportRange1}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
                resizableColumns
                removableSort
                filterDisplay="menu"
              >
                <SipcoColumn
                  field="1"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="2"
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="3"
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="4"
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="5"
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="6"
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="7"
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="8"
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="9"
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="10"
                  header={columnsNames[9].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="11"
                  header={columnsNames[10].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="12"
                  header={columnsNames[11].label}
                  sortable
                  filter
                />
              </SipcoDataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval02Start} ~ {filter.interval02End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reportRange2}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
                resizableColumns
                removableSort
                filterDisplay="menu"
              >
                <SipcoColumn
                  field="1"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="2"
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="3"
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="4"
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="5"
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="6"
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="7"
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="8"
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="9"
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="10"
                  header={columnsNames[9].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="11"
                  header={columnsNames[10].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="12"
                  header={columnsNames[11].label}
                  sortable
                  filter
                />
              </SipcoDataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval03Start} ~ {filter.interval03End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reportRange3}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
                resizableColumns
                removableSort
                filterDisplay="menu"
              >
                <SipcoColumn
                  field="1"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="2"
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="3"
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="4"
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="5"
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="6"
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="7"
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="8"
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="9"
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="10"
                  header={columnsNames[9].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="11"
                  header={columnsNames[10].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="12"
                  header={columnsNames[11].label}
                  sortable
                  filter
                />
              </SipcoDataTable>
            </div>
          </TabPanel>
          <TabPanel
            headerTemplate={(options: TabPanelHeaderTemplateOptions) => {
              return (
                <div className={options.className} onClick={options.onClick}>
                  <span className={options.titleClassName}>
                    <LanguageProvider id="4880" alt="Intervalo" />{" "}
                    {filter.interval04Start} ~ {filter.interval04End}{" "}
                    <LanguageProvider id="3277" alt={"Días"} />
                  </span>
                </div>
              );
            }}
          >
            <div className="tab-content">
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reportRange4}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
                resizableColumns
                removableSort
                filterDisplay="menu"
              >
                <SipcoColumn
                  field="1"
                  header={columnsNames[0].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="2"
                  header={columnsNames[1].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="3"
                  header={columnsNames[2].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="4"
                  header={columnsNames[3].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="5"
                  header={columnsNames[4].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="6"
                  header={columnsNames[5].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="7"
                  header={columnsNames[6].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="8"
                  header={columnsNames[7].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="9"
                  header={columnsNames[8].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="10"
                  header={columnsNames[9].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="11"
                  header={columnsNames[10].label}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="12"
                  header={columnsNames[11].label}
                  sortable
                  filter
                />
              </SipcoDataTable>
            </div>
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
export default AntiguedadStock;
