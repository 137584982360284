import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DespachoMercadoNacionalForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const [oferta, setLisOferta] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);
  const [mercados, setMercados] = useState([]);
  const [checked, setChecked] = useState(false);
  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [hojaDeRuta, setHojaDeRuta] = useState([]);
  const [modal, setModal] = useState(false);
  const [mensaje, setMensajes] = useState("");
  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: null,
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    ingreseElVin: null,
    hojaDeRuta: null,
  });

  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const filterNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }

  const columnsIdsDespachoMercadoLocal = [
    54, 6781, 13358, 932, 3344, 1284, 1491, 396, 2139, 2140, 2496, 13335, 16923,
    2941, 4216, 3591, 8299, 159, 3186, 1719, 3625,
  ];
  const columnsIdsOfertaDeServicios = [
    396, 2139, 1491, 23006, 22908, 3069, 164, 23007, 22909,
  ];

  const columnsNamesDespachoMercadoLocal = filterColumnsIds(
    columnsIdsDespachoMercadoLocal
  );

  const columnsNamesOfertaDeServicios = filterColumnsIds(
    columnsIdsOfertaDeServicios
  );

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }

  async function loadIngreseElVin(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.client?.id &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.account.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: filter.ingreseElVin,
            },
          }
        );
        if (status === 200) {
          if (query) {
            setIngreseElVin(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setIngreseElVin(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes e Cuentas Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchHojaDeRuta(event: any) {
    await loadHojaDeRuta(event.query);
  }

  async function loadHojaDeRuta(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.client?.id &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.account.id &&
        securityFilters.securityValues.subaccount
      ) {
        const params = {
          codPais: securityFilters.securityValues.country.id,
          codCliente: securityFilters.securityValues.client.id,
          descripcionCliente:
            securityFilters.securityValues.client.descripcion || "TODOS",
          codCuenta: securityFilters.securityValues.account.id,
          codSubcuenta: securityFilters.securityValues.subaccount?.id,
          vin: filter.ingreseElVin?.id || "",
        };
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/hoja-ruta",
          {
            params,
          }
        );
        if (status === 200) {
          if (query) {
            setHojaDeRuta(
              data.filter((x: any) =>
                x.id.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            if (data.length === 0) {
              data.push({ id: "NO HAY RESULTADOS" });
            }
            setHojaDeRuta(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes, Cuentas e Subcuenta Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.client?.id &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.account.id &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/reporte",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              hojaRuta: filter.hojaDeRuta?.id || "",
            },
          }
        );
        if (status === 200) {
          if (data.length === 0) {
            data.push({ id: "NO HAY RESULTADOS" });
          }
          // console.log("data report ", data);
          const list = [];
          data.map((item) => list.push(item.remito));
          // console.log("listRemitos ", list);
          if (data[0].mensajes) {
            setMensajes(data[0].mensajes);
            setModal(true);
          }
          setListRemitos(list);
          setReporte(data);
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes e Cuentas Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const DateColumnTemplate = (data) => {
    const filter = format(
      new Date(data.fechaLiberacion.date),
      "dd/MM/yyyy HH:mm:ss"
    );
    return <td>{filter}</td>;
  };

  return (
    <div className="despacho-mercado-nacional">
      <Toast ref={toast} position="top-center" />
      <div className="card flex justify-content-center">
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">Mensajes</span>
          }
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => setModal(false)}
              autoFocus
            />
          }
          style={{ width: "50rem" }}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <p className="m-0" style={{ padding: "1rem" }}>
            {mensaje}
          </p>
        </Dialog>
      </div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <SipcoAutocomplete
                value={filter.ingreseElVin}
                suggestions={ingreseElVin}
                completeMethod={searchIngreseElVin}
                onChange={(e) =>
                  setFilter({ ...filter, ingreseElVin: e.value })
                }
                field="id"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"924"} alt="Hoja de Ruta" />
              </label>
              <SipcoAutocomplete
                value={filter.hojaDeRuta}
                suggestions={hojaDeRuta}
                completeMethod={searchHojaDeRuta}
                onChange={(e) => setFilter({ ...filter, hojaDeRuta: e.value })}
                field="id"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Administración de Remitos
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNamesDespachoMercadoLocal}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="tags">
                <Tag
                  value="Vehículos ya Despachados en Puerta de Salida"
                  style={{ background: "#00aa00" }}
                />
              </div>
              <div>
                <div className="filter-options">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
                    </label>
                    <SipcoCalendar
                      showIcon
                      showTime
                      hourFormat="24"
                      showButtonBar
                    />
                  </div>
                </div>
                <Accordion multiple activeIndex={[0]}>
                  <AccordionTab
                    header={
                      <LanguageProvider
                        id={"16641"}
                        alt="Oferta de Servicios"
                      />
                    }
                  >
                    <SipcoDataTable
                      size={"small"}
                      showGridlines
                      stripedRows
                      ref={dt}
                      value={oferta}
                      paginator
                      scrollHeight="flex"
                      filterDisplay="menu"
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      style={{ maxWidth: "100%" }}
                      loading={loading}
                    >
                      {columnsNamesOfertaDeServicios.map((colum, index) => (
                        <SipcoColumn
                          key={index}
                          // field={colum.id}
                          header={colum.label}
                          sortable
                          filter
                        />
                      ))}
                    </SipcoDataTable>
                  </AccordionTab>
                  <AccordionTab
                    header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
                  >
                    <div className="filter-options">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"3127"} alt="Transportista :" />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"4285"} alt="Tractor" />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider
                            id={"4284"}
                            alt="Batea / Transportín"
                          />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"4655"} alt="Chofer" />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider
                            id={"8811"}
                            alt="Cantidad de Unidades"
                          />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"16876"} alt="Venta flete" />
                        </label>
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"16455"} alt="Aforo Venta" />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"16773"} alt="Aforo compra" />
                        </label>
                        <SipcoInputText />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"16875"} alt="Compra flete" />
                        </label>
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>
                      </div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
              {/* buttons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "16283",
                    alt: "Procesar",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={listRemitos}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {/* aqui */}
          <SipcoColumn field={"cliente.descripcion"} header={"Cliente"} />
          <SipcoColumn field={"letra"} header={"Letra"} sortable filter />
          <SipcoColumn field={"puntoDeVenta"} header={"Punto Venta"} />
          <SipcoColumn field={"id"} header={"Código Remito"} sortable filter />
          <SipcoColumn field={"estadoRemito.descripcion"} header={"Estado"} />
          <SipcoColumn header={"Liberación"} body={DateColumnTemplate} />
          <SipcoColumn field={"vin.id"} header={"VIN"} sortable filter />
          <SipcoColumn field={"vin.modelo.marca"} header={"Marca"} />
          <SipcoColumn field={"vin.modelo.descripcionFull"} header={"Modelo"} />
          {/* TODO- VERIFICAR CHAVES CORESPONDENTES - LUCAS SILVESTRE 29/07/2024 */}
          <SipcoColumn field={"TODO"} header={"Color"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Origen"} sortable filter />
          <SipcoColumn field={"vin.motorizacion.id"} header={"Motor"} />
          <SipcoColumn
            field={"idPedidoCliente"}
            header={"Cód.Pedido Cliente"}
          />
          <SipcoColumn field={"concesionario.id"} header={"Concesionario"} />
          <SipcoColumn
            field={"direccionEntrega.id"}
            header={"Boca de Entrega"}
          />
          <SipcoColumn field={"TODO"} header={"Razón Social"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Domicilio"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Provincia"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Localidad"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Fecha Salida"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Fecha Salida"} sortable filter />
          <SipcoColumn field={"TODO"} header={"Observaciones"} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default DespachoMercadoNacionalForm;
