import "./OrdenReparacionRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalAltaOrdenReparacion from "./modals/ModalAltaOrdenReparacion";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function OrdenReparacionRepuestos() {
  const SCREEN_CODE = "abm0107_orden_reparacion_repuestos";
  ScreenCodeValue(SCREEN_CODE);

  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columns = [];

  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: "",
    estados: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
              descripcionCliente: "",
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTaller(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/orden-reparacion/estados",

        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });

        const response = data.map((x: any) => {
          return { ...x, label: `${x.descripcion} - (${x.id})` };
        });

        setEstados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-repuestos/find",
        {
          pais: pais,
          cuenta: cuenta,
          subcuenta: subcuenta,
          taller: tallerFilter,
          ordenReparacion: {
            id: nroOrden,
          },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const taller = await loadTaller();
        const estados = await loadEstados();
        setFilter({ ...filter, taller: taller[0], estados: estados[0] });
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [securityFilters.securityValues]);

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  const columnsNamesTable = [
    { field: "numeroIngreso", header: "Nro Ingreso" },
    { field: "nroOrden", header: "Nro Orden" },
    { field: "fechaAlta", header: "Fecha Alta" },
    { field: "vin", header: "Vin" },
    { field: "marca", header: "Marca" },
    { field: "modelo", header: "Modelo" },
    { field: "inspector", header: "Inspector" },
    { field: "subcuenta", header: "Subcuenta" },
    { field: "taller", header: "Taller" },
    { field: "fechaCierreReal", header: "Fecha Cierre Real" },
    { field: "fechaCierreEstimada", header: "Fecha Cierre Estimada" },
    { field: "fechaFacturacion", header: "Fecha Facturacion" },
    { field: "fechaAnulacion", header: "Fecha Anulacion" },
    { field: "estado", header: "Estado" },
    { field: "incidentes", header: "Incidentes" },
  ];

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="orden-reparacion">
      <ModalAltaOrdenReparacion visible={visible} onClose={hideModal} />

      <Toast ref={toast} position="top-center" />
      <Filter onSearch={loadReportData}>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <SipcoInputText value={pais.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoInputText
                  value={tallerFilter.descripcion}
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <SipcoInputText value={cuenta.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <SipcoInputText value={nroOrden} disabled={true} />
              </div>
              <div className={"sipco-option"} style={{ marginBottom: "5em" }}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <SipcoInputText value={subcuenta.descripcion} disabled={true} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={showModal}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={reporte}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Alta" ||
              colum.header === "Fecha Cierre Real" ||
              colum.header === "Fecha Cierre Estimada" ||
              colum.header === "Fecha Facturacion" ||
              colum.header === "Fecha Anulacion"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacionRepuestos;
