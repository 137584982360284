import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

export function ConsultaOsTerceros() {
  const SCREEN_CODE = "con0097_consulta_os_terceros";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [16563, 18997, 25182, 2622, 901, 54, 2621];
  const filterNames = [];
  const filterColumnsId = [
    16563, 54, 3600, 8388, 6256, 22833, 1669, 22796, 1491, 4724, 396, 10746,
    22908, 22909, 16817, 23241, 22893, 22895, 14712, 1342, 2521, 23242, 22356,
    5496, 5490, 16590,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 2612, 1184, 1182, 5044, 54, 1791, 4791, 327, 467, 468, 1140, 2020,
    2607, 2609, 2610, 2608, 2890, 16531, 448, 16563, 7795, 18997, 2621, 2622,
    901, 3600, 8388, 6256, 22833, 1669, 22796, 1491, 4724, 396, 10746, 22908,
    22909, 16817, 23241, 22893, 22895, 14712, 1342, 2521, 23242, 1480, 653,
    22356, 5496, 5490, 16590, 23234,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    nroOfertaServ: null,
    descCliente: null,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    vin: null,
    codCliente: TODOS_OBJ,
  });

  const [loading, setLoading] = useState(false);

  const [nroOfertaServ, setNroOfertaServ] = useState([]);
  const [descCliente, setDescCliente] = useState([]);
  const [vin, setVin] = useState([]);
  const [cliente, setCliente] = useState([]);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/consulta-os-terceros/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: vin,
            },
          }
        );

        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchCliente(event: any) {
    await loadCliente(event.query);
  }
  async function loadCliente(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/consulta-os-terceros/combo/cliente",
          {
            params: {},
          }
        );

        if (status === 200) {
          const cliente = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          cliente.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const clienteFiltrados = cliente.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setCliente(clienteFiltrados);
            return clienteFiltrados;
          } else {
            setCliente(cliente);
            return cliente;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/consulta-os-terceros/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,

          nroOfertaServ: filter?.nroOfertaServ || "",
          descCliente: filter?.descCliente || "",
          rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
          vin: filter?.vin?.id || "",
          codCliente: filter?.codCliente.id || null,
          clienteDescripcion: filter?.codCliente.descripcion || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[0].label}
                  alt="Nro. Oferta Servicio"
                />
              </label>
              <SipcoAutocomplete
                value={filter.nroOfertaServ}
                suggestions={nroOfertaServ}
                onChange={(e) =>
                  setFilter({ ...filter, nroOfertaServ: e.value })
                }
                field="label"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="Desc. Cliente"
                />
              </label>
              <SipcoAutocomplete
                value={filter.descCliente}
                suggestions={descCliente}
                onChange={(e) =>
                  setFilter({ ...filter, descCliente: e.value.toUpperCase() })
                }
                field="label"
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider
                  id={filterNames[2].label}
                  alt="Fecha Cotización Desde"
                />
              </label>
              <SipcoCalendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Hasta" />
              </label>
              <SipcoCalendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[4].label}
                  alt="Ingrese el VIN"
                />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[5].label} alt="Cliente" />
              </label>
              <SipcoAutocomplete
                value={filter.codCliente}
                suggestions={cliente}
                completeMethod={searchCliente}
                onChange={(e) => setFilter({ ...filter, codCliente: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[53].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta OS Terceros"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
          <SipcoColumn field="23" header={columnsNames[23].label} />
          <SipcoColumn field="24" header={columnsNames[24].label} />
          <SipcoColumn field="25" header={columnsNames[25].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOsTerceros;
