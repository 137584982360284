import "./ConsultaTranslado.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaTranslado() {
  const SCREEN_CODE = "con0065_consulta_traslados";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();
  const usuarioLogado = JSON.parse(localStorage.getItem("loginData"));
  const [filter, setFilter] = useState({
    vin: "",
    perfil: null,
    subcuentaDestino: null,
  });

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loadingReporte, setLoadingReporte] = useState(true);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [4392, 901, 16120, 16096];
  const filterNames = [];
  const filterColumnsId = [54, 2892, 17088, 1269, 2139, 367, 2496];
  const columnsNames = [];
  const [checked, setChecked] = useState(false);

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    13, 27, 31, 32, 34, 39, 54, 65, 187, 321, 326, 367, 396, 491, 653, 660, 739,
    740, 741, 743, 748, 749, 753, 754, 849, 883, 901, 924, 990, 991, 992, 1024,
    1210, 1269, 1316, 1480, 1491, 1492, 1517, 1533, 1595, 1791, 2139, 2140,
    2496, 2561, 2724, 2785, 2805, 2892, 2898, 2939, 2941, 3141, 3309, 3625,
    3754, 4284, 4285, 4655, 4791, 5044, 5417, 5435, 5618, 5619, 5686, 6590,
    8296, 8596, 9215, 9306, 9314, 10034, 10269, 10270, 10746, 10758, 10767,
    10772, 10776, 10793, 10794, 10859, 10879, 10880, 10883, 10884, 10885, 10886,
    10887, 10888, 10889, 10890, 10891, 10892, 10893, 10894, 10895, 10896, 10897,
    10898, 10899, 10900, 10902, 10903, 10904, 10905, 10909, 10910, 10914, 10915,
    10958, 10959, 11047, 11142, 11143, 11144, 11234, 11328, 14282, 14722, 15596,
    16050, 16051, 16053, 16054, 16096, 16120, 16121, 16136, 16137, 16164, 16186,
    16187, 16188, 16844, 16846, 17088, 2502, 17577, 16144, 16145, 4392,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [vins, setVins] = useState([]);
  const [loadingVins, setLoadingVins] = useState(false);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(vin: any) {
    setLoadingVins(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        vin.length >= 8 &&
        vin.length <= 17
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-transferencia/combo/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: vin,
            },
          }
        );

        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVins(vin);
          return vin;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingVins(false);
    }
  }
  const [perfil, setPerfil] = useState([]);
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  async function loadPerfil() {
    setLoadingPerfil(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-transferencia/combo/perfil",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setPerfil(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPerfil(false);
    }
  }
  const [subcuentaDestino, setSubcuentaDestino] = useState([]);
  const [loadingSubcuentaDestino, setLoadingSubcuentaDestino] = useState(false);
  async function loadSubcuentaDestino() {
    setLoadingSubcuentaDestino(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-transferencia/combo/subcuenta-destino",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            usuario: {
              id: usuarioLogado.userName,
              idioma: {
                id: usuarioLogado.idioma.codIdioma,
              },
              nivel: usuarioLogado.nivel,
            },
          }
        );
        if (status === 200) {
          setSubcuentaDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubcuentaDestino(false);
    }
  }

  async function loadGrid() {
    setLoadingReporte(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        filter.subcuentaDestino &&
        filter.perfil
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-transferencia/grid",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            subcuentaDestino: filter.subcuentaDestino,
            perfil: filter.perfil,
          }
        );
        if (status === 200) {
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReporte(false);
    }
  }
  async function loadGrid2() {
    setLoadingReporte(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        filter.subcuentaDestino &&
        filter.perfil &&
        filter.vin
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-transferencia/grid2",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            subcuentaDestino: filter.subcuentaDestino,
            perfil: filter.perfil,
            vin: filter.vin,
          }
        );
        if (status === 200) {
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReporte(false);
    }
  }
  useEffect(() => {
    async function initialize() {
      try {
        const subcuentaDestinos = await loadSubcuentaDestino();
        const perfis = await loadPerfil();
        setFilter({
          ...filter,
          perfil: perfis[0],
          subcuentaDestino: subcuentaDestinos[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="consulta-translado">
      <Filter
        onSearch={() => {
          if (checked) {
            console.log("grid 1");
            loadGrid();
          } else {
            console.log("grid 2");
            loadGrid2();
          }
        }}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Subcuenta Destino</label>
                <SipcoDropdown
                  loading={loadingSubcuentaDestino}
                  options={subcuentaDestino}
                  filter
                  value={filter.subcuentaDestino}
                  optionLabel="descripcion"
                  onChange={(e) =>
                    setFilter({ ...filter, subcuentaDestino: e.value })
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={searchVin}
                  disabled={!checked}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                />
              </div>
              <div
                className={"sipco-option"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <label>Consulta desde Stock</label>
                <Checkbox
                  value={checked}
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />{" "}
              </div>
              <div className={"sipco-option"}>
                <label>Perfil</label>
                <SipcoDropdown
                  loading={loadingPerfil}
                  options={perfil}
                  filter
                  value={filter.perfil}
                  optionLabel="descripcion"
                  onChange={(e) => setFilter({ ...filter, perfil: e.value })}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="16148" alt="Listado de Transferencia" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado de Transferencia"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          header={"se puede trasnladar"}
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
        </SipcoDataTable>
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          header={"no se puede trasnladar"}
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaTranslado;
