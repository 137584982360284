import "./IngresoEcomDaniosFord.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function IngresoEcomDaniosFord() {
  const SCREEN_CODE = "fun0211_ingreso_ecom_danios_ford";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [validationDatosDialog, setValidationDatosDialog] = useState(false);

  const [filterDatos, setFilterDatos] = useState({
    marca: null,
    concesionario: null,
    bocaEntrega: null,
    imputacione: null,
    responsable: null,
    gravedadDano: null,
    calle: null,
    columna: null,
    fechaIngreso: new Date(),
    archivo: null,
  });

  async function ingresarClick() {
    try {
      if (selectedReportData <= 0) {
        setValidationDatosDialog(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function bajaClick() {
    try {
      if (selectedReportData <= 0) {
        setValidationDatosDialog(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
  };

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/marca",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          const marcaData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setMarca(marcaData);
          return marcaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [concesionario, setConcesionario] = useState([]);
  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  async function loadConcesionario() {
    setLoadingConcesionario(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/concesionario",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          const concesionarioData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setConcesionario(concesionarioData);
          return concesionarioData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
    setConcesionario([]);
    return [];
  }

  const [bocaEntrega, setBocaEntrega] = useState([]);
  const [loadingBocaEntrega, setLoadingBocaEntrega] = useState(false);
  async function loadBocaEntrega(concesionarioId) {
    setLoadingBocaEntrega(true);
    try {
      if (concesionarioId && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/boca-entrega",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            concesionario: { id: concesionarioId },
          }
        );

        if (status === 200) {
          const bocaEntregaData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setBocaEntrega(bocaEntregaData);
          return bocaEntregaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBocaEntrega(false);
    }
    setBocaEntrega([]);
    return [];
  }

  const [imputaciones, setImputaciones] = useState([]);
  const [loadingImputaciones, setLoadingImputaciones] = useState(false);
  async function loadimputaciones() {
    setLoadingImputaciones(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/imputacion",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          const imputacioneData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setImputaciones(imputacioneData);
          return imputacioneData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingImputaciones(false);
    }
    setImputaciones([]);
    return [];
  }

  const [responsable, setResponsable] = useState([]);
  const [loadingResponsable, setLoadingResponsable] = useState(false);
  async function loadResponsable(imputacioneId) {
    setLoadingResponsable(true);
    try {
      if (imputacioneId && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/responsable",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            imputacion: { id: imputacioneId },
          }
        );

        if (status === 200) {
          const responsableData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setResponsable(responsableData);
          return responsableData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResponsable(false);
    }
    setResponsable([]);
    return [];
  }

  const [gravedadDano, setGravedadDano] = useState([]);
  const [loadingGravedadDano, setLoadingGravedadDano] = useState(false);
  async function loadGravedadDano() {
    setLoadingGravedadDano(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/gravedad-de-dano",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          const gravedadDanoData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setGravedadDano(gravedadDanoData);
          return gravedadDanoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGravedadDano(false);
    }
    setGravedadDano([]);
    return [];
  }

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle() {
    setLoadingCalle(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/calle",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    setCalle([]);
    return [];
  }

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  async function loadColumna(calle) {
    setLoadingColumna(true);
    try {
      if (calle && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom-danios-ford/columna",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    setColumna([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const marcas = await loadMarca();
          const concesionarios = await loadConcesionario();
          const imputaciones = await loadimputaciones();
          const gravedadDanos = await loadGravedadDano();
          const calles = await loadCalle();

          setFilterDatos((prevFilter) => ({
            ...prevFilter,
            marca: marcas?.length > 0 ? marcas[0] : null,
            concesionario:
              concesionarios?.length > 0 ? concesionarios[0] : null,
            imputacione: imputaciones?.length > 0 ? imputaciones[0] : null,
            gravedadDano: gravedadDanos?.length > 0 ? gravedadDanos[0] : null,
            calle: calles?.length > 0 ? calles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateBocaEntrega() {
      if (filterDatos?.concesionario) {
        try {
          const bocaEntregas = await loadBocaEntrega(
            filterDatos?.concesionario?.id
          );
          setFilterDatos((prevFilter) => ({
            ...prevFilter,
            bocaEntrega: bocaEntregas?.length > 0 ? bocaEntregas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateBocaEntrega();
  }, [filterDatos?.concesionario]);

  useEffect(() => {
    async function updateResponsable() {
      if (filterDatos?.imputacione) {
        try {
          const responsables = await loadResponsable(
            filterDatos?.imputacione?.id
          );
          setFilterDatos((prevFilter) => ({
            ...prevFilter,
            responsable: responsables?.length > 0 ? responsables[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateResponsable();
  }, [filterDatos?.imputacione]);

  useEffect(() => {
    async function updateColumna() {
      if (filterDatos?.calle) {
        try {
          const columnas = await loadColumna(filterDatos.calle);
          setFilterDatos((prevFilter) => ({
            ...prevFilter,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [filterDatos?.calle]);

  return (
    <div className="ingreso-ecom-danios-ford">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"9077"} alt="Datos" />}>
          <div className="form">
            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23892"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filterDatos.marca}
                  options={marca}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, marca: e.value })
                  }
                  optionLabel="label"
                  loading={loadingMarca}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario" />
                </label>
                <SipcoDropdown
                  value={filterDatos.concesionario}
                  options={concesionario}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, concesionario: e.value })
                  }
                  optionLabel="label"
                  loading={loadingConcesionario}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4216"} alt="Boca de Entrega" />
                </label>
                <SipcoDropdown
                  value={filterDatos.bocaEntrega}
                  options={bocaEntrega}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, bocaEntrega: e.value })
                  }
                  optionLabel="label"
                  loading={loadingBocaEntrega}
                  filter
                />
              </div>
            </div>

            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"486"} alt="Imputaciones" />
                </label>
                <SipcoDropdown
                  value={filterDatos.imputacione}
                  options={imputaciones}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, imputacione: e.value })
                  }
                  optionLabel="label"
                  loading={loadingImputaciones}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11097"} alt="Responsable" />
                </label>
                <SipcoDropdown
                  value={filterDatos.responsable}
                  options={responsable}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, responsable: e.value })
                  }
                  optionLabel="label"
                  loading={loadingResponsable}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"17007"} alt="Gravedad del Daño" />
                </label>
                <SipcoDropdown
                  value={filterDatos.gravedadDano}
                  options={gravedadDano}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, gravedadDano: e.value })
                  }
                  optionLabel="label"
                  loading={loadingGravedadDano}
                  filter
                />
              </div>
            </div>

            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoDropdown
                  value={filterDatos.calle}
                  options={calle}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, calle: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingCalle}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoDropdown
                  value={filterDatos.columna}
                  options={columna}
                  onChange={(e) =>
                    setFilterDatos({ ...filterDatos, columna: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingColumna}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3232"} alt="Fecha Ingreso" />
                </label>
                <SipcoCalendar
                  value={filterDatos.fechaIngreso}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilterDatos({ ...filterDatos, fechaIngreso: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  showTime
                />
              </div>
            </div>

            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"927"} alt="Archivo" />
                </label>
                <SipcoInputText value={filterDatos.archivo} />
              </div>
              <div className={"sipco-option-btn"}>
                <Button
                  label={LanguageProvider({
                    id: "4997",
                    alt: "Agregar Archivo",
                  })}
                  icon="pi pi-upload"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="23456"
                    alt="Ingreso Vines y Daños Por Archivo Texto"
                  ></LanguageProvider>
                }
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field=""
            header={<LanguageProvider id="13" alt="Error"></LanguageProvider>}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="1491" alt="VIN"></LanguageProvider>}
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="18727" alt="Cod. Modelo"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="2139" alt="Modelo"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="367" alt="Mercado"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="4102" alt="Destino"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="10540" alt="Codigo Daño"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider
                id="10541"
                alt="Descripción Daño"
              ></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="16240" alt="Parte Daño"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="31" alt="Descripción"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="14288" alt="Transacción"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="1669" alt="Observación"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider
                id="12103"
                alt="Fecha Fabricación"
              ></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="849" alt="Letra"></LanguageProvider>}
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="13358" alt="Punto Venta"></LanguageProvider>
            }
          />
          <SipcoColumn
            field=""
            header={
              <LanguageProvider id="3184" alt="Remito"></LanguageProvider>
            }
          />
        </SipcoDataTable>
      </Panel>

      <div className="tags">
        <Tag
          value={
            <LanguageProvider
              id="13444"
              alt="Registros con errores."
            ></LanguageProvider>
          }
          className="tag-registros-con-errores"
        />
        <Tag
          value={
            <LanguageProvider
              id="19359"
              alt="Registros con advertencia (Se procesan)."
            ></LanguageProvider>
          }
          className="tag-registros-con-advertencia"
        />
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "3",
            alt: "Ingresar",
          })}
          icon="pi pi-taxi pi pi-car"
          onClick={ingresarClick}
        />
        <Button
          label={LanguageProvider({
            id: "26",
            alt: "Baja",
          })}
          icon="pi pi-times"
          onClick={bajaClick}
        />
        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          icon="pi pi-check-circle"
        />

        {/* No hay registros en la grilla Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validationDatosDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider
              id="2502"
              alt="No hay registros en la grilla para procesar"
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
