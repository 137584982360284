import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function LiberadosDespachados() {
  const SCREEN_CODE = "con0048_liberados_vs_despachados";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [467, 468, 396, 2941];
  const filterNames = [];
  const filterColumnsId = [
    1491, 396, 2139, 2941, 3069, 159, 164, 3186, 4791, 0, 0, 4197, 5618, 5619,
    0, 3277, 818, 16868, 10502,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 467, 468, 396, 2941, 1491, 2139, 3069, 159, 164,
    3186, 4791, 4197, 5618, 5619, 3277, 818, 16868, 10502, 5614,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    marca: null,
    concesionario: null,
  });

  const [loading, setLoading] = useState(false);

  const [marca, setMarca] = useState([]);
  const [concesionario, setConcesionario] = useState([]);

  async function searchMarca(event: any) {
    await loadMarca(event.query);
  }
  async function loadMarca(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/liberados-vs-despachados/combo/marca",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const marca = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });

          if (query) {
            const marcaFiltrados = marca.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setMarca(marcaFiltrados);
            return marcaFiltrados;
          } else {
            setMarca(marca);
            return marca;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchConcesionario(event: any) {
    await loadConcesionario(event.query);
  }
  async function loadConcesionario(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/liberados-vs-despachados/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const concesionario = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });

          if (query) {
            const concesionarioFiltrados = concesionario.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setConcesionario(concesionarioFiltrados);
            return concesionarioFiltrados;
          } else {
            setConcesionario(concesionario);
            return concesionario;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [filterParamsExport, setFilterParamsExport] = useState([]);
  function setFilterExport() {
    // const filterLabelId = [467, 468, 396, 2941];
    const filtersTeste = [
      { 1499: securityFilters.securityValues.country?.descripcion },
      { 488: securityFilters.securityValues.client?.descripcion },
      { 1791: securityFilters.securityValues.account?.descripcion },
      { 4791: securityFilters.securityValues.subaccount?.descripcion },
      { 467: filter.fechaInicio },
      { 468: filter.fechaFinal },
      // { 396: filter.marca?.descripcion },
      // { 2941: filter.concesionario?.descripcion },
    ];
    setFilterParamsExport(filtersTeste);
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        marca: filter?.marca?.id || null,
        concesionario: filter?.concesionario?.id || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberados-vs-despachados/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
        setFilterExport();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const cleanData = (data) => {
    return data.map((childArray) => {
      // Remove os elementos nas posições 9, 10 e 14.
      return childArray.filter((_, index) => ![9, 10, 14].includes(index));
    });
  };
  const removeZeroes = (array) => {
    return array.filter((value) => value.id !== 0);
  };

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Desde" />
              </label>
              <SipcoCalendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Hasta" />
              </label>
              <SipcoCalendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            {/* <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[2].label} alt="" />
              </label>
              <SipcoAutocomplete
                value={filter.marca}
                suggestions={marca}
                completeMethod={searchMarca}
                onChange={(e) => setFilter({ ...filter, marca: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div> */}

            {/* <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="" />
              </label>
              <SipcoAutocomplete
                value={filter.concesionario}
                suggestions={concesionario}
                completeMethod={searchConcesionario}
                onChange={(e) =>
                  setFilter({ ...filter, concesionario: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div> */}
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[23].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  //função para remover posições do array no qual não são exibidas no relatório
                  data={cleanData(data)}
                  //função para remover ids 0 do array
                  columns={removeZeroes(columnsNames)}
                  screenName={"Liberados Vs Despachados"}
                  pdf={false}
                  csv={false}
                  filterProps={filterParamsExport}
                  filter={true}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          {/* <SipcoColumn field="9" header={columnsNames[9].label} sortable filter /> */}
          {/* <SipcoColumn field="10" header={columnsNames[10].label} sortable filter /> */}
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          {/* <SipcoColumn field="14" header={columnsNames[14].label} sortable filter /> */}
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default LiberadosDespachados;
