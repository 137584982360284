import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { CalendarSelectEvent } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import TreeErrorDialog from "./TreeErrorDialog";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function LlegadaBuqueCamionBrForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);

  const columnsIds1 = [1491, 10830, 396, 2139, 2140, 1736, 13489, 13490];
  const columnsNames1 = filterColumnsIds(columnsIds1);

  const [treeErrorDialogProps, setTreeErrorDialogProps] = useState({
    visible: false,
    errorMessage: "",
    treeData: [],
  });

  const [filter, setFilter] = useState({
    calle: null,
    columna: null,
    nivel: null,
    tipoTransporte: null,
    vin: null,
    marca: null,
    modelo: null,
    ciudad: null,
    terminal: null,
    despachante: null,
    danios: null,
    puntoRetiro: null,
    transportista: null,
    tractor: null,
    batea: null,
    chofer: null,
    fechaRecepcion: null,
    armador: null,
    transporte: null,
    ganchos: null,
    hayTerrestres: false,
    cobraFlete: false,
  });

  const [loding, setLoading] = useState(false);
  const [calles, setCalles] = useState(null);
  const [loadingCalles, setLoadingCalles] = useState(false);

  const [columnas, setColumnas] = useState(null);
  const [loadingColumnas, setLoadingColumnas] = useState(false);

  const [niveles, setNiveles] = useState(null);
  const [loadingNiveles, setLoadingNiveles] = useState(false);

  const [tiposTransporte, setTiposTransporte] = useState(null);
  const [loadingTiposTransporte, setLoadingTiposTransporte] = useState(false);

  const [vin, setVin] = useState(null);
  const [vinSuggestions, setVinSuggestions] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);

  const [grid, setGrid] = useState([]);
  const [selectedGrid, setSelectedGrid] = useState([]);

  const [loadingGrid, setLoadingGrid] = useState(false);

  const [cidades, setCidades] = useState(null);
  const [loadingCiudad, setLoadingCiudad] = useState(false);

  const [terminais, setTerminais] = useState(null);
  const [loadingTerminais, setLoadingTerminais] = useState(false);

  const [despachantes, setDespachantes] = useState(null);
  const [loadingDespachantes, setLoadingDespachantes] = useState(false);

  const [proveedorDanios, setProveedorDanios] = useState(null);
  const [loadingProvedorDanios, setLoadingProvedorDanios] = useState(false);

  const [puntosRetiro, setPuntosRetiro] = useState(null);
  const [loadingPuntosRetiro, setLoadingPuntosRetiro] = useState(false);

  const [transportistas, setTransportistas] = useState(null);
  const [loadingTransportistas, setLoadingTransportistas] = useState(false);

  const [tractores, setTractores] = useState(null);
  const [loadingTractores, setLoadingTractores] = useState(false);

  const [bateas, setBateas] = useState(null);
  const [loadingBateas, setLoadingBateas] = useState(false);

  const [choferes, setChoferes] = useState(null);
  const [loadingChoferes, setLoadingChoferes] = useState(false);

  const [armadores, setArmadores] = useState(null);
  const [loadingArmadores, setLoadingArmadores] = useState(false);

  const [transportes, setTransportes] = useState(null);
  const [loadingTransportes, setLoadingTransportes] = useState(false);

  const handleDateChange = (e: CalendarSelectEvent) => {
    setFilter((filter) => ({ ...filter, fechaRecepcion: e.value }));
  };
  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }
  async function loadGrid() {
    setLoadingGrid(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/importado-br",
          {
            pais: securityFilters.securityValues.country,

            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: filter?.vin || null,
            marca: filter?.marca || null,
            modelo: filter?.modelo || null,
          }
        );
        if (status === 200) {
          const convertDate = {};
          console.log(data);
          setGrid(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrid(false);
    }
  }
  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.value &&
      securityFilters.securityValues.client?.value &&
      securityFilters.securityValues.account?.value &&
      securityFilters.securityValues.subaccount?.value
    );
  }
  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }
  async function loadIngreseElVin(query = "") {
    setLoadingVin(true);
    try {
      const check = validateSecurityFilters();
      if (typeof query !== "string" || query.length < 8 || query.length > 17) {
        setVinSuggestions([]);
        return;
      }

      if (!check) {
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        vin: { id: query },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/vin",
        params
      );
      if (status === 200) {
        setVinSuggestions(data);
        setFilter({
          ...filter,
          vin: vinSuggestions[0],
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  async function loadCalle() {
    setLoadingCalles(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/calle-br",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setCalles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalles(false);
    }
    return [];
  }
  async function loadColumnas(calle: any) {
    setLoadingColumnas(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        calle != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/columna-br",
          {
            pais: securityFilters.securityValues.country,

            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: calle,
          }
        );
        if (status === 200) {
          setColumnas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnas(false);
    }
    return [];
  }
  async function loadNiveles(calle: any, columna: any) {
    setLoadingNiveles(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        calle != null &&
        columna != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/nivel-br",
          {
            pais: securityFilters.securityValues.country,

            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: calle,
            columna: columna,
          }
        );
        if (status === 200) {
          setNiveles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNiveles(false);
    }
    return [];
  }
  async function loadTipTransporte() {
    setLoadingTiposTransporte(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/tipo-transporte",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setTiposTransporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTiposTransporte(false);
    }
    return [];
  }
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }
  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/modelo",
          {
            pais: securityFilters.securityValues.country,

            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
    return [];
  }
  async function loadCiudad() {
    setLoadingCiudad(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/ciudad",
        {}
      );
      if (status === 200) {
        setCidades(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudad(false);
    }
    return [];
  }
  async function loadTerminal() {
    setLoadingTerminais(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/proveedor",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setTerminais(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTerminais(false);
    }
    return [];
  }
  async function loadDespachantes() {
    setLoadingDespachantes(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/despachante",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setDespachantes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDespachantes(false);
    }
    return [];
  }
  async function loadDanios() {
    setLoadingProvedorDanios(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/danios",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setProveedorDanios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvedorDanios(false);
    }
    return [];
  }
  async function loadPuntosRetiro() {
    setLoadingPuntosRetiro(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/contable-punto",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          setPuntosRetiro(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPuntosRetiro(false);
    }
    return [];
  }
  async function loadTransportistas() {
    setLoadingTransportistas(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/transportista",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setTransportistas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportistas(false);
    }
    return [];
  }
  async function loadTractores(transportista: any) {
    setLoadingTractores(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        transportista != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/tractores",
          {
            pais: securityFilters.securityValues.country,
            transportista: transportista,
          }
        );
        if (status === 200) {
          setTractores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTractores(false);
    }
    return [];
  }
  async function loadBateas(transportista: any, tractor: any) {
    setLoadingBateas(true);
    try {
      if (transportista != null && tractor != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/remolque-br",
          {
            transportista: transportista,
            tractor: tractor,
          }
        );
        if (status === 200) {
          setBateas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBateas(false);
    }
  }
  async function loadChoferes(transportista: any) {
    setLoadingChoferes(true);
    try {
      if (transportista != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/chofer-br",
          {
            transportista: transportista,
          }
        );
        if (status === 200) {
          setChoferes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChoferes(false);
    }
    return [];
  }
  async function loadTransportes(tipoTransporte: any) {
    setLoadingTransportes(true);
    try {
      if (
        tipoTransporte != null &&
        securityFilters?.securityValues?.country != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/transporte-playa-br",
          {
            pais: securityFilters.securityValues.country,
            tipoTransporte: tipoTransporte,
          }
        );
        if (status === 200) {
          setTransportes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportes(false);
    }
    return [];
  }
  async function loadArmadores() {
    setLoadingArmadores(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/armador",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setArmadores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingArmadores(false);
    }
    return [];
  }
  const [ganchos, setGanchos] = useState([]);
  const [loadingGanchos, setLoadingGanchos] = useState(false);
  async function loadGanchos() {
    setLoadingGanchos(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/ganchos",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setGanchos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGanchos(false);
    }
    return [];
  }
  function procesar() {
    try {
      confirmDialog({
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        message: (
          <LanguageProvider
            id={"13275"}
            alt="¿Desea procesar los cambios realizados?"
          />
        ),
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => procesarConfirmado(),
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function validateProcesar() {
    setLoading(true);
    try {
      if (selectedGrid.length === 0) {
        displayNoDataToast();
        return;
      }
      if (filter.danios == null || filter.ciudad == null) {
        displayNoDataToast();
        return;
      }
      if (securityFilters.subAccountDetails == null) {
        if (
          filter.calle == null ||
          filter.columna == null ||
          filter.nivel == null
        ) {
          displayNoDataToast();
          return;
        }
      } else if (
        securityFilters.subAccountDetails.algoritmoUbicacionPlaya.id !== 1 ||
        securityFilters.subAccountDetails.algoritmoUbicacionPlaya.id !== 2 ||
        securityFilters.subAccountDetails.algoritmoUbicacionPlaya.id !== 3
      ) {
        if (
          filter.calle == null ||
          filter.columna == null ||
          filter.nivel == null
        ) {
          displayNoDataToast();
          return;
        }
      }

      if (filter.hayTerrestres) {
        if (
          //filter.transportista == null ||
          filter.tractor == null ||
          filter.batea == null ||
          filter.chofer == null
        ) {
          displayNoDataToast();
          return;
        }
      }

      if (
        //filter.transportista == null ||
        filter.despachante == null ||
        filter.terminal == null
      ) {
        displayNoDataToast();
        return;
      }

      if (filter.transporte == null || filter.armador == null) {
        displayNoDataToast();
        return;
      }
      await validarLlegadaBuqueCamionBr();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function validarLlegadaBuqueCamionBr() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/validar-processar-br",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: {
            id: securityFilters.securityValues.subaccount.id,
            subcuentaDetalle: securityFilters.subAccountDetails,
          },
          armador: filter.armador,
          ciudad: filter.ciudad,
          items: selectedGrid,
          fechaRecepcion: {
            date: filter.fechaRecepcion,
          },
          hayTerrestres: filter.hayTerrestres,
          stockPlayaUbicacion: {
            calle: filter.calle,
            columna: filter.columna,
            nivel: filter.nivel,
          },
          tiposTransporte: filter.tipoTransporte,
        }
      );
      if (status === 200) {
        await validateProcesarResult(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function validarFechaRecepcion() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/validar-fecha-recepcion-br",
        {
          pais: securityFilters.securityValues.country,
          items: selectedGrid,
          fechaRecepcion: {
            date: filter.fechaRecepcion,
          },
        }
      );
      if (status === 200) {
        validarFechaRecepcionResult(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function validarFechaRecepcionResult(data: any) {
    try {
      let vinesQueNoPuedenIngresar = [];
      for (const vin of data) {
        vinesQueNoPuedenIngresar.push({ label: vin.toString() });
      }

      if (data.length > 0) {
        setTreeErrorDialogProps({
          ...treeErrorDialogProps,
          visible: true,
          treeData: vinesQueNoPuedenIngresar,
          errorMessage: LanguageProvider({
            id: "21071",
            alt: "Los siguientes vines poseen fecha de despacho en pais origen mayor a la fecha de recepción",
          }),
        });
      } else {
        await procesar();
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function procesarConfirmado() {
    setLoading(true);
    try {
      let stockPlaya = {
        calle: filter.calle,
        columna: filter.columna,
        nivel: filter.nivel,
      };
      const { status } = await sipcoAxiosService.post(
        "/llegada-buque-camion/llegada-buque-camion-br",
        {
          url: "",
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: {
            id: securityFilters.securityValues.subaccount.id,
            subcuentaDetalle: securityFilters.subAccountDetails,
          },
          items: selectedGrid,
          importado: {
            ciudad: filter.ciudad,
            tipoTransporte: filter.tipoTransporte,
            ubicacionTrasaldo: stockPlaya,
            hayTerrestres: filter.hayTerrestres,
            proveedorGancho: filter.ganchos,
            proveedorDanios: filter.danios,
            terminal: filter.terminal.id,
            despachante: filter.despachante.id,
            contablePuntoRetiro: filter.puntoRetiro,
            cobraFlete: false,
            armador: filter.armador,
            transportePlaya: filter.transporte,
            transportista: filter?.transportista?.id ?? null,
          },
          stockPlayaUbicacion: stockPlaya,
          fechaRecepcion: {
            date: filter.fechaRecepcion,
            timestamp: filter.fechaRecepcion,
          },
        }
      );
      if (status === 200) {
        clean();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function handleTipoTransporteChange(value: any) {
    try {
      const transportes = await loadTransportes(value);
      setFilter({
        ...filter,
        tipoTransporte: value,
        transporte: transportes[0] ?? null,
        hayTerrestres: value.id === 1,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function validateProcesarResult(procesarResult: any) {
    try {
      if (procesarResult != 0) {
        if (procesarResult === 660) {
          confirmDialog({
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            message: (
              <LanguageProvider
                id={"660"}
                alt="Esta ultrapassando a Quantidade permitida - Deseja Continuar ?"
              />
            ),
            header: <LanguageProvider id={"16239"} alt="Atención" />,
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "reject",
            accept: () => validateProcesar(),
          });
          return;
        }
      }
      await pedirConfirmacion();
    } catch (error) {
      console.error(error);
    }
  }
  async function pedirConfirmacion() {
    try {
      if (filter.tipoTransporte.id === 2) {
        confirmDialog({
          acceptLabel: acceptLabel,
          rejectLabel: rejectLabel,
          message: mensageConfirmacion,
          header: <LanguageProvider id={"16239"} alt="Atención" />,
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "reject",
          accept: () => validarFechaRecepcion(),
        });
      } else {
        await validarFechaRecepcion();
      }
    } catch (error) {
      console.error(error);
    }
  }
  function displayNoDataToast() {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: <LanguageProvider id="39" alt="Há dados em branco." />,
      life: 3000,
    });
  }
  function clean() {
    try {
      setFilter({
        ...filter,
        ganchos: null,
        armador: null,
        cobraFlete: false,
        transporte: null,
        chofer: null,
        batea: null,
        tractor: null,
        transportista: null,
        puntoRetiro: null,
        danios: null,
        despachante: null,
        terminal: null,
        ciudad: null,
        fechaRecepcion: new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const [
          calles,
          ciudades,
          tiposTransporte,
          marcas,
          terminais,
          despachentes,
          danios,
          puntosRetiro,
          transportistas,
          armadores,
          ganchos,
        ] = await Promise.all([
          loadCalle(),
          loadCiudad(),
          loadTipTransporte(),
          loadMarcas(),
          loadTerminal(),
          loadDespachantes(),
          loadDanios(),
          loadPuntosRetiro(),
          loadTransportistas(),
          loadArmadores(),
          loadGanchos(),
        ]);

        const [columnas, tractores, transporte, modelos, choferes] =
          await Promise.all([
            loadColumnas(calles[0] ?? null),
            loadTractores(transportistas[0] ?? null),
            loadTransportes(tiposTransporte[0] ?? null),
            loadModelos(marcas[0] ?? null),
            loadChoferes(transportistas[0] ?? null),
          ]);

        const [niveles, bateas] = await Promise.all([
          loadNiveles(calles[0] ?? null, columnas[0] ?? null),
          loadBateas(transportistas[0] ?? null, tractores[0] ?? null),
        ]);

        setFilter({
          vin: null,
          calle: calles[0],
          columna: columnas?.[0] || null,
          nivel: niveles?.[0] || null,
          tipoTransporte: tiposTransporte[0],
          marca: null,
          modelo: null,
          ciudad: null,
          terminal: null,
          despachante: null,
          danios: null,
          puntoRetiro: null,
          transportista: null,
          tractor: null,
          batea: null,
          chofer: null,
          fechaRecepcion: new Date(),
          armador: armadores[0] ?? null,
          transporte: transporte[0] ?? null,
          ganchos: ganchos[0] ?? null,
          hayTerrestres: tiposTransporte[0] === 1,
          cobraFlete: false,
        });
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const mensageConfirmacion = `${LanguageProvider({ id: "5860", alt: "Está gerando-se a Chegada do Navio:" })} ${filter?.transporte?.descripcion} ${LanguageProvider({ id: "5861", alt: "com a Data :" })} ${new Date(filter?.fechaRecepcion).toLocaleDateString()} ${new Date(filter?.fechaRecepcion).toLocaleTimeString()}. ${LanguageProvider({ id: "4300", alt: "Quantidade de VIN" })}: ${selectedGrid.length}`;
  const acceptLabel = LanguageProvider({ id: "10043", alt: "Sim" });
  const rejectLabel = LanguageProvider({ id: "10044", alt: "Não" });

  return (
    <div className="llegada-buque-camion-br">
      <Filter
        onSearch={loadGrid}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"0"} alt="Calle" />
                </label>
                <SipcoDropdown
                  value={filter.calle}
                  options={calles}
                  onChange={(e) => {
                    setFilter({ ...filter, calle: e.value });
                  }}
                  loading={loadingCalles}
                  filter
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"0"} alt="Columna" />
                </label>
                <SipcoDropdown
                  value={filter.columna}
                  options={columnas}
                  onChange={(e) => {
                    setFilter({ ...filter, columna: e.value });
                  }}
                  loading={loadingColumnas}
                  filter
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"992"} alt="Niveles" />
                </label>
                <SipcoDropdown
                  value={filter.nivel}
                  options={niveles}
                  onChange={(e) => {
                    setFilter({ ...filter, nivel: e.value });
                  }}
                  loading={loadingNiveles}
                  filter
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"493"} alt="Tipo Transporte" />
                </label>
                <SipcoDropdown
                  value={filter.tipoTransporte}
                  options={tiposTransporte}
                  onChange={(e) => handleTipoTransporteChange(e.value)}
                  loading={loadingTiposTransporte}
                  optionLabel="descripcion"
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"1052"} alt="Chegada de Navio ou Caminhão" />
        }
      >
        <div className="fieldset-content">
          <div className="form" style={{ marginBottom: "1rem" }}>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"662"} alt="Ingresse o VIN" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={vin}
                  suggestions={vinSuggestions}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) => setVin(e.value)}
                  field="id"
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <SipcoDropdown
                  value={filter.marca}
                  options={marcas}
                  onChange={(e) => {
                    setFilter({ ...filter, marca: e.value });
                  }}
                  loading={loadingMarcas}
                  optionLabel="descripcion"
                  filter
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"408"} alt="Modelos" />
                </label>
                <SipcoDropdown
                  value={filter.modelo}
                  options={modelos}
                  onChange={(e) => {
                    setFilter({ ...filter, modelo: e.value });
                  }}
                  loading={loadingModelos}
                  optionLabel="descripcion"
                  filter
                  showClear
                />
              </div>
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div className="header-data-table">
                <div className="chips">
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Selecionados"
                      />
                    </label>
                    <Chip label={selectedGrid?.length.toString() ?? "0"} />
                  </div>
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider id={"TODO"} alt="Total de Registros" />
                    </label>
                    <Chip label={grid?.length.toString() ?? "0"} />
                  </div>
                </div>
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={grid}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingGrid}
            selectionMode={true ? null : "checkbox"}
            selection={selectedGrid}
            onSelectionChange={(e) => setSelectedGrid(e.value)}
          >
            <SipcoColumn
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            />
            <SipcoColumn
              key={0}
              field="vin.id"
              header={columnsNames1[0].label}
              sortable
              filter
            />
            <SipcoColumn
              key={1}
              field="transporteInformado"
              header={columnsNames1[1].label}
              sortable
              filter
            />
            <SipcoColumn
              key={2}
              field="vin.marca.descripcion"
              header={columnsNames1[2].label}
              sortable
              filter
            />
            <SipcoColumn
              key={3}
              field="vin.modelo.descripcion"
              header={columnsNames1[3].label}
              sortable
              filter
            />
            <SipcoColumn
              key={4}
              field="vin.color.descripcion"
              header={columnsNames1[4].label}
              sortable
              filter
            />
            <SipcoColumn
              key={5}
              field="fechaUltimaModificacion?.date"
              header={columnsNames1[5].label}
              sortable
              filter
            />
            <SipcoColumn
              key={6}
              field="usuarioUltimaModificacion.id"
              header={columnsNames1[6].label}
              sortable
              filter
            />
            <SipcoColumn
              key={7}
              field="fechaUltimaModificacion.date"
              header={columnsNames1[7].label}
              sortable
              filter
            />
          </SipcoDataTable>
        </div>
        <Divider />
        <Accordion>
          <AccordionTab
            header={
              <LanguageProvider
                id={"884"}
                alt="entrada de Dados para a Recepção"
              />
            }
          >
            <div className="form">
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"889"} alt="Data de Recepção" />
                  </label>
                  <SipcoCalendar
                    value={filter.fechaRecepcion}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showIcon
                    showTime
                    onSelect={handleDateChange}
                  />
                </div>
                {!filter.hayTerrestres ? (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"23814"} alt="Transporte" />
                    </label>
                    <SipcoDropdown
                      value={filter.transporte}
                      options={transportes}
                      onChange={(e) => {
                        setFilter({ ...filter, transporte: e.value });
                      }}
                      loading={loadingTransportes}
                      optionLabel="descripcion"
                      filter
                    />
                  </div>
                ) : (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"21565"} alt="Transportador" />
                    </label>
                    <SipcoDropdown
                      value={filter.transportista}
                      options={transportistas}
                      onChange={(e) => {
                        setFilter({ ...filter, transportista: e.value });
                      }}
                      loading={loadingTransportistas}
                      optionLabel="descripcionFull"
                      filter
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origem" />
                  </label>
                  <SipcoDropdown
                    value={filter.ciudad}
                    options={cidades}
                    onChange={(e) => {
                      setFilter({ ...filter, ciudad: e.value });
                    }}
                    loading={loadingCiudad}
                    optionLabel="descripcion"
                    filter
                  />
                </div>
                {!filter.hayTerrestres ? (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"19466"} alt="Armador" />
                    </label>
                    <SipcoDropdown
                      value={filter.armador}
                      options={armadores}
                      onChange={(e) => {
                        setFilter({ ...filter, armador: e.value });
                      }}
                      loading={loadingArmadores}
                      optionLabel="descripcionFull"
                      filter
                    />
                  </div>
                ) : (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"4285"} alt="Trator" />
                    </label>
                    <SipcoDropdown
                      value={filter.tractor}
                      options={tractores}
                      onChange={(e) => {
                        setFilter({ ...filter, tractor: e.value });
                      }}
                      loading={loadingTractores}
                      optionLabel="descripcion"
                      filter
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1119"} alt="Terminais" />
                  </label>
                  <SipcoDropdown
                    value={filter.terminal}
                    options={terminais}
                    onChange={(e) => {
                      setFilter({ ...filter, terminal: e.value });
                    }}
                    loading={loadingTerminais}
                    optionLabel="descripcionFull"
                    filter
                  />
                </div>
                {filter.hayTerrestres && (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                    </label>
                    <SipcoDropdown
                      value={filter.batea}
                      options={bateas}
                      onChange={(e) => {
                        setFilter({ ...filter, batea: e.value });
                      }}
                      loading={loadingBateas}
                      optionLabel="descripcion"
                      filter
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1118"} alt="Despachantes" />
                  </label>
                  <SipcoDropdown
                    value={filter.despachante}
                    options={despachantes}
                    onChange={(e) => {
                      setFilter({ ...filter, despachante: e.value });
                    }}
                    loading={loadingDespachantes}
                    optionLabel="descripcionFull"
                    filter
                  />
                </div>
                {filter.hayTerrestres && (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"4655"} alt="Motorista" />
                    </label>
                    <SipcoDropdown
                      value={filter.chofer}
                      options={choferes}
                      onChange={(e) => {
                        setFilter({ ...filter, chofer: e.value });
                      }}
                      loading={loadingChoferes}
                      optionLabel="nombre"
                      filter
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16844"} alt="Fornecedor danhos" />
                  </label>
                  <SipcoDropdown
                    value={filter.danios}
                    options={proveedorDanios}
                    onChange={(e) => {
                      setFilter({ ...filter, danios: e.value });
                    }}
                    loading={loadingProvedorDanios}
                    optionLabel="descripcionFull"
                    filter
                  />
                </div>
                {filter.hayTerrestres && (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider
                        id={"8811"}
                        alt="Quantidade de Unidades"
                      />
                    </label>
                    <SipcoInputText readOnly value={grid?.length.toString()} />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1373"} alt="Pontos de Retiro" />
                  </label>
                  <SipcoDropdown
                    value={filter.puntoRetiro}
                    options={puntosRetiro}
                    onChange={(e) => {
                      setFilter({ ...filter, puntoRetiro: e.value });
                    }}
                    loading={loadingPuntosRetiro}
                    optionLabel="descripcionFull"
                    filter
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <div className="actions">
          <Button
            label={LanguageProvider({
              id: "34",
              alt: "Processar ou guardar Mudanças",
            })}
            onClick={validateProcesar}
          />
          <Button
            label={LanguageProvider({
              id: "187",
              alt: "Imprimir ou gerar arquivo Excel",
            })}
          />
        </div>
      </Fieldset>
      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />
      <TreeErrorDialog
        {...treeErrorDialogProps}
        onClose={() => {
          setTreeErrorDialogProps({ ...treeErrorDialogProps, visible: false });
        }}
      />
    </div>
  );
}
export default LlegadaBuqueCamionBrForm;
