import "./ListadoArmadoViajes.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConsultaDto } from "@shared/components/excelExportFunc/interfaces/ExportPropsTypesListadoArmadoViajes";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import generateExcelListadoArmadoViajes from "@shared/components/excelExportFunc/export-excel-listado-armado-viajes";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ListadoArmadoViajes() {
  const SCREEN_CODE = "con0051_listado_armado_viajes";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const filterLabelId = [
    10527, 10528, 1617, 10774, 1151, 266, 1285, 2622, 25188, 2621, 2622, 382,
    2621, 2622, 25189, 2621, 2622,
  ];
  const filterNames = [];
  const filterColumnsId = [
    54, 0, 0, 10502, 0, 164, 3186, 10509, 10508, 10507, 10506, 492, 1491, 932,
    441, 2139, 4179, 1284, 924, 4145, 1492, 2496,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    10750, 6697, 54, 65, 164, 266, 382, 441, 467, 468, 530, 748, 749, 924, 932,
    1151, 1182, 1284, 1285, 1491, 1617, 1791, 2139, 2502, 3186, 4145, 4179,
    5044, 5583, 10502, 10506, 10507, 10508, 10509, 10527, 10528, 10531, 10774,
    16531, 16842, 1492, 2496, 492, 10494,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState<any>({
    radioButton: false,
    tipoRemito: [],
    mercado: [],
    estadoViaje: [],
    concesionarios: [],
    llegadaDesde: new Date(),
    llegadaHasta: new Date(),
    liberacion: false,
    fechaLiberacionDesde: new Date(),
    fechaLiberacioHasta: new Date(),
    despacho: false,
    fechaDespachoDesde: new Date(),
    fechaDespachoHasta: new Date(),
    cargaRetorno: false,
    fechaCargaRetornoDesde: new Date(),
    fechaCargaRetornoHasta: new Date(),
  });

  const [loading, setLoading] = useState(false);

  const [tipoRemito, setTipoRemito] = useState([]);
  const [mercado, setMercado] = useState([]);
  const [estado, setEstado] = useState([]);
  const [concesionario, setConcesionario] = useState([]);

  const [loadingTipoRemito, setLoadingTipoRemito] = useState(true);
  const [loadingMercado, setLoadingMercado] = useState(true);
  const [loadingEstado, setLoadingEstado] = useState(true);
  const [loadingConcesionario, setLoadingConcesionario] = useState(true);

  async function loadTipoRemito(query = "") {
    setLoadingTipoRemito(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/tipo-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const tipoRemito = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const tipoRemitoFiltrados = tipoRemito.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setTipoRemito(tipoRemitoFiltrados);
            return tipoRemitoFiltrados;
          } else {
            setTipoRemito(tipoRemito);
            return tipoRemito;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemito(false);
    }
    return [];
  }

  async function loadMercado(query = "") {
    setLoadingMercado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const mercado = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const mercadoFiltrados = mercado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setMercado(mercadoFiltrados);
            return mercadoFiltrados;
          } else {
            setMercado(mercado);
            return mercado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    return [];
  }

  async function loadEstado(query = "") {
    setLoadingEstado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estado = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const estadoFiltrados = estado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstado(estadoFiltrados);
            return estadoFiltrados;
          } else {
            setEstado(estado);
            return estado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
    return [];
  }

  async function loadConcesionario(query = "") {
    setLoadingConcesionario(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-armado-viajes/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const concesionario = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });

          if (query) {
            const concesionarioFiltrados = concesionario.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setConcesionario(concesionarioFiltrados);
            return concesionarioFiltrados;
          } else {
            setConcesionario(concesionario);
            return concesionario;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tipoRemitos = await loadTipoRemito();
          const mercados = await loadMercado();
          const estados = await loadEstado();
          const concesionarios = await loadConcesionario();

          setFilter({
            ...filter,
            tipoRemito: tipoRemitos,
            mercado: mercados,
            estadoViaje: estados,
            concesionarios: concesionarios,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        radioButton: filter.radioButton
          ? "Días totales a vencer para despacho"
          : "Días totales a vencer para entregas",
        tipoRemito: filter?.tipoRemito?.map((x) => x.id) || [],
        mercado: filter?.mercado?.map((x) => x.id) || [],
        estadoViaje: filter?.estadoViaje?.map((x) => x.id) || [],
        concesionarios: filter?.concesionarios?.map((x) => x.id) || [],
        rangeFecha: [filter?.llegadaDesde, filter?.llegadaHasta],
        liberacionBoolean: filter.liberacion ? true : false,
        despachoBoolean: filter.despacho ? true : false,
        cargaRetornoBoolean: filter.cargaRetorno ? true : false,
        fechaLiberacion: [
          filter?.fechaLiberacionDesde,
          filter?.fechaLiberacioHasta,
        ],
        fechaDespacho: [filter?.fechaDespachoDesde, filter?.fechaDespachoHasta],
        fechaCargaRetorno: [
          filter?.fechaCargaRetornoDesde,
          filter?.fechaCargaRetornoHasta,
        ],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/listado-armado-viajes/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
        setFilterExport();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function getFilterValue(
    filterArray: any[],
    comparisonArray: any[],
    defaultValue: string
  ) {
    if (filterArray.length === comparisonArray.length) {
      return "TODOS";
    } else if (filterArray.length === 1) {
      return filterArray[0].label;
    } else {
      return defaultValue || `${filterArray.length}`;
    }
  }

  const [paramsGenericXlsx, setParamsGenericXlsx] = useState([]);
  const [paramsListadoXlsx, setParamsListadoXlsx] = useState<ConsultaDto>(null);
  function setFilterExport() {
    const resultTipoRemito = getFilterValue(filter.tipoRemito, tipoRemito, "");
    const resultMercado = getFilterValue(filter.mercado, mercado, "");
    const resultEstadoViaje = getFilterValue(filter.estadoViaje, estado, "");

    let resultConcesionarios = "";
    if (!clientIsTodos) {
      resultConcesionarios = getFilterValue(
        filter.concesionarios,
        concesionario,
        ""
      );
    }

    const filtersGenericXlsx = [
      { 1499: securityFilters.securityValues.country?.descripcion },
      { 488: securityFilters.securityValues.client?.descripcion },
      { 1791: securityFilters.securityValues.account?.descripcion },
      { 4791: securityFilters.securityValues.subaccount?.descripcion },
      { 10527: filter.radioButton ? "N" : "S" }, //Días totales a vencer para entregas
      { 10528: filter.radioButton ? "S" : "N" }, //Días totales a vencer para despacho
      { 1617: resultTipoRemito }, //Tipo remito
      { 10774: resultMercado }, //Mercados
      { 1151: resultEstadoViaje }, //Estados
      ...(clientIsTodos ? [] : [{ 266: resultConcesionarios }]), //Concesionarios
      { 1285: "" }, //Llegada
      { 2621: filter.llegadaDesde }, //Llegada Desde
      { 2622: filter.llegadaHasta }, //Llegada Hasta
      { 25188: filter.liberacion ? "S" : "N" }, //Liberacion
      ...(filter.liberacion
        ? [
            { 2621: filter.fechaLiberacionDesde }, // Liberacion Desde
            { 2622: filter.fechaLiberacioHasta }, // Liberacion Hasta
          ]
        : []),

      { 382: filter.despacho ? "S" : "N" }, //Despacho
      ...(filter.despacho
        ? [
            { 2621: filter.fechaDespachoDesde }, // Despacho Desde
            { 2622: filter.fechaDespachoHasta }, // Despacho Hasta
          ]
        : []),
      { 25189: filter.cargaRetorno ? "S" : "N" }, // Carga Retorno
      ...(filter.cargaRetorno
        ? [
            { 2621: filter.fechaCargaRetornoDesde }, // Carga Retorno Desde
            { 2622: filter.fechaCargaRetornoHasta }, // Carga Retorno Hasta
          ]
        : []),
    ];
    setParamsGenericXlsx(filtersGenericXlsx);

    const filtersListadoXlsx = {
      country: {
        label: 1499,
        value: securityFilters.securityValues.country?.descripcion,
      },
      client: {
        label: 488,
        value: securityFilters.securityValues.client?.descripcion,
      },
      account: {
        label: 1791,
        value: securityFilters.securityValues.account?.descripcion,
      },
      subaccount: {
        label: 4791,
        value: securityFilters.securityValues.subaccount?.descripcion,
      },
      radioButton: { label: 10527, value: filter.radioButton }, //Días totales a vencer para entregas
      tipoRemito: { label: 1617, value: resultTipoRemito }, //Tipo remito
      mercado: { label: 10774, value: resultMercado }, //Mercados
      estados: { label: 1151, value: resultEstadoViaje }, //Estados
      concesionarios: { label: 266, value: resultConcesionarios || null }, //Concesionarios
      llegada: { label: 1285, value: "" }, //Llegada
      llegadaDesde: { label: 2621, value: filter.llegadaDesde }, //Llegada Desde
      llegadaHasta: { label: 2622, value: filter.llegadaHasta }, //Llegada Hasta
      liberacion: { label: 25188, value: filter.liberacion }, //Liberacion
      fechaLiberacionDesde: { label: 2621, value: filter.fechaLiberacionDesde }, // Liberacion Desde
      fechaLiberacioHasta: { label: 2622, value: filter.fechaLiberacioHasta }, // Liberacion Hasta
      despacho: { label: 382, value: filter.despacho }, //Despacho
      fechaDespachoDesde: { label: 2621, value: filter.fechaDespachoDesde }, // Despacho Desde
      fechaDespachoHasta: { label: 2622, value: filter.fechaDespachoHasta }, // Despacho Hasta
      cargaRetorno: { label: 25189, value: filter.cargaRetorno }, // Carga Retorno
      fechaCargaRetornoDesde: {
        label: 2621,
        value: filter.fechaCargaRetornoDesde,
      }, // Carga Retorno Desde
      fechaCargaRetornoHasta: {
        label: 2622,
        value: filter.fechaCargaRetornoHasta,
      }, // Carga Retorno Hasta
    };
    setParamsListadoXlsx(filtersListadoXlsx);
    console.log(filtersListadoXlsx);
  }

  async function listadoXlsx() {
    const dataResponse = {
      items: data,
      consult: paramsListadoXlsx as ConsultaDto,
    };
    const exportXlsx = await generateExcelListadoArmadoViajes({
      user,
      dataResponse,
    });
    if (!exportXlsx) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="gen.error.excel.message"
            alt="Error al generar archivo Excel"
          />
        ),
        life: 3000,
      });
    }
  }

  const cleanData = (data) => {
    return data.map((childArray) => {
      // Remove os elementos nas posições 1, 2, 4 e 22.
      return childArray.filter((_, index) => ![1, 2, 4, 22].includes(index));
    });
  };
  const removeZeroes = (array) => {
    return array.filter((value) => value.id !== 0);
  };

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";

  return (
    <div className="listado-armado-viajes">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={loadReportData}
        // onSearch={setFilterExport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={!filter.radioButton}
                  onChange={(e) => {
                    setFilter({ ...filter, radioButton: false });
                    setReporte([]);
                  }}
                />
                <label>
                  <LanguageProvider id={filterNames[0].label} />
                </label>
                <RadioButton
                  checked={filter.radioButton}
                  onChange={(e) => {
                    setFilter({ ...filter, radioButton: true });
                    setReporte([]);
                  }}
                />
                <label>
                  <LanguageProvider id={filterNames[1].label} />
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[2].label}
                    alt="Tipo remito"
                  />
                </label>
                <SipcoMultiSelect
                  value={filter.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoRemito: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingTipoRemito}
                  showClear
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Mercados" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercado}
                  options={mercado}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercado}
                  showClear
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[4].label} alt="Estados" />
                </label>
                <SipcoMultiSelect
                  value={filter.estadoViaje}
                  options={estado}
                  onChange={(e) => {
                    setFilter({ ...filter, estadoViaje: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingEstado}
                  showClear
                />
              </div>

              {!clientIsTodos && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider
                      id={filterNames[5].label}
                      alt="Concesionarios"
                    />
                  </label>
                  <SipcoMultiSelect
                    value={filter.concesionarios}
                    options={concesionario}
                    onChange={(e) => {
                      setFilter({ ...filter, concesionarios: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingConcesionario}
                    showClear
                  />
                </div>
              )}
            </div>

            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[6].label}
                    alt="Llegada Desde"
                  />
                </label>
                <SipcoCalendar
                  value={filter.llegadaDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, llegadaDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[7].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.llegadaHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, llegadaHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>

            {/* Liberacion Desde */}
            <div className="form-row">
              <div className="CheckboxButtonFilter">
                <Checkbox
                  checked={filter.liberacion}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      liberacion: !filter.liberacion,
                    });
                    setReporte([]);
                  }}
                />
                <label>
                  <LanguageProvider id={filterNames[8].label} />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[9].label} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaLiberacionDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaLiberacionDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.liberacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[10].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaLiberacioHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaLiberacioHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.liberacion}
                />
              </div>
            </div>

            {/* Despacho Desde */}
            <div className="form-row">
              <div className="CheckboxButtonFilter">
                <Checkbox
                  checked={filter.despacho}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      despacho: !filter.despacho,
                    });
                    setReporte([]);
                  }}
                />
                <label>
                  <LanguageProvider id={filterNames[11].label} />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[12].label} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDespachoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDespachoDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.despacho}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[13].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDespachoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDespachoHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.despacho}
                />
              </div>
            </div>

            {/* Carga Retorno Desde */}
            <div className="form-row">
              <div className="CheckboxButtonFilter">
                <Checkbox
                  checked={filter.cargaRetorno}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      cargaRetorno: !filter.cargaRetorno,
                    });
                    setReporte([]);
                  }}
                />
                <label>
                  <LanguageProvider id={filterNames[14].label} />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[15].label} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCargaRetornoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaCargaRetornoDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.cargaRetorno}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[16].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCargaRetornoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaCargaRetornoHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.cargaRetorno}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[43].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  //função para remover posições do array no qual não são exibidas no relatório
                  data={cleanData(data)}
                  //função para remover ids 0 do array
                  columns={removeZeroes(columnsNames)}
                  screenName={"Listado de Armado de Viajes"}
                  pdf={false}
                  csv={false}
                  filter={true}
                  filterProps={paramsGenericXlsx}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={() => {
          return (
            <div className="buttons">
              <Button
                onClick={() => listadoXlsx()}
                label={LanguageProvider({
                  id: "10750",
                  alt: "Informe",
                })}
                text
              />
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          {/* <SipcoColumn field="1" header={columnsNames[1].label} sortable filter /> */}
          {/* <SipcoColumn field="2" header={columnsNames[2].label} sortable filter /> */}
          <SipcoColumn field="3" header={columnsNames[3].label} />
          {/* <SipcoColumn field="4" header={columnsNames[4].label} sortable filter /> */}
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ListadoArmadoViajes;
