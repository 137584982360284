import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "0",
  descripcion: "TODOS",
  descripcionFull: "TODOS",
  activo: true,
  search: "TODOS*",
};

export function StockTaller() {
  const SCREEN_CODE = "con0042_stock_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [327, 2183];
  const filterNames = [];
  const filterColumnsId = [16476, 10829, 31, 2183, 1253, 2265, 2890];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 327, 2183, 16476, 10829, 31, 1253, 2183,
    2265, 2890, 1002, 2911,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    rubro: TODOS_OBJ,
  });

  const [loading, setLoading] = useState(false);

  const [loadingCodTaller, setLoadingCodTaller] = useState(true);
  const [codTaller, setCodTaller] = useState([]);
  const [loadingCodRubro, setLoadingCodRubro] = useState(true);
  const [codRubro, setCodRubro] = useState([]);

  async function loadTalleres() {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/taller-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters?.securityValues?.account?.id,
              codSubcuenta: securityFilters?.securityValues?.subaccount?.id,
            },
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedElements = data.map((x) => {
              return {
                ...x,
                search: x.descripcion + x.id,
                descripcionFull: `${x.descripcion} - (${x.id})`,
              };
            });
            setCodTaller(updatedElements);
            return updatedElements;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadRubros() {
    setLoadingCodRubro(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/report/rubro-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          if (data.length > 0) {
            const updatedElements = data.map((x) => {
              return {
                ...x,
                search: x.descripcion + x.id,
                descripcionFull: `${x.descripcion} - (${x.id})`,
              };
            });
            updatedElements.unshift(TODOS_OBJ);
            setCodRubro(updatedElements);
            return updatedElements;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCodRubro(false);
    }
    return [];
  }
  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        pais: securityFilters?.securityValues?.country ?? null,
        clienteObj: securityFilters?.securityValues?.client ?? null,
        cuenta: securityFilters?.securityValues?.account ?? null,
        subcuenta: securityFilters?.securityValues?.subaccount ?? null,
        codTaller: filter?.taller?.id ?? null,
        codRubro: filter?.rubro.id ?? null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/stock-taller",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          setLoadingCodTaller(true);
          setLoadingCodRubro(true);
          const tallers = await loadTalleres();
          const rublos = await loadRubros();
          setFilter({
            ...filter,
            taller: tallers[0],
            rubro: rublos[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingCodTaller(false);
          setLoadingCodRubro(false);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: false,
          allSubaccountsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Taller" />
              </label>
              <SipcoDropdown
                value={filter.taller}
                options={codTaller}
                onChange={(e) => setFilter({ ...filter, taller: e.value })}
                optionLabel="descripcionFull"
                filter
                filterBy="search"
                loading={loadingCodTaller}
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Rubro" />
              </label>
              <SipcoDropdown
                value={filter.rubro}
                options={codRubro}
                onChange={(e) => setFilter({ ...filter, rubro: e.value })}
                optionLabel="descripcionFull"
                itemTemplate={rubloOptionTemplate}
                valueTemplate={rubloOptionTemplate}
                filter
                filterBy="search"
                loading={loadingCodRubro}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[16].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock en Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn
            field="5"
            header={columnsNames[5].label}
            sortable
            filter
            dataType="numeric"
            body={formatNumber}
          />
          <SipcoColumn
            field="6"
            header={columnsNames[6].label}
            sortable
            filter
            dataType="numeric"
            body={formatNumber}
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default StockTaller;

function rubloOptionTemplate(option: any, props = null) {
  if (option) {
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        <div>{`${option.descripcion} (${option.id})`}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
}

const formatNumber = (value: any, props: any) => {
  return new Intl.NumberFormat().format(value[props.field]);
};
