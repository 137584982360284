import "./ListadoColectas.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ListadoColectas() {
  const SCREEN_CODE = "con0047_listado_colectas";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [16453, 11787, 1491, 3344, 11370, 5686];
  const filterNames = [];

  const filterColumnsId = [
    10758, 5686, 2941, 3069, 164, 159, 1491, 396, 2139, 2140, 2898, 1316, 10747,
    4675, 4791, 1269, 2521, 2521, 10766, 10774, 21275, 1736, 990, 991, 5701,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    5239, 3754, 10758, 5686, 2941, 3069, 164, 159, 1491, 396, 2139, 2140, 2898,
    1316, 10747, 4675, 4791, 1269, 2521, 10766, 10774, 11247, 748, 749, 4791,
    3344, 10764, 11787, 16453, 1, 2, 32, 54, 65, 396, 408, 467, 468, 491, 748,
    749, 1182, 1357, 1396, 1491, 1551, 1552, 1791, 2496, 2502, 2941, 4655, 4870,
    4871, 5044, 5583, 6590, 7811, 10021, 10531, 10540, 10774, 11070, 11078,
    11097, 11314, 14712, 16243, 16246, 16505, 16531, 16544, 16576, 16808, 16809,
    21275, 18588, 1736, 990, 991, 5701, 188, 16239, 10043, 10044, 11370,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaHojaRutaDesde: new Date(),
    fehcaHojaRutaHasta: new Date(),
    provedorTractor: null,
    vin: "",
    estado: null,
    colectasDesde: "",
    colectasHasta: "",
    orden: null,
  });

  const [provedoresTractor, setProvedoresTractor] = useState([]);
  const [loadingProvedoresTractor, setLoadingProvedoresTractor] =
    useState(false);
  async function loadProvedoresTractor() {
    setLoadingProvedoresTractor(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-colectas/combo/proveedor",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setProvedoresTractor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvedoresTractor(false);
    }
    return [];
  }
  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-colectas/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
    return [];
  }
  const [ordenes, setOrdenes] = useState([]);
  const [loadingOrdenes, setLoadingOrdenes] = useState(false);
  async function loadOrdenes() {
    setLoadingOrdenes(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/listado-colectas/combo/orden",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setOrdenes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrdenes(false);
    }
    return [];
  }

  return (
    <div className="listado-colectas">
      <Filter
        onSearch={() => console.log("TODO")}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16453" alt="Fecha Hoja de Ruta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHojaRutaDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHojaRutaDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16453" alt="Fecha Hoja de Ruta" />
                </label>
                <SipcoCalendar
                  value={filter.fehcaHojaRutaHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fehcaHojaRutaHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className={"sipco-option"}>
                <label>Proveedor Tractor</label>
                <SipcoAutocomplete dropdown />
                <div className="form-row">
                  <label>colecta</label>
                  <SipcoInputText style={{ width: "50px" }} />
                  <label>até</label>
                  <SipcoInputText style={{ width: "50px" }} />
                </div>
              </div>

              <div className={"sipco-option"}>
                <label>VIN</label>
                <SipcoInputText />
              </div>
              <div className={"sipco-option"}>
                <label>Estado</label>
                <SipcoAutocomplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Listado de Colectas para Transportadora
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
          <SipcoColumn field="13" header={columnsNames[13].label} />
          <SipcoColumn field="14" header={columnsNames[14].label} />
          <SipcoColumn field="15" header={columnsNames[15].label} />
          <SipcoColumn field="16" header={columnsNames[16].label} />
          <SipcoColumn field="17" header={columnsNames[17].label} />
          <SipcoColumn field="18" header={columnsNames[18].label} />
          <SipcoColumn field="19" header={columnsNames[19].label} />
          <SipcoColumn field="20" header={columnsNames[20].label} />
          <SipcoColumn field="21" header={columnsNames[21].label} />
          <SipcoColumn field="22" header={columnsNames[22].label} />
          <SipcoColumn field="23" header={columnsNames[23].label} />
          <SipcoColumn field="24" header={columnsNames[24].label} />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ListadoColectas;
