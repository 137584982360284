import React, { useRef } from "react";

import ExportButtons from "@shared/components/Export";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";

const Operations: React.FC<{ vinData: any }> = ({ vinData }) => {
  const dt = useRef(null);
  const data = convertDateObjects(vinData?.reporteOperaciones.filas);

  const filterColumnsId = [
    54, 13324, 16580, 31, 3344, 5788, 16416, 32, 31, 13706, 5271, 1669, 15739,
    18604, 15596,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const rowClassName = (data) => {
    let className = "";
    if (data[14] != null) {
      className = "color-operaciones-pendientes-bloqueantes";
    } else if (data[13] != null) {
      if (data[13].toString() === "1") {
        className = "color-operaciones-pendientes";
      }
    }

    return className;
  };

  return (
    <div className="Operations">
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Operations"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowClassName}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
          <SipcoColumn field="11" header={columnsNames[11].label} />
          <SipcoColumn field="12" header={columnsNames[12].label} />
        </SipcoDataTable>
        <div className="tags">
          <Tag
            value={columnsNames[13].label}
            style={{ background: "#ff0000", marginRight: "5px" }}
          />
          <Tag
            value={columnsNames[14].label}
            style={{ background: "#0000ff", marginRight: "5px" }}
          />
        </div>
      </Panel>
    </div>
  );
};
export default Operations;
