import { useEffect, useMemo } from "react";

/**
 * useRowStyles
 *
 * Este hook aplica dinamicamente uma classe de estilo CSS para destacar uma linha selecionada em um ou mais `DataTable`s.
 * Ele injeta o estilo diretamente no DOM e define uma função para aplicar a classe de estilo
 * `highlight-row` à linha do `DataTable` que corresponde ao item selecionado.
 * Agora, ele pode lidar com até dois conjuntos de dados para os `DataTable`s, permitindo destacar linhas
 * de múltiplos conjuntos de dados simultaneamente.
 *
 * @param {Object[]} selectedRows - Array de objetos que representam as linhas selecionadas de cada `DataTable`.
 *                                 Pode ser um ou dois arrays, correspondendo a dois conjuntos de dados (ex: `selectedReportData` e `selectedChildReportData`).
 *
 * @returns {Function} - Uma função que recebe uma linha `data` e retorna a classe `highlight-row` se a linha
 * corresponder a qualquer uma das linhas selecionadas.
 *
 * @example
 * // No componente
 * const rowStyles = useRowStyles([selectedReportData, selectedChildReportData]);
 *
 * <SipcoDataTable
 *   value={formattedReportData}
 *   rowClassName={rowStyles}
 *   selectionMode="single"
 *   onSelectionChange={(e) => setSelectedReportData(e.value)}
 *   selection={selectedReportData}
 * />
 *
 * // O estilo da linha será aplicado automaticamente à linha selecionada no `DataTable`.
 */
const useRowStyles = (selectedRows) => {
  // Insere o estilo CSS diretamente no DOM ao montar o componente
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .highlight-row {
        background-color: #ecfeff;
        cursor: pointer;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Define a função para aplicar a classe de estilo condicionalmente
  return useMemo(
    () => (data) => {
      // Verifica se qualquer um dos selectedRows corresponde à linha de dados atual
      return selectedRows.some(
        (selectedRow) => JSON.stringify(selectedRow) === JSON.stringify(data)
      )
        ? "highlight-row"
        : "";
    },
    [selectedRows]
  );
};

export default useRowStyles;
