import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DespachoMercadoExportacionForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [
    6781, 13358, 932, 3344, 1284, 1491, 396, 2139, 2140, 2496, 13335, 16923,
    2941, 4216, 3591, 8299, 159, 3186, 9387, 15538, 3359, 2724,
  ];

  const columnsNames1 = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  const loadReportData = () => {
    console.log("crick");
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="filter-options">
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"14535"} alt="Destino" />
                  </label>
                  <SipcoInputText />
                </div>
              </div>
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"266"} alt="Concessionários" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider
                      id={"13035"}
                      alt="Direção final da entrega"
                    />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16962"} alt="Tipo de Transporte" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"662"} alt="Acesse o VIN" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"310"} alt="Despacho Internacional" />}
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider
                    id={"1500"}
                    alt="Trabalhar com Leitora Cod.Barras"
                  />
                </label>
                <Checkbox checked={false} />
              </div>
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsIds1}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames1.map((colum, index) => (
              <SipcoColumn
                key={index}
                field={colum.id}
                header={colum.label}
                sortable
                filter
              />
            ))}
          </SipcoDataTable>
        </div>
        <Divider />
        <Accordion>
          <AccordionTab header={<LanguageProvider id={"845"} alt="Despacho" />}>
            <div className="filter-options-column">
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1342"} alt="Fecha Despacho" />
                  </label>
                  <SipcoCalendar showTime showIcon showButtonBar />
                </div>
                <div className={"sipco-chip"}>
                  <label>
                    <LanguageProvider id={"864"} alt="Registros Selecionados" />
                  </label>
                  <Chip label="0" />
                </div>
                <div className={"sipco-chip"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Total de Registros" />
                  </label>
                  <Chip label="0" />
                </div>
              </div>
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1148"} alt="Instalación de Radios" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16963"} alt="Prov. Danhos" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"13036"} alt="Destino siguiente" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
            </div>
            <div className="filter-options">
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"2250"} alt="Despacho Cliente" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1118"} alt="Despachantes" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider
                      id={"1306"}
                      alt="Despacho Transportista"
                    />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option-text-small">
                  <label>
                    <LanguageProvider id={"1105"} alt="Unidades por batea" />
                  </label>
                  <SipcoInputText />
                </div>
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
          >
            <div className="filter-options-column">
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"754"} alt="Transportista" />
                  </label>
                  <SipcoCalendar showTime showIcon showButtonBar />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4285"} alt="Tractor" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
              </div>
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4655"} alt="Chofer" />
                  </label>
                  <SipcoAutocomplete dropdown />
                </div>
                <div className="sipco-option-text">
                  <label>
                    <LanguageProvider id={"2561"} alt="Cant Unid Totales" />
                  </label>
                  <SipcoInputText />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>

        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default DespachoMercadoExportacionForm;
