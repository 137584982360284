import { Column, ColumnProps } from "primereact/column";

import React from "react";

type SipcoColumnProps = ColumnProps & {};

const SipcoColumn: React.FC<SipcoColumnProps> = ({ ...props }) => {
  return (
    <Column {...props} className="sipco-column" sortable={true} filter={true} />
  );
};

export default SipcoColumn;
