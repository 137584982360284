import "./scss/login.scss";

import { FormikErrors, useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import CevaLogo from "../../../themes/media/backgrounds/SSOLogin.png";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { ThemeContext } from "../../../App";
import { Toast } from "primereact/toast";
import settings from "../../../settings.json";
import useAuth from "@shared/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSipcoAxiosService } from "../../../services/axios/sipco-axios-service";


const Login = () => {
  const sipcoAxiosService = useSipcoAxiosService();
  const environment = settings.Environment;
  const passwordRecoveryUrl =
    settings.ConnectionStrings[environment].PasswordRecovery;

  const toast = useRef<Toast>(null);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { changeCurrentTheme } = useContext(ThemeContext);
  const [authenticating, setAuthenticating] = useState(false);

  const invalidCountryErrorMessage = LanguageProvider({
    id: "",
    alt: "Páis é obrigatório",
  });
  const invalidUserNameErrorMessage = LanguageProvider({
    id: 24600,
    alt: "Usuário é obrigatório",
  });
  const invalidPasswordErrorMessage = LanguageProvider({
    id: 16934,
    alt: "Senha é obrigatório",
  });

  const countryOptions = [
    { label: "ARGENTINA", value: "ARGENTINA" },
    { label: "BRAZIL", value: "BRAZIL" },
    { label: "CHILE", value: "CHILE" },
    { label: "MÉXICO", value: "MEXICO" },
  ];

  const loginFormik = useFormik<FormValues>({
    initialValues: {
      country: countryOptions[0].value,
      username: "",
      password: "",
    },
    validate: (data) => {
      let errors: FormikErrors<FormValues> = {};
      if (!data.username) {
        errors.username = invalidUserNameErrorMessage;
      }
      if (!data.password) {
        errors.password = invalidPasswordErrorMessage;
      }
      if (!data.country) {
        errors.country = invalidCountryErrorMessage;
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      setAuthenticating(true);
      try {
        const { status, data } = await sipcoAxiosService.login(values);
        if (status === 200) {
          login(data);
          navigate("/home");
        } else {
          toast.current?.show({
            severity: "error",
            // ToDo: Lucas Silvestre - DuettSoftware 18/07/2024 - erro no LanguageProvider.
            // summary: LanguageProvider({
            //   id: "22964",
            //   alt: "No se pudo lograr la conexión con el servidor",
            // }),
            summary: "Error",
            detail: data,
            life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAuthenticating(false);
      }
    },
  });

  useEffect(() => {
    changeCurrentTheme("lara-light");
  }, []);

  function isColor() {
    if (environment.toLowerCase() === "dev") {
      return settings.STAGE;
    } else if (environment.toLowerCase() === "prod") {
      return settings.PROD;
    }
  }
  const color = isColor();
  

  return (
    <div className="login" style={{ backgroundColor: color }}>
      <div className="login-container-wrapper">
        <Toast ref={toast} position="top-center" />
        <div className="login-background-img">
          <img className="ceva-img" src={CevaLogo} alt="CEVA Logo" />
        </div>
        <div className="login-form">
          <div className="form-wrapper">
            <div className="form-body">
              <div className="login-header">
                <span className="login-title">
                  <LanguageProvider id="15445" alt={"SIPCO SIGN IN"} />
                </span>
              </div>
              <div className="form">
                <div className="form-row">
                  <label className="form-input">
                    <LanguageProvider id="154" alt="Pais: " />
                  </label>
                  <SipcoDropdown
                    name="country"
                    value={loginFormik.values.country}
                    options={countryOptions}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      loginFormik.setFieldValue("country", e.value);
                    }}
                    placeholder={"Select a Country"}
                    className="form-drop-down"
                  />
                  {loginFormik.errors.country && loginFormik.touched.country ? (
                    <small className="p-error">
                      {loginFormik.errors.country}
                    </small>
                  ) : null}
                </div>
                <div className="form-row">
                  <label className="form-input">
                    <LanguageProvider id="1" alt="Usuário: " />
                  </label>{" "}
                  <SipcoInputText
                    name="username"
                    onChange={(e) => {
                      loginFormik.setFieldValue("username", e.target.value);
                    }}
                  />
                  {loginFormik.touched.username &&
                  loginFormik.errors.username ? (
                    <small className="p-error">
                      {loginFormik.errors.username}
                    </small>
                  ) : (
                    <small className="p-error">&nbsp;</small>
                  )}
                </div>
                <div className="form-row">
                  <label className="form-input">
                    <LanguageProvider id="2" alt="Senha: " />
                  </label>{" "}
                  <SipcoInputText
                    type="password"
                    name="password"
                    onChange={(e) => {
                      loginFormik.setFieldValue("password", e.target.value);
                    }}
                  />
                  {loginFormik.touched.password &&
                  loginFormik.errors.password ? (
                    <small className="p-error">
                      {loginFormik.errors.password}
                    </small>
                  ) : (
                    <small className="p-error">&nbsp;</small>
                  )}
                </div>
                <div className="form-row">
                  <div className="access-wrapper-forgot-wrapper">
                    <a
                      className="password-recovery"
                      href={passwordRecoveryUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LanguageProvider id="213" alt="Recupear Senha" />
                    </a>
                  </div>
                </div>
                <div className="body-actions">
                  <Button
                    className="sign-in-button"
                    label="SIGN IN"
                    type="submit"
                    loading={authenticating}
                    iconPos="right"
                    icon="pi pi-sign-in"
                    size="large"
                    onClick={!authenticating && loginFormik.submitForm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="footer">
          <p className="row">
            Copyright © 2022 CEVA Logistics. All rights reserved.
            <a
              className="terms-conditions-link"
              href="https://www.cevalogistics.com/terms-and-conditions"
            >
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

interface FormValues {
  country: string;
  username: string;
  password: string;
}

export default Login;
