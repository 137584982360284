import "./ConsultaOperacionesCanceladasWeb.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useScreenCodeContext } from "@shared/ScrennContext";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConsultaOperacionesCanceladasWeb() {
  const SCREEN_CODE = "con0092_consulta_operaciones_canceladas_web";
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1491, 18309, 1569, 15739, 19265];
  const filterNames = [];
  const filterColumnsId = [54, 19179, 18637, 17088, 15739, 19265, 11986, 5271];
  const columnsNames = [];
  for (let i = 0; i < filterLabelId.length; i++) {
    filterNames.push({
      id: filterLabelId[i],
      label: LanguageProvider({
        id: filterLabelId[i],
        alt: "Error Filter Labels",
      }),
    });
  }
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 2140, 2892, 3750, 4589, 4791, 5044,
    5271, 5583, 19179, 18637, 17088, 15739, 19265, 11986, 5271, 10034, 13706,
    14288, 16501, 13514, 18305, 367, 12251, 3410, 18202, 11507, 17131, 1492,
    3344, 10871, 2941, 10868, 10904, 18306, 18307, 748, 749, 321, 18308, 18309,
    10531, 5366, 19178, 16245, 10774, 16416, 5788, 19179, 8380, 2502, 2521,
  ];
  for (let i = 0; i < ids.length; i++) {
    mensagens.push({
      id: ids[i],
      label: LanguageProvider({ id: ids[i], alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  const [vinOptions, setVinOptions] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);

  const [operacion, setOperacion] = useState([]);
  const [loadingOperaciones, setLoadingOperaciones] = useState(false);
  const [grupoOperacion, setGrupoOperacion] = useState([]);
  const [loadingGruposOperaciones, setLoadingGruposOperaciones] =
    useState(false);

  const [filter, setFilter] = useState({
    fechaAlta: false,
    fechaAltaOtInicio: null,
    fechaAltaFim: null,
    fechaCancelacion: false,
    fechaCancelacionOtInicio: null,
    fechaCancelacionFim: null,
    operacion: [],
    grupoOperacion: [],
    vin: null,
  });

  const loadGrupoOperacion = async () => {
    setLoadingGruposOperaciones(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/operaciones-canceladas-web/combo/grupo-operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setGrupoOperacion(data);
          return data;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingGruposOperaciones(false);
    }
  };

  const loadOperation = async (grupoOperacion: any) => {
    setLoadingOperaciones(true);
    try {
      if (securityFilters.securityValues.country && grupoOperacion) {
        const { status, data } = await sipcoAxiosService.get(
          "/operaciones-canceladas-web/combo/operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              idGrupoOperacion: grupoOperacion.id,
            },
          }
        );

        if (status === 200) {
          setOperacion(data);
          return data;
        } else {
          console.log("Resposta: " + data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOperaciones(false);
    }
    return [];
  };

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  const loadVin = async (vin: any) => {
    setLoadingOperaciones(true);
    try {
      if (
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.account?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/operaciones-canceladas-web/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codCliente: securityFilters.securityValues.client.id,
              vin: vin,
            },
          }
        );

        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });

          setVinOptions(vin);
          return vin;
        }
      } else {
        console.warn("Algum dos valores necessários está faltando.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOperaciones(false);
    }
  };

  const loadReport = async () => {
    setLoadingReport(true);
    try {
      const date =
        filter?.vin && typeof filter.vin === "object"
          ? { id: filter.vin.id || null }
          : null;

      const altaDates = filter.fechaAlta
        ? [filter.fechaAltaOtInicio || null, filter.fechaAltaFim || null]
        : [null, null];

      const cancelacionDates = filter.fechaCancelacion
        ? [
            filter.fechaCancelacionOtInicio || null,
            filter.fechaCancelacionFim || null,
          ]
        : [null, null];

      const { status, data } = await sipcoAxiosService.post(
        "/operaciones-canceladas-web/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || "",
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          vin: date?.id || null,
          alta: altaDates,
          cancelacion: cancelacionDates,
          operacion: filter?.operacion?.map((x) => x.id) || [],
          altaBoolean: filter.fechaAlta,
          cancelacionBoolean: filter.fechaCancelacion,
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        if (securityFilters?.securityValues?.country?.id) {
          const grupoOperacion = await loadGrupoOperacion();
          setFilter({
            ...filter,
            grupoOperacion: grupoOperacion[0],
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    initialize();
  }, [securityFilters.securityValues.country]);

  useEffect(() => {
    const handleGrupoOperacionChange = async () => {
      try {
        if (filter.grupoOperacion) {
          const operaciones = await loadOperation(filter.grupoOperacion);
          setFilter({
            ...filter,
            operacion: operaciones,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleGrupoOperacionChange();
  }, [filter.grupoOperacion]);

  return (
    <div className="consulta-operaciones-canceladas-web">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-option" style={{ width: "10%" }}>
              <label>
                <LanguageProvider
                  id={filterNames[4].label}
                  alt="Ingrese el VIN"
                />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vinOptions}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className="sipco-option">
              <label>
                <LanguageProvider
                  id={filterNames[4].label}
                  alt="Grupo Operación"
                />
              </label>
              <SipcoDropdown
                value={filter.grupoOperacion}
                options={grupoOperacion}
                onChange={(e) =>
                  setFilter({ ...filter, grupoOperacion: e.value || null })
                }
                optionLabel="descripcion"
                filter
                loading={loadingOperaciones}
              />
            </div>

            <div className="sipco-option">
              <label>
                <LanguageProvider id={filterNames[4].label} alt="Operación" />
              </label>
              <SipcoMultiSelect
                value={filter?.operacion}
                options={operacion}
                onChange={(e) =>
                  setFilter({ ...filter, operacion: e.value || [] })
                }
                display="chip"
                optionLabel="label"
                filter
                showClear
                loading={loadingOperaciones}
              />
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.fechaAlta}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        fechaAlta: true,
                        fechaAltaOtInicio: new Date(),
                        fechaAltaFim: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        fechaAlta: false,
                        fechaAltaOtInicio: null,
                        fechaAltaFim: null,
                      });
                    }
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[3].label} />
                </label>
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaAltaOtInicio}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaAltaOtInicio: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaAlta}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaAltaFim}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaAltaFim: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaAlta}
                />
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.fechaCancelacion}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        fechaCancelacion: true,
                        fechaCancelacionOtInicio: new Date(),
                        fechaCancelacionFim: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        fechaCancelacion: false,
                        fechaCancelacionOtInicio: null,
                        fechaCancelacionFim: null,
                      });
                    }
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[4].label} />
                </label>
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCancelacionOtInicio}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaCancelacionOtInicio: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaCancelacion}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCancelacionFim}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaCancelacionFim: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!filter.fechaCancelacion}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Consulta Operaciones Canceladas Web
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Operaciones Canceladas Web"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          {/*<SipcoColumn field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<SipcoColumn field="" header={columnsNames[13].label} sortable filter/>*/}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOperacionesCanceladasWeb;
