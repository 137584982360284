import "./CargaManualRemitos.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const initialStateRemito = {
  letra: "",
  puntoDeVenta: "",
  id: "",
  vin: "",
  concesionario: "",
  tipoRemito: "",
  idPedidoCliente: "",
  direccionEntrega: "",
  leyendaRemito: "",
  caiImpresion: "",
  fechaEmision: { date: new Date() },
  fechaLlegada: null as Date | null,
  fechaAnulacion: null as Date | null,
  fechaLiberacion: null as Date | null,
  razonSocial: "",
  domicilioImpresion: "",
  modeloImpresion: "",
  fechaDespacho: null as Date | null,
  fechaReingreso: null as Date | null,
  fechaAnticipoRetorno: null as Date | null,
  fechaCargaRetorno: null as Date | null,
  cuitImpresion: "",
  provinciaImpresion: "",
  localidadImpresion: "",
  fechaRetorno: null as Date | null,
  fechaVencimientoCaiImpresion: "",
  fechaControl: null as Date | null,
  fechaUltimaModificacion: null as Date | null,
  ivaImpresion: "",
  estadoRemitoId: "",
  pagaFlete: "",
  codigoPostalImpresion: "",
  // é definido como 1 de acordo com codigo antigo para novos remitos - Lucas Silvestre - Duett - 09/12/2024
  // this.remito.estadoRemito = ModelHelper.createEstadoRemito(1);
  estadoRemito: { id: "1" },
  naturalezaFiscal: null,
  valor: 0,
  comentarioImpresion: "",
  ficticio: false,
};

export function CargaManualRemitos() {
  const SCREEN_CODE = "fun0069_carga_manual_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const [remito, setRemito] = useState<any>(initialStateRemito);

  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [ingreseElVin, setIngreseElVin] = useState([]);

  const [naturalezaFiscal, setNaturalezaFiscal] = useState([]);
  const [tipoRemito, setTipoRemito] = useState([]);
  const [leyendasRemitos, setLeyendasRemitos] = useState([]);
  const [concesionario, setConcesionario] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [pagaFlete, setPagaFlete] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [borrarLoading, setBorrarLoading] = useState(false);
  const [altaLoading, setAltaLoading] = useState(false);

  const [initialConcesionario, setInitialConcesionario] = useState([]);
  const [initialDireccion, setInitialDireccion] = useState([]);
  const [initialTipoRemito, setInitialTipoRemito] = useState([]);
  const [initialLeyendasRemitos, setInitialLeyendasRemitos] = useState([]);
  const [initialPagaFlete, setInitialPagaFlete] = useState([]);

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "País, Clientes e Cuentas Obrigatorio",
        life: 3000,
      });
      return false;
    }
  }

  function newRemito() {
    if (!isNew) {
      //novo remito
      setIsNew(true);
      setIsDisabled(false);
      setRemito(initialStateRemito);
      setIsSearch(false);
    } else {
      //cancelar
      setIsNew(false);
      setIsDisabled(true);
      setIngreseElVin([]);
      setRemito(initialStateRemito);
    }
  }
  async function getTipoRemito() {
    setLoading(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        setLoading(false);
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/tipo-remito",
        params
      );
      if (status === 200) {
        // console.log("response getTipoRemito", data);
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setInitialTipoRemito(dataLabel);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function loadTipoRemito(query = "") {
    if (query) {
      const dataFiltered = initialTipoRemito
        .filter((x: any) =>
          x.label.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.label === value.label)
        );
      setTipoRemito(dataFiltered);
    } else {
      const uniqueData = initialTipoRemito.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
      setTipoRemito(uniqueData);
    }
  }

  async function searchTipoRemito(event: any) {
    await loadTipoRemito(event.query);
  }

  async function getLeyendasRemitos() {
    setLoading(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        setLoading(false);
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/leyendas-remitos",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setInitialLeyendasRemitos(dataLabel);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function loadLeyendasRemitos(query = "") {
    if (query) {
      const dataFiltered = initialLeyendasRemitos
        .filter((x: any) =>
          x.label.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.label === value.label)
        );
      setLeyendasRemitos(dataFiltered);
    } else {
      const uniqueData = initialLeyendasRemitos.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
      setLeyendasRemitos(uniqueData);
    }
  }
  async function searchLeyendasRemitos(event: any) {
    await loadLeyendasRemitos(event.query);
  }

  async function getPagaFlete() {
    setLoading(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        setLoading(false);
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/paga-flete",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setInitialPagaFlete(dataLabel);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function loadPagaFlete(query = "") {
    if (query) {
      const dataFiltered = initialPagaFlete
        .filter((x: any) =>
          x.descripcionFull
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.descripcionFull === value.descripcionFull)
        );
      setPagaFlete(dataFiltered);
    } else {
      const uniqueData = initialPagaFlete.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.descripcionFull === value.descripcionFull)
      );
      setPagaFlete(uniqueData);
    }
  }
  async function searchPagaFlete(event: any) {
    await loadPagaFlete(event.query);
  }

  async function loadNaturalezaFiscal(vin: string, query = "") {
    if (!vin) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Informe um Vin valido",
        life: 3000,
      });
      setNaturalezaFiscal([]);
      return;
    }
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        vin: vin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/natureza-fiscal",
        params
      );
      if (status === 200) {
        if (query) {
          setNaturalezaFiscal(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setNaturalezaFiscal(data);
        }
      }
    } catch (error) {
      setNaturalezaFiscal([]);
      console.error(error);
    }
  }

  async function searchNaturalezaFiscal(event: any) {
    await loadNaturalezaFiscal(remito?.vin, event.query);
  }

  async function getConcesionario() {
    setLoading(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        setLoading(false);
        return;
      }
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/concesionario",
        params
      );
      if (status === 200) {
        // console.log("response getConcesionario", data);
        if (data.length > 0) {
          setInitialConcesionario(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function loadConcesionario(query = "") {
    if (query) {
      const dataFiltered = initialConcesionario
        .filter((x: any) =>
          x.descripcion.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.descripcion === value.descripcion)
        );
      setConcesionario(dataFiltered);
    } else {
      const uniqueData = initialConcesionario.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.descripcion === value.descripcion)
      );
      setConcesionario(uniqueData);
    }
  }

  // async function loadConcesionario(query = "") {
  //   try {
  //     if (concesionario.length === 0) {
  //       setLoading(true);
  //     }
  //     const params = {
  //       pais: {
  //         id: securityFilters.securityValues.country.id,
  //       },
  //       cliente: {
  //         id: securityFilters.securityValues.client.id,
  //       },
  //     };
  //     const { status, data } = await sipcoAxiosService.post(
  //       "/carga-manual-remitos/concesionario",
  //       params
  //     );
  //     if (status === 200) {
  //       if (query) {
  //         setConcesionario(
  //           data.filter((x: any) =>
  //             x.descripcionFull.toLowerCase().includes(query.toLowerCase())
  //           )
  //         );
  //       } else {
  //         setConcesionario(data);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  async function searchConcesionario(event: any) {
    await loadConcesionario(event.query);
  }

  // async function loadDireccion(concesionario: any, query = "") {
  //   try {
  //     if (direccion.length === 0) {
  //       setLoading(true);
  //     }
  //     const params = {
  //       pais: {
  //         id: securityFilters.securityValues.country.id,
  //       },
  //       cliente: {
  //         id: securityFilters.securityValues.client.id,
  //       },
  //       concesionario,
  //     };
  //     const { status, data } = await sipcoAxiosService.post(
  //       "/carga-manual-remitos/direccion-entrega",
  //       params
  //     );
  //     if (status === 200) {
  //       if (query) {
  //         setDireccion(
  //           data.filter((x: any) =>
  //             x.descripcionFull.toLowerCase().includes(query.toLowerCase())
  //           )
  //         );
  //       } else {
  //         setDireccion(data);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async function getDireccion() {
    try {
      setLoading(true);
      if (
        typeof remito?.concesionario !== "object" ||
        remito?.concesionario === null ||
        Object.keys(remito?.concesionario).length === 0
      ) {
        setRemito({
          ...remito,
          direccionEntrega: undefined,
        });
        setLoading(false);
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        concesionario: remito?.concesionario,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/direccion-entrega",
        params
      );
      if (status === 200) {
        // console.log("response getConcesionario", data);
        if (data.length > 0) {
          setInitialDireccion(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function loadDireccion(query = "") {
    if (query) {
      const dataFiltered = initialDireccion
        .filter((x: any) =>
          x.direccion.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.direccion === value.direccion)
        );
      setDireccion(dataFiltered);
    } else {
      const uniqueData = initialDireccion.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.direccion === value.direccion)
      );
      setDireccion(uniqueData);
    }
  }
  async function searchDireccion(event: any) {
    // await loadDireccion(remito?.concesionario, event.query);
    await loadDireccion(event.query);
  }
  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }
  async function loadIngreseElVin(query = "") {
    try {
      const check = checkSecurityFilters();
      if (typeof query !== "string" || query.length < 8 || query.length > 17) {
        setIngreseElVin([]);
        return;
      }

      if (!check) {
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        vin: { id: query },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/vin",
        params
      );
      if (status === 200) {
        if (query) {
          setIngreseElVin(
            data.filter((x: any) =>
              x.id.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setIngreseElVin(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  function showToast(params) {
    const { severity, detail } = params;
    toast.current?.show({
      severity: severity,
      detail: detail,
      life: 3000,
    });
  }
  async function searchRemito() {
    if (!isNew) {
      setLoading(true);
      if (
        remito?.letra === "" ||
        remito?.puntoDeVenta === "" ||
        remito?.numero === ""
      ) {
        showToast({
          severity: "warn",
          detail: <LanguageProvider id={"39"} alt="Existen datos en Blanco." />,
        });
        setLoading(false);
        return;
      }
      const check = checkSecurityFilters();
      if (!check) {
        setLoading(false);
        return;
      }

      try {
        const params = {
          remito: {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            },
            letra: remito?.letra?.toUpperCase(),
            puntoDeVenta: remito?.puntoDeVenta,
            id: remito?.id,
          },
        };
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-remitos/remito",
          params
        );
        if (status === 200) {
          if (data !== "") {
            setRemito(data);
            setRemito((prevRemito) => ({
              ...prevRemito,
              tipoRemito: initialTipoRemito.find(
                (t) => t.id === data?.tipoRemito?.id
              ),
              pagaFlete: initialPagaFlete.find(
                (t) => t.id === data?.pagaFlete?.id
              ),
              leyendaRemito: initialLeyendasRemitos.find(
                (t) => t.id === data?.leyendaRemito?.id
              ),
            }));
            setIsSearch(true);
          } else {
            setIsSearch(false);
            setRemito((preState) => ({
              ...initialStateRemito,
              letra: preState.letra,
              puntoDeVenta: preState.puntoDeVenta,
              numero: preState.numero,
            }));
            showToast({
              severity: "warn",
              detail: (
                <LanguageProvider
                  id={"5818"}
                  alt="No hay Informacion para Listar"
                />
              ),
            });
            setLoading(false);
            return;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  function cancelSearch() {
    setRemito(initialStateRemito);
    setIsSearch(false);
  }

  async function checkValidaLetra() {
    try {
      const params = {
        remito: {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          cuenta: {
            id: securityFilters.securityValues.account.id,
          },
          formulario: SCREEN_CODE,
          fun: "submitForm",
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/configuracion/buscaAccionPaisCuenta",
        params
      );
      if (status === 200) {
        return data ? true : false;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoading(false);
    }
  }

  async function validarAltaRemito() {
    try {
      const payload = {
        remito: {
          ...remito,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/validar-alta",
        payload
      );
      if (status === 200) {
        if (typeof data !== "object") {
          return true;
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Error",
            detail: data?.mensaje,
            life: 3000,
          });
          return false;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function validarAceptarAlta() {
    const { country } = securityFilters.securityValues;
    if (
      typeof remito.vin !== "object" ||
      remito.letra === "" ||
      remito.puntoDeVenta === "" ||
      remito.numero === ""
    ) {
      showToast({
        severity: "warn",
        detail: <LanguageProvider id={"39"} alt="Existen datos en Blanco." />,
      });
      return false;
    }

    if (country?.id === 2) {
      if (!remito.fechaEmision.date) {
        showToast({
          severity: "warn",
          detail: (
            <LanguageProvider
              id={"21415"}
              alt="La fecha de emisión no puede estar vacia."
            />
          ),
        });
        return false;
      }
    }

    if (checkValidaLetra()) {
      if (
        remito.letra.toUpperCase() !== "P" &&
        remito.letra.toUpperCase() !== "C" &&
        remito.letra.toUpperCase() !== "M"
      ) {
        showToast({
          severity: "warn",
          detail: (
            <LanguageProvider id={"12284"} alt="Letra debe ser C, P o M." />
          ),
        });
        return false;
      }
    }
    return true;
  }

  async function onResultFindByPk() {
    try {
      const payload = {
        remito: {
          ...remito,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/remito",
        payload
      );
      if (status === 200) {
        if (typeof data !== "object") {
          return true;
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Error",
            detail: <LanguageProvider id={"1479"} alt="O remessa já existe" />,
            life: 3000,
          });
          return false;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function save() {
    try {
      setAltaLoading(true);
      const payload = {
        remito: {
          ...remito,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          fechaEmision:
            securityFilters.securityValues.country === "2"
              ? remito.fechaEmision
              : null,
        },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/save",
        payload
      );
      if (status === 200) {
        newRemito();
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAltaLoading(false);
    }
  }

  async function aceptarAlta() {
    setAltaLoading(true);
    try {
      if (await validarAceptarAlta()) {
        if (await validarAltaRemito()) {
          if (await onResultFindByPk()) {
            confirmDialog({
              header: <LanguageProvider id={"16239"} alt="Atención" />,
              message: (
                <LanguageProvider
                  id={"13275"}
                  alt="Sua transação se realizou com sucesso."
                />
              ),
              icon: "pi pi-exclamation-triangle",
              defaultFocus: "reject",
              accept: () => save(),
              reject: () => setAltaLoading(false),
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAltaLoading(false);
    }
  }

  async function existeRegistroBy() {
    try {
      const payload = {
        remito: {
          ...remito,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/cod-tipo",
        payload
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function remove() {
    try {
      setBorrarLoading(true);
      const payload = {
        remito: {
          ...remito,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/remove",
        payload
      );
      if (status === 200) {
        if (data === "Remito Removido") {
          cancelSearch();
          toast.current?.show({
            severity: "success",
            detail: (
              <LanguageProvider
                id={"36"}
                alt="Sua transação se realizou com sucesso."
              />
            ),
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBorrarLoading(false);
    }
  }

  async function borrar() {
    try {
      setBorrarLoading(true);
      if (!(await existeRegistroBy())) {
        remove();
      } else {
        confirmDialog({
          header: <LanguageProvider id={"16239"} alt="Atención" />,
          message: (
            <LanguageProvider
              id={"22855"}
              alt="O VIN tem motivos de atraso associados a ele. Deseja excluí-los também?"
            />
          ),
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "reject",
          accept: () => remove(),
          reject: () => setBorrarLoading(false),
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      getConcesionario();
      getTipoRemito();
      getLeyendasRemitos();
      getPagaFlete();
    }
    // console.log("use securityFilters.securityValues.country");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues.country]);

  useEffect(() => {
    if (isNew) {
      if (
        initialConcesionario &&
        initialConcesionario.length > 0 &&
        initialTipoRemito &&
        initialTipoRemito.length > 0 &&
        initialLeyendasRemitos &&
        initialLeyendasRemitos.length > 0 &&
        initialPagaFlete &&
        initialPagaFlete.length > 0
      ) {
        setRemito({
          ...remito,
          concesionario: initialConcesionario[0],
          tipoRemito: initialTipoRemito[0],
          leyendaRemito: initialLeyendasRemitos[0],
          pagaFlete: initialPagaFlete[0],
        });
      }
    }
    // console.log("use isNew");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    if (remito?.vin && typeof remito?.vin === "object") {
      loadNaturalezaFiscal(remito?.vin);

      setRemito({
        ...remito,
        modeloImpresion: remito?.vin?.modelo?.descripcion,
      });
    }
    // console.log("use remito?.vin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remito?.vin]);

  useEffect(() => {
    if (remito?.concesionario && typeof remito?.concesionario === "object") {
      setRemito({
        ...remito,
        cuitImpresion: remito?.concesionario?.cuit,
        codigoPostalImpresion: remito?.concesionario?.codigoPostal,
      });
      if (isNew) {
        getDireccion();
      }
    }
    // console.log("use remito?.concesionario");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remito?.concesionario]);

  useEffect(() => {
    if (initialDireccion.length > 0) {
      setRemito({
        ...remito,
        direccionEntrega: initialDireccion[0],
      });
    }
    // console.log("use direccion");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDireccion]);

  useEffect(() => {
    if (
      remito?.direccionEntrega &&
      typeof remito?.direccionEntrega === "object"
    ) {
      if (initialDireccion.length > 0) {
        setRemito({
          ...remito,
          domicilioImpresion: remito?.direccionEntrega?.direccion,
          provinciaImpresion: remito?.direccionEntrega.provincia?.descripcion,
          localidadImpresion: remito?.direccionEntrega.localidad?.descripcion,
        });
      }
    }
    // console.log("use remito?.direccionEntrega");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remito?.direccionEntrega]);

  useEffect(() => {
    if (!isNew && naturalezaFiscal.length > 0) {
      setRemito({
        ...remito,
        naturalezaFiscal: naturalezaFiscal.find(
          (t) => t.id === remito?.naturalezaFiscal?.id
        ),
      });
    }
  }, [naturalezaFiscal]);

  // console.log("remito => ", remito);
  // console.log("remito?.cuitImpresion => ", remito?.cuitImpresion);
  // console.log(
  //   "remito?.direccionEntrega.provincia.descripcion, => ",
  //   remito?.direccionEntrega?.provincia?.descripcion
  // );

  return (
    <div className="carga-manual-remitos">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset
        legend={<LanguageProvider id={"1478"} alt="Carga Manual de Remitos" />}
      >
        <div className="fieldset-content">
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <SipcoInputText
                  value={remito?.letra}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      letra: e.target.value.toUpperCase(),
                    })
                  }
                  keyfilter="alpha"
                  maxLength={1}
                  invalid={remito?.letra === ""}
                  disabled={isSearch}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <SipcoInputText
                  value={remito?.puntoDeVenta}
                  onChange={(e) =>
                    setRemito({ ...remito, puntoDeVenta: e.target.value })
                  }
                  keyfilter="int"
                  maxLength={4}
                  invalid={remito?.puntoDeVenta === ""}
                  disabled={isSearch}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <SipcoInputText
                  value={remito?.id}
                  onChange={(e) => setRemito({ ...remito, id: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchRemito();
                    }
                  }}
                  keyfilter="int"
                  maxLength={8}
                  invalid={remito?.id === ""}
                  disabled={isSearch}
                />
              </div>

              {!isNew && (
                <div className={"mt-auto"}>
                  {isSearch ? (
                    <Button
                      onClick={cancelSearch}
                      icon="pi pi-times"
                      className="p-button-danger"
                    />
                  ) : (
                    <Button
                      onClick={searchRemito}
                      loading={loading}
                      icon="pi pi-search"
                    />
                  )}
                </div>
              )}

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={remito?.vin}
                  suggestions={ingreseElVin}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) => setRemito({ ...remito, vin: e.value })}
                  field="id"
                  dropdown
                  maxLength={17}
                  forceSelection
                  disabled={isDisabled}
                  invalid={
                    !!(
                      isNew &&
                      (remito?.vin === "" || remito?.vin === undefined)
                    )
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.concesionario}
                  suggestions={concesionario}
                  completeMethod={searchConcesionario}
                  onChange={(e) =>
                    setRemito({ ...remito, concesionario: e.value })
                  }
                  field="descripcion"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"4216"} alt="Boca de Entrega" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.direccionEntrega}
                  suggestions={direccion}
                  completeMethod={searchDireccion}
                  onChange={(e) =>
                    setRemito({ ...remito, direccionEntrega: e.value })
                  }
                  field="direccion"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1617"} alt="Tipo remito" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.tipoRemito}
                  suggestions={tipoRemito}
                  completeMethod={searchTipoRemito}
                  onChange={(e) =>
                    setRemito({ ...remito, tipoRemito: e.value })
                  }
                  field="descripcionFull"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16757"} alt="Leyenda" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.leyendaRemito}
                  suggestions={leyendasRemitos}
                  completeMethod={searchLeyendasRemitos}
                  onChange={(e) =>
                    setRemito({ ...remito, leyendaRemito: e.value })
                  }
                  field="descripcionFull"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16923"} alt="Cód.Pedido Cliente" />
                </label>
                <SipcoInputText
                  value={remito?.idPedidoCliente}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      codigoPedidoDonde: e.target.value,
                    })
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"7888"} alt="Nro. CAI" />
                </label>
                <SipcoInputText
                  value={remito?.caiImpresion}
                  onChange={(e) =>
                    setRemito({ ...remito, caiImpresion: e.target.value })
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1492"} alt="Fecha Emisión" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaEmision?.date &&
                    new Date(remito?.fechaEmision.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaEmision: { date: e.value } })
                  }
                  showButtonBar
                  showIcon
                  disabled={
                    securityFilters.securityValues.country === "2" || isDisabled
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"382"} alt="Despacho" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaDespacho?.date &&
                    new Date(remito?.fechaDespacho.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaDespacho: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"295"} alt="Retorno" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaRetorno?.date &&
                    new Date(remito?.fechaRetorno?.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaReingreso: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1285"} alt="Chegada" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaLlegada?.date &&
                    new Date(remito?.fechaLlegada.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaLlegada: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13710"} alt="Reingreso" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaReingreso?.date &&
                    new Date(remito?.fechaReingreso.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaReingreso: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16924"} alt="Vence CAI" />
                </label>
                <SipcoInputText
                  value={remito?.fechaVencimientoCaiImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      fechaVencimientoCaiImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"14683"} alt="Fecha Anulación" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaAnulacion?.date &&
                    new Date(remito?.fechaAnulacion.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaAnulacion: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16925"} alt="Fec. Ant. Retorno" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaAnticipoRetorno?.date &&
                    new Date(remito?.fechaAnticipoRetorno.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaAnticipoRetorno: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"9712"} alt="Fecha de Control" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaControl?.date &&
                    new Date(remito?.fechaControl?.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaControl: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"21503"} alt="Liberación" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaLiberacion?.date &&
                    new Date(remito?.fechaLiberacion.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaLiberacion: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16926"} alt="Fecha carga retorno" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaCargaRetorno?.date &&
                    new Date(remito?.fechaCargaRetorno.date)
                  }
                  onChange={(e) =>
                    setRemito({ ...remito, fechaCargaRetorno: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="16927" alt="Actualizó fecha" />
                </label>
                <SipcoCalendar
                  dateFormat="dd/mm/yy"
                  value={
                    remito?.fechaUltimaModificacion?.date &&
                    new Date(remito?.fechaUltimaModificacion?.date)
                  }
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      fechaUltimaModificacion: e.value,
                    })
                  }
                  showButtonBar
                  showIcon
                  readOnlyInput
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3591"} alt="Razón Social" />
                </label>
                <SipcoInputText
                  value={remito?.razonSocial}
                  onChange={(e) =>
                    setRemito({ ...remito, razonSocial: e.target.value })
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11178"} alt="CUIT" />
                </label>
                <SipcoInputText
                  value={remito?.cuitImpresion}
                  onChange={(e) =>
                    setRemito({ ...remito, cuitImpresion: e.target.value })
                  }
                  disabled
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="22216" alt="IVA" />
                </label>
                <SipcoInputText
                  value={remito?.ivaImpresion}
                  onChange={(e) =>
                    setRemito({ ...remito, iva: e.target.value })
                  }
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"8299"} alt="Domicilio" />
                </label>
                <SipcoInputText
                  value={remito?.domicilioImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      domicilioImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"159"} alt="Provincia" />
                </label>
                <SipcoInputText
                  value={remito?.provinciaImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      provinciaImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="11176" alt="Código Postal" />
                </label>
                <SipcoInputText
                  value={remito?.codigoPostalImpresion}
                  readOnly
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      codigoPostalImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16928"} alt="Modelo a imprimir" />
                </label>
                <SipcoInputText
                  value={remito?.modeloImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      modeloImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3186"} alt="Localidad" />
                </label>
                <SipcoInputText
                  value={remito?.localidadImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      localidadImpresion: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"9387"} alt="Estado del remito" />
                </label>
                <SipcoInputText
                  value={remito?.estadoRemito?.id}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      estadoRemito: {
                        ...remito.estadoRemito,
                        id: e.target.value,
                      },
                    })
                  }
                  disabled
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10855"} alt="Paga Flete" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.pagaFlete}
                  suggestions={pagaFlete}
                  completeMethod={searchPagaFlete}
                  onChange={(e) => setRemito({ ...remito, pagaFlete: e.value })}
                  field="descripcionFull"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10769"} alt="Naturaleza Fiscal" />
                </label>
                <SipcoAutocomplete
                  forceSelection
                  value={remito?.naturalezaFiscal}
                  suggestions={naturalezaFiscal}
                  completeMethod={searchNaturalezaFiscal}
                  onChange={(e) =>
                    setRemito({ ...remito, naturalezaFiscal: e.value })
                  }
                  field="descripcionFull"
                  disabled={isDisabled}
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"32"} alt="Valor" />
                </label>
                <SipcoInputText
                  value={remito?.valor}
                  onChange={(e) =>
                    setRemito({ ...remito, valor: e.target.value })
                  }
                  maxLength={8}
                  keyfilter="int"
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4974"} alt="Comentarios" />
                </label>
                <SipcoInputTextArea
                  autoResize
                  rows={5}
                  cols={30}
                  value={remito?.comentarioImpresion}
                  onChange={(e) =>
                    setRemito({
                      ...remito,
                      comentarioImpresion: e.target.value,
                    })
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"22446"} alt="Ficticio" />
                </label>
                <Checkbox
                  checked={remito?.ficticio}
                  onChange={(e) =>
                    setRemito({ ...remito, ficticio: e.checked })
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-table mt-5">
          {isNew ? (
            <div className="flex gap-2">
              <Button
                label={LanguageProvider({
                  id: "9491",
                  alt: "Aceptar",
                })}
                onClick={aceptarAlta}
                loading={altaLoading}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "3155",
                  alt: "Cancelar",
                })}
                onClick={newRemito}
                text
              />
            </div>
          ) : (
            <div className="flex gap-2">
              <Button
                label={LanguageProvider({
                  id: "25",
                  alt: "Alta",
                })}
                onClick={newRemito}
              />
              <Button
                label={LanguageProvider({
                  id: "27",
                  alt: "Modificar",
                })}
                disabled={true}
              />
              <Button
                label={LanguageProvider({
                  id: "21880",
                  alt: "Borrar",
                })}
                disabled={!isSearch}
                loading={borrarLoading}
                onClick={borrar}
              />
              <Button
                label={LanguageProvider({
                  id: "13334",
                  alt: "Cargar Vines de PEUGEOT CHILE con archivo Excel",
                })}
                disabled={true}
              />
              <Button
                label={LanguageProvider({
                  id: "15076",
                  alt: "Cargar Remitos MACO CHILE desde archivo excel.",
                })}
                disabled={true}
              />
              <Button
                label={LanguageProvider({
                  id: "17526",
                  alt: "Genérico",
                })}
              />
            </div>
          )}
        </div>
      </Fieldset>
    </div>
  );
}
export default CargaManualRemitos;
