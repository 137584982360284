import "./VehiculosTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function VehiculosTaller() {
  const SCREEN_CODE = "con0035_vehiculos_en_taller";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);

  const filterLabelId = [327, 467, 468, 1151];
  const filterNames = [];
  const filterColumnsId = [
    16478, 1491, 396, 2139, 4724, 2140, 16479, 3625, 1317, 2205, 2245,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 327, 396, 467, 468, 1002, 1151, 1182, 1317, 1491, 1791, 2139, 2140,
    2205, 2245, 3625, 4724, 4791, 5044, 5583, 5627, 5628, 16478, 16479, 16531,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    estado: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [talleres, setTalleres] = useState([]);
  const [loadingTalleres, setLoadingTalleres] = useState(false);
  async function loadTalleres() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account &&
        securityFilters?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "vehiculos-taller/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }
  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "vehiculos-taller/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
            activo: true,
          });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  const [reportData, setReportData] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        codTaller: filter?.taller?.id || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        estado: filter?.estado?.id || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/vehiculos-taller/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const [estados, talleres] = await Promise.all([
          loadEstados(),
          loadTalleres(),
        ]);
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
          fechaDesde: new Date(),
          fechaHasta: new Date(),
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const formattedReportData = convertDateObjects(reportData?.filas);

  return (
    <div className="vehiculos-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"18487"} alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={talleres}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                  filter
                  loading={loadingTalleres}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoDropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estado: e.value })}
                  optionLabel="descripcion"
                  showClear
                  filter
                  loading={loadingEstados}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"2375"} alt="Vehículos en Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Vehiculos Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
          <SipcoColumn field="7" header={columnsNames[7].label} />
          <SipcoColumn field="8" header={columnsNames[8].label} />
          <SipcoColumn field="9" header={columnsNames[9].label} />
          <SipcoColumn field="10" header={columnsNames[10].label} />
        </SipcoDataTable>
        <div className="tag">
          <Tag
            style={{ background: "red" }}
            value={<LanguageProvider id={"5627"} alt="Saneamiento 6 Meses" />}
          />
          <Tag
            style={{ background: "green" }}
            value={<LanguageProvider id={"5628"} alt="Saneamiento 12 Meses" />}
          />
        </div>
      </Panel>
    </div>
  );
}
export default VehiculosTaller;
