import { Calendar, CalendarProps } from "primereact/calendar";

import React from "react";

type SipcoCalendarProps = CalendarProps;

const SipcoCalendar: React.FC<SipcoCalendarProps> = ({ ...props }) => {
  return <Calendar className="sipco-calendar" showIcon {...props} />;
};

export default SipcoCalendar;
